import { TemperatureUnit } from '@energybox/react-ui-library/dist/types';
import { getUserPreferenceTemperatureUnit } from '@energybox/react-ui-library/dist/utils/temperature';
import { useState, useEffect } from 'react';
import useCurrentUser from '../useCurrentUser';

export const useTemperatureChartYRange = () => {
  const currentUser = useCurrentUser();
  const [minYData, setMinYData] = useState(0);
  const [maxYData, setMaxYData] = useState(100);
  useEffect(() => {
    if (!currentUser) return;
    const temperatureUnit = getUserPreferenceTemperatureUnit(currentUser);
    switch (temperatureUnit) {
      case TemperatureUnit.C:
        setMinYData(0);
        setMaxYData(100);
        break;
      case TemperatureUnit.F:
        setMinYData(32);
        setMaxYData(212);
        break;
    }
  }, [currentUser]);
  return {
    minYData,
    maxYData,
    setMinYData,
    setMaxYData,
  };
};
