import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import thunk from 'redux-thunk';
import rootReducer from './reducers';

import apiMiddleware from './middleware/apiMiddleware';
import authMiddleware from './middleware/authMiddleware';
import dashboardMiddleware from './middleware/dashboardMiddleware';
import gaMiddleware from './middleware/gaMiddleware';
import streamApiMiddleware from './middleware/streamApiMiddleware';
import redirectMiddleware from './middleware/redirectMiddleware';
import { loadState } from './middleware/localStorage';

import history from './history';

import type { PreloadedState, Middleware } from '@reduxjs/toolkit';
import ApplicationState from './reducers';
import mixpanelMiddleware from './middleware/mixpanelMiddleware';

interface Window {
  [key: string]: any;
}

export interface Action {
  type: string;
  payload: any;
}

const middleware: Middleware[] = [thunk];
let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger');
  const w: Window = window;
  composeEnhancers =
    typeof w !== 'undefined' && w['__REDUX_DEVTOOLS_EXTENSION__']
      ? w['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({})
      : compose;

  middleware.push(logger);
}

export const configureStore = (
  preloadedState?: PreloadedState<typeof ApplicationState>
) =>
  createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        ...middleware,
        routerMiddleware(history),
        gaMiddleware,
        mixpanelMiddleware,
        apiMiddleware,
        authMiddleware,
        streamApiMiddleware,
        dashboardMiddleware,
        redirectMiddleware
      )
    )
  );

const store = configureStore(loadState());

export default store;
