import * as R from 'ramda';
import { Gateway, ControlBoard } from '@energybox/react-ui-library/dist/types';
import { Actions } from '../actions/gateways';

export type GatewaysByNetworkGroupId = {
  [networkGroupId: string]: {
    isLoading: boolean;
    data: (Gateway | ControlBoard)[];
  };
};

export interface Gateways {
  gatewaysByNetworkGroupId: GatewaysByNetworkGroupId;
}

export const initialState = {
  gatewaysByNetworkGroupId: {},
};

const gateways = (state: Gateways = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_GATEWAYS_BY_NETWORK_GROUP_ID_LOADING:
      return R.pipe(
        R.assocPath(
          ['gatewaysByNetworkGroupId', action.networkGroupId, 'isLoading'],
          true
        )
      )(state);
    case Actions.GET_GATEWAYS_BY_NETWORK_GROUP_ID_ERROR:
    case Actions.GET_GATEWAYS_BY_NETWORK_GROUP_ID_SUCCESS:
      return R.pipe(
        R.assocPath(['gatewaysByNetworkGroupId', action.networkGroupId], {
          isLoading: false,
          data: action.payload,
        })
      )(state);

    default:
      return state;
  }
};

export default gateways;
