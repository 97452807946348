import { SubscribedControlStatusById } from '@energybox/react-ui-library/dist/types/SubscribedControlStatus';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions as StreamActions } from '../actions/streamApi';

const initialState = {
  byCamId: {},
};

const subscribedControlStatus = (
  state: SubscribedControlStatusById = initialState,
  action: any
) => {
  switch (action.type) {
    case StreamActions.RECEIVED_DEVICE_STATUS: {
      if (
        isDefined(action.payload) &&
        isDefined(action.payload.deviceType) &&
        action.payload.deviceType === 'control' &&
        isDefined(action.payload.status) &&
        isDefined(action.payload.status.workingMode)
      ) {
        const controlStatus = {
          id: action.payload.id,
          uuid: action.payload.uuid,
          updatedAt: action.payload.status.updatedAt,
          timestamp: action.payload.status.timestamp,
          unixTimestamp: new Date(action.payload.status.timestamp).getTime(),
          healthiness: action.payload.status.healthiness,
          workingMode: action.payload.status.workingMode,
        };
        return pipe(
          assocPath(['byCamId', action.payload.id.toString()], controlStatus)
        )(state);
      }

      return state;
    }

    default:
      return state;
  }
};

export default subscribedControlStatus;
