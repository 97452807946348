import { Actions } from '../actions/userGroups';
import * as R from 'ramda';
import { mapValues, mapArrayToObject } from '../util';
import { UserGroup } from '@energybox/react-ui-library/dist/types';
import { mapScopeToAccessResource } from '@energybox/react-ui-library/dist/utils';

export type UserGroupsById = {
  [id: string]: UserGroup;
};

export interface UserGroups {
  userGroupsById: UserGroupsById;
}

function userGroupFromApiResponse(data: any): UserGroup {
  return {
    id: data.id,
    title: data.title,
    description: data.description,
    users: data.users.map((u) => ({
      id: u.id,
      firstName: u.firstName,
      lastName: u.lastName,
    })),
    accessResources: data.scopes.map(mapScopeToAccessResource),
    region: data.region,
  };
}

export const initialState = {
  userGroupsById: {},
};

export const userGroups = (state: UserGroups = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_USER_GROUPS_SUCCESS:
      return R.pipe(
        R.assoc(
          'userGroupsById',
          R.mergeRight(
            R.view(R.lensProp('userGroupsById'), state),
            mapArrayToObject(
              mapValues(action.payload, userGroupFromApiResponse)
            )
          )
        )
      )(state);

    default:
      return state;
  }
};

export default userGroups;
