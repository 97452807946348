import {
  EnergyApiFilter,
  NormalizedTSEquipmentRecentPower,
  TimeSeriesAggregationLevel,
} from '@energybox/react-ui-library/dist/types';
import {
  formatDateForTimeSeriesApi,
  normalizeTSFetchRecentPowerByEquipment,
} from '@energybox/react-ui-library/dist/utils';
import { Service } from '../config';
import { fetchApi } from '../utils/fetchApi';

export enum Actions {
  TS_ENERGY_BY_EQUIPMENT_ID_SUCCESS = '@@TS_energy/TS_ENERGY_BY_EQUIPMENT_ID_SUCCESS',
  TS_ENERGY_BY_EQUIPMENT_ID_LOADING = '@@TS_energy/TS_ENERGY_BY_EQUIPMENT_ID_LOADING',
  TS_ENERGY_BY_EQUIPMENT_ID_ERROR = '@@TS_energy/TS_ENERGY_BY_EQUIPMENT_ID_ERROR',

  TS_ENERGY_BY_EQUIPMENT_GROUP_ID_SUCCESS = '@@TS_energy/TS_ENERGY_BY_EQUIPMENT_GROUP_ID_SUCCESS',
  TS_ENERGY_BY_EQUIPMENT_GROUP_ID_LOADING = '@@TS_energy/TS_ENERGY_BY_EQUIPMENT_GROUP_ID_LOADING',
  TS_ENERGY_BY_EQUIPMENT_GROUP_ID_ERROR = '@@TS_energy/TS_ENERGY_BY_EQUIPMENT_GROUP_ID_ERROR',

  TS_ENERGY_BY_EQUIPMENT_TYPE_ID_SUCCESS = '@@TS_energy/TS_ENERGY_BY_EQUIPMENT_TYPE_ID_SUCCESS',
  TS_ENERGY_BY_EQUIPMENT_TYPE_ID_LOADING = '@@TS_energy/TS_ENERGY_BY_EQUIPMENT_TYPE_ID_LOADING',
  TS_ENERGY_BY_EQUIPMENT_TYPE_ID_ERROR = '@@TS_energy/TS_ENERGY_BY_EQUIPMENT_TYPE_ID_ERROR',

  TS_GET_EQUIPMENT_POWER_BY_SITE_ID_LOADING = '@@energy/TS_GET_EQUIPMENT_POWER_BY_SITE_ID_LOADING',
  TS_GET_EQUIPMENT_POWER_BY_SITE_ID_ERROR = '@@energy/TS_GET_EQUIPMENT_POWER_BY_SITE_ID_ERROR',
  TS_GET_EQUIPMENT_POWER_BY_SITE_ID_SUCCESS = '@@energy/TS_GET_EQUIPMENT_POWER_BY_SITE_ID_SUCCESS',

  TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING = '@@energy/TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING',
  TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_ERROR = '@@energy/TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_ERROR',
  TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS = '@@energy/TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS',

  TS_GET_SITE_ENERGY_CONSUMPTION_LOADING = '@@energy/TS_GET_SITE_ENERGY_CONSUMPTION_LOADING',
  TS_GET_SITE_ENERGY_CONSUMPTION_ERROR = '@@energy/TS_GET_SITE_ENERGY_CONSUMPTION_ERROR',
  TS_GET_SITE_ENERGY_CONSUMPTION_SUCCESS = '@@energy/TS_GET_SITE_ENERGY_CONSUMPTION_SUCCESS',

  TS_GET_ENERGY_BY_SITE_ID_LOADING = '@@energy/TS_GET_ENERGY_BY_SITE_ID_LOADING',
  TS_GET_ENERGY_BY_SITE_ID_SUCCESS = '@@energy/TS_GET_ENERGY_BY_SITE_ID_SUCCESS',
  TS_GET_ENERGY_BY_SITE_ID_ERROR = '@@energy/TS_GET_ENERGY_BY_SITE_ID_ERROR',
}

const baseUrl = '/energy';

export const setEnergyFilter = (filter?: EnergyApiFilter): string => {
  const queryParams = new URLSearchParams();

  //filter by equipmentId
  if (filter && filter.id) {
    queryParams.set('id', filter.id.toString());
  }

  if (filter && filter.from) {
    queryParams.set('from', filter.from.toString());
  }

  if (filter && filter.to) {
    queryParams.set('to', filter.to.toString());
  }

  if (filter && filter.aggregationLevel) {
    queryParams.set('agg', filter.aggregationLevel.toString());
  }

  if (filter && filter.seeSpilloverPoint) {
    queryParams.set('seeSpilloverPoint', filter.seeSpilloverPoint.toString());
  }

  return queryParams.toString();
};

//IMPORTANT: all from/to time queryParams for these functions
//expected to be initially epoch timestamp (in seconds),
//must convert to ISO 8601 format for TS API

const convertQueryParamsToTsApiFormat = (queryParams: EnergyApiFilter) => {
  const updatedQueryParams = { ...queryParams };

  Object.keys(queryParams).forEach((param) => {
    const paramValue = queryParams[param];
    if (param === 'to' || param === 'from') {
      const dateFormat = new Date(paramValue * 1000);
      updatedQueryParams[param] = formatDateForTimeSeriesApi(dateFormat);
    } else if (param === 'aggregationLevel') {
      updatedQueryParams[param] = TimeSeriesAggregationLevel[paramValue];
    } else {
      updatedQueryParams[param] = queryParams[param];
    }
  });

  return updatedQueryParams;
};

export const getTSEnergyByEquipmentId = (
  equipmentId: number | string,
  queryParams: EnergyApiFilter,
  notificationId?: number | string,
  idAffix?: string
) => ({
  type: 'API_GET',
  service: Service.timeSeries,
  path: `${baseUrl}/equipment/${equipmentId}/series?${setEnergyFilter(
    convertQueryParamsToTsApiFormat(queryParams)
  )}`,
  loading: {
    type: Actions.TS_ENERGY_BY_EQUIPMENT_ID_LOADING,
    equipmentId: idAffix ? equipmentId + idAffix : equipmentId,
    notificationId,
  },
  success: {
    type: Actions.TS_ENERGY_BY_EQUIPMENT_ID_SUCCESS,
    equipmentId: idAffix ? equipmentId + idAffix : equipmentId,
    notificationId,
  },
  error: {
    type: Actions.TS_ENERGY_BY_EQUIPMENT_ID_ERROR,
    equipmentId: idAffix ? equipmentId + idAffix : equipmentId,
    notificationId,
  },
});

export const getTSEnergyByEquipmentGroupId = (params: {
  siteId: number | string;
  groupId: number | string;
  queryParams: EnergyApiFilter;
  idAffix?: string;
}) => ({
  type: 'API_GET',
  service: Service.timeSeries,
  path: `${baseUrl}/site/${params.siteId}/equipment-group/${
    params.groupId
  }/series?${setEnergyFilter(
    convertQueryParamsToTsApiFormat(params.queryParams)
  )}`,
  loading: {
    type: Actions.TS_ENERGY_BY_EQUIPMENT_GROUP_ID_LOADING,
    groupId: params.idAffix ? params.groupId + params.idAffix : params.groupId,
  },
  success: {
    type: Actions.TS_ENERGY_BY_EQUIPMENT_GROUP_ID_SUCCESS,
    groupId: params.idAffix ? params.groupId + params.idAffix : params.groupId,
  },
  error: {
    type: Actions.TS_ENERGY_BY_EQUIPMENT_GROUP_ID_ERROR,
    groupId: params.idAffix ? params.groupId + params.idAffix : params.groupId,
  },
});

export const getTSEnergyByEquipmentTypeId = (params: {
  siteId: number | string;
  typeId: number | string;
  queryParams: EnergyApiFilter;
  idAffix?: string;
}) => ({
  type: 'API_GET',
  service: Service.timeSeries,
  path: `${baseUrl}/site/${params.siteId}/equipment-type/${
    params.typeId
  }/series?${setEnergyFilter(
    convertQueryParamsToTsApiFormat(params.queryParams)
  )}`,
  loading: {
    type: Actions.TS_ENERGY_BY_EQUIPMENT_TYPE_ID_LOADING,
    typeId: params.idAffix ? params.typeId + params.idAffix : params.typeId,
  },
  success: {
    type: Actions.TS_ENERGY_BY_EQUIPMENT_TYPE_ID_SUCCESS,
    typeId: params.idAffix ? params.typeId + params.idAffix : params.typeId,
  },
  error: {
    type: Actions.TS_ENERGY_BY_EQUIPMENT_TYPE_ID_ERROR,
    typeId: params.idAffix ? params.typeId + params.idAffix : params.typeId,
  },
});

export const getTSEnergyBySiteId = (params: {
  siteId: number | string;
  queryParams: EnergyApiFilter;
}) => ({
  type: 'API_GET',
  service: Service.timeSeries,
  path: `${baseUrl}/site/${params.siteId}/series?${setEnergyFilter(
    convertQueryParamsToTsApiFormat(params.queryParams)
  )}`,
  loading: {
    type: Actions.TS_GET_ENERGY_BY_SITE_ID_LOADING,
    siteId: params.siteId,
  },
  success: {
    type: Actions.TS_GET_ENERGY_BY_SITE_ID_SUCCESS,
    siteId: params.siteId,
  },
  error: {
    type: Actions.TS_GET_ENERGY_BY_SITE_ID_ERROR,
    siteId: params.siteId,
  },
});

export const fetchTSRecentPowerByEquipment = (
  equipmentId: number
): Promise<NormalizedTSEquipmentRecentPower> =>
  fetchApi({
    service: Service.timeSeries,
    endpoint: `${baseUrl}/equipment/${equipmentId}/recent-power`,
  }).then((data) => {
    return normalizeTSFetchRecentPowerByEquipment(data);
  });

export const getTSEnergyDashboardBySiteId = (
  siteId: string | number,
  queryParams: EnergyApiFilter
) => ({
  type: 'API_GET',
  service: Service.timeSeries,
  path: `${baseUrl}/site/${siteId}/dashboard?${setEnergyFilter(
    convertQueryParamsToTsApiFormat(queryParams)
  )}`,
  loading: {
    type: Actions.TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING,
    siteId,
  },
  success: {
    type: Actions.TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_ERROR,
    siteId,
  },
});

export const getTSEquipmentEnergyPowerBySiteId = (siteId: number) => ({
  type: 'API_GET',
  service: Service.timeSeries,
  path: `${baseUrl}/site/${siteId}/equipment/recent-power`,
  loading: { type: Actions.TS_GET_EQUIPMENT_POWER_BY_SITE_ID_LOADING, siteId },
  success: { type: Actions.TS_GET_EQUIPMENT_POWER_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.TS_GET_EQUIPMENT_POWER_BY_SITE_ID_ERROR, siteId },
});

export const getTSSiteEnergyConsumption = (
  siteId: string | number,
  queryParams: EnergyApiFilter,
  key: string
) => ({
  type: 'API_GET',
  service: Service.timeSeries,
  path: `${baseUrl}/site/${siteId}/consumption?${setEnergyFilter(
    convertQueryParamsToTsApiFormat(queryParams)
  )}`,
  loading: {
    type: Actions.TS_GET_SITE_ENERGY_CONSUMPTION_LOADING,
    siteId,
    key,
  },
  success: {
    type: Actions.TS_GET_SITE_ENERGY_CONSUMPTION_SUCCESS,
    siteId,
    key,
  },
  error: { type: Actions.TS_GET_SITE_ENERGY_CONSUMPTION_ERROR, siteId, key },
});
