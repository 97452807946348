import { Tooltip } from '@energybox/react-ui-library/dist/components';
import {
  classNames,
  shortenString,
} from '@energybox/react-ui-library/dist/utils';
import React from 'react';

interface Props {
  maxStringLength: number;
  content: string;
  arrowDirection?: 'top' | 'left' | 'bottom' | 'right' | 'closeBottom';
  extraClassNames?: string[];
  tooltipTextClassName?: string;
  disableTooltipAutoAdjust?: boolean;
}

const ShortenedSpan = ({
  maxStringLength,
  content,
  arrowDirection,
  tooltipTextClassName,
  extraClassNames = [],
  disableTooltipAutoAdjust,
}: Props) => {
  const shortenedContent = shortenString(content, maxStringLength);
  if (shortenedContent === content) {
    return <span className={classNames(...extraClassNames)}>{content}</span>;
  } else {
    return (
      <Tooltip
        content={content}
        arrowDirection={arrowDirection}
        extraClassNames={extraClassNames}
        tooltipTextClassName={tooltipTextClassName}
        disableAutoAdjust={disableTooltipAutoAdjust}
      >
        <span>{shortenedContent}</span>
      </Tooltip>
    );
  }
};

export default ShortenedSpan;
