export enum Actions {
  PATH_LOADING = '@@resources/PATH_LOADING',
  PATH_SUCCESS = '@@resources/PATH_SUCCESS',
  PATH_ERROR = '@@resources/PATH_ERROR',

  PATH_TINY_LOADING = '@@resources/PATH_TINY_LOADING',
  PATH_TINY_SUCCESS = '@@resources/PATH_TINY_SUCCESS',
  PATH_TINY_ERROR = '@@resources/PATH_TINY_ERROR',

  CLEAR_RESOURCE_PATH = '@@resources/CLEAR_RESOURCE_PATHS',
}

export const getResourcePathById = (resourceId: number) => ({
  type: 'API_GET',
  path: `/api/v1/resources/${resourceId}/path`,
  loading: { type: Actions.PATH_LOADING, resourceId },
  success: { type: Actions.PATH_SUCCESS, resourceId },
  error: { type: Actions.PATH_ERROR, resourceId },
});

export const getResourcePathTinyById = (resourceId: number) => ({
  type: 'API_GET',
  path: `/api/v1/resources/${resourceId}/path-tiny`,
  loading: { type: Actions.PATH_TINY_LOADING, resourceId },
  success: { type: Actions.PATH_TINY_SUCCESS, resourceId },
  error: { type: Actions.PATH_TINY_ERROR, resourceId },
});

export const validateResourcePath = (resourcePathsById, resource: any) => {
  const id =
    typeof resource === 'number' || typeof resource === 'string'
      ? resource
      : resource.id;
  const path = resourcePathsById[id];
  if (path && path.length) {
    return path;
  } else {
    return false;
  }
};

export const clearResourcePath = (id: number) => ({
  type: Actions.CLEAR_RESOURCE_PATH,
  id,
});
