import {
  CommentType,
  CommentTypeLabel,
  Locale,
  TemperatureUnit,
  ThermostatWorkingMode,
  WorkingMode,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  determineTimeStringByTimezone,
  global,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import parseISO from 'date-fns/parseISO';
import React from 'react';
import useCurrentUser from '../../hooks/useCurrentUser';
import { ThermostatActivityProcessedData } from '../EquipmentPageCharts/EquipmentThermostatChart/EquipmentThermostatChart';
import styles from './ThermostatActivityChartTooltip.module.css';
import { getVenstarEdgeApp2dot4DeploymentDate } from '../../config';
import { skipDataBasedOnTime } from '../../util';

type Payload = {
  color: string;
  dataKey: string;
  fill: string;
  fillOpacity: string;
  formatter: (value: number) => string;
  name: string;
  payload?: ThermostatActivityProcessedData;
  stroke: string;
  type?: string;
  unit?: string;
  value: number | null;
  comment?: string;
  commentType?: CommentType;
  commentValue?: number;
};

type Props = {
  locale: Locale;
  label: number;
  payload: Payload[];
  isBarChartShowing?: boolean;
  setChartMouseoverTime: React.Dispatch<React.SetStateAction<number>>;
  ianaTimeZoneCode?: string;
  unit?: string;
  isVenstarHeatpump?: boolean;
};

const ThermostatActivityChartTooltip: React.FC<Props> = ({
  ianaTimeZoneCode,
  locale,
  payload,
  label,
  isBarChartShowing,
  setChartMouseoverTime,
  unit,
  isVenstarHeatpump,
}) => {
  const currentUser = useCurrentUser();
  //BUG: Something is screwy about this when using barchart as well
  // Normal behavior is it always resolves to a point, with barchart it resolves only when it hits points on barchart
  if (!currentUser || !payload || !payload[0] || !payload[0].payload)
    return null;
  const dataPayload = payload[0].payload;
  const edgeApp2dot4Date = getVenstarEdgeApp2dot4DeploymentDate();
  const {
    online,
    temperature,
    coolSetPoint,
    heatSetPoint,
    temperatureUnit,
    mode,
    isLocalOverrideActive,
    overrideForcedOffValue,
    workingMode,
    overrideForcedOnValue,
    coolingStage1Running,
    coolingStage2Running,
    heatingStage1Running,
    heatingStage2Running,
    fanRunning,
    localAdjustmentOverrideEnergybox,
    timestamp,
  } = dataPayload;

  const renderCommentTooltips = ({ payload }) => {
    const { from, commentValue, commentType, comment, username, createdAt } =
      payload;
    const valueTimeString = determineTimeStringByTimezone(
      parseISO(from),
      locale.fullDateTimeFormat,
      ianaTimeZoneCode,
      true
    );
    const createdAtTimeString = determineTimeStringByTimezone(
      parseISO(createdAt),
      locale.fullDateTimeFormat,
      ianaTimeZoneCode,
      true
    );
    return (
      <div className={styles.root}>
        <div className={styles.commentWrapper}>
          <div className={classNames(styles.secondaryText, styles.italics)}>
            {valueTimeString}
          </div>
          <div className={styles.commentValue}>{`${commentValue} ${unit}`}</div>

          <div className={styles.commentTextContainer}>
            <div className={styles.commentType}>
              <strong>{CommentTypeLabel[commentType]}</strong>
            </div>
            <div className={styles.commentText}>{comment}</div>
            <div className={classNames(styles.secondaryText, styles.italics)}>
              {username ? username : global.NOT_AVAILABLE} commented on{' '}
              {createdAtTimeString}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const isImperial = temperatureUnit === TemperatureUnit.F;

  // TODO: This is causing major lag, we need to find a better way to
  // communicate the cursor position to the other chart involved.
  if (isBarChartShowing) setChartMouseoverTime(label);

  if (online === false) {
    return (
      <div className={styles.root}>
        <div className={classNames(styles.secondaryText, styles.italics)}>
          {determineTimeStringByTimezone(
            new Date(label),
            locale.fullDateTimeFormat,
            ianaTimeZoneCode,
            true
          )}
        </div>
        <div className={classNames(styles.offlineText, styles.tooltipLine)}>
          Offline
        </div>
      </div>
    );
  }

  let coolingSetPointString: string, heatingSetPointString: string;
  // These values have been converted in useThermoStatActivity hook
  coolingSetPointString = `${coolSetPoint} ${temperatureUnit}`;
  heatingSetPointString = `${heatSetPoint} ${temperatureUnit}`;

  const renderOverrideActivity = () => {
    let overrideText = '';

    if (workingMode === WorkingMode.CALIBRATION) {
      overrideText = 'Manual Mode';
    }
    if (overrideForcedOffValue === 0.15) {
      overrideText = 'Forced Off';
    }

    if (overrideForcedOnValue === 0.15) {
      overrideText = 'Forced On';
    }

    if (overrideText === '') return null;
    return (
      <div className={styles.override}>
        <div>{overrideText}</div>
        {isLocalOverrideActive && (
          <div className={styles.localOverride}>Local Override</div>
        )}
      </div>
    );
  };

  const renderHvacStagesActivity = () => {
    if (
      isBarChartShowing &&
      (!isVenstarHeatpump ||
        (isVenstarHeatpump &&
          !skipDataBasedOnTime(timestamp, edgeApp2dot4Date))) && (
        <div className={styles.separatorLine} />
      )
    ) {
      const activeText = 'Active';
      const idleText = 'Idle';
      const heatingStageToDisplay = heatingStage2Running ? '2' : '1';
      const coolingStageToDisplay = coolingStage2Running ? '2' : '1';
      const heatingActiveText =
        heatingStage1Running || heatingStage2Running ? activeText : idleText;
      const coolingActiveText =
        coolingStage1Running || coolingStage2Running ? activeText : idleText;
      const fanActiveText = fanRunning ? activeText : idleText;
      return (
        <div className={classNames(styles.secondaryText)}>
          {(mode === ThermostatWorkingMode.HEATING ||
            mode === ThermostatWorkingMode.AUTO) && (
            <div className={styles.tooltipLine}>
              Heating Stage {heatingStageToDisplay}: {heatingActiveText}
            </div>
          )}
          {(mode === ThermostatWorkingMode.COOLING ||
            mode === ThermostatWorkingMode.AUTO) && (
            <div className={styles.tooltipLine}>
              Cooling Stage {coolingStageToDisplay}: {coolingActiveText}
            </div>
          )}
          <div className={styles.tooltipLine}>Fan: {fanActiveText}</div>
        </div>
      );
    }
    return <></>;
  };

  const commentPayload = payload.find((p) => p.dataKey === 'commentValue');

  if (commentPayload && commentPayload.payload?.timestamp === label) {
    return renderCommentTooltips({
      payload: commentPayload.payload,
    });
  } else {
    return (
      <div className={styles.root}>
        <div
          className={classNames(styles.secondaryTextComment, styles.italics)}
        >
          {determineTimeStringByTimezone(
            new Date(label),
            locale.fullDateTimeFormat,
            ianaTimeZoneCode,
            true
          )}
        </div>
        <div className={classNames(styles.primaryText, styles.tooltipLine)}>
          {isDefined(temperature)
            ? `${
                isImperial ? temperature.toFixed(0) : temperature
              } ${temperatureUnit}`
            : global.NOT_AVAILABLE}
        </div>
        {mode === ThermostatWorkingMode.OFF && (
          <div className={classNames(styles.secondaryText, styles.tooltipLine)}>
            Thermostat Controls Off
          </div>
        )}
        {localAdjustmentOverrideEnergybox === true && (
          <div
            className={classNames(
              styles.energyboxOverrideText,
              styles.tooltipLine
            )}
          >
            Local Override
          </div>
        )}
        {(mode === ThermostatWorkingMode.COOLING ||
          mode === ThermostatWorkingMode.AUTO) && (
          <div
            className={classNames(
              styles.secondaryText,
              styles.tooltipLine,
              mode === ThermostatWorkingMode.COOLING ? styles.paddingBottom : ''
            )}
          >
            Cooling Set Point: {coolingSetPointString}
          </div>
        )}
        {(mode === ThermostatWorkingMode.HEATING ||
          mode === ThermostatWorkingMode.AUTO) && (
          <div
            className={classNames(
              styles.secondaryText,
              styles.tooltipLine,
              styles.paddingBottom
            )}
          >
            Heating Set Point: {heatingSetPointString}
          </div>
        )}

        {/*always show hvac activity for EB thermostat
        show hvac activity for Venstar thermostat after feature flag date */}
        {(isLocalOverrideActive || isBarChartShowing) &&
          (!isVenstarHeatpump ||
            (isVenstarHeatpump &&
              !skipDataBasedOnTime(timestamp, edgeApp2dot4Date))) && (
            <div className={styles.separatorLine} />
          )}

        {renderOverrideActivity()}
        {renderHvacStagesActivity()}
      </div>
    );
  }
};

export default ThermostatActivityChartTooltip;
