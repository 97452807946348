import { MobileNavMenu } from '@energybox/react-ui-library/dist/components';
import { PageNav } from '@energybox/react-ui-library/dist/types';
import { useLocation } from 'react-router';
import history from '../../../../history';
import * as Routes from '../../../../routes';

import styles from './SiteMobileNav.module.css';

const SiteMobileNav = () => {
  const mobileTabs: PageNav[] = [
    {
      to: Routes.SITES,
      displayName: 'Sites',
    },
    {
      to: Routes.MAP,
      displayName: 'Map',
    },
  ];
  const currentPath = useLocation()?.pathname || '';

  return (
    <div className={styles.mobileSiteNavBar}>
      <MobileNavMenu
        tabs={mobileTabs}
        currentPath={currentPath}
        genericOnClick={(to: string) => history.push(to)}
      />
    </div>
  );
};

export default SiteMobileNav;
