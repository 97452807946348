import {
  Card,
  ErrorBoundary,
} from '@energybox/react-ui-library/dist/components';
import React from 'react';
import tileStyles from '../../../styles/tiles.module.css';
import Loading from '../Loading';

type Props = {
  className?: string;
  isLoading: boolean;
  children: React.ReactNode;
  testId?: string;
};

const Tile: React.FC<Props> = ({ testId, className, isLoading, children }) => {
  return (
    <Card className={className} testId={testId}>
      <div className={tileStyles.tileContainer}>
        <ErrorBoundary>{isLoading ? <Loading /> : children}</ErrorBoundary>
      </div>
    </Card>
  );
};

export default Tile;
