import { Card } from '@energybox/react-ui-library/dist/components';
import React, { useEffect, useState } from 'react';
import { fetchSentinels } from '../../actions/sentinels';
import styles from './CardStyles.module.css';

type Props = {
  sites: any;
};

const ConfiguredSites = ({ sites }: Props) => {
  const [configuredCount, setConfiguredCount] = useState(0);
  useEffect(() => {
    const siteIds = sites.map((site) => site.id);
    fetchSentinels({ siteIds: siteIds })
      .then((results) => {
        setConfiguredCount(
          results.filter((result) => result.length > 0).length
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [sites]);

  return (
    <Card>
      <div className={styles.content}>
        {' '}
        <h6 className={styles.title}>Site without configurations</h6>
        <div className={styles.rowNoSpace}>
          <span className={styles.bigRedNumber}>{configuredCount}</span>
          <span
            style={{
              transform: 'translate(7%, 30%)',
              fontWeight: 'bold',
            }}
          >
            {' '}
            / {sites.length} sites
          </span>
        </div>
      </div>
    </Card>
  );
};

export default ConfiguredSites;
