import {
  ResourceType,
  Sensor,
  SensorType,
  Space,
  SpacesByParentId,
} from '@energybox/react-ui-library/dist/types';
import { listDifference } from '@energybox/react-ui-library/dist/utils';
import equals from 'ramda/src/equals';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSensorById, getSensorsBySiteId } from '../actions/sensors';
import {
  subscribeToDeviceReadings as subscribeToDeviceReadingsAction,
  unsubscribeFromDeviceReadings as unsubscribeFromDeviceReadingsAction,
} from '../actions/streamApi';
import { ApplicationState } from '../reducers';
import {
  SensorReading,
  SensorReadingsById,
  SensorsByEquipmentId,
  SensorsById,
} from '../reducers/sensors';
import { Queue } from '../utils/queue';
import {
  extractSensorReadingsByType,
  extractSensorsForSpaceFromSite,
  isSameSensorArray,
} from '../utils/sensors';
import usePrevious from './usePrevious';

export const useHasSensorsForEquipment = (equipmentId: string) => {
  return useSelector<ApplicationState, boolean>((state) => {
    if (state.sensors.sensorsByEquipmentId[equipmentId] !== undefined) {
      return true;
    } else {
      return false;
    }
  });
};

export const useSensorsByEquipmentId = (): {
  sensorsByEquipmentId: SensorsByEquipmentId;
  filterByEquipIdAndSensorType: (
    equipId: number,
    sensorType: SensorType
  ) => Sensor[];
} => {
  const sensorsByEquipmentId = useSelector<
    ApplicationState,
    SensorsByEquipmentId
  >(({ sensors }) => sensors.sensorsByEquipmentId);

  const filterByEquipIdAndSensorType = useCallback(
    (equipId, type) =>
      sensorsByEquipmentId[equipId].filter((s) =>
        s.types.some((t) => t === type)
      ),
    [sensorsByEquipmentId]
  );

  return {
    sensorsByEquipmentId,
    filterByEquipIdAndSensorType,
  };
};

export const useSensorById = (sensorId: number): Sensor | undefined => {
  const dispatch = useDispatch();

  const sensor: Sensor | undefined = useSelector<ApplicationState, Sensor>(
    ({ sensors }) => {
      return sensors.sensorsById[sensorId];
    },
    equals
  );

  useEffect(() => {
    if (sensor === undefined) {
      dispatch(getSensorById(sensorId));
    }
  }, [dispatch, sensor, sensorId]);

  return sensor;
};

export const useHasSensorsById = (sensorId: string) => {
  return useSelector<ApplicationState, boolean>((state) => {
    if (state.sensors.sensorsById[sensorId] !== undefined) {
      return true;
    } else {
      return false;
    }
  });
};

export const useHasSensorsBySiteId = (siteId: string | number) => {
  return useSelector<ApplicationState, boolean>((state) => {
    if (state.sensors.sensorsBySiteId[siteId] !== undefined) {
      return true;
    } else {
      return false;
    }
  });
};

export const useSensorsBySiteId = (siteId: string | number) => {
  const dispatch = useDispatch();
  const hasSensors = useHasSensorsBySiteId(siteId);
  const isLoading = useSelector<ApplicationState, boolean>(({ sensors }) => {
    return sensors.sensorsBySiteIdLoading[siteId];
  });
  const sensors = useSelector<ApplicationState, Sensor[]>(({ sensors }) => {
    return sensors.sensorsBySiteId[siteId];
  });
  const sensorsById = useSelector<ApplicationState, SensorsById>(
    ({ sensors }) => {
      return sensors.sensorsById;
    }
  );

  useEffect(() => {
    if (!hasSensors) {
      dispatch(getSensorsBySiteId(siteId));
    }
  }, [siteId, dispatch, hasSensors]);

  return {
    isLoading,
    sensors,
    sensorsById,
  };
};

export const useHasSensorsByResourceId = (
  resourceId: string,
  resourceType: ResourceType.EQUIPMENT | ResourceType.SENSOR | ResourceType.SITE
) => {
  const equipmentHasSensors = useHasSensorsForEquipment(resourceId);
  const sensorInStore = useHasSensorsById(resourceId);
  const hasSiteSensors = useHasSensorsBySiteId(resourceId);
  if (resourceType === ResourceType.SENSOR) {
    return sensorInStore;
  } else if (resourceType === ResourceType.EQUIPMENT) {
    return equipmentHasSensors;
  } else if (resourceType === ResourceType.SITE) {
    return hasSiteSensors;
  } else {
    return false;
  }
};

/**
 * Retrieves sensor objects from redux for either a piece of equipment or a sensor.
 * @param resourceId ID of either equipment or sensor we are interested in
 * @param resourceType Specifies which type of ID is passed to resourceID
 * @param sensorType Optional and only has an effect for equipment. The type of
 * sensors we want for a piece of equipment.
 */
export const useSensorsByResourceId = (
  resourceId: string | number | undefined,
  resourceType:
    | ResourceType.EQUIPMENT
    | ResourceType.SENSOR
    | ResourceType.SPACE,
  includeSubspaces: boolean,
  spacesByParentId?: SpacesByParentId,
  sensorType?: SensorType,
  siteId?: string
): Sensor[] => {
  const [sensors, setSensors] = useState<Sensor[]>([]);

  const selectorFunc = useCallback(
    (state: ApplicationState) => {
      const filterSensorType = ({ types }) => {
        if (sensorType === undefined || types.includes(sensorType)) {
          return true;
        } else {
          return false;
        }
      };
      if (resourceId === undefined) return [];
      if (resourceType === ResourceType.EQUIPMENT) {
        const allSensorsForEquipment =
          state.sensors.sensorsByEquipmentId[resourceId];
        if (allSensorsForEquipment === undefined) {
          return [];
        } else {
          return allSensorsForEquipment.filter(filterSensorType);
        }
      } else if (resourceType === ResourceType.SENSOR) {
        const sensor = state.sensors.sensorsById[resourceId];
        return sensor !== undefined ? [sensor] : [];
      } else if (resourceType === ResourceType.SPACE && siteId !== undefined) {
        const siteSensors: Sensor[] | undefined =
          state.sensors.sensorsBySiteId[siteId];
        if (includeSubspaces && spacesByParentId !== undefined) {
          const spaceTree = createSpaceTree(siteId, spacesByParentId);
          if (spaceTree === undefined) {
            return [];
          } else {
            const desiredSpace = findSpaceInTree(
              spaceTree,
              resourceId.toString()
            );
            return collectAllSensorsBelowSpace(
              desiredSpace,
              siteSensors
            ).filter(filterSensorType);
          }
        } else {
          return extractSensorsForSpaceFromSite(
            siteSensors,
            resourceId.toString(),
            includeSubspaces
          ).filter(filterSensorType);
        }
      } else {
        return [] as Sensor[];
      }
    },
    [
      resourceId,
      resourceType,
      includeSubspaces,
      spacesByParentId,
      sensorType,
      siteId,
    ]
  );

  const relevantSensorsForEquipment = useSelector<ApplicationState, Sensor[]>(
    selectorFunc
  );

  if (!isSameSensorArray(sensors, relevantSensorsForEquipment)) {
    setSensors(relevantSensorsForEquipment);
  }

  return sensors;
};

const createSpaceTree = (
  siteId: string,
  spacesByParentId: SpacesByParentId
) => {
  if (spacesByParentId[siteId] === undefined) return undefined;
  const root = { siteId, id: siteId, isRoot: true, children: [] as Space[] };
  const q = new Queue<Space>();
  spacesByParentId[siteId].forEach((space) => {
    //@ts-ignore
    space.children = [];
    q.enqueue(space);
    root.children.push(space);
  });
  while (!q.isEmpty()) {
    const currentSpace = q.peek();
    q.remove();
    const currentSpaceChildren: undefined | Space[] =
      spacesByParentId[currentSpace.id];
    if (currentSpaceChildren !== undefined) {
      currentSpace.children = currentSpaceChildren;
      currentSpaceChildren.forEach((space) => {
        q.enqueue(space);
      });
    }
  }

  return root;
};

type Tree = {
  children: Space[];
  id: string;
  siteId: string;
  isRoot: boolean;
};

const findSpaceInTree = (
  spaceTree: Tree,
  spaceId: string
): Tree | Space | undefined => {
  if (spaceTree.id === spaceId) {
    return spaceTree;
  }
  const q = new Queue<Space>();
  let desiredSpace: { desiredSpace: undefined | Space } = {
    desiredSpace: undefined,
  };
  spaceTree.children.forEach((space: Space) => {
    if (space.id.toString() === spaceId) desiredSpace.desiredSpace = space;
    q.enqueue(space);
  });
  if (desiredSpace.desiredSpace !== undefined) return desiredSpace.desiredSpace;
  while (!q.isEmpty()) {
    const currentSpace = q.peek();
    q.remove();
    const currentSpaceChildren: undefined | Space[] = currentSpace.children;
    if (currentSpaceChildren !== undefined) {
      currentSpaceChildren.forEach((space: Space) => {
        // Need to use desiredSpace.desiredSpace to avoid possible closure bug,
        // eslint rule no-loop-func
        if (space.id.toString() === spaceId) desiredSpace.desiredSpace = space;
        q.enqueue(space);
      });
    }
  }
  if (desiredSpace.desiredSpace !== undefined) return desiredSpace.desiredSpace;
};

const collectAllSensorsBelowSpace = (
  desiredSpace: Tree | Space | undefined,
  siteSensors: Sensor[] | undefined
): Sensor[] => {
  if (desiredSpace === undefined) return [];
  let sensors = extractSensorsForSpaceFromSite(
    siteSensors,
    desiredSpace.id.toString(),
    false
  );

  //@ts-ignore
  if (desiredSpace.children !== undefined && desiredSpace.children.length > 0) {
    const q = new Queue<Space>();
    //@ts-ignore
    desiredSpace.children.forEach((space: Space) => {
      q.enqueue(space);
    });
    while (!q.isEmpty()) {
      const currentSpace = q.peek();
      q.remove();
      const currentSpaceSensors = extractSensorsForSpaceFromSite(
        siteSensors,
        currentSpace.id.toString(),
        false
      );
      sensors = sensors.concat(currentSpaceSensors);
      const currentSpaceChildren: undefined | Space[] = currentSpace.children;
      if (
        currentSpaceChildren !== undefined &&
        //@ts-ignore
        desiredSpace.children.length > 0
      ) {
        currentSpaceChildren.forEach((space: Space) => {
          q.enqueue(space);
        });
      }
    }
  }

  return sensors;
};

/**
 * Retrieves sensor readings from redux
 * @param sensors List of sensors for which we want readings from redux
 * @param sensorType Type of sensor readings to retrieve
 */
export const useSensorStreamReadings = (
  sensors: Sensor[] | undefined,
  sensorType: SensorType,
  collapseBinaryValues: boolean
): SensorReading[] | undefined => {
  const sensorReadingsById = useSelector<ApplicationState, SensorReadingsById>(
    (state) => state.sensors.sensorReadingsById
  );

  return extractSensorReadingsByType(
    sensors,
    sensorReadingsById,
    sensorType,
    collapseBinaryValues
  );
};

export const useSubscribeToSensors = (sensors: Sensor[] | undefined) => {
  const _dispatch = useDispatch();
  const dispatch = useCallback(
    (fn) => {
      _dispatch(fn);
    },
    [_dispatch]
  );

  const streamConnected = useSelector(
    (state: ApplicationState) => state.app.streamConnected
  );
  const alreadySubscribed = useRef<boolean>(false);
  const prevSensors = usePrevious<Sensor[] | undefined>(sensors);

  // The purpose of this use effect is to set a boolean guard that will prevent
  // us from re-subscribing as the props update
  useEffect(() => {
    // do nothing at first, we can't mark subscribed until we actually subscribe
    return () => {
      // at the end, set back to false to allow unsubscribe
      alreadySubscribed.current = false;
    };
  }, []);

  // This effect subscribes for each sensor provided, and will handle updates to
  // the sensor list. It prevents subscription churn by using the
  // `alreadySubscribed` ref.
  useEffect(() => {
    const subscribeToDeviceReadings = (
      vendor: string,
      uuid: string,
      id: string | number
    ) => dispatch(subscribeToDeviceReadingsAction(vendor, uuid, id));
    const unsubscribeFromDeviceReadings = (
      vendor: string,
      uuid: string,
      id: string | number
    ) => dispatch(unsubscribeFromDeviceReadingsAction(vendor, uuid, id));

    if (
      sensors !== undefined &&
      prevSensors !== undefined &&
      !isSameSensorArray(prevSensors, sensors)
    ) {
      // If the sensors list has updated for any reason we need to make sure to
      // subscribe to the new sensors
      alreadySubscribed.current = false;
      // Also, need to find any sensors dropped from the new list and
      // unsubscribe from them
      const droppedSensors = listDifference<Sensor>(
        prevSensors,
        sensors,
        (item: Sensor) => item.id
      );
      droppedSensors.forEach(({ vendor, uuid, id }) => {
        if (vendor) {
          unsubscribeFromDeviceReadings(vendor, uuid, id);
        }
      });
    }

    if (streamConnected && sensors !== undefined && sensors.length > 0) {
      const unsubscribeFns = [] as (() => void)[];

      sensors.forEach(({ vendor, uuid, id }) => {
        if (uuid && vendor) {
          if (!alreadySubscribed.current) {
            subscribeToDeviceReadings(vendor, uuid, id);
          }

          unsubscribeFns.push(() => {
            // alreadySubscribed should always be true from after this loop,
            // unless the component is being torn down, in which case the first
            // use effect will have already ran before this clean up and set the
            // flag back
            if (!alreadySubscribed.current) {
              unsubscribeFromDeviceReadings(vendor, uuid, id);
            }
          });
        }
      });

      alreadySubscribed.current = true;
      return () => unsubscribeFns.forEach((unsubscribeFns) => unsubscribeFns());
    }
  }, [sensors, streamConnected, dispatch, prevSensors]);
  // Can't just use [] dep array for the effect because we need to fetch sensors
  // from BE and so sensors array is empty on first render
};
