import {
  IncidentStatus,
  SiteOnlineStatus,
} from '@energybox/react-ui-library/dist/types';
import { startOfDay } from 'date-fns';
import { useState } from 'react';
import { TodayPageTimeRange } from '../containers/views/today/TodayOverviewPage/TodayOverviewPage';
import { useGetAllIncidentsByStatus } from './useIncidents';
import useRefreshTimeRange from './useRefreshTimeRange';
import { useSiteOnlineStats } from './useSiteOnlineStatus';
import { useSitesById } from './useSites';

export const useSiteDataForMap = (
  onlyShowingIncidents: boolean,
  timeRange?: TodayPageTimeRange
): {
  isLoading: boolean;
  siteDataForMap: SiteOnlineStatus[];
  numberOfSitesWithActiveIncidents: number;
} => {
  const [defaultTimeRange, setDefaultTimeRange] = useState({
    from: startOfDay(new Date()).toISOString(),
    to: new Date().toISOString(),
  });
  const sitesById = useSitesById();
  const siteOnlineStats = useSiteOnlineStats();
  const { isLoading: isLoadingIncidents, data: activeIncidents } =
    useGetAllIncidentsByStatus(
      IncidentStatus.ACTIVE,
      timeRange !== undefined ? timeRange.from : defaultTimeRange.from,
      timeRange !== undefined ? timeRange.to : defaultTimeRange.to
    );

  //setInterval for every 15 minutes if the user keeps this window open - for the big map
  useRefreshTimeRange(setDefaultTimeRange);
  const sitesWithActiveIncidents = new Set();
  const activeIncidentCountBySite = new Map<number, number>();

  if (siteOnlineStats === undefined) {
    return {
      isLoading: isLoadingIncidents,
      siteDataForMap: [],
      numberOfSitesWithActiveIncidents: 0,
    };
  }

  const { siteOnlineStatuses } = siteOnlineStats;
  let siteDataForMap = siteOnlineStatuses;
  if (activeIncidents !== undefined) {
    activeIncidents.forEach((incident) => {
      const siteId = incident.siteId;

      sitesWithActiveIncidents.add(siteId);
      sitesWithActiveIncidents.add(siteId);
      const currentSiteCount = activeIncidentCountBySite.get(siteId);
      if (currentSiteCount !== undefined) {
        activeIncidentCountBySite.set(siteId, currentSiteCount + 1);
      } else {
        activeIncidentCountBySite.set(siteId, 1);
      }
    });

    if (onlyShowingIncidents && siteDataForMap !== undefined) {
      siteDataForMap = siteOnlineStatuses.filter(({ siteId }) =>
        sitesWithActiveIncidents.has(siteId)
      );
    } else if (onlyShowingIncidents === false) {
      siteDataForMap = siteOnlineStatuses;
    }

    siteDataForMap.forEach((siteStatus) => {
      const currentSiteCount = activeIncidentCountBySite.get(siteStatus.siteId);
      siteStatus.numberActiveIncidents =
        currentSiteCount === undefined ? 0 : currentSiteCount;
      siteStatus.address = sitesById[siteStatus.siteId]?.address;
      siteStatus.images = sitesById[siteStatus.siteId]?.images;
    });
  }

  return {
    isLoading: isLoadingIncidents,
    siteDataForMap,
    numberOfSitesWithActiveIncidents: sitesWithActiveIncidents.size,
  };
};
