import { Sensor } from '@energybox/react-ui-library/dist/types';
import { Actions } from '../actions/sensors';

export type SensorsBySiteId = {
  [key: number]: Sensor[];
};

const initialState = {};

export default (state: SensorsBySiteId = initialState, action: any) => {
  switch (action.type) {
    case Actions.SENSORS_SUCCESS: {
      const { siteId, sensors } = action.payload;
      if (!siteId) return state;
      return {
        ...state,
        [siteId]: sensors,
      };
    }

    default:
      return state;
  }
};
