const apiBase = '/api/v1/control-boards';

export enum Actions {
  GET_CONTROL_BOARD_SUCCESS = '@control_boards/GET_CONTROL_BOARD_SUCCESS',
  GET_CONTROL_BOARD_ERROR = '@control_boards/GET_CONTROL_BOARD_ERROR',
  GET_CONTROL_BOARD_LOADING = '@control_boards/GET_CONTROL_BOARD_LOADING',
}

export const getControlBoardById = (id: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: { type: Actions.GET_CONTROL_BOARD_SUCCESS, id },
  error: Actions.GET_CONTROL_BOARD_ERROR,
  loading: Actions.GET_CONTROL_BOARD_LOADING,
});
