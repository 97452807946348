import {
  OperationalSample,

} from '@energybox/react-ui-library/dist/types';
import {
  mapValues,
  normalizeTSOperationalDataBySensorId,
  OperationalSampleFromApiResponse,
} from '@energybox/react-ui-library/dist/utils';
import assocPath from 'ramda/src/assocPath';
import dissocPath from 'ramda/src/dissocPath';
import pipe from 'ramda/src/pipe';
import {Actions} from '../actions/samples';


export type OperationalSamples = {
  isLoadingByResourceId: IsLoadingByResourceId;
  isLoadingValueChipByResourceId: IsLoadingByResourceId;
  operationalSamplesBySensorId: OperationalSamplesById;
  operationalSamplesByFeatureNotificationId: OperationalSamplesById;
  valueChipOperationalSamplesBySensorId: OperationalSamplesById;
};

export type IsLoadingByResourceId = {
  [resourceId: string]: boolean;
};

export type OperationalSamplesById = {
  [id: string]: OperationalSample[];
};

export const initialState = {
  isLoadingByResourceId: {},
  isLoadingValueChipByResourceId: {},
  operationalSamplesBySensorId: {},
  operationalSamplesByFeatureNotificationId: {},
  valueChipOperationalSamplesBySensorId: {},
};

// Storing samples most recent first -> least recent last.
const operationalSamples = (
  state: OperationalSamples = initialState,
  action: any
) => {
  switch (action.type) {
    case Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING:
      return pipe(
        assocPath(
          ['isLoadingByResourceId', action.notificationId || action.sensorId],
          true
        ),
        dissocPath(
          action.notificationId
            ? [
              'operationalSamplesByFeatureNotificationId',
              action.notificationId,
            ]
            : ['operationalSamplesBySensorId', action.sensorId]
        )
      )(state);

    case Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR:
      return assocPath(
        ['isLoadingByResourceId', action.notificationId || action.sensorId],
        false,
        state
      );

    case Actions.GET_VALUE_CHIP_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR:
      return assocPath(
        ['isLoadingValueChipByResourceId', action.sensorId],
        false,
        state
      );

    case Actions.GET_VALUE_CHIP_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING:
      return pipe(
        assocPath(['isLoadingValueChipByResourceId', action.sensorId], true),
        dissocPath(['valueChipOperationalSamplesBySensorId', action.sensorId])
      )(state);

    case Actions.GET_VALUE_CHIP_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS:
      return pipe(
        assocPath(
          ['valueChipOperationalSamplesBySensorId', action.sensorId],
          mapValues(
            normalizeTSOperationalDataBySensorId(action.payload,  action.sensorId),
            OperationalSampleFromApiResponse
          )
        ),
        assocPath(['isLoadingValueChipByResourceId', action.sensorId], false)
      )(state);

    case Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS:
      return pipe(
        assocPath(
          action.notificationId
            ? [
              'operationalSamplesByFeatureNotificationId',
              action.notificationId,
            ]
            : ['operationalSamplesBySensorId', action.sensorId],
          mapValues(
            normalizeTSOperationalDataBySensorId(action.payload,  action.sensorId),
            OperationalSampleFromApiResponse
          )),
        assocPath(
          ['isLoadingByResourceId', action.notificationId || action.sensorId],
          false
        )
      )(state);

    default:
      return state;
  }
};

export default operationalSamples;
