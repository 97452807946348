import { useCallback, useEffect } from 'react';
import useDynamicFilter from './useDynamicFilter';

const useNotificationsDynamicFilter = (
  filterType: string,
  externalSetFilter,
  transformFn?: (value: string) => any
) => {
  const { selectedFilters: filtersFromUrl, setFilter: setDynamicFilter } =
    useDynamicFilter(filterType, transformFn);

  useEffect(() => {
    externalSetFilter && externalSetFilter(filtersFromUrl);
  }, [filtersFromUrl]);

  const setNotificationsFilter = useCallback(
    (newFilters) => {
      setDynamicFilter(newFilters);
      externalSetFilter && externalSetFilter(newFilters);
    },
    [setDynamicFilter, externalSetFilter]
  );

  return { filtersFromUrl, setNotificationsFilter };
};

export default useNotificationsDynamicFilter;
