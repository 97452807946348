import React from 'react';
import TileContent from '../TileContent';
import HappyHorn from '../../icons/ui/HappyHorn';
import styles from './HappyHornOverlay.module.css';

const HappyHornOverlay = () => (
  <TileContent className={styles.root}>
    <div className={styles.iconContainer}>
      <HappyHorn />
    </div>
    <div className={styles.wellDone}>Well done!</div>
    <div>Nothing to report here</div>
  </TileContent>
);

export default HappyHornOverlay;
