export enum Actions {
  CURRENT_ORGANIZATION_SUCCESS = '@@energy/CURRENT_ORGANIZATION_SUCCESS',
  CURRENT_ORGANIZATION_LOADING = '@@energy/CURRENT_ORGANIZATION_LOADING',
  CURRENT_ORGANIZATION_ERROR = '@@energy/CURRENT_ORGANIZATION_ERROR',
}

export const getCurrentOrganization = () => ({
  type: 'API_GET',
  path: '/api/v1/organizations/current',
  loading: Actions.CURRENT_ORGANIZATION_LOADING,
  success: Actions.CURRENT_ORGANIZATION_SUCCESS,
  error: Actions.CURRENT_ORGANIZATION_ERROR,
});
