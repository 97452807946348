import { ResourceType, Sensor, SensorType } from '@energybox/react-ui-library/dist/types';
import { mapArrayToObject, mapValues } from '@energybox/react-ui-library/dist/utils';
import { isAfter } from 'date-fns';
import { assoc, assocPath, mergeRight, pathOr, pipe, view, lensProp } from 'ramda';
import { Actions as SensorActions } from '../actions/sensors';
import { Actions as StreamActions } from '../actions/streamApi';
import { equipmentsFromApiResponse } from './equipment';
import { spacesFromApiResponse } from './spaces';

export type Sensors = {
  sensorsById: SensorsById;
  sensorsByEquipmentId: SensorsByEquipmentId;
  sensorsBySiteId: SensorsBySiteId;
  sensorsBySiteIdLoading: {
    [siteId: string]: boolean;
  };
  isLoading: boolean;
  sensorReadingsById: SensorReadingsById;
};

export type SensorsById = {
  [id: string]: Sensor;
};

export type SensorsByEquipmentId = {
  [equipmentId: string]: Sensor[];
};

export interface SensorReadingFromAPI {
  processedAt: string;
  receivedAt: string;
  sensorId: string;
  sensor?: Sensor;
  timestamp: string;
  uuid: string;
  vendor: string;
  temperature?: number;
  humidity?: number;
  lux?: number;
  state?: [boolean];
}

export interface SensorReading {
  processedAt: string;
  receivedAt: string;
  sensorId: string;
  sensor?: Sensor;
  timestamp: string;
  uuid: string;
  vendor: string;
  temperature?: number;
  humidity?: number;
  lux?: number;
  binary?: boolean;
}

export interface SensorTypeToReading {
  [SensorType.TEMPERATURE]?: SensorReading;
  [SensorType.HUMIDITY]?: SensorReading;
  [SensorType.BINARY]?: SensorReading;
}

export type SensorReadingsById = {
  [id: string]: SensorTypeToReading;
};

export type SensorsBySiteId = {
  [id: string]: Sensor[];
};

export const sensorFromApiResponse = (data: any) => ({
  id: data.id,
  title: data.title,
  description: data.description || '',
  modelId: data.modelId || undefined,
  resourceId: data.resourceId,
  uuid: data.uuid,
  vendor: data.vendor,
  productId: data.productId || undefined,
  sensorStatus: data.sensorStatus || undefined,
  sensorInfo: data.sensorInfo || undefined,
  firmwareVersion: data.firmwareVersion || undefined,
  hardwareVersion: data.hardwareVersion || undefined,
  sensorOffset: data.sensorOffset || undefined,
  resource:
    data.resource._entity === 'Space'
      ? spacesFromApiResponse(data.resource)
      : equipmentsFromApiResponse(data.resource),
  parentResourceType: data.resource
    ? ResourceType[(data.resource._entity as string).toUpperCase()]
    : undefined,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt || undefined,
  types: data.types,
  gatewayId: data.gatewayId || undefined,
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
  //Backend doesn't seem to return location
  // location: data.location,
});

const checkIfReadingIsMoreRecent = (
  newReading: SensorReading,
  oldReading?: SensorReading
) => {
  if (!oldReading) return true;
  return isAfter(
    new Date(newReading.timestamp),
    new Date(oldReading.timestamp)
  );
};

const initialState = {
  isLoading: false,
  sensorsById: {},
  sensorsByEquipmentId: {},
  sensorsBySiteIdLoading: {},
  sensorsBySiteId: {},
  sensorReadingsById: {},
};

export const sortSensorsByRecentDate = (sensorList: Sensor[]) => {
  return sensorList.sort((a: Sensor, b: Sensor) => {
    let sensorA: number, sensorB: number;

    if (!a.sensorStatus || !(a.sensorStatus && a.sensorStatus.receivedAt)) {
      sensorA = 0;
    } else sensorA = +new Date(a.sensorStatus.receivedAt);

    if (!b.sensorStatus || !(b.sensorStatus && b.sensorStatus.receivedAt)) {
      sensorB = 0;
    } else sensorB = +new Date(b.sensorStatus.receivedAt);

    return sensorB - sensorA;
  });
};

const normalizeSubscribedSensorReading = (rawData: SensorReadingFromAPI) => ({
  processedAt: rawData.processedAt,
  receivedAt: rawData.receivedAt,
  sensorId: rawData.sensorId,
  sensor: rawData.sensor,
  timestamp: rawData.timestamp,
  uuid: rawData.uuid,
  vendor: rawData.vendor,
  temperature: rawData.temperature,
  humidity: rawData.humidity,
  binary:
    rawData.state && Array.isArray(rawData.state)
      ? rawData.state[0]
      : undefined,
});

const processSensor = (sensor: Sensor): Sensor => {
  if (sensor.vendor === 'monnit') {
    return {
      ...sensor,
      types: [SensorType.TEMPERATURE]
    }
  }
  return sensor;
}

const sensors = (state: Sensors = initialState, action: any) => {
  switch (action.type) {
    case SensorActions.BY_ID_SUCCESS:
      return assocPath(
        ['sensorsById', action.payload.id],
        processSensor(action.payload),
        state
      );

    case SensorActions.GET_SENSORS_BY_SITE_ID_SUCCESS: {
      const processedSensors = action.payload.map(processSensor)
      return pipe(
        assocPath(['sensorsBySiteId', action.siteId], processedSensors),
        assocPath(['sensorsBySiteIdLoading', action.siteId], false),
        assoc(
          'sensorsById',
          mergeRight(
            view(lensProp('sensorsById'), state),
            mapArrayToObject(mapValues(processedSensors, sensorFromApiResponse))
          )
        )
      )(state);
    }

    case SensorActions.GET_SENSORS_BY_SITE_ID_LOADING: {
      return assocPath(['sensorsBySiteIdLoading', action.siteId], true, state);
    }

    case SensorActions.GET_SENSORS_BY_SITE_ID_ERROR: {
      return assocPath(['sensorsBySiteIdLoading', action.siteId], false, state);
    }

    case SensorActions.GET_SENSORS_BY_EQUIPMENT_ID_SUCCESS:
      const sensorList = action.payload.map(processSensor) as Sensor[];
      const sensorsById = mapArrayToObject(sensorList);
      return pipe(
        assoc('isLoading', false),
        assocPath(
          ['sensorsByEquipmentId', action.equipmentId],
          sortSensorsByRecentDate(sensorList)
        ),
        assoc('sensorsById', mergeRight(state.sensorsById, sensorsById))
      )(state);

    case SensorActions.GET_SENSORS_BY_EQUIPMENT_ID_LOADING:
      return assoc('isLoading', true, state);

    case SensorActions.GET_SENSORS_BY_EQUIPMENT_ID_ERROR:
      return assoc('isLoading', false, state);

    case StreamActions.RECEIVED_DEVICE_READING:
      const normalizedPayload: SensorReading = normalizeSubscribedSensorReading(
        action.payload
      );
      const { sensorId, temperature, humidity, binary } = normalizedPayload;
      let oldValue: SensorReading | undefined = undefined;
      let sensorType: SensorType | undefined = undefined;

      if (humidity || humidity === 0) {
        oldValue = pathOr(
          null,
          ['sensorReadingsById', sensorId, SensorType.HUMIDITY],
          state
        );
        sensorType = SensorType.HUMIDITY;
      } else if (temperature || temperature === 0) {
        oldValue = pathOr(
          null,
          ['sensorReadingsById', sensorId, SensorType.TEMPERATURE],
          state
        );
        sensorType = SensorType.TEMPERATURE;
      } else if (binary || binary === false) {
        oldValue = pathOr(null, [sensorId, SensorType.BINARY], state);
        sensorType = SensorType.BINARY;
      }

      if (
        oldValue === undefined ||
        !checkIfReadingIsMoreRecent(action.payload, oldValue)
      ) {
        return state;
      } else {
        return assocPath(
          ['sensorReadingsById', sensorId, sensorType],
          normalizedPayload,
          state
        );
      }

    default: {
      return state;
    }
  }
};

export default sensors;
