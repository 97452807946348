import { useDispatch, useSelector } from 'react-redux';
import { useOrganizationId } from './useCurrentUser';
import { useTimeFilter } from './useFilters';
import { ApplicationState } from '../reducers';
import {
  ByEq,
  Thermostat_complaince_Equipment,
  Thermostat_complaince_report_data,
} from '../reducers/analytics';
import { useEffect, useMemo, useState } from 'react';
import {
  getOrgThermostatComplainaceReport,
  getOrgThermostatComplianceReportKey,
} from '../actions/analytics';

export const useHvacControlsCompliance = () => {
  const dispatch = useDispatch();
  const orgId = useOrganizationId();
  const { timePeriod } = useTimeFilter();
  const [repeatDataCall, setRepeatDataCall] = useState(false);

  // ******** Selectors ********
  const reportKeyObject = useSelector(({ analytics }: ApplicationState) => {
    return analytics.thermostatComplaianceReportKey;
  });
  const reportKeyLoading = reportKeyObject[orgId!]?.['isLoading'];
  const reportKey = reportKeyObject[orgId!]?.['reportKey'];
  const thermostat_compliance_report = useSelector(
    ({ analytics }: ApplicationState) => {
      return analytics.thermostatComplainceReport;
    }
  );
  const { isLoading, thermostat_complaince_report_data } =
    thermostat_compliance_report;
  const thermostatPerformanceData: Thermostat_complaince_report_data =
    thermostat_complaince_report_data?.data;

  const report_version = thermostat_complaince_report_data?.report_version;
  const performanceData =
    thermostatPerformanceData?.by_eq &&
    Object.entries(thermostatPerformanceData.by_eq);
  const complianceThreshold =
    thermostatPerformanceData?.compliance_threshold * 100;
  const runtimeThreshold = thermostatPerformanceData?.runtime_threshold * 100;

  const dataLoading =
    report_version !== undefined && report_version < 1 ? true : isLoading;
  const loadingSeconds =
    report_version === 0 ? thermostatPerformanceData['loading'] : 0;

  useEffect(() => {
    if (orgId !== undefined)
      dispatch(
        getOrgThermostatComplianceReportKey(
          orgId,
          timePeriod.fromDate,
          timePeriod.toDate
        )
      );
    setRepeatDataCall(true);
  }, [orgId, timePeriod]);

  useEffect(() => {
    let interval;

    const fetchReport = () => {
      dispatch(getOrgThermostatComplainaceReport(orgId!, reportKey));
    };

    if (
      reportKey &&
      orgId &&
      (repeatDataCall || (report_version !== undefined && report_version < 1))
    ) {
      fetchReport();

      setRepeatDataCall(false);

      interval = setInterval(() => {
        fetchReport();
      }, 4000);
    }

    if (report_version === 1 && interval) {
      clearInterval(interval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [reportKey, report_version, orgId, repeatDataCall, dispatch]);

  return {
    reportKeyLoading,
    performanceData,
    isDataLoading: dataLoading,
    complianceThreshold,
    runtimeThreshold,
    loadingSeconds,
  };
};
