import { DialogTypes } from '../types/dialog';
import { Actions as DialogActions } from '../actions/dialog';
import { Actions as NotificationActions } from '../actions/notifications';

export type Dialog = {
  isOpen: boolean;
  title?: string;
  content?: string;
  actionFn?: any;
  targetId?: string;
  type?: string;
};

const initialState: Dialog = {
  isOpen: false
};

export default (state: Dialog = initialState, action: any) => {
  switch (action.type) {
    case DialogActions.DIALOG_OPEN: {
      return {
        isOpen: true,
        type: DialogTypes.DEFAULT,
        ...action.payload
      };
    }

    case NotificationActions.DISMISS_NOTIFICATION_SUCCESS:
    case DialogActions.DIALOG_CLOSE: {
      return initialState;
    }

    default:
      return state;
  }
};
