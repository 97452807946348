import { SiteGroupsById } from '../../reducers/site_groups';

export const filterBySiteGroups = (data, sitesBySelectedSiteGroups) => {
  sitesBySelectedSiteGroups = sitesBySelectedSiteGroups.map(Number);

  if (data?.length) {
    return data.filter((data) => {
      const siteId = data?.siteId || data?.id || data[1].site_id;
      return (
        sitesBySelectedSiteGroups?.length &&
        sitesBySelectedSiteGroups.includes(Number(siteId))
      );
    });
  }
  return [];
};

export const sortSiteGroupTitles = (siteGroupsById: SiteGroupsById) => {
  if (Object.values(siteGroupsById)?.length) {
    return Object.values(siteGroupsById)
      .map((siteGroup) => siteGroup?.title)
      ?.sort((a, b) => {
        const titleA = a.toLowerCase();
        const titleB = b.toLowerCase();

        if (titleA < titleB) {
          return -1;
        } else if (titleA > titleB) {
          return 1;
        } else {
          return 0;
        }
      });
  }

  return [];
};
