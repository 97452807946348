import {
  FilterTimePeriod,
  Notification,
  SentinelPriorityLevel,
} from '@energybox/react-ui-library/dist/types';
import {
  deriveDateValue,
  createTimeFilterMapping,
} from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';
import { Actions as FilterActions } from '../actions/notificationFilter';
import { Actions } from '../actions/notifications';
import { filterNotificationsOutsideDesiredRange } from '../utils/notifications';

const notificationDateFilterMap = createTimeFilterMapping({
  useCurrentTime: true,
});

export type NotificationFilters = {
  timePeriod: FilterTimePeriod;
  equipmentIds: number[];
  status: any[];
  priority: any[];
  filteredNotifications: Notification[];
  isLoading: boolean;
};

const initialState: NotificationFilters = {
  timePeriod: {
    title: notificationDateFilterMap['last7Days'].title,
    fromDate: notificationDateFilterMap['last7Days'].fromDate,
    toDate: notificationDateFilterMap['last7Days'].toDate,
  },
  equipmentIds: [],
  status: [],
  priority: [],
  filteredNotifications: [],
  isLoading: false,
};

function reverseHandlingLogs(notification): Notification {
  const { handlingLogs, ...rest } = notification;
  return {
    ...rest,
    handlingLogs: [...handlingLogs].reverse(),
  };
}

function sortByDateDescending(a: Notification, b: Notification): number {
  return (
    deriveDateValue(b.handlingLogs[0].at) -
    deriveDateValue(a.handlingLogs[0].at)
  );
}

const priorityMap = {
  [SentinelPriorityLevel.NORMAL]: 1,
  [SentinelPriorityLevel.MEDIUM]: 2,
  [SentinelPriorityLevel.HIGH]: 3,
  [SentinelPriorityLevel.HIGHEST]: 4,
};

function sortByPriority(a: Notification, b: Notification): number {
  const aPrio =
    priorityMap[a.handlingLogs[0] && a.handlingLogs[0].priorityLevel] || 0;
  const bPrio =
    priorityMap[b.handlingLogs[0] && b.handlingLogs[0].priorityLevel] || 0;

  return bPrio - aPrio;
}

function sortByPriorityDateDescending(
  a: Notification,
  b: Notification
): number {
  return sortByPriority(a, b) === 0
    ? sortByDateDescending(a, b)
    : sortByPriority(a, b);
}

function sortByPriorityDateUnseen(a: Notification, b: Notification): number {
  if (a.dismissedBy) {
    if (b.dismissedBy) {
      return sortByPriorityDateDescending(a, b);
    }
    return 1;
  } else if (b.dismissedBy) {
    return -1;
  }
  return sortByPriorityDateDescending(a, b);
}

const normalizeNotification = (n) => ({
  createdAt: n.createdAt,
  dismissedAt: n.dismissedAt,
  dismissedBy: n.dismissedBy,
  dismissedComment: n.dismissedComment,
  handlingLogs: n.handlingLogs,
  id: n.id,
  recoveredAt: n.recoveredAt,
  sensorParams: n.sensorParams,
  sentinelId: n.sentinelId,
  sentinelTitle: n.sentinelTitle,
  sentinelType: n.sentinelType,
  equipmentIds: pathOr(undefined, ['resourcePath', 'equipment'], n).map(
    (e) => e.id
  ),

  //only available from enriched notifications endpoint
  siteId: pathOr(undefined, ['resourcePath', 'sites', 0, 'id'], n),
});

const notificationFilter = (
  state: NotificationFilters = initialState,
  action: any
) => {
  switch (action.type) {
    case FilterActions.PRIORITY: {
      return {
        ...state,
        priority: action.payload,
      };
    }

    case FilterActions.STATUS: {
      return {
        ...state,
        status: action.payload,
      };
    }

    case FilterActions.DATE: {
      return {
        ...state,
        timePeriod: action.payload,
      };
    }

    case FilterActions.EQUIPMENT: {
      return {
        ...state,
        equipmentIds: action.payload,
      };
    }

    case Actions.FILTERED_NOTIFICATIONS_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case Actions.FILTERED_NOTIFICATIONS_SUCCESS: {
      action.payload.map((item) => {
        item.handlingLogs.map((data) => {
          if (data.priorityLevel === 'NORMAL') {
            data.priorityLevel = 'LOW';
          } else if (data.priorityLevel === 'HIGHEST') {
            data.priorityLevel = 'CRITICAL';
          }
        });
      });
      return {
        ...state,
        isLoading: false,
        filteredNotifications: filterNotificationsOutsideDesiredRange(
          action.payload,
          state.timePeriod
        )
          .map(normalizeNotification)
          .map(reverseHandlingLogs)
          .sort(sortByPriorityDateUnseen),
      };
    }

    case Actions.DISMISS_NOTIFICATION_SUCCESS: {
      const notificationIndex = state.filteredNotifications.findIndex(
        (e) => e.id === action.payload.id
      );

      return {
        ...state,
        isLoading: false,
        filteredNotifications: [
          ...state.filteredNotifications.slice(0, notificationIndex),
          reverseHandlingLogs(action.payload),
          ...state.filteredNotifications.slice(notificationIndex + 1),
        ].sort(sortByPriorityDateUnseen),
      };
    }

    case Actions.DISMISS_NOTIFICATION_LOADING: {
      return {
        ...state,
        isLoading: true,
      };
    }

    // TODO: error handling across Pulse app
    case Actions.DISMISS_NOTIFICATION_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default notificationFilter;
