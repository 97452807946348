import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import { EquipmentType } from '@energybox/react-ui-library/dist/types';

import React from 'react';
import { Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import * as Routes from '../../../routes';
import { formatNumber } from '../../../util';
import EquipmentTypeFilter from '../../Filters/EquipmentTypeFilter/EquipmentTypeFilter';
import BlurredInfo from '../../Tile/BlurredInfo';
import TileContent from '../../Tile/TileContent';
import TileFooter from '../../Tile/TileFooter';
import TileHeader from '../../Tile/TileHeader';
import TileHeaderMoreInfoContent from '../../Tile/TileHeaderMoreInfoContent/TileHeaderMoreInfoContent';
import styles from './TemperatureComplianceTile.module.css';

type Props = {
  title: string;
  entities?: PercentageData[];
  tableColumns?: Columns<any>[];
  averagePercentage?: number;
  errorMessage?: string;
  className?: string;
  equipmentTypes: Partial<EquipmentType>[];
};

export interface PercentageData {
  percentage: number;
  title: string;
}

const CHART_OFFSET_START = 450;
const TABLE_LIMIT = 5;

const TemperatureComplianceTile: React.FC<Props> = ({
  title,
  entities,
  averagePercentage,
  tableColumns,
  errorMessage,
  equipmentTypes,
}) => {
  const top5Entities = (entities || []).slice(0, TABLE_LIMIT);
  const tooltipDescription = `This tile shows you the percentage of time, the temperature sensitive
    equipment (such as refrigeration, freezer or incubators) across your organization are within the
    temperature compliance ranges set. With this information, you will find a list of the worst 5
    temperature compliant site in your organization.  To see the compliance of all sites, please click
    "See All" on the bottom right.`;
  const moreInfo = (
    <TileHeaderMoreInfoContent
      howToUseContent="This tile is a high-level overview of the status of your temperature
    compliance. Users should go through the list of sites with the worst compliance and dig
    deeper into why the compliance is so low. This can be done by clicking on the site name
    on the tile which will take you to a workspace analyzing the temperature compliance of all
    equipment at that site."
      howCalculatedContent={
        <>
          <p>If you have 3 sites with 2 equipment each:</p>
          <p>
            Site Compliance = AVG (Data points within SOP set for Equipment 1
            type/ Total data points for Equipment 1 + Data points within SOP set
            for Equipment 2 type/ Total data points for Equipment 2)
          </p>
          <p>
            Overall Compliance = Average of all site compliance/ Average of all
            equipment compliance in organization
          </p>
        </>
      }
    />
  );

  if (errorMessage)
    return <BlurredInfo title={title} errorMessage={errorMessage} />;

  return (
    <>
      <TileHeader
        allowShortenedTitle
        title={title}
        tooltipDescription={tooltipDescription}
        moreInfo={moreInfo}
        extraRightAlignContent={
          <div className={styles.equipmentTypeFilter}>
            <EquipmentTypeFilter
              equipmentTypes={equipmentTypes}
              alignItemsRight
            />
          </div>
        }
      />

      <TileContent className={styles.cardBody}>
        {averagePercentage !== undefined && !errorMessage && (
          <>
            <div className={styles.chartVisual}>
              <ResponsiveContainer height="100%" width="100%">
                <PieChart>
                  <Pie
                    // placeholder data to obtain desired chart behavior
                    // breaks if value is 0 or less than 0
                    data={[{ fakeData: 1 }]}
                    dataKey="fakeData"
                    innerRadius="93"
                    outerRadius="100"
                    startAngle={CHART_OFFSET_START}
                    endAngle={CHART_OFFSET_START - 360}
                    fill="var(--ambient-basePlus90)"
                    isAnimationActive={false}
                  />
                  <Pie
                    data={[{ fakeData: 1 }]}
                    dataKey="fakeData"
                    innerRadius="91"
                    outerRadius="100"
                    startAngle={CHART_OFFSET_START}
                    endAngle={
                      CHART_OFFSET_START - (360 * averagePercentage) / 100
                    }
                    fill="#2ADDD0"
                  >
                    <Label
                      position="center"
                      content={renderPercentLabel(averagePercentage)}
                    />
                    <Label position="center" content={renderLegend} />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
            <div className={styles.siteInfo}>
              <Table
                className={styles.topTable}
                columns={tableColumns}
                data={top5Entities}
              />
            </div>
          </>
        )}
      </TileContent>

      <TileFooter redirectTo={Routes.ORG_TEMPERATURE_COMPLIANCE} />
    </>
  );
};

const renderPercentLabel = (percentage: number) => {
  return ({ viewBox }) => {
    const { cx, cy } = viewBox;
    const formattedPercentage = `${formatNumber(percentage, 1)}%`;

    return (
      <g>
        <text
          x={cx + 4}
          y={cy + 13}
          fill="var(--ambient-basePlus50)"
          className="recharts-text recharts-label"
          textAnchor="middle"
          fontSize={45}
          fontWeight={500}
        >
          {formattedPercentage}
        </text>
      </g>
    );
  };
};

const renderLegend = ({ viewBox }) => {
  const { cx, cy } = viewBox;

  return (
    <g>
      <text
        x={cx + 4}
        y={cy + 135}
        fill="var(--ambient-basePlus50)"
        className="recharts-text recharts-label"
        textAnchor="middle"
        fontSize="15"
      >
        Overall Temperature Compliance
      </text>
    </g>
  );
};

export default TemperatureComplianceTile;
