import {
  CommentType,
  NewCommentFields,
  NonRequiredCommentApiFilters,
  ShowNewCommentPopupPayload,
  ValueType,
} from '@energybox/react-ui-library/dist/types';
import pathOr from 'ramda/src/pathOr';
import uniq from 'ramda/src/uniq';

import { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  create,
  getDownstreamComments,
  hideNewCommentPopup,
  showNewCommentPopup,
  updateField,
} from '../actions/comments';
import { getUsers } from '../actions/users';
import { ApplicationState } from '../reducers';
import { CommentStatus } from '../reducers/comments';
import { ApiError } from '../utils/apiErrorFeedback';
import { useTimeFilter } from './useFilters';

export const useNewComment = (resourceId_resourceType?: string) => {
  const dispatch = useDispatch();

  //*** Actions ***//
  const createComment = useCallback(
    (payload: NewCommentFields, parentId?: number | string) => {
      dispatch(create(payload, parentId));
    },
    [dispatch]
  );
  const updateCommentField = useCallback(
    (field: string, value: any) => {
      if (!resourceId_resourceType) return null;
      dispatch(updateField(resourceId_resourceType, field, value));
    },
    [dispatch, resourceId_resourceType]
  );

  const showPopup = useCallback(
    (payload: ShowNewCommentPopupPayload) => {
      if (!resourceId_resourceType) return null;
      dispatch(showNewCommentPopup(resourceId_resourceType, payload));
    },
    [dispatch, resourceId_resourceType]
  );

  const hidePopup = useCallback(() => {
    if (!resourceId_resourceType) return null;
    dispatch(hideNewCommentPopup(resourceId_resourceType));
  }, [dispatch, resourceId_resourceType]);

  //*** Selectors ***//
  const fields = useSelector<ApplicationState, NewCommentFields>(
    ({ comments }) => {
      return pathOr(
        {},
        [resourceId_resourceType, 'fields'],
        comments.newCommentsByResourceId_ResourceType
      );
    },
    shallowEqual
  );

  const isLoading = useSelector<ApplicationState, boolean>(({ comments }) => {
    return pathOr(
      false,
      [resourceId_resourceType, 'isLoading'],
      comments.newCommentsByResourceId_ResourceType
    );
  });

  const apiError = useSelector<ApplicationState, ApiError>(({ comments }) => {
    return pathOr(
      {},
      [resourceId_resourceType, 'apiError'],
      comments.newCommentsByResourceId_ResourceType
    );
  });

  const isNewCommentPopupOpen = useSelector<ApplicationState, boolean>(
    ({ comments }) => {
      return pathOr(
        false,
        [resourceId_resourceType],
        comments.isNewCommentPopupOpenByResourceId_ResourceType
      );
    }
  );

  return {
    create: createComment,
    updateField: updateCommentField,
    showNewCommentPopup: showPopup,
    hideNewCommentPopup: hidePopup,
    fields,
    isNewCommentPopupOpen,
    isLoading,
    apiError,
  };
};

export const useCommentsByResourceId = (
  resourceId: string | number,
  options?: {
    useFilters?: boolean;
    doNotFetchComments?: boolean;
  }
) => {
  const dispatch = useDispatch();
  const { timePeriod } = useTimeFilter();
  const { fromDate, toDate } = timePeriod;
  const { useFilters = false, doNotFetchComments = false } = options || {};

  const commentsAndLoadingStatus = useSelector<
    ApplicationState,
    CommentStatus | undefined
  >(({ comments }) => {
    return comments.commentsByResourceId[resourceId];
  }, shallowEqual);

  const commentFilters = useSelector<
    ApplicationState,
    NonRequiredCommentApiFilters
  >(({ comments }) => {
    return comments.commentFilters;
  }, shallowEqual);

  const { comments = undefined } = commentsAndLoadingStatus || {};

  useEffect(() => {
    let queryParams = {
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    };
    if (useFilters) {
      queryParams = {
        ...commentFilters,
        ...queryParams,
      };
    }

    if (!doNotFetchComments) {
      dispatch(getDownstreamComments(resourceId, queryParams));
    }
  }, [
    doNotFetchComments,
    fromDate,
    toDate,
    commentFilters,
    resourceId,
    useFilters,
    dispatch,
  ]);

  useEffect(() => {
    if (comments && comments.length > 0) {
      const userIds = comments.map((c) => c.userId);
      dispatch(getUsers(uniq(userIds)));
    }
  }, [comments, dispatch]);

  return commentsAndLoadingStatus;
};

export const useCommentFilters = () => {
  return useSelector<ApplicationState, NonRequiredCommentApiFilters>(
    ({ comments }) => {
      return comments.commentFilters;
    }
  );
};

export const useTempCheckCommentCallback = () => {
  const dispatch = useDispatch();

  //*** Actions ***//
  const createComment = useCallback(
    (
      value,
      timestamp: number,
      parentId: number
    ) => {
      const dateStringISO = new Date(timestamp).toISOString();
      dispatch(create({
        from: dateStringISO,
        to: dateStringISO,
        value,
        valueType: ValueType.TEMPERATURE,
        commentType: CommentType.DAILY_CHECK,
        comment: 'Checked',
        resourceId: parentId
      }, parentId));
    },
    [dispatch]
  );

  return createComment;
};
