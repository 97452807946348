import { FilterTimePeriod } from '@energybox/react-ui-library/dist/types';
import { determineDateRangeAfterZoomIn } from '@energybox/react-ui-library/dist/utils';
import { toDate } from 'date-fns';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setNotificationDateFilter } from '../actions/notificationFilter';
import { useTimeFilter } from '../hooks/useFilters';

const useChartZoomUtils = () => {
  const { timePeriod, setTimeFilter } = useTimeFilter();
  const prevTimePeriod = useRef<FilterTimePeriod>(timePeriod);

  const dispatch = useDispatch();

  const [zoomStartTime, setZoomStartTime] = useState<number | undefined>(
    undefined
  );

  const [zoomEndTime, setZoomEndTime] = useState<number | undefined>(undefined);

  useEffect(() => {
    return function cleanUp() {
      dispatch(setNotificationDateFilter(prevTimePeriod.current));
    };
  }, [dispatch]);

  useEffect(() => {
    if (!timePeriod.isZoomingIn) {
      prevTimePeriod.current = timePeriod;
    }
    setZoomStartTime(undefined);
    setZoomEndTime(undefined);
  }, [timePeriod, setTimeFilter]);

  const zoomIn = useCallback(() => {
    if (
      zoomStartTime === zoomEndTime ||
      zoomStartTime === undefined ||
      zoomEndTime === undefined
    ) {
      setZoomStartTime(undefined);
      setZoomEndTime(undefined);
      return;
    }

    const { fromDate: fromDateEpochMs, toDate: toDateEpochMs } =
      determineDateRangeAfterZoomIn(zoomStartTime, zoomEndTime);

    const zoomTimePeriod: FilterTimePeriod = {
      fromDate: toDate(fromDateEpochMs),
      toDate: toDate(toDateEpochMs),
      isZoomingIn: true,
    };
    setZoomStartTime(undefined);
    setZoomEndTime(undefined);

    setTimeFilter(zoomTimePeriod);
  }, [zoomStartTime, zoomEndTime, setTimeFilter]);

  const zoomReset = () => {
    setTimeFilter(prevTimePeriod.current);
  };

  return {
    timePeriod,
    zoomStartTime,
    zoomEndTime,
    setZoomStartTime,
    setZoomEndTime,
    zoomIn,
    zoomReset,
    isZoomed: timePeriod.isZoomingIn || false,
  };
};

/**
 * used for local independent charts (i.e. notification/incident charts) that don't use
 * global redux time filter
 */
export const useLocalChartUtils = (
  initialFromDate: number,
  initialToDate: number
) => {
  const [refAreaStart, setRefAreaStart] = useState<string>('');
  const [refAreaEnd, setRefAreaEnd] = useState<string>('');
  const [chartFromDate, setChartFromDate] = useState<number>(initialFromDate);
  const [chartToDate, setChartToDate] = useState<number>(initialToDate);

  const zoomIn = useCallback(() => {
    if (
      refAreaStart === refAreaEnd ||
      refAreaStart === undefined ||
      refAreaEnd === undefined
    ) {
      setRefAreaStart('');
      setRefAreaEnd('');
      return;
    }

    const { fromDate: fromDateMs, toDate: toDateMs } =
      determineDateRangeAfterZoomIn(+refAreaStart, +refAreaEnd);

    setChartFromDate(fromDateMs);
    setChartToDate(toDateMs);
    setRefAreaStart('');
    setRefAreaEnd('');
  }, [refAreaStart, refAreaEnd]);

  const onZoomReset = () => {
    setChartFromDate(initialFromDate);
    setChartToDate(initialToDate);
  };

  return {
    refAreaStart,
    setRefAreaStart,
    refAreaEnd,
    setRefAreaEnd,
    zoomIn,
    onZoomReset,
    chartFromDate,
    chartToDate,
  };
};

export default useChartZoomUtils;
