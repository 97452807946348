import { SiteOnlineStatus } from '@energybox/react-ui-library/dist/types';
import React, { Component, createRef } from 'react';
import { Map, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import {
  createClusterCustomIcon,
  createMarkerWithPopup,
} from '../../containers/MultiSiteTiles/SitesMapTile/SitesMapArea';
import { ArrayLatLngBounds, getBounds } from '../../utils/map';
import styles from './MapElement.module.css';

function durationForDistance(kilometers: number) {
  return kilometers > 5 ? 0.8 : 0.1;
}

export type Viewport = {
  center: [number, number];
  zoom: number;
};

type Props = {
  onOpenPopup: (Site) => void;
  onClosePopup: () => void;
  onViewportChanged: (Viewport) => void;
  initialBounds?: ArrayLatLngBounds;
  initialViewport?: Viewport;
  sidebarCollapsed: boolean;
  siteStatuses: SiteOnlineStatus[];
};

class MapElement extends Component<Props> {
  mapRef = createRef<Map>();
  markerRefs: any = {};
  worldMaxBounds: [number, number][] = [
    [-90, -180],
    [90, 180],
  ];

  shouldComponentUpdate(prevProps: Props) {
    // Only update if sites change, react-leaflet doesn't handle
    // updates very well.
    // TODO: This might not make too much sense since it disabled
    // most of the react integration... Could use Leaflet directly?
    return (
      prevProps.siteStatuses !== this.props.siteStatuses ||
      prevProps.sidebarCollapsed !== this.props.sidebarCollapsed
    );
  }

  componentDidUpdate(prevProps: Props) {
    // When container resizes, leaflet needs to fetch new tiles and
    // resize the map, but react-leaftlet doesn't react automatically
    // to container resizing. Force a resize when sidebar is toggled
    // by creating window resize event.
    if (prevProps.sidebarCollapsed !== this.props.sidebarCollapsed) {
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 400);
    }
  }

  getLeaflet = () => {
    return this.mapRef.current ? this.mapRef.current.leafletElement : null;
  };

  openPopup = (site: SiteOnlineStatus) => {
    const leaflet = this.getLeaflet();
    if (!leaflet) return;
    if (this.openPopupHandle) {
      clearTimeout(this.openPopupHandle.timerHandle);
      this.openPopupHandle = null;
    }
    leaflet.openPopup(this.markerRefs[site.siteId].leafletElement, {
      lat: site.latitude,
      lng: site.longitude,
    });
  };

  // This function is called from other components via a ref to this component
  focusSite = (site: SiteOnlineStatus) => {
    const leaflet = this.getLeaflet();
    if (!leaflet) return;
    // Close popup if open, otherwise the new popup won't open
    leaflet.closePopup();
    const latLng = { lat: site.latitude, lng: site.longitude };
    const currentZoom = leaflet.getZoom();
    const center = leaflet.getCenter();
    const distance = leaflet.distance(center, latLng) / 1000.0;
    const duration = durationForDistance(distance);
    leaflet.flyTo(latLng, Math.max(currentZoom, 16), {
      duration,
    });
    // Open new popup only ofter animation has (hopefully) completed
    setTimeout(() => {
      this.openPopup(site);
    }, (duration + 0.2) * 1000);
  };

  closeSite = () => {
    const leaflet = this.getLeaflet();
    if (!leaflet) return;
    leaflet.closePopup();
  };

  focusBounds = (bounds) => {
    const leaflet = this.getLeaflet();
    if (!leaflet) return;
    leaflet.fitBounds(bounds);
  };

  getBounds = () => {
    const leaflet = this.getLeaflet();
    if (!leaflet) return null;
    const mapBounds = leaflet.getBounds();
    return mapBounds;
  };

  showAllSites = () => {
    const leaflet = this.getLeaflet();
    if (!leaflet) return;
    leaflet.closePopup();
    this.focusBounds(getBounds(this.props.siteStatuses));
  };

  onSiteClick = (event) => {
    const leaflet = this.getLeaflet();
    if (!leaflet) return;
    // Close popup if open, otherwise the new popup won't open
    leaflet.closePopup();
    const center = leaflet.getCenter();
    const distance = leaflet.distance(center, event.latlng) / 1000.0;
    const currentZoom = leaflet.getZoom();
    const duration = durationForDistance(distance);
    leaflet.flyTo(event.latlng, Math.max(currentZoom, 16), {
      duration,
    });

    // Open new popup only ofter animation has (hopefully) completed
    setTimeout(() => {
      if (this.openPopupHandle) {
        clearTimeout(this.openPopupHandle.timerHandle);
        this.openPopupHandle = null;
      }
      event.target.openPopup();
    }, (duration + 0.2) * 1000);
  };

  /*
    Popup is opened by hover on the marker. To stay consistent, we also
    want to close it on mouse leave. This causes a problem because when
    user moves mouse from the marker to the popup, it will instantly
    close, preventing interaction. To overcome this:
    * Defer closing the popup when mouse leaves the marker by 500ms
    * If users moves cursor to popover within this 500ms, cancel closing
      the popup entirely
    * Then only close the popup once cursor leaves popover
    * Cornercases where user moves from popover directly to another marker
      should be handled by leaflet which ensures that only one popup is
      open at any given time.
  */
  openPopupHandle: {
    target: any;
    timerHandle: number;
  } | null = null;

  render() {
    return (
      <Map
        attributionControl={false}
        className="markercluster-map"
        zoom={12}
        minZoom={2}
        maxZoom={18}
        style={{ height: 'calc(100vh - 40px - 3.75rem)', width: '100%' }}
        ref={this.mapRef}
        onViewportChanged={this.props.onViewportChanged}
        bounds={
          !this.props.initialViewport ? this.props.initialBounds : undefined
        }
        maxBounds={this.worldMaxBounds}
        maxBoundsViscosity={0.77}
        viewport={this.props.initialViewport}
      >
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/eb-mb/cjsv8jqst6s821fruiv0865kv/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZWItbWIiLCJhIjoiY2pzdjRucmNsMDM3YjQ0cHVraWtwN3BjaiJ9.EnEZGKjFPe1pSx8hhzL1Lw`}
          attribution='&copy; <a href="http://mapbox.com">Mapbox</a> | &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | <a href="https://apps.mapbox.com/feedback/#/-74.5/40/10">Improve this map</a>'
        />
        <MarkerClusterGroup
          showCoverageOnHover={false}
          spiderfyOnMaxZoom={true}
          iconCreateFunction={createClusterCustomIcon}
        >
          {this.props.siteStatuses.map(
            createMarkerWithPopup(true, this.markerRefs)
          )}
        </MarkerClusterGroup>
        <div className={styles.mapboxWordmark} />
      </Map>
    );
  }
}

export default MapElement;
