import { fetchWrapper } from '../utils/fetchApi';
import { getServiceUrl, Service } from '../config';

const ALLOWED_METHODS = ['GET', 'POST', 'PUT', 'PATCH', 'UPDATE', 'DELETE'];

const matcher = new RegExp(`^API_(${ALLOWED_METHODS.join('|')})$`);

const dispatchAction = (store: any, action: any, data: any = {}) => {
  if (typeof action === 'string') {
    store.dispatch({ type: action, ...data });
  } else {
    store.dispatch({ ...action, ...data });
  }
};

const requestCache = new Set<string>();

const apiMiddleware = (store) => (next) => (action) => {
  const matchedGroup = action.type ? action.type.match(matcher) : {};
  if (matchedGroup) {
    const { path, payload, service, contentType } = action;

    const successAction = action.success || '@@API/UNHANDLED_SUCCESS_RESPONSE';
    const errorAction = action.error || '@@API/UNHANDLED_ERROR_RESPONSE';

    const method = matchedGroup[1];

    const url = getServiceUrl(service) + path;
    const urlKey = method + url;

    if (action.loading || (action.type && action.type.loading)) {
      if (requestCache.has(urlKey)) {
        return;
      }

      requestCache.add(urlKey);
      dispatchAction(store, action.loading);
    }

    fetchWrapper({
      store,
      action,
      url,
      method,
      payload,
      limit: action.limit,
      offset: action.offset,
      contentType,
    })
      .then((response) => {
        dispatchAction(store, response.error ? errorAction : successAction, {
          payload: response,
        });
        requestCache.delete(urlKey);
      })
      .catch((error) => {
        dispatchAction(store, errorAction, { data: error });
        requestCache.delete(urlKey);
      });
  } else {
    next(action);
  }
};

export default apiMiddleware;
