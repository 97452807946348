import {
  Site,
  SiteAstronomicalClock,
  SitesById,
} from '@energybox/react-ui-library/dist/types';
import { DateTime } from 'luxon';
import { any } from 'prop-types';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import merge from 'ramda/src/merge';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/reportworker';
import { ReportStatus, StatusDetail } from '../types/reportworker';

const fileIdsString = localStorage.getItem('saveFileIds');
const fileIdsData =
  typeof fileIdsString === 'string' ? JSON.parse(fileIdsString) : [];

const reportWorkString = localStorage.getItem('reportWorkerData');
const reportWorkData =
  typeof reportWorkString === 'string' ? JSON.parse(reportWorkString) : [];

const initialState = {
  reportWorker: reportWorkData,
  notifyStatus: false,
  fileCounter: 0,
  toggleIcon: false,
  saveFileIds: fileIdsData,
  latestCount: 0,
  initiateFileDownload: false,
  activateLink: false,
  saveEquipData: {},
  pageStatus: {},
};

export type PageStatus = {
  [p: string]: StatusDetail;
};

export type ReportWorker = {
  reportWorker: any;
  notifyStatus: boolean;
  fileCounter: number;
  toggleIcon: boolean;
  saveFileIds: any;
  latestCount: number;
  initiateFileDownload: boolean;
  activateLink: boolean;
  saveEquipData: any;
  pageStatus: PageStatus;
};

export default (state: ReportWorker = initialState, action: any) => {
  const reportWorkerString = localStorage.getItem('reportWorkerData');
  const reportWorkerData =
    typeof reportWorkerString === 'string'
      ? JSON.parse(reportWorkerString)
      : [];

  const saveFileIdsString = localStorage.getItem('saveFileIds');
  const saveFileIdsData =
    typeof saveFileIdsString === 'string' ? JSON.parse(saveFileIdsString) : [];

  switch (action.type) {
    case Actions.REPORT_WORKER_LOADING:
      return {
        ...state,
        reportWorker: action.payload,
        pageStatus: action.page
          ? {
              ...state.pageStatus,
              [action.page]: { status: ReportStatus.TRIGGER_LOADING, msg: '' },
            }
          : state.pageStatus,
      };

    case Actions.REPORT_WORKER_SUCCESS:
      localStorage.setItem(
        'reportWorkerData',
        JSON.stringify([...reportWorkerData, action.payload])
      );

      return {
        ...state,
        reportWorker: [...reportWorkerData, action.payload],
        notifyStatus: true,
        toggleIcon: true,
        pageStatus: action.page
          ? {
              ...state.pageStatus,
              [action.page]: { status: ReportStatus.TRIGGER_SUCCESS, msg: '' },
            }
          : state.pageStatus,
      };

    case Actions.REPORT_WORKER_ERROR:
      let payload =
        { ...action.payload, ...{ failedAt: DateTime.now().toMillis() } } || {};
      if (Object.keys(payload).length === 0) {
        payload = {
          jobId: undefined,
          fileName: undefined,
          errorStatusResponse: {
            errorCode: 503,
            message: 'Please try again later',
          },
          failedAt: DateTime.now().toMillis(),
        };
      }
      localStorage.setItem(
        'reportWorkerData',
        JSON.stringify([...reportWorkerData, payload])
      );
      localStorage.setItem(
        'saveFileIds',
        JSON.stringify(state.saveFileIds.filter((val) => val !== payload.jobId))
      );
      return {
        ...state,
        reportWorker: [...reportWorkerData, payload],
        saveFileIds: state.saveFileIds.filter((val) => val !== payload.jobId),
        pageStatus: action.page
          ? {
              ...state.pageStatus,
              [action.page]: {
                status: ReportStatus.TRIGGER_SUCCESS,
                msg: payload?.errorStatusResponse?.message || '',
              },
            }
          : state.pageStatus,
      };

    case Actions.NOTIFIY_DOWNLOAD_STATUS:
      return {
        ...state,
        notifyStatus: action.payload,
      };

    case Actions.SET_DOWNLOAD_FILE_COUNTER:
      return {
        ...state,
        fileCounter: action.payload,
      };

    case Actions.TOGGLE_HIDE_SHOW_ICON:
      return {
        ...state,
        toggleIcon: action.payload,
      };

    case Actions.UPDATE_REPORT_WORKER_JOBS:
      localStorage.setItem('reportWorkerData', JSON.stringify(action.payload));
      return {
        ...state,
        reportWorker: action.payload,
      };

    case Actions.REMOVE_OUTDATED_REPORT_WORKER_JOBS:
      const now = DateTime.now().toMillis();
      const filteredReportWorkerData =
        reportWorkerData instanceof Array
          ? reportWorkerData.filter((data) => {
              if (data.failedAt && data.failedAt > 0) {
                return now - data.failedAt < 86400000;
              }
              return true;
            })
          : [];
      localStorage.setItem(
        'reportWorkerData',
        JSON.stringify(filteredReportWorkerData)
      );
      return {
        ...state,
        reportWorker: filteredReportWorkerData,
        toggleIcon: filteredReportWorkerData.length !== 0,
        notifyStatus: filteredReportWorkerData.length !== 0,
      };

    case Actions.GET_FILE_IDS:
      return {
        ...state,
        saveFileIds: saveFileIdsData,
      };

    case Actions.SAVE_FILE_IDS:
      const newSaveFileIds = [...state.saveFileIds, action.payload].filter(
        (e, i) => {
          return [...state.saveFileIds, action.payload].indexOf(e) === i;
        }
      );
      localStorage.setItem('saveFileIds', JSON.stringify(newSaveFileIds));
      return {
        ...state,
        saveFileIds: newSaveFileIds,
      };

    case Actions.REMOVE_FILE_IDS:
      localStorage.setItem(
        'saveFileIds',
        JSON.stringify(
          state.saveFileIds.filter((val) => val !== action.payload)
        )
      );
      return {
        ...state,
        saveFileIds: state.saveFileIds.filter((val) => val !== action.payload),
      };

    case Actions.INCREMENT_FILE_COUNT:
      return {
        ...state,
        latestCount: state.latestCount + action.payload,
      };

    case Actions.DECREMENT_FILE_COUNT:
      return {
        ...state,
        latestCount:
          state.latestCount > 0
            ? state.latestCount - action.payload
            : state.latestCount,
      };

    case Actions.LATEST_FILE_COUNT:
      return {
        ...state,
        latestCount: saveFileIdsData.length,
      };

    case Actions.INITIATE_FILE_DOWNLOAD:
      return {
        ...state,
        initiateFileDownload: action.payload,
      };

    case Actions.DEINITIATE_FILE_DOWNLOAD:
      return {
        ...state,
        initiateFileDownload: action.payload,
      };

    case Actions.GET_INITIATE_FILE_DOWNLOAD:
      return {
        ...state,
        initiateFileDownload: state.initiateFileDownload,
      };

    case Actions.SAVE_EQUIP_DATA_FOR_DOWNLOAD:
      return {
        ...state,
        saveEquipData: action.payload,
      };
    case Actions.CLEAR_EQUIP_DATA_FOR_DOWNLOAD:
      return {
        ...state,
        saveEquipData: {},
      };
    case Actions.GET_EQUIP_DATA_FOR_DOWNLOAD:
      return {
        ...state,
        saveEquipData: state.saveEquipData,
      };

    default:
      return state;
  }
};
