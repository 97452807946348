export enum Actions {
  INIT = '@@app/INIT',
  INIT_WITH_TOKEN = '@@app/INIT_WITH_TOKEN',

  ACCESS_TOKEN_UPDATED = '@@app/ACCESS_TOKEN_UPDATED',
  ACCESS_TOKEN_CLEARED = '@@app/ACCESS_TOKEN_CLEARED',

  RESET_SOCKET_CONNECTION = '@@app/RESET_SOCKET_CONNECTION',

  LOGIN_SUCCESS = '@@app/LOGIN_SUCCESS',
  LOGIN_LOADING = '@@app/LOGIN_LOADING',
  LOGIN_ERROR = '@@app/LOGIN_ERROR',
  UPDATE_LOGIN_FIELD = '@app/UPDATE_LOGIN_FIELD',

  FORGOT_PASSWORD_SUCCESS = '@app/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_LOADING = '@app/FORGOT_PASSWORD_LOADING',
  FORGOT_PASSWORD_ERROR = '@app/FORGOT_PASSWORD_ERROR',
  UPDATE_FORGOT_PASSWORD_FIELD = '@app/UPDATE_FORGOT_PASSWORD_FIELD',

  RESET_PASSWORD_SUCCESS = '@app/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_LOADING = '@app/RESET_PASSWORD_LOADING',
  RESET_PASSWORD_ERROR = '@app/RESET_PASSWORD_ERROR',
  UPDATE_RESET_PASSWORD_FIELD = '@app/UPDATE_RESET_PASSWORD_FIELD',

  LOGIN_FROM_LOCALSTORAGE = '@@LOGIN_FROM_LOCALSTORAGE',

  LOGOUT = '@@app/LOGOUT',

  CURRENT_USER_SUCCESS = '@@app/CURRENT_USER_SUCCESS',
  CURRENT_USER_ERROR = '@@app/CURRENT_USER_ERROR',
  CURRENT_USER_LOADING = '@@app/CURRENT_USER_LOADING',

  CHANGE_ORGANIZATION_SUCCESS = '@@app/CHANGE_ORGANIZATION_SUCCESS',
  CHANGE_ORGANIZATION_ERROR = '@@app/CHANGE_ORGANIZATION_ERROR',
  CHANGE_ORGANIZATION_LOADING = '@@app/CHANGE_ORGANIZATION_LOADING',

  GET_TIMEZONES_SUCCESS = '@app/GET_TIMEZONES_SUCCESS',
  GET_TIMEZONES_ERROR = '@app/GET_TIMEZONES_ERROR',
  GET_TIMEZONES_LOADING = '@app/CHANGE_ORGANIZATION_LOADING',

  USER_SITE_CURRENT_SITE = '@app/USER_SITE_CURRENT_SITE',

  UPDATE_SCROLL_POSITION = '@app/UPDATE_SCROLL_POSITION',
  ACTIVATE_UPDATE_SCROLL_POSITION = '@app/ACTIVATE_UPDATE_SCROLL_POSITION',

  TOGGLE_MOBILE_MENU = '@app/TOGGLE_MOBILE_MENU',

  USER_TYPE_SUCCESS = '@@user/USER_TYPE_SUCCESS',
  USER_TYPE_ERROR = '@@user/USER_TYPE_ERROR',

  USER_CHANGE_PASSWORD_LOADING = '@users/USER_CHANGE_PASSWORD_LOADING',
  USER_CHANGE_PASSWORD_ERROR = '@users/USER_CHANGE_PASSWORD_ERROR',
  USER_CHANGE_PASSWORD_SUCCESS = '@users/USER_CHANGE_PASSWORD_SUCCESS',
  USER_CHANGE_PASSWORD_CLEAR_AUTH_ERROR = '@users/USER_CHANGE_PASSWORD_CLEAR_AUTH_ERROR',

  PATCH_DASHBOARD_SUCCESS = '@dashboards/PATCH_DASHBOARD_SUCCESS',
  PATCH_DASHBOARD_ERROR = '@dashboards/PATCH_DASHBOARD_ERROR',
  PATCH_DASHBOARD_LOADING = '@dashboards/PATCH_DASHBOARD_LOADING',

  CREATE_DASHBOARD_SUCCESS = '@dashboards/CREATE_DASHBOARD_SUCCESS',
  CREATE_DASHBOARD_ERROR = '@dashboards/CREATE_DASHBOARD_ERROR',
  CREATE_DASHBOARD_LOADING = '@dashboards/CREATE_DASHBOARD_LOADING',

  DELETE_DASHBOARD_SUCCESS = '@dashboards/DELETE_DASHBOARD_SUCCESS',
  DELETE_DASHBOARD_ERROR = '@dashboards/DELETE_DASHBOARD_ERROR',
  DELETE_DASHBOARD_LOADING = '@dashboards/DELETE_DASHBOARD_LOADING',

  DISMISS_NEW_PASSWORD_POPUP = '@app/DISMISS_NEW_PASSWORD_POPUP',

  SET_LAST_SERVER_TIME = '@app/SET_LAST_SERVER_TIME',
}
