import { KWH_UNIT } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import {
  Bar,
  Cell,
  ComposedChart,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styles from './RechartsBarWithBaseline.module.css';

const AVERAGE_TITLE = 'Average of All Sites';
export const AVERAGE_ID = 'avg';

export type BarChartWithBaselineData<T> = {
  id: string;
  value: number;
  title: string;
  dataObject?: T;
};

type Props<T> = {
  countryCode: string;
  data: BarChartWithBaselineData<T>[];
  baseline: number;
  // I can't figure out how to type this well... You need to pass in a
  // component for recharts `tick` prop for <YAxis>
  CustomYTick: React.ElementType;
  CustomTooltip: React.ElementType;
  yAxisWidth?: number;
  yTickMargin?: number;
};

const RechartsBarWithBaseline = <T extends object>({
  countryCode,
  data,
  baseline,
  CustomYTick,
  CustomTooltip,
  yAxisWidth = 140,
  yTickMargin = 20,
}: Props<T>) => {
  if (!data || data.length === 0 || countryCode === '') {
    return <div data-testid="noData" />;
  } else {
    const dataWithBaseline = insertBaseline(data, baseline);
    return (
      <ResponsiveContainer height={300} width="100%">
        <ComposedChart data={dataWithBaseline} layout="vertical">
          <XAxis
            type="number"
            tick={{ fontSize: '10px', fill: 'var(--base50)' }}
            axisLine={{ stroke: 'var(--base50)' }}
            tickLine={false}
          >
            <Label
              value={KWH_UNIT}
              position={'insideBottom'}
              dy={10}
              className={styles.axisLabel}
            />
          </XAxis>
          <YAxis
            type="category"
            dataKey="title"
            axisLine={{ stroke: 'none' }}
            tickLine={false}
            tick={
              <CustomYTick
                data={dataWithBaseline}
                averageTitle={AVERAGE_TITLE}
              />
            }
            width={yAxisWidth}
            tickMargin={yTickMargin}
          />
          <Bar dataKey="value" unit={KWH_UNIT} barSize={30} legendType="circle">
            {dataWithBaseline.map((entry) => {
              return (
                <Cell
                  key={`${entry.id}BarWithBaselineCell`}
                  fill={entry.id === AVERAGE_ID ? '#626DF9' : '#2ADDD0'}
                />
              );
            })}
          </Bar>
          <Tooltip
            cursor={false}
            //@ts-ignore because this is how the documentation suggest we handle custom tooltip
            content={<CustomTooltip />}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
};

const insertBaseline = <T extends object>(
  data: BarChartWithBaselineData<T>[],
  baseline: number
) => {
  const baselineData: BarChartWithBaselineData<T> = {
    id: AVERAGE_ID,
    title: AVERAGE_TITLE,
    value: baseline,
    dataObject: undefined,
  };
  if (baseline > data[0].value) {
    return [baselineData, ...data];
  } else if (baseline < data[data.length - 1].value) {
    return [...data, baselineData];
  } else {
    let indexLocationToPlace = 0;
    for (let idx = 0; idx < data.length - 1; idx++) {
      const current = data[idx].value;
      const next = data[idx + 1].value;
      if (baseline < current && baseline > next) {
        indexLocationToPlace = idx + 1;
      }
    }
    const newData = [...data];
    newData.splice(indexLocationToPlace, 0, baselineData);
    return newData;
  }
};

export default RechartsBarWithBaseline;
