import { useCallback, useMemo } from 'react';
import { getUrlStateParams } from '../util';
import history from '../history';
import { areArraysIdentical } from '../utils/areArraysIdentical';
import {
  SentinelType,
  Space,
  WorkingModeLabel,
} from '@energybox/react-ui-library/dist/types';
import mixpanel from 'mixpanel-browser';

const useDynamicFilter = <
  T extends
    | string[]
    | string
    | number[]
    | number
    | WorkingModeLabel
    | SentinelType
    | Space
>(
  filterType: string,
  transformFn?: (value: string) => T
) => {
  const search = history?.location?.search;
  const selectedFilters: T[] = useMemo(() => {
    let filters = getUrlStateParams(history, filterType, []);

    return transformFn ? filters.map(transformFn) : filters;
  }, [search, filterType]);

  const setFilter = useCallback(
    (props) => {
      const filterValues = Array.isArray(props) ? props : [props];
      const filtersMappedToFilterHaveNotChanged = areArraysIdentical(
        filterValues,
        selectedFilters
      );

      if (filtersMappedToFilterHaveNotChanged) {
        return;
      }

      mixpanel?.track('Filter Applied', {
        type: filterType,
        filterValues: filterValues,
      });

      setFilterAndUpdateUrl(filterValues, filterType);
    },
    [selectedFilters, filterType]
  );

  return {
    setFilter,
    selectedFilters,
  };
};

export default useDynamicFilter;

const setFilterAndUpdateUrl = (items, filterType) => {
  const pathname = history?.location?.pathname;
  const updatedSearchParams = new URLSearchParams(history?.location?.search);

  updatedSearchParams.delete(filterType);

  if (Array.isArray(items)) {
    items.forEach((value) => {
      updatedSearchParams.append(filterType, value);
    });
  }

  history?.push({ pathname, search: updatedSearchParams.toString() });
};
