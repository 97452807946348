import {
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';
import { Trash as TrashIcon } from '@energybox/react-ui-library/dist/icons';
import React from 'react';

type Props = {
  onRemoveTile: () => void;
};

const TilesMenuDropdown = ({ onRemoveTile }: Props) => {
  return (
    <div style={{ paddingLeft: '5px' }}>
      <MenuDropdown>
        <MenuDropdownItem isRed onSelect={onRemoveTile}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TrashIcon size={16} />

            <span style={{ paddingLeft: '0.25rem' }}>Remove tile</span>
          </div>
        </MenuDropdownItem>
      </MenuDropdown>
    </div>
  );
};

export default TilesMenuDropdown;
