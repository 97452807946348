import { SearchBox } from '@energybox/react-ui-library/dist/components';
import { SiteOnlineStatus } from '@energybox/react-ui-library/dist/types';
import React, { useState } from 'react';
import ChevronUpTiny from '../icons/ui/ChevronUpTiny';
import styles from './MapSidebar.module.css';
import SearchResult from './SearchResult';

function sortResults(a: SiteOnlineStatus, b: SiteOnlineStatus) {
  const aCount =
    a.numberActiveIncidents !== undefined ? a.numberActiveIncidents : 0;
  const bCount =
    b.numberActiveIncidents !== undefined ? b.numberActiveIncidents : 0;
  const diff = bCount - aCount;
  return diff === 0 ? a.title.localeCompare(b.title) : diff;
}

type Props = {
  query?: string;
  handleQueryChange: (query: string) => void;
  handleClick: (site: SiteOnlineStatus) => void;
  openSite?: SiteOnlineStatus;
  siteStatuses?: SiteOnlineStatus[];
};

const MapSidebar = ({
  query,
  handleQueryChange,
  handleClick,
  openSite,
  siteStatuses,
}: Props) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const filteredSiteStatuses =
    query !== undefined && siteStatuses !== undefined
      ? siteStatuses.filter((filterSite) => {
          return filterSite.title

            .toLowerCase()

            .trim()

            .match(query.trim().toLocaleLowerCase());
        })
      : siteStatuses;

  return (
    <div className={styles.container}>
      <div
        className={styles.searchBoxContainer}
        onClick={() => setSidebarCollapsed(false)}
      >
        <SearchBox
          width="100%"
          widthActive="100%"
          placeholder="Search site by name"
          query={query}
          onChange={handleQueryChange}
          error={
            !filteredSiteStatuses ||
            (filteredSiteStatuses.length === 0 && !!query)
          }
        />
      </div>
      <div className={sidebarCollapsed ? styles.containerCollapsed : ''}>
        <div className={styles.searchResults}>
          {filteredSiteStatuses !== undefined &&
            filteredSiteStatuses
              .sort((a, b) => sortResults(a, b))
              .map((site) => (
                <SearchResult
                  key={site.siteId}
                  site={site}
                  onClick={handleClick}
                  isOpen={site === openSite}
                />
              ))}
        </div>

        <div
          className={styles.hideButton}
          onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
        >
          <span className={styles.hideText}>
            <ChevronUpTiny size={16} /> &nbsp; Hide
          </span>
        </div>
      </div>
    </div>
  );
};

export default MapSidebar;
