export enum Actions {
  CONNECTED = '@@streamApi/CONNECTED',
  ERROR = '@@streamApi/ERROR',
  CLOSED = '@@streamApi/CLOSED',
  SEND_MESSAGE = '@@streamApi/SEND_MESSAGE',
  RECEIVED_DEVICE_STATUS = '@@streamApi/RECEIVED_DEVICE_STATUS',
  RECEIVED_DEVICE_READING = '@@streamApi/RECEIVED_DEVICE_READING',
  RECEIVED_SUBSCRIBED_INCIDENT = '@@streamApi/RECEIVED_SUBSCRIBED_INCIDENT',
  RECEIVED_SUBSCRIBED_REPORT = '@@streamApi/RECEIVED_SUBSCRIBED_REPORT',
  RECEIVED_REPORT_STATUS = '@@streamApi/RECEIVED_REPORT_STATUS',
}

export enum CommandType {
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  ENQUEUE_SENSOR_ACTION = 'ENQUEUE_SENSOR_ACTION',
}

export enum Channel {
  PING = 'PING',
  DEVICE_STATUS = 'DEVICE_STATUS',
  SENSOR_READINGS = 'SENSOR_READINGS',
  INCIDENT_INFO = 'INCIDENT_INFO',
  REPORT_WORKER = 'REPORT_WORKER',
  REPORTS_STATUS = 'REPORTS_STATUS',
}

export enum SensorAction {
  SET_REPORT_INTERVAL = 'SET_REPORT_INTERVAL',
  SET_POLLING_INTERVAL = 'SET_POLLING_INTERVAL',
  EMIT_SENSOR_CONFIG = 'EMIT_SENSOR_CONFIG',
  EMIT_SENSOR_STATUS = 'EMIT_SENSOR_STATUS',
  TOGGLE_LED = 'TOGGLE_LED',
}

export const subscribeToDeviceReadings = (
  vendor: string,
  uuid: string,
  id?: number | string
) => {
  return {
    type: Actions.SEND_MESSAGE,
    data: {
      type: CommandType.SUBSCRIBE,
      channel: Channel.SENSOR_READINGS,
      vendor,
      uuid,
      id,
    },
  };
};

export const unsubscribeFromDeviceReadings = (
  vendor: string,
  uuid: string,
  id?: number | string
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.SENSOR_READINGS,
    vendor,
    uuid,
    id,
  },
});

export const subscribeToDeviceStatus = (
  vendor: string,
  uuid: string,
  id?: number | string
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.SUBSCRIBE,
    channel: Channel.DEVICE_STATUS,
    vendor,
    uuid,
    id,
  },
});

export const unsubscribeFromDeviceStatus = (
  vendor: string,
  uuid: string,
  id?: number | string
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.DEVICE_STATUS,
    vendor,
    uuid,
    id,
  },
});

export const subscribeToIncident = ({
  equipmentId,
  spaceId,
}: {
  equipmentId?: number | string;
  spaceId?: number | string;
}) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.SUBSCRIBE,
    channel: Channel.INCIDENT_INFO,
    equipmentId,
    spaceId,
  },
});

export const unsubscribeFromIncident = ({
  equipmentId,
  spaceId,
}: {
  equipmentId?: number | string;
  spaceId?: number | string;
}) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.INCIDENT_INFO,
    equipmentId,
    spaceId,
  },
});

// Subscribe and unscribe for report worker
export const subscribeToReportWorker = ({
  jobId,
}: {
  jobId?: number | string;
}) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.SUBSCRIBE,
    channel: Channel.REPORT_WORKER,
    jobId,
  },
});

export const unsubscribeFromReportWorker = ({
  jobId,
}: {
  jobId?: number | string;
}) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.REPORT_WORKER,
    jobId,
  },
});

// Subscribe to report worker status
export const subscribeToWorkerStatus = (jobId?: string | number) => {
  return {
    type: Actions.SEND_MESSAGE,
    data: {
      type: CommandType.SUBSCRIBE,
      channel: Channel.REPORTS_STATUS,
      jobId,
    },
  };
};

// Un-Subscribe to report worker status
export const unsubscribeFromWorkerStatus = (jobId?: string | number) => {
  return {
    type: Actions.SEND_MESSAGE,
    data: {
      type: CommandType.UNSUBSCRIBE,
      channel: Channel.REPORTS_STATUS,
      jobId,
    },
  };
};
