import { Dispatch } from 'redux';
import { ApplicationState } from '../reducers';
import { Actions } from '../actions/reportworker';
import mixpanel from 'mixpanel-browser';
import mixpanelEvents from '../mixpanelEvents';

const mixpanelMiddleware =
  (store: { getState: () => ApplicationState; dispatch: Dispatch }) =>
  (next: (action: any) => any) =>
  (action: any) => {
    if (action.type === Actions.REPORT_WORKER_SUCCESS) {
      const requestBody = action.requestBody;

      if (requestBody.searchFilter && requestBody.searchFilter.siteIds) {
        requestBody.siteIds = requestBody.searchFilter.siteIds;
        delete requestBody.searchFilter;
      }
      if (requestBody.reportOrgImage) {
        delete requestBody.reportOrgImage;
      }
      if (requestBody.token) {
        delete requestBody.token;
      }
      if (requestBody.siteId) {
        requestBody.siteId = requestBody.siteId.toString();
      }

      // if unix timestamp format, convert it to ISO string
      if (typeof requestBody.reportFromDate === 'number') {
        requestBody.reportFromDate = new Date(
          requestBody.reportFromDate * 1000
        ).toISOString();
      }
      if (typeof requestBody.reportToDate === 'number') {
        requestBody.reportToDate = new Date(
          requestBody.reportToDate * 1000
        ).toISOString();
      }

      mixpanel.track(mixpanelEvents.REPORT_GENERATION, requestBody);
    }
    next(action);
  };

export default mixpanelMiddleware;
