import { HvacControl } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { pathOr } from 'ramda';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHvacControlsBySiteId } from '../../actions/controls';
import { ApplicationState } from '../../reducers';

export const useGetHvacControlsBySiteId = (
  siteId: number | string | undefined | null
) => {
  const dispatch = useDispatch();

  const siteHvacControls: HvacControl[] | undefined = useSelector(
    ({ controls }: ApplicationState) => {
      if (!isDefined(siteId)) return undefined;
      return pathOr(undefined, [siteId, 'data'], controls.hvacControlsBySiteId);
    }
  );

  useEffect(() => {
    if (!isDefined(siteId)) return;
    dispatch(getHvacControlsBySiteId(siteId));
  }, [siteId, dispatch]);

  return siteHvacControls;
};
