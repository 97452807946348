import {
  MediumLongSkeletonCell,
  SearchBox,
  SkeletonDisplayText,
  MenuDropdown,
  MenuDropdownItem,
  FileIcon,
} from '@energybox/react-ui-library/dist/components';
import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  Height,
  Length,
  OpacityIndex,
  Site,
  TimeZone,
  Locale,
} from '@energybox/react-ui-library/dist/types';
import {
  asIsoWithTz,
  genericTableSort,
  getUTCDate,
  SORT_IGNORED_VALUES,
  determineTimeStringByTimezone,
  displayByte,
} from '@energybox/react-ui-library/dist/utils';
import { Download } from '@energybox/react-ui-library/dist/icons';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import parseISO from 'date-fns/parseISO';
import * as R from 'ramda';
import { Component, default as React, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { getTimeZones } from '../../actions/app';
import {
  REGIONAL_MANAGER_NOTIFICATIONS,
  fetchNotificationCountsBySite,
} from '../../actions/fetchNotifications';
import { getSites } from '../../actions/sites';
import {
  initiateDownload,
  getFiles,
  fileDownload,
  deleteFiles,
  toggleDeleteStatus,
} from '../../actions/files';
import {
  getFileIDs,
  getReportWorker,
  notifyDownload,
  updateReportWorkerJobs,
} from '../../actions/reportworker';
import BaseContentContainer from '../../components/layout/BaseContentContainer';
import { ApplicationState } from '../../reducers';
import { SiteOnlineStatusById } from '../../reducers/siteOnlineStatusById';
import { capitalizeFirstLetter } from '../../util';
import SiteMobileNav from '../views/sites/SiteMobileNav';
import styles from './MyDownloads.module.css';
import BaseFilterBar from '../../components/Filters/BaseFilterBar';
import PdfIcon from '../../components/icons/PdfIcon';
import CsvIcon from '../../components/icons/CsvIcon';
import { useDispatch } from 'react-redux';
import useAppLocale from '../../hooks/useAppLocale';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import {
  toggleShowHideIcon,
  saveFileIDs,
  removeFileIDs,
} from '../../actions/reportworker';
import {
  incrementFileCount,
  decrementFileCount,
  latestFileCount,
  clearEquipDataForDownload,
} from '../../actions/reportworker';
import { PageContentHeader } from '../../components/Page';
import { TableWrapper } from '../../components/Tables';
import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import theme from '../../styles/theme';
import { FileType } from '@energybox/react-ui-library/dist/types/FileType';
import { Box } from '@material-ui/core';
import { ReportStatusById } from '../../reducers/subscribeReport';
import { File } from '../../reducers/files';
import mixpanel from 'mixpanel-browser';
import mixpanelEvents from '../../mixpanelEvents';

const ReportStartStatus = ['STARTED'];
const ReportMidStatus = ['GENERATING', 'STORING'];
const ReportSuccessStatus = ['SUCCESS'];
const ReportFailureStatus = ['FAILURE'];
const ReportEndStatus = [...ReportSuccessStatus, ...ReportFailureStatus];
const ReportAllStatus = [
  ...ReportStartStatus,
  ...ReportMidStatus,
  ...ReportEndStatus,
];

const hasSubstr = (s: string, q: string) =>
  (s || '').toLowerCase().indexOf((q || '').toLowerCase()) !== -1;

type Props = {
  path: string;
  sites: Site[];
  timezones?: TimeZone[];
  onlineStatus: SiteOnlineStatusById;
  getSites: () => void;
  getTimeZones: () => void;
  fetchDownloadFiles: any;
  getFiles: any;
  fileDownload: any;
  deleteFiles: any;
  toggleDeleteStatus: any;
  getReportWorker: any;
  initiateDownload: any;
  child?: any;
  isSitesLoading: boolean;
  siteId: string | number;
  files: File[];
  totalFileCount: number;
  reportStatusData: any;
  equipData: any;
  localStorageReportsData: any;
  locale: any;
  timezone: string;
  deleteFile: any;
  accessToken: any;
  fileCounter: any;
  toggleShowHideIcon: any;
  notifyDownload: any;
  toggleIcon: any;
  saveFileId: any;
  saveFileIDs: any;
  removeFileIDs: any;
  incrementFileCount: any;
  decrementFileCount: any;
  latestFileCount: any;
  clearEquipDataForDownload: any;
  isMobile: boolean;
  updateReportWorkerJobs: any;
};

type State = {
  query: string;
  reportWorkerData: any;
  confirmDelete: any;
  dialogOpen: any;
  getReportData: any;
  deleteReportStatus: any;
  allFiles: any;
  downloading: boolean;
  recentFile: boolean;
  totalFiles: number;
  setJobIdForDel: string;
  dialogSaveFileId: any;
};

const MyDownloadWrapper = (props: Props) => {
  const dispatch = useDispatch();
  const isMobile = useViewportType() === ViewportTypes.MOBILE;

  /*
  Get The Saved Equipment Data
  */
  let getEquipData = useSelector(
    ({ reportworker }: ApplicationState) => reportworker.saveEquipData
  );

  // Get File Counter from store
  let fileCounter = useSelector(
    ({ reportworker }: ApplicationState) => reportworker.fileCounter
  );

  // Get Report Worker jobId's
  let saveFileId = useSelector(
    ({ reportworker }: ApplicationState) => reportworker.saveFileIds
  );

  // Get reportWorker job array from localStorage
  const reportWorker = useSelector(
    ({ reportworker }: ApplicationState) => reportworker.reportWorker
  );

  // Get latest report status update from channel
  const subscribedReportStatusById = useSelector<
    ApplicationState,
    ReportStatusById | undefined
  >(({ subscribedReportWorker }) => subscribedReportWorker.reportStatusById);

  const locale = useAppLocale();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    dispatch(getFileIDs());
  }, []);

  return (
    <MyDownloads
      {...props}
      equipData={getEquipData}
      reportStatusData={subscribedReportStatusById}
      localStorageReportsData={reportWorker}
      locale={locale}
      timezone={timezone}
      fileCounter={fileCounter}
      saveFileId={saveFileId && saveFileId.filter((item) => item && item)}
    />
  );
};

export class MyDownloads extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      reportWorkerData: {},
      confirmDelete: false,
      dialogOpen: false,
      getReportData: '',
      deleteReportStatus: true,
      allFiles: this.props.files,
      downloading: false,
      recentFile: false,
      totalFiles: this.props.files && this.props.files.length,
      setJobIdForDel: '',
      dialogSaveFileId: [],
    };
  }

  componentDidMount() {
    this.props.getSites();
    this.props.getTimeZones();
    this.props.getFiles();
  }

  // Re-render the component when delete file is successful
  componentWillReceiveProps(nextProps) {
    const { reportStatusData, localStorageReportsData } = this.props;
    const { reportStatusData: nextReportStatusData, files } = nextProps;
    const { jobState, jobId } = nextReportStatusData;

    // populate download list
    const fileList =
      files instanceof Array
        ? files.map((file) => {
            if (nextProps.saveFileId.includes(file.metadata.jobId))
              file.fileType = 'RECENT';
            return file;
          })
        : [];
    this.setState({ allFiles: fileList });

    // new download stored in localStorage
    const newDownloads = this.newDownload(localStorageReportsData, files);
    if (newDownloads.length !== 0) {
      this.setState({ allFiles: [...newDownloads, ...files] });
    }

    // update from stream api status channel
    if (jobId !== undefined && jobState !== undefined) {
      this.setState({
        allFiles: this.updateDownloadStatus(reportStatusData, [
          ...newDownloads,
          ...files,
        ]),
      });
    }

    // Compair prev and next props when delete file is performed
    if (nextProps.deleteFile !== this.props.deleteFile) {
      setTimeout(() => {
        nextProps.getFiles();
        this.props.toggleDeleteStatus(true);
        this.setState({ totalFiles: this.props.files.length });
      }, 500);
    }

    // Compair prev and next props when report worked JOBID is changes when delete file or download file is performed
    if (nextProps.saveFileId.length !== this.props.saveFileId.length) {
      setTimeout(() => {
        nextProps.getFiles();
        this.props.toggleDeleteStatus(false);
        this.setState({ totalFiles: this.props.files.length });
      }, 300);
    }
  }

  updateDownloadStatus = (statusData: any, files: File[]) => {
    // create file entry
    const { jobState, jobId, fileId } = statusData;
    const matchedLocalStorageData = this.props.localStorageReportsData.find(
      (data) => data.jobId === jobId
    );
    const {
      fileName: localFileName,
      jobId: localJobId,
      errorStatusResponse,
      error,
    } = matchedLocalStorageData || {};
    const statusFile: any = {
      fileId: jobState,
      fileName: localFileName,
      fileSize: '',
      fileType:
        error || errorStatusResponse?.errorCode !== 0 ? 'REPORT' : 'RECENT',
      fileLocation: '',
      resourceId: null,
      createdAt: '',
      modifiedAt: '',
      metadata: {
        jobId: localJobId,
        message: errorStatusResponse?.message || '',
      },
      tags: {},
    };

    let newFiles: File[] = [];

    newFiles = files.map((file) =>
      file.metadata.jobId === jobId && ReportAllStatus.includes(file.fileId)
        ? statusFile
        : file
    );

    if (
      files.find((file) => file.metadata.jobId === jobId) === undefined &&
      statusFile.metadata.jobId !== undefined
    ) {
      newFiles.unshift(statusFile);
    }

    return newFiles.filter((file) => file && file);
  };

  newDownload = (localStorageData: any, files: File[]) => {
    return localStorageData instanceof Array
      ? localStorageData
          .map((localStorage) => {
            // create file entry
            const { error, errorStatusResponse, jobId, fileName } =
              localStorage;

            if (
              files.find((file) => file.metadata.jobId === jobId) === undefined
            ) {
              const statusFile: any = {
                fileId:
                  error ||
                  (errorStatusResponse && errorStatusResponse.errorCode !== 0)
                    ? 'FAILURE'
                    : 'STARTED',
                fileName: fileName,
                fileSize: '',
                fileType:
                  error ||
                  (errorStatusResponse && errorStatusResponse.errorCode !== 0)
                    ? 'REPORT'
                    : 'RECENT',
                fileLocation: '',
                resourceId: null,
                createdAt: '',
                modifiedAt: '',
                metadata: {
                  jobId,
                  message: errorStatusResponse && errorStatusResponse.message,
                },
                tags: {},
              };
              return statusFile;
            } else {
              this.props.saveFileIDs(jobId);
              this.props.updateReportWorkerJobs(
                localStorageData.filter((data) => data.jobId !== jobId)
              );
            }
          })
          .filter((file) => file && file)
      : [];
  };

  handleSearchChange = (query) => {
    this.setState({ query });
  };

  onPageDelete = (cardId, jobId) => {
    this.setState({
      confirmDelete: true,
      dialogOpen: true,
      getReportData: cardId,
      setJobIdForDel: jobId,
      dialogSaveFileId: this.props.saveFileId,
    });
  };

  handelDialogClose = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  handelDialogSubmit = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  startDownloadProcess = async (fileId: string, jobId) => {
    try {
      if (!this.state.downloading) {
        this.setState({ downloading: true });
        await this.props.fileDownload(fileId && fileId);
        this.setState({ downloading: false });
      }
      if (this.props.saveFileId.includes(jobId)) {
        this.props.decrementFileCount();
        this.props.removeFileIDs(jobId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  renderTime = (
    isoString: string | undefined,
    locale: Locale,
    textAlign: 'left' | 'right',
    ianaTimeZoneCode?: string,
    showTimezone?: boolean
  ) => {
    if (!isoString) return '';

    const date = parseISO(isoString);
    const formattedDate = determineTimeStringByTimezone(
      date,
      locale.dateFormat,
      ianaTimeZoneCode
    );
    const formattedTime = determineTimeStringByTimezone(
      date,
      locale.timeFormat,
      ianaTimeZoneCode,
      showTimezone
    );
    return (
      <div className={styles.dateCreated}>
        <div className={styles.formattedTime}>{formattedTime}</div>
        <div className={styles.formattedDate}>{formattedDate}</div>
      </div>
    );
  };

  render() {
    let { timezone, locale, isMobile } = this.props;
    let { sites, timezones, isSitesLoading } = this.props;

    const { query, allFiles } = this.state;

    // TODO: NOTIFICATIONS don't account for notifications loading for now
    // b/c api sometimes hangs/lags
    const dataIsLoading = isSitesLoading;

    if (!timezones) {
      return <BaseContentContainer isLoading={true}>{''}</BaseContentContainer>;
    }

    const filteredFiles =
      query && query.length >= 3
        ? allFiles.filter((file: File) =>
            hasSubstr(`${file.fileName}${file.fileType}`, query)
          )
        : allFiles.map((file) => file && file);

    const columns: Columns<File>[] = [
      {
        width: '40%',
        header: 'Name',
        cellContent: (filteredFiles) => {
          return (
            <div className={styles.icontitleContainer}>
              {filteredFiles.fileName && (
                <Box>
                  <FileIcon
                    fileType={
                      filteredFiles.fileName.split('.').pop() as FileType
                    }
                    size={26}
                  />
                </Box>
              )}
              <div className={styles.tableTitle}>
                {filteredFiles.fileType === 'RECENT' ||
                [
                  ...ReportStartStatus,
                  ...ReportMidStatus,
                  ...ReportSuccessStatus,
                ].includes(filteredFiles.fileId) ? (
                  <strong>{filteredFiles.fileName} </strong>
                ) : (
                  <span>{filteredFiles.fileName}</span>
                )}
              </div>
            </div>
          );
        },

        comparator: (a, b, sortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        width: '20%',
        header: 'Date Created',
        align: 'right',
        cellContent: (filteredFiles) =>
          filteredFiles.createdAt
            ? this.renderTime(
                filteredFiles.createdAt,
                locale,
                'right',
                timezone,
                true
              )
            : '',
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
      },
      {
        width: '20%',
        header: 'Size',
        align: 'right',
        cellContent: (filteredFiles) =>
          filteredFiles.fileSize ? (
            <div className={styles.fileSize}>
              {displayByte(filteredFiles.fileSize)}
            </div>
          ) : (
            ''
          ),
      },
      {
        width: '20%',
        header: '',
        align: 'right',
        rightAlignContent: true,
        cellContent: (filteredFiles) => {
          return (
            <>
              {[...ReportStartStatus, ...ReportMidStatus].includes(
                filteredFiles.fileId
              ) ? (
                <div className={styles.statusWrap}>
                  <div className={styles.statusGenerating}>
                    {`${capitalizeFirstLetter(filteredFiles.fileId)}...`}
                    <span></span>
                  </div>
                </div>
              ) : filteredFiles.fileId === 'SUCCESS' ? (
                <div className={styles.statusWrap}>
                  <div className={styles.statusSuccess}>
                    {capitalizeFirstLetter(filteredFiles.fileId)}
                    <span></span>
                  </div>
                </div>
              ) : filteredFiles.fileId === 'FAILURE' ? (
                <div className={styles.statusWrap}>
                  <div className={styles.statusError}>
                    {capitalizeFirstLetter('Failed') +
                      ' - ' +
                      filteredFiles.metadata.message}
                    <span></span>
                  </div>
                </div>
              ) : filteredFiles.fileId === undefined ? (
                console.log('No fileId presented')
              ) : (
                ''
              )}

              {ReportAllStatus.includes(filteredFiles.fileId) ||
              filteredFiles.fileId === undefined ? (
                ''
              ) : (
                <div className={styles.DownloadedStats}>
                  <div
                    className={styles.DownloadIconContainer}
                    onClick={() => {
                      this.startDownloadProcess(
                        filteredFiles.fileId,
                        filteredFiles.metadata.jobId
                      ).then(() => {
                        mixpanel.track(mixpanelEvents.REPORT_DOWNLOAD, {
                          createdAt: filteredFiles.createdAt,
                          reportTemplate: filteredFiles.metadata.reportTemplate,
                        });
                      });
                    }}
                  >
                    <Download size={18} color={'var(--accent-baseMinus10)'} />
                  </div>
                  <span className={styles.menuDropdownContainer}>
                    <MenuDropdown>
                      <MenuDropdownItem
                        isRed
                        onSelect={() =>
                          this.onPageDelete(
                            filteredFiles.fileId,
                            filteredFiles.metadata.jobId
                          )
                        }
                      >
                        Delete
                      </MenuDropdownItem>
                    </MenuDropdown>
                  </span>
                </div>
              )}
            </>
          );
        },
      },
    ];

    const mobileColumns: Columns<File>[] = [
      {
        width: '35%',
        header: 'Name',
        cellContent: (filteredFiles) => {
          return (
            <div className={styles.icontitleContainer}>
              {filteredFiles.fileName &&
              filteredFiles.fileName.split('.').reverse()[0] === 'csv' ? (
                <CsvIcon />
              ) : (
                <PdfIcon />
              )}
              <div className={styles.tableTitle}>
                {filteredFiles.fileType === 'RECENT' ||
                ReportAllStatus.includes(filteredFiles.fileId) ? (
                  <strong>{filteredFiles.fileName} </strong>
                ) : (
                  <span>{filteredFiles.fileName}</span>
                )}
              </div>
            </div>
          );
        },
        comparator: (a, b, sortDirection) => {
          return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
            'title',
          ]);
        },
      },
      {
        width: '15%',
        header: 'Date Created',
        cellContent: (filteredFiles) =>
          filteredFiles.createdAt
            ? determineTimeStringByTimezone(
                new Date(filteredFiles.createdAt),
                locale.dateTimeFormat,
                timezone,
                true
              )
            : '',
        skeletonCellContent: (rowIndex: OpacityIndex) => (
          <MediumLongSkeletonCell opacityIndex={rowIndex} />
        ),
      },
      {
        width: '10%',
        header: 'Size',
        cellContent: (filteredFiles) =>
          filteredFiles.fileSize ? displayByte(filteredFiles.fileSize) : '',
      },
      {
        width: '30%',
        header: '',
        align: 'right',
        rightAlignContent: true,
        cellContent: (filteredFiles) => {
          return (
            <>
              {[...ReportStartStatus, ...ReportMidStatus].includes(
                filteredFiles.fileId
              ) ? (
                <div className={styles.statusWrap}>
                  <div className={styles.statusGenerating}>
                    {`${capitalizeFirstLetter(filteredFiles.fileId)}...`}
                    <span></span>
                  </div>
                </div>
              ) : filteredFiles.fileId === 'SUCCESS' ? (
                <div className={styles.statusWrap}>
                  <div className={styles.statusSuccess}>
                    {capitalizeFirstLetter(filteredFiles.fileId)}
                    <span></span>
                  </div>
                </div>
              ) : filteredFiles.fileId === 'FAILURE' ? (
                <div className={styles.statusWrap}>
                  <div className={styles.statusError}>
                    {capitalizeFirstLetter(filteredFiles.fileId)}
                    <span></span>
                  </div>
                </div>
              ) : filteredFiles.fileId === undefined ? (
                console.log('No fileId presented')
              ) : (
                ''
              )}

              {ReportAllStatus.includes(filteredFiles.fileId) ||
              filteredFiles.fileId === undefined ? (
                ''
              ) : (
                <div className={styles.DownloadedStats}>
                  <div
                    className={styles.DownloadIconContainer}
                    onClick={() => {
                      this.startDownloadProcess(
                        filteredFiles.fileId,
                        filteredFiles.metadata.jobId
                      ).then(() => {
                        mixpanel.track(mixpanelEvents.REPORT_DOWNLOAD, {
                          createdAt: filteredFiles.createdAt,
                          reportTemplate: filteredFiles.metadata.reportTemplate,
                        });
                      });
                    }}
                  >
                    <Download size={18} color={'var(--accent-baseMinus10)'} />
                  </div>
                  <span className={styles.menuDropdownContainer}>
                    <MenuDropdown>
                      <MenuDropdownItem
                        isRed
                        onSelect={() =>
                          this.onPageDelete(
                            filteredFiles.fileId,
                            filteredFiles.metadata.jobId
                          )
                        }
                      >
                        Delete
                      </MenuDropdownItem>
                    </MenuDropdown>
                  </span>
                </div>
              )}
            </>
          );
        },
      },
    ];

    return (
      <div>
        <div className={styles.navBar}>
          <BaseFilterBar>
            <div className={styles.tabBar}>
              <div className={styles.currentTab}>
                <div className={styles.topLink}>My Downloads</div>
              </div>
            </div>
          </BaseFilterBar>
        </div>

        <SiteMobileNav />
        <BaseContentContainer className={styles.container} gapBetween>
          <PageContentHeader>
            <SearchBox
              placeholder="Quick Search"
              onChange={this.handleSearchChange}
              query={query}
              width={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
              widthActive={
                isMobile
                  ? theme.size.table.searchBox.mobile
                  : theme.size.table.searchBox.web
              }
            />
          </PageContentHeader>
          <TableWrapper>
            <Table
              dataIsLoading={dataIsLoading}
              columns={isMobile ? mobileColumns : columns}
              data={filteredFiles ? filteredFiles : []}
              listView
            />
          </TableWrapper>
        </BaseContentContainer>

        {this.state.confirmDelete && (
          <ConfirmDeleteDialog
            isCreateDashboardDialogOpen={this.state.dialogOpen}
            dialogReportData={this.state.getReportData}
            dialogSetJobIdForDel={this.state.setJobIdForDel}
            dialogSaveFileId={this.state.dialogSaveFileId}
            onSubmit={this.handelDialogSubmit}
            onClose={this.handelDialogClose}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    isSitesLoading: state.loading.sites,
    sites: state.sites.sites,
    timezones: state.app.timezones,
    onlineStatus: state.siteOnlineStatusById,
    files: state.files.allDownloadFiles ? state.files.allDownloadFiles : [],
    totalFileCount: state.files.allDownloadFiles
      ? state.files.allDownloadFiles.length
      : 0,
    reportworker: state.reportworker,
    deleteFile: state.files.deleteFile,
    accessToken: state.app.accessToken,
    toggleIcon: state.reportworker.toggleIcon,
    reportStatusData: state.reportworker.reportWorker,
  };
}
export default connect(mapStateToProps, {
  getSites,
  getTimeZones,
  getFiles,
  initiateDownload,
  getReportWorker,
  fileDownload,
  deleteFiles,
  toggleDeleteStatus,
  toggleShowHideIcon,
  notifyDownload,
  saveFileIDs,
  removeFileIDs,
  incrementFileCount,
  decrementFileCount,
  latestFileCount,
  clearEquipDataForDownload,
  updateReportWorkerJobs,
})(MyDownloadWrapper);
