import { Site, SitesById } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgEnergyConsumption } from '../actions/analytics';
import {
  getFeaturesBySiteId,
  getFeaturesByOrganizationId,
} from '../actions/features';
import { getSites, getSite } from '../actions/sites';
import { ApplicationState } from '../reducers';
import {
  OrganizationEnergyReportBySiteId,
  OrganizationEnergyReportState,
} from '../reducers/analytics';
import {
  SiteLevelFeatures,
  OrganizatioinLevelFeatures,
} from '../reducers/types/features';
import { sanitizeApiLoadingState } from '../util';
import { useTimeFilter } from './useFilters';
import pathOr from 'ramda/src/pathOr';
import { getSiteGroups } from '../actions/site_groups';

export const useSites = () => {
  return useSelector<ApplicationState, Site[]>(({ sites }) => sites.sites);
};

export const useSitesById = () => {
  return useSelector<ApplicationState, SitesById>(
    ({ sites }) => sites.sitesById
  );
};

export const useSite = (siteId?: string | number) => {
  return useSelector<ApplicationState, Site | undefined>(({ sites }) =>
    siteId ? sites.sitesById[siteId] : undefined
  );
};

export const useGetSite = (siteId: number | string | undefined) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (siteId) {
      dispatch(getSite(String(siteId)));
    }
  }, [siteId]);

  return useSite(siteId);
};

export const useGetAllSites = () => {
  const dispatch = useDispatch();
  const sitesById = useSelector<ApplicationState, SitesById>(({ sites }) => {
    return sites.sitesById;
  });

  useEffect(() => {
    dispatch(getSites());
  }, [dispatch]);

  return sitesById;
};

export const useEnergyUseBySite = (): {
  orgReportBySiteId: OrganizationEnergyReportBySiteId;
  isLoading: boolean;
} => {
  const dispatch = useDispatch();
  const { bySiteId: orgReportBySiteId, isLoading } = useSelector<
    ApplicationState,
    OrganizationEnergyReportState
  >(({ analytics }) => {
    return analytics.orgEnergyReport;
  });
  const sitesById = useSelector<ApplicationState, SitesById>(({ sites }) => {
    return sites.sitesById;
  });
  const orgId = useSelector<ApplicationState, number | undefined>(
    ({ organizations }) => {
      return organizations.currentOrganization !== undefined
        ? organizations.currentOrganization.id
        : undefined;
    }
  );
  const { timePeriod } = useTimeFilter();

  useEffect(() => {
    const { fromDate, toDate } = timePeriod;
    if (orgId !== undefined) {
      dispatch(getOrgEnergyConsumption(String(orgId), fromDate, toDate));
    }
  }, [orgId, dispatch, timePeriod]);

  const orgReportList = Object.entries(orgReportBySiteId);
  if (orgReportList.length > 0) {
    orgReportList.forEach(([siteId, siteConsumption]) => {
      const site = sitesById[siteId];
      if (site !== undefined) {
        siteConsumption.site = site;
        siteConsumption.siteTitle = site.title;
      }
    });
    return { orgReportBySiteId, isLoading };
  } else {
    return {
      orgReportBySiteId: {},
      isLoading,
    };
  }
};

export const useSiteTimezone = (
  siteId: number | string
): string | undefined => {
  const sitesById = useSitesById();
  if (isDefined(sitesById) && isDefined(sitesById[siteId])) {
    return sitesById[siteId].timeZone;
  }
  return undefined;
};

export const useSiteFeatures = (
  siteId: number | string
): {
  isLoading: boolean;
  siteFeatures: SiteLevelFeatures | undefined;
} => {
  const dispatch = useDispatch();
  const isLoading = useSelector(({ features }: ApplicationState) => {
    return features.isLoadingBySiteId[siteId];
  });
  const siteFeatures: SiteLevelFeatures | undefined = useSelector(
    ({ features }: ApplicationState) => {
      return features.featuresBySiteId[siteId];
    }
  );

  useEffect(() => {
    if (isLoading === undefined) {
      dispatch(getFeaturesBySiteId(siteId));
    }
  }, [isLoading]);
  return {
    isLoading: sanitizeApiLoadingState(isLoading),
    siteFeatures,
  };
};

export const useMultisiteFeatures = (
  organizationId: number | string
): {
  isLoading: boolean;
  multisiteFeatures: OrganizatioinLevelFeatures | undefined;
} => {
  const dispatch = useDispatch();
  const isLoading = useSelector(({ features }: ApplicationState) => {
    return features.isLoadingByOrganizationId[organizationId];
  });
  const multisiteFeatures: OrganizatioinLevelFeatures | undefined = useSelector(
    ({ features }: ApplicationState) => {
      return features.featuresByOrganizationId[organizationId];
    }
  );

  useEffect(() => {
    if (isLoading === undefined) {
      dispatch(getFeaturesByOrganizationId(organizationId));
    }
  }, [isLoading]);
  return {
    isLoading: sanitizeApiLoadingState(isLoading),
    multisiteFeatures,
  };
};

export const useGetAllSiteGroups = () => {
  const dispatch = useDispatch();
  const siteGroupsById = useSelector(
    ({ siteGroups }: ApplicationState) => siteGroups.siteGroupsById ?? {}
  );

  useEffect(() => {
    dispatch(getSiteGroups());
  }, []);

  return siteGroupsById;
};
