export enum Service {
  iam = 'SERVICE_IAM',
  timeSeries = 'SERVICE_TIMESERIES',
  sentinel = 'SERVICE_SENTINELS',
  tsd = 'SERVICE_TSD_ROOT',
  cdn = 'SERVICE_CDN',
  analytics = 'SERVICE_ANALYTICS',
  reports = 'SERVICE_REPORTS',
  weather = 'SERVICE_WEATHER',
  templates = 'SERVICE_TEMPLATES',
  comments = 'SERVICE_COMMENTS',
  files = 'SERVICE_FILES',
  reportworker = 'SERVICE_REPORTWORKER',
  help = 'SERVICE_HELP',
}

export function getConfig() {
  const serviceUrls = {
    [Service.iam]:
      process.env.REACT_APP_SERVICE_IAM_ROOT ||
      'https://iam.staging.energybox.com',
    [Service.sentinel]:
      process.env.REACT_APP_SERVICE_SENTINEL_ROOT ||
      'https://sentinel.staging.energybox.com',
    [Service.cdn]:
      process.env.REACT_APP_SERVICE_CDN_ROOT ||
      'https://cdn.staging.energybox.com',
    [Service.analytics]:
      process.env.REACT_APP_SERVICE_ANALYTICS_ROOT ||
      'https://analytics.staging.energybox.com',
    [Service.reports]:
      process.env.REACT_APP_SERVICE_REPORTS_ROOT ||
      'https://reports.staging.energybox.com',
    [Service.weather]:
      process.env.REACT_APP_SERVICE_WEATHER_ROOT ||
      'https://weather.staging.energybox.com',
    [Service.templates]:
      process.env.REACT_APP_SERVICE_TEMPLATES_ROOT ||
      'https://templates.staging.energybox.com',
    [Service.timeSeries]:
      process.env.REACT_APP_SERVICE_TIMESERIES_ROOT ||
      'https://ts.staging.energybox.com',
    [Service.comments]:
      process.env.REACT_APP_SERVICE_COMMENTS_ROOT ||
      'https://logging.staging.energybox.com',
    [Service.files]:
      process.env.REACT_APP_SERVICE_FILES_ROOT ||
      'https://files.staging.energybox.com',
    [Service.reportworker]:
      process.env.REACT_APP_SERVICE_REPORTWORKER_ROOT ||
      'https://reportworker.staging.energybox.com',
    [Service.help]:
      process.env.REACT_APP_SERVICE_HELP_ROOT ||
      'https://help.staging.energybox.com',
  };

  if (
    process.env.ENVIRONMENT === 'production' &&
    Object.values(serviceUrls).includes('')
  ) {
    throw new Error('env vars incomplete');
  }

  return { serviceUrls };
}

const config = getConfig();

export function getServiceUrl(service): string {
  switch (service) {
    case Service.sentinel:
      return config.serviceUrls.SERVICE_SENTINELS;
    // case Service.tsd:
    //   return config.serviceUrls.SERVICE_TSD_ROOT;
    case Service.analytics:
      return config.serviceUrls.SERVICE_ANALYTICS;
    case Service.reports:
      return config.serviceUrls.SERVICE_REPORTS;
    case Service.weather:
      return config.serviceUrls.SERVICE_WEATHER;
    case Service.templates:
      return config.serviceUrls.SERVICE_TEMPLATES;
    case Service.timeSeries:
      return config.serviceUrls.SERVICE_TIMESERIES;
    case Service.comments:
      return config.serviceUrls.SERVICE_COMMENTS;
    case Service.files:
      return config.serviceUrls.SERVICE_FILES;
    case Service.reportworker:
      return config.serviceUrls.SERVICE_REPORTWORKER;
    case Service.help:
      return config.serviceUrls.SERVICE_HELP;
    default:
      return config.serviceUrls.SERVICE_IAM;
  }
}

export function getVenstarEdgeApp2dot4DeploymentDate() {
  if (process.env.REACT_APP_DISPLAY_VENSTAR_HP_CONTROL_GRAPH_FROM_DATE) {
    return process.env.REACT_APP_DISPLAY_VENSTAR_HP_CONTROL_GRAPH_FROM_DATE;
  }
  return '';
}

export function isProduction() {
  return process.env.ENVIRONMENT === 'production';
}
