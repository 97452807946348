import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import LoadingBar from '../LoadingBar';
import styles from './BaseContentContainer.module.css';

type Props = {
  children: React.ReactNode;
  isLoading?: boolean;
  fillFrame?: boolean;
  capHeight?: boolean;
  gapBetween?: boolean;
  className?: string;
};

const BaseContentContainer = ({
  children,
  isLoading = false,
  fillFrame = false,
  capHeight = true,
  gapBetween = false,
  className = '',
}: Props) => (
  <div
    className={classNames(
      styles.root,
      fillFrame ? styles.fillFrame : styles.margin,
      capHeight ? styles.capHeight : null,
      gapBetween ? styles.gapBetween : null,
      className
    )}
  >
    {children}

    <LoadingBar isLoading={isLoading} />
  </div>
);

export default BaseContentContainer;
