import {
  IncidentApiFilter,
  IncidentCountsByEquipment,
  IncidentCountsBySite,
  IncidentStatus,
} from '@energybox/react-ui-library/dist/types';
import equals from 'ramda/src/equals';
import pathOr from 'ramda/src/pathOr';

import { useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIncidentById,
  getIncidentCountsByEquipment,
  getIncidentCountsBySite,
  getIncidents,
  getIncidentsBySiteId,
} from '../actions/incidents';
import { ApplicationState } from '../reducers';
import incidents, {
  IncidentCountsByEquipmentDataStatus,
  IncidentCountsBySiteDataStatus,
  IncidentsBySiteIdDataStatus,
  IncidentsDataStatus,
} from '../reducers/incidents';

import {
  subscribeToIncident as subscribeToIncidentAction,
  unsubscribeFromIncident as unsubscribeFromIncidentAction,
} from '../actions/streamApi';
import { SubscribedIncidentsByResourceId } from '@energybox/react-ui-library/dist/types/Incident';

export function useGetIncidentCountsBySite(queryParams: IncidentApiFilter): {
  isLoading: boolean;
  data?: IncidentCountsBySite;
} {
  const dispatch = useDispatch();

  const countsBySiteReturn = useSelector<
    ApplicationState,
    IncidentCountsBySiteDataStatus
  >(({ incidents }) => {
    return incidents.countsBySite;
  });

  useLayoutEffect(() => {
    dispatch(getIncidentCountsBySite(queryParams));
  }, [JSON.stringify(queryParams), dispatch]);

  return countsBySiteReturn;
}

export function useGetIncidentCountsByEquipment(
  queryParams: IncidentApiFilter
): { isLoading: boolean; data?: IncidentCountsByEquipment } {
  const dispatch = useDispatch();

  const countsByEquipmentReturn = useSelector<
    ApplicationState,
    IncidentCountsByEquipmentDataStatus
  >(({ incidents }) => {
    return incidents.countsByEquipment;
  });

  useLayoutEffect(() => {
    dispatch(getIncidentCountsByEquipment(queryParams));
  }, [queryParams, dispatch]);

  return countsByEquipmentReturn;
}

export function useGetIncidentsBySiteId(queryParams: IncidentApiFilter) {
  const dispatch = useDispatch();
  const { siteIds, from, to } = queryParams;
  //queryParms accepts siteIds as array, so single site will be one element array
  const siteId = pathOr(undefined, [0], siteIds);

  const siteIncidentsDataStatus = useSelector<
    ApplicationState,
    IncidentsBySiteIdDataStatus
  >(({ incidents }) => {
    return pathOr(undefined, [siteId], incidents.bySiteId);
  }, equals);

  useLayoutEffect(() => {
    const queryParams = {
      siteIds: [siteId],
      from,
      to,
    };
    dispatch(getIncidentsBySiteId(queryParams));
  }, [siteId, from, to, dispatch]);
  return siteIncidentsDataStatus || { isLoading: true };
}

export function useGetIncidents(queryParams: IncidentApiFilter) {
  const dispatch = useDispatch();

  const incidentsDataStatus = useSelector<
    ApplicationState,
    IncidentsDataStatus
  >(({ incidents }) => {
    return incidents.incidents;
  }, equals);

  const isLastLoading = useSelector<ApplicationState, boolean>(
    ({ incidents }) => {
      return incidents.isLastLoading < 0;
    },
    equals
  );

  useLayoutEffect(() => {
    dispatch(getIncidents(queryParams));
  }, [JSON.stringify(queryParams), dispatch]);

  return { ...incidentsDataStatus, isLastLoading };
}

export function useGetIncidentById(incidentId: string) {
  const dispatch = useDispatch();

  const incidentById = useSelector<ApplicationState, IncidentsDataStatus>(
    ({ incidents }) => {
      return incidents.incidentReview;
    },
    equals
  );

  useLayoutEffect(() => {
    dispatch(getIncidentById(incidentId));
  }, [incidentId, dispatch]);

  return incidentById;
}

export function useGetAllIncidentsByStatus(
  incidentStatus: IncidentStatus,
  from: string,
  to: string
) {
  const dispatch = useDispatch();

  const incidentsDataStatus = useSelector<
    ApplicationState,
    IncidentsDataStatus
  >(({ incidents }) => {
    return incidents.incidents;
  });

  useEffect(() => {
    const queryParams = {
      from,
      to,
      incidentStatus,
    };
    dispatch(getIncidents(queryParams));
  }, [from, to, incidentStatus, dispatch]);

  let filteredIncidents =
    incidentsDataStatus.data !== undefined ? incidentsDataStatus.data : [];
  if (incidentStatus === IncidentStatus.ACTIVE) {
    filteredIncidents = filteredIncidents.filter(
      ({ resolved }) => resolved === false
    );
  } else {
    filteredIncidents = filteredIncidents.filter(
      ({ resolved }) => resolved === true
    );
  }

  return {
    isLoading: incidentsDataStatus.isLoading,
    data: filteredIncidents,
  };
}

export const useEquipmentSubscribedIncidents = (
  equipmentIds: number[] | undefined
): SubscribedIncidentsByResourceId => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (equipmentIds) {
      equipmentIds.forEach((equipmentId) => {
        subscribeToIncident(dispatch, { equipmentId });
      });

      return () => {
        equipmentIds.forEach((equipmentId) => {
          unsubscribeFromIncident(dispatch, { equipmentId });
        });
      };
    }
  }, [dispatch, equipmentIds]);

  return useSelector<ApplicationState, {}>(({ incidents }) => {
    return incidents.subscribedIncidentsByResourceId;
  });
};

const subscribeToIncident = (
  dispatch,
  resourceIds: {
    equipmentId?: string | number;
    spaceId?: string | number;
  }
) => {
  dispatch(subscribeToIncidentAction(resourceIds));
};

const unsubscribeFromIncident = (
  dispatch,
  resourceIds: {
    equipmentId?: string | number;
    spaceId?: string | number;
  }
) => {
  dispatch(unsubscribeFromIncidentAction(resourceIds));
};
