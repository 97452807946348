import { Tooltip } from '@energybox/react-ui-library/dist/components';
import { Notification as NotificationIcon } from '@energybox/react-ui-library/dist/icons';
import React from 'react';
import styles from './ActiveIncidentIcon.module.css';

const ActiveIncidentIcon = ({
  numberActiveIncidents,
  showTooltip,
}: {
  numberActiveIncidents: number;
  showTooltip?: boolean;
}): JSX.Element => {
  const iconWithCount = (
    <div className={styles.row}>
      <span className={styles.incidentCount}>{numberActiveIncidents}</span>
      <NotificationIcon size={12} />
    </div>
  );
  let toReturn: JSX.Element = <></>;
  if (showTooltip) {
    toReturn = (
      <Tooltip
        simpleContent={{
          title: `${numberActiveIncidents} Active Incident(s)`,
          description: '',
        }}
      >
        {iconWithCount}
      </Tooltip>
    );
  } else {
    toReturn = iconWithCount;
  }
  return toReturn;
};

export default ActiveIncidentIcon;
