import { Sensor } from '@energybox/react-ui-library/dist/types';

type HasStringTitle = {
  title: string;
};

export const sortSensorsBySpaceName = (a: Sensor, b: Sensor) => {
  if (a.resource !== undefined && b.resource !== undefined) {
    return a.resource.title.localeCompare(b.resource.title);
  } else if (a.resource !== undefined) {
    return 1;
  } else {
    return -1;
  }
};

export const sortByTitleWithEmptyStringToLast = (
  { title: aTitle }: HasStringTitle,
  { title: bTitle }: HasStringTitle
) => {
  if (aTitle && bTitle) return aTitle.localeCompare(bTitle);
  else if (aTitle) return -1;
  else return 1;
};
