import {
  LightReading,
  LightReadingControlsData,
  LocalOverrideActivityApiReturn,
  LocalOverrideActivityByControlBoardId,
  SystemOverrideActivity,
  SystemOverrideActivityApiReturn,
  SystemOverrideApiRes,
  TemperatureControlStatusTimeSeries,
  TemperatureUnit,
  ThermostatActivity,
  ThermostatActivityApiRes,
  ThermostatActivityApiReturn,
  ThermostatWorkingMode,
  ThermostatWorkingModeMap,
  WorkingModeMap,
  ZippedLocalOverrideActivityData,
  ZippedTimeSeriesApiRes,
  WorkingMode,
} from '@energybox/react-ui-library/dist/types';
import {
  getTimeSeriesMinMax,
  getUnixTimestamp,
  zipTSApiData,
} from '@energybox/react-ui-library/dist/utils';
import { parseISO } from 'date-fns';
import assocPath from 'ramda/src/assocPath';
import pathOr from 'ramda/src/pathOr';
import mergeDeepRight from 'ramda/src/mergeDeepRight';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/controls';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import {
  ActuatorActivityApiReturn,
  ActuatorCircuitActivityById,
  ActuatorsByEquipmentId,
  ControlBoardActuators,
  ControlsOverviewBySiteId,
  ControlsTimeSeries,
  HvacControlsByEquipmentId,
  HvacControlsBySiteId,
  SchedulersByEquipmentId,
  SchedulersBySiteId,
  SystemOverrideByControlId,
  SystemOverrideByFeatureNotificationId,
  TemperatureControlsBySiteId,
  ThermostatActivityById,
  ThermostatActivityByFeatureNotificationId,
  ThermostatTileInfoBySiteId,
  ZippedActuatorCircuitActivityData,
  ControlledHvacInfoByOrgId,
  PrimedControlModeUpdateById,
  PrimedControlModeUpdate,
  HvacTileInfoBySiteId,
} from './types/controlsReducer';

export interface Controls {
  temperatureControlsBySiteId: TemperatureControlsBySiteId;
  schedulersByEquipmentId: SchedulersByEquipmentId;
  schedulersBySiteId: SchedulersBySiteId;
  controlsOverviewBySiteId: ControlsOverviewBySiteId;
  showUpdateControlWorkingModeModal: boolean;
  updateControlWorkingModeApiError: ApiError;
  controlBoardActuators: ControlBoardActuators;
  actuatorsByEquipmentId: ActuatorsByEquipmentId;
  actuatorCircuitActivityById: ActuatorCircuitActivityById;
  localOverrideActivityByControlBoardId: LocalOverrideActivityByControlBoardId;
  hvacControlsByEquipmentId: HvacControlsByEquipmentId;
  hvacControlsBySiteId: HvacControlsBySiteId;
  thermostatActivityById: ThermostatActivityById;
  thermostatActivityByFeatureNotificationId: ThermostatActivityByFeatureNotificationId;
  timeSeries: ControlsTimeSeries;
  systemOverrideByControlId: SystemOverrideByControlId;
  systemOverrideByFeatureNotificationId: SystemOverrideByFeatureNotificationId;
  thermostatTileInfoBySiteId: ThermostatTileInfoBySiteId;
  controlledHvacInfoByOrgId: ControlledHvacInfoByOrgId;
  primedControlModeUpdateById: PrimedControlModeUpdateById;
  currentControlModeUpdate: PrimedControlModeUpdate;
  currentMode: string;
  hvacTileInfoBySiteId: HvacTileInfoBySiteId;
  ishvacDataLoading: boolean;
}

export const initialState = {
  temperatureControlsBySiteId: {},
  schedulersByEquipmentId: {},
  schedulersBySiteId: {},
  controlsOverviewBySiteId: {},
  showUpdateControlWorkingModeModal: false,
  updateControlWorkingModeApiError: {},
  controlBoardActuators: {
    bySiteId: {},
    byEquipmentId: {},
  },
  actuatorsByEquipmentId: {},
  actuatorCircuitActivityById: {},
  localOverrideActivityByControlBoardId: {},
  hvacControlsByEquipmentId: {},
  hvacControlsBySiteId: {},
  thermostatActivityById: {},
  thermostatActivityByFeatureNotificationId: {},
  timeSeries: {
    temperatureControlStatus: {
      byId: {},
    },
    lightSensorReadings: {
      byControlBoardId: {},
      bySchedulerId: {},
    },
  },
  systemOverrideByControlId: {},
  systemOverrideByFeatureNotificationId: {},
  thermostatTileInfoBySiteId: {},
  controlledHvacInfoByOrgId: {},
  hvacTileInfoBySiteId: {},
  primedControlModeUpdateById: {},
  currentControlModeUpdate: {},
  currentMode: '',
  ishvacDataLoading: false,
};

const normalizeActuatorOrLocalOverrideActivityApiReturn = (
  data: ActuatorActivityApiReturn | LocalOverrideActivityApiReturn
) => {
  return zipTSApiData(data).map(
    (
      d: ZippedActuatorCircuitActivityData | ZippedLocalOverrideActivityData
    ) => ({
      timestamp: parseISO(d.time).getTime(),
      state: d.state,
    })
  );
};

const normalizeThermostatActivityApiReturn = (
  data: ThermostatActivityApiReturn
): ThermostatActivity[] => {
  const thermostatColumnData: ThermostatActivityApiRes[] = zipTSApiData(data);
  const dataWithTimestamp: ThermostatActivity[] = thermostatColumnData.map(
    // Don't copy over working mode because the value isn't from edge. We use
    // getSystemOverrideActivityByControlId
    // (/control-agent-module/status/${controlId}) and merge that data source
    // into this one for the chart. If we bring over this workingMode it
    // interweaves the values and makes a mess
    ({ workingMode, ...data }) => ({
      ...data,
      timestamp: parseISO(data.time).getTime(),
      temperatureUnit: TemperatureUnit.C,
      mode:
        typeof data.mode === 'number'
          ? ThermostatWorkingModeMap[data.mode]
          : ThermostatWorkingMode.AUTO,
      localAdjustmentOverrideEnergybox: data.localAdjustmentOverrideEnergybox,
    })
  );
  return dataWithTimestamp;
};

const normalizeTemperatureControlStatusApiReturn = (
  data: any
): TemperatureControlStatusTimeSeries[] => {
  return zipTSApiData(data).map((d: ZippedTimeSeriesApiRes) => ({
    ...d,
    timestamp: getUnixTimestamp(d.time.toString()) * 1000,
  }));
};

const normalizeSystemOverrideActivityApiReturn = (
  data: SystemOverrideActivityApiReturn
): SystemOverrideActivity[] => {
  const systemOverrideColumnData: SystemOverrideApiRes[] = zipTSApiData(data);
  const dataWithTimestamp: SystemOverrideActivity[] =
    systemOverrideColumnData.map((data) => ({
      timestamp: parseISO(data.time).getTime(),
      workingMode: WorkingModeMap[data.workingMode],
    }));
  return dataWithTimestamp;
};

const normalizeSchedulerApiReturn = (data: any): LightReading[] => {
  return zipTSApiData(data).map((d: ZippedTimeSeriesApiRes) => {
    const {
      time,
      threshold,
      actionInterval,
      hysteresis,
      lightSensorSourceId,
      lightSensorTimetableId,
      schedulerTimetableId,
      fixedScheduleActive,
      lightSensorActive,
    } = d;

    const normalizedLightReading: LightReading = {
      timestamp: getUnixTimestamp(time.toString()) * 1000,
      threshold: threshold || null,
      fixedScheduleActive: fixedScheduleActive,
      lightSensorActive: lightSensorActive,
    };

    return normalizedLightReading;
  });
};

const normalizeLightReadingApiReturn = (data: any): LightReading[] => {
  return zipTSApiData(data).map((d: ZippedTimeSeriesApiRes) => {
    const controlsData: LightReadingControlsData = d.controlsData
      ? JSON.parse(d.controlsData)
      : null;

    const normalizedLightReading: LightReading = {
      timestamp: getUnixTimestamp(d.time.toString()) * 1000,
      lux: d.lux,

      //Below values are available, but we don't use

      // Don't use threshold from here.
      // Actions?.GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_SUCCESS has accurate threshold data

      // threshold: controlsData?.[0]?.threshold || null,
      // hysteresis: controlsData?.[0]?.hysteresis || null
      // schedulerId: controlsData?.[0]?.schedulerId || null,
      // timetableId: controlsData?.[0]?.timetableId || null
    };

    return normalizedLightReading;
  });
};

const updateCurrentControlModeUpdate = (state, action) =>
  action?.currentControlModeUpdate
    ? assocPath(
        ['currentControlModeUpdate'],
        action.currentControlModeUpdate,
        state
      )
    : state;

const updateCurrentMode = (state, action) => {
  return action?.currentMode
    ? assocPath(['currentMode'], action.currentMode, state)
    : state;
};

const updateShowUpdateControlWorkingModeModal = (state, action) => {
  return action?.value
    ? assocPath(['showUpdateControlWorkingModeModal'], action.value, state)
    : state;
};

const updatePrimedControlModeUpdateById = (state, action) => {
  if (action?.primedControlModeUpdateById) {
    if (
      state.primedControlModeUpdateById &&
      state?.primedControlModeUpdateById?.[action.id]
    ) {
      return assocPath(
        ['primedControlModeUpdateById', action.id, 'controlMode'],
        action.primedControlModeUpdateById.controlMode,
        state
      );
    } else {
      return mergeDeepRight(state, {
        primedControlModeUpdateById: action.primedControlModeUpdateById,
      });
    }
  } else {
    return state;
  }
};

const applyAction = (fn, action) => (state) => fn(state, action);

const controls = (state: Controls = initialState, action: any): Controls => {
  switch (action.type) {
    case Actions.GET_TEMPERATURE_CONTROLS_BY_SITE_ID_SUCCESS: {
      const tempControl = action.payload;
      return pipe(
        assocPath(
          ['temperatureControlsBySiteId', action.siteId, 'data'],
          tempControl
        ),
        assocPath(
          ['temperatureControlsBySiteId', action.siteId, 'isLoading'],
          false
        )
      )(state);
    }

    case Actions.GET_TEMPERATURE_CONTROLS_BY_SITE_ID_LOADING:
      return assocPath(
        ['temperatureControlsBySiteId', action.siteId, 'isLoading'],
        true
      )(state);

    case Actions.GET_TEMPERATURE_CONTROLS_BY_SITE_ID_ERROR:
      return assocPath(
        ['temperatureControlsBySiteId', action.siteId, 'isLoading'],
        false
      )(state);

    case Actions.GET_SCHEDULER_BY_EQUIPMENT_ID_LOADING:
      return assocPath(
        ['schedulersByEquipmentId', action.equipmentId, 'isLoading'],
        true
      )(state);

    case Actions.GET_SCHEDULER_BY_EQUIPMENT_ID_ERROR:
      return assocPath(
        ['schedulersByEquipmentId', action.equipmentId, 'isLoading'],
        false
      )(state);

    case Actions.GET_SCHEDULER_BY_EQUIPMENT_ID_SUCCESS: {
      const scheduler = action.payload;
      return pipe(
        assocPath(
          ['schedulersByEquipmentId', action.equipmentId, 'data'],
          scheduler
        ),
        assocPath(
          ['schedulersByEquipmentId', action.equipmentId, 'isLoading'],
          false
        )
      )(state);
    }

    case Actions.GET_SCHEDULERS_BY_SITE_ID_LOADING:
      return assocPath(
        ['schedulersBySiteId', action.siteId, 'isLoading'],
        true
      )(state);

    case Actions.GET_SCHEDULERS_BY_SITE_ID_ERROR:
      return assocPath(
        ['schedulersBySiteId', action.siteId, 'isLoading'],
        false
      )(state);

    case Actions.GET_SCHEDULERS_BY_SITE_ID_SUCCESS: {
      const scheduler = action.payload;
      return pipe(
        assocPath(['schedulersBySiteId', action.siteId, 'data'], scheduler),
        assocPath(['schedulersBySiteId', action.siteId, 'isLoading'], false)
      )(state);
    }

    case Actions.TOGGLE_UPDATE_CONTROL_WORKING_MODE_MODAL:
      return assocPath(
        ['showUpdateControlWorkingModeModal'],
        action.value
      )(state);

    case Actions.PATCH_HVAC_CONTROL_WORKING_MODE_ERROR:
    case Actions.PATCH_TEMPERATURE_CONTROL_WORKING_MODE_ERROR:
    case Actions.PATCH_SCHEDULER_WORKING_MODE_ERROR:
      return pipe(
        assocPath(['updateControlWorkingModeApiError'], storeAPIerror(action))
      )(state);

    case Actions.PATCH_HVAC_CONTROL_WORKING_MODE_SUCCESS:
    case Actions.PATCH_TEMPERATURE_CONTROL_WORKING_MODE_SUCCESS:
    case Actions.PATCH_SCHEDULER_WORKING_MODE_SUCCESS:
      return pipe(
        assocPath(['showUpdateControlWorkingModeModal'], false),
        assocPath(
          ['primedControlModeUpdateById', action.id, 'controlMode'],
          action.workingMode
        )
      )(state);

    case Actions.GET_CONTROLS_OVERVIEW_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['controlsOverviewBySiteId', action.siteId, 'data'],
          action.payload
        ),
        assocPath(
          ['controlsOverviewBySiteId', action.siteId, 'isLoading'],
          false
        )
      )(state);

    case Actions.GET_CONTROLS_OVERVIEW_BY_SITE_ID_LOADING:
      return pipe(
        assocPath(
          ['controlsOverviewBySiteId', action.siteId, 'isLoading'],
          true
        )
      )(state);

    case Actions.GET_CONTROLS_OVERVIEW_BY_SITE_ID_ERROR:
      return pipe(
        assocPath(
          ['controlsOverviewBySiteId', action.siteId, 'isLoading'],
          false
        )
      )(state);

    case Actions.GET_ACTUATORS_BY_EQUIPMENT_ID_LOADING:
      return pipe(
        assocPath(
          ['actuatorsByEquipmentId', action.equipmentId, 'isLoading'],
          true
        )
      )(state);

    case Actions.GET_ACTUATORS_BY_EQUIPMENT_ID_ERROR:
      return pipe(
        assocPath(
          ['actuatorsByEquipmentId', action.equipmentId, 'isLoading'],
          false
        )
      )(state);

    case Actions.GET_ACTUATORS_BY_EQUIPMENT_ID_SUCCESS:
      return pipe(
        assocPath(['actuatorsByEquipmentId', action.equipmentId], {
          isLoading: false,
          data: action.payload,
        })
      )(state);

    case Actions.GET_ACTUATOR_CIRCUIT_ACTIVITY_SUCCESS:
      return pipe(
        assocPath(['actuatorCircuitActivityById', action.actuatorId], {
          isLoading: false,
          data: normalizeActuatorOrLocalOverrideActivityApiReturn(
            action.payload
          ),
        })
      )(state);

    case Actions.GET_ACTUATOR_CIRCUIT_ACTIVITY_LOADING:
      return pipe(
        assocPath(
          ['actuatorCircuitActivityById', action.actuatorId, 'isLoading'],
          true
        )
      )(state);

    case Actions.GET_ACTUATOR_CIRCUIT_ACTIVITY_ERROR:
      return pipe(
        assocPath(
          ['actuatorCircuitActivityById', action.actuatorId, 'isLoading'],
          false
        )
      )(state);

    case Actions.GET_LOCAL_OVERRIDE_ACTIVITY_SUCCESS:
      return pipe(
        assocPath(
          ['localOverrideActivityByControlBoardId', action.controlBoardId],
          {
            isLoading: false,
            data: normalizeActuatorOrLocalOverrideActivityApiReturn(
              action.payload
            ),
          }
        )
      )(state);

    case Actions.GET_LOCAL_OVERRIDE_ACTIVITY_LOADING:
      return pipe(
        assocPath(
          [
            'localOverrideActivityByControlBoardId',
            action.controlBoardId,
            'isLoading',
          ],
          true
        )
      )(state);

    case Actions.GET_LOCAL_OVERRIDE_ACTIVITY_ERROR:
      return pipe(
        assocPath(
          [
            'localOverrideActivityByControlBoardId',
            action.controlBoardId,
            'isLoading',
          ],
          false
        )
      )(state);

    case Actions.GET_CONTROL_BOARD_ACTUATORS_FOR_SITE_ID_SUCCESS: {
      const { payload: actuator, siteId } = action;
      return pipe(
        assocPath(
          ['controlBoardActuators', 'bySiteId', siteId, 'data'],
          actuator
        ),
        assocPath(
          ['controlBoardActuators', 'bySiteId', siteId, 'isLoading'],
          false
        )
      )(state);
    }

    case Actions.GET_CONTROL_BOARD_ACTUATORS_FOR_SITE_ID_LOADING: {
      const { siteId } = action;
      return assocPath(
        ['controlBoardActuators', 'bySiteId', siteId, 'isLoading'],
        true,
        state
      );
    }

    case Actions.GET_CONTROL_BOARD_ACTUATORS_FOR_SITE_ID_ERROR: {
      const { siteId } = action;
      return assocPath(
        ['controlBoardActuators', 'bySiteId', siteId, 'isLoading'],
        false,
        state
      );
    }

    case Actions.GET_HVAC_CONTROLS_BY_EQUIPMENT_ID_SUCCESS: {
      return assocPath(
        ['hvacControlsByEquipmentId', action.equipmentId],
        {
          isLoading: false,
          data: action.payload,
        },
        state
      );
    }

    case Actions.GET_HVAC_CONTROLS_BY_EQUIPMENT_ID_LOADING: {
      return assocPath(
        ['hvacControlsByEquipmentId', action.equipmentId, 'isLoading'],
        true,
        state
      );
    }

    case Actions.GET_HVAC_CONTROLS_BY_EQUIPMENT_ID_ERROR: {
      return assocPath(
        ['hvacControlsByEquipmentId', action.equipmentId, 'isLoading'],
        false,
        state
      );
    }

    case Actions.GET_HVAC_CONTROLS_BY_SITE_ID_SUCCESS: {
      return assocPath(
        ['hvacControlsBySiteId', action.siteId],
        {
          isLoading: false,
          data: action.payload,
        },
        state
      );
    }

    case Actions.GET_HVAC_CONTROLS_BY_SITE_ID_LOADING: {
      return assocPath(
        ['hvacControlsBySiteId', action.siteId, 'isLoading'],
        true,
        state
      );
    }

    case Actions.GET_HVAC_CONTROLS_BY_SITE_ID_ERROR: {
      return assocPath(
        ['hvacControlsBySiteId', action.siteId, 'isLoading'],
        false,
        state
      );
    }

    case Actions.GET_THERMOSTAT_ACTIVITY_BY_ID_SUCCESS: {
      const path = action.featureNotificationId
        ? [
            'thermostatActivityByFeatureNotificationId',
            action.featureNotificationId,
          ]
        : ['thermostatActivityById', action.thermostatId];

      return pipe(
        assocPath(path, {
          isLoading: false,
          data: normalizeThermostatActivityApiReturn(action.payload),
        })
      )(state);
    }

    case Actions.GET_THERMOSTAT_ACTIVITY_BY_ID_LOADING: {
      const path = action.featureNotificationId
        ? [
            'thermostatActivityByFeatureNotificationId',
            action.featureNotificationId,
          ]
        : ['thermostatActivityById', action.thermostatId];

      return assocPath(
        path,
        {
          isLoading: false,
          data: [],
        },
        state
      );
    }

    case Actions.GET_THERMOSTAT_ACTIVITY_BY_ID_ERROR: {
      const path = action.featureNotificationId
        ? [
            'thermostatActivityByFeatureNotificationId',
            action.featureNotificationId,
            'isLoading',
          ]
        : ['thermostatActivityById', action.thermostatId, 'isLoading'];

      return assocPath(path, false, state);
    }

    case Actions.GET_TEMP_CONTROL_STATUS_TIMESERIES_BY_ID_SUCCESS: {
      return pipe(
        assocPath(
          ['timeSeries', 'temperatureControlStatus', 'byId', action.controlId],
          {
            isLoading: false,
            data: normalizeTemperatureControlStatusApiReturn(action.payload),
          }
        )
      )(state);
    }

    case Actions.GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_SUCCESS: {
      const path = action.featureNotificationId
        ? [
            'systemOverrideByFeatureNotificationId',
            action.featureNotificationId,
          ]
        : ['systemOverrideByControlId', action.controlId];

      return pipe(
        assocPath(path, {
          isLoading: false,
          data: normalizeSystemOverrideActivityApiReturn(action.payload),
        })
      )(state);
    }

    case Actions.GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_LOADING: {
      const path = action.featureNotificationId
        ? [
            'systemOverrideByFeatureNotificationId',
            action.featureNotificationId,
          ]
        : ['systemOverrideByControlId', action.controlId];

      return pipe(assocPath(path, true))(state);
    }

    case Actions.GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_ERROR: {
      const path = action.featureNotificationId
        ? [
            'systemOverrideByFeatureNotificationId',
            action.featureNotificationId,
            'isLoading',
          ]
        : ['systemOverrideByControlId', action.controlId, 'isLoading'];

      return pipe(assocPath(path, false))(state);
    }

    case Actions.GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_SUCCESS:
      const lightReadingTimeSeries = normalizeLightReadingApiReturn(
        action.payload
      );
      const { min, max } = getTimeSeriesMinMax(lightReadingTimeSeries, 'lux');

      return pipe(
        assocPath(
          [
            'timeSeries',
            'lightSensorReadings',
            'byControlBoardId',
            action.controlBoardId,
          ],
          {
            isLoading: false,
            data: lightReadingTimeSeries,
            min,
            max,
          }
        )
      )(state);

    case Actions.GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_LOADING:
      return pipe(
        assocPath(
          [
            'timeSeries',
            'lightSensorReadings',
            'byControlBoardId',
            action.controlBoardId,
          ],
          {
            isLoading: true,
          }
        )
      )(state);

    case Actions.GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_ERROR:
      return pipe(
        assocPath(
          [
            'timeSeries',
            'lightSensorReadings',
            'byControlBoardId',
            action.controlBoardId,
          ],
          {
            isLoading: false,
          }
        )
      )(state);

    case Actions.GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_SUCCESS:
      const lightThresholdTimeSeriesFromSchedule = normalizeSchedulerApiReturn(
        action.payload
      );
      const { min: minThreshold, max: maxThreshold } = getTimeSeriesMinMax(
        lightThresholdTimeSeriesFromSchedule,
        'threshold'
      );

      return pipe(
        assocPath(
          [
            'timeSeries',
            'lightSensorReadings',
            'bySchedulerId',
            action.schedulerId,
          ],
          {
            isLoading: false,
            data: lightThresholdTimeSeriesFromSchedule,
            min: minThreshold,
            max: maxThreshold,
          }
        )
      )(state);

    case Actions.GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_LOADING:
      return pipe(
        assocPath(
          [
            'timeSeries',
            'lightSensorReadings',
            'bySchedulerId',
            action.schedulerId,
          ],
          {
            isLoading: true,
          }
        )
      )(state);

    case Actions.GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_ERROR:
      return pipe(
        assocPath(
          [
            'timeSeries',
            'lightSensorReadings',
            'bySchedulerId',
            action.schedulerId,
          ],
          {
            isLoading: false,
          }
        )
      )(state);

    case Actions.GET_THERMOSTATS_BY_SITE_ID_LOADING:
      return pipe(
        assocPath(['thermostatTileInfoBySiteId', action.siteId], {
          isLoading: true,
        })
      )(state);

    case Actions.GET_THERMOSTATS_BY_SITE_ID_SUCCESS:
      const data = action.payload;

      return pipe(
        assocPath(
          ['thermostatTileInfoBySiteId', action.siteId, 'isLoading'],
          false
        ),
        assocPath(['thermostatTileInfoBySiteId', action.siteId, 'data'], data)
      )(state);

    case Actions.GET_HVAC_CONTROLS_BY_SITE_ID_AGGREGATE_SUCCESS:
      return pipe(
        assocPath(
          ['hvacTileInfoBySiteId', action.siteId, 'data'],
          action.payload
        ),
        assocPath(['hvacTileInfoBySiteId', action.siteId, 'isLoading'], false)
      )(state);

    case Actions.GET_HVAC_CONTROLS_BY_SITE_ID_AGGREGATE_LOADING:
      return pipe(
        assocPath(['hvacTileInfoBySiteId', action.siteId], {
          isLoading: true,
        })
      )(state);

    case Actions.GET_HVAC_CONTROLS_BY_SITE_ID_AGGREGATE_ERROR: {
      return assocPath(
        ['hvacControlsBySiteId', action.siteId, 'isLoading'],
        false,
        state
      );
    }

    case Actions.GET_CONTROLLED_HVACS_BY_ORG_ID_LOADING:
      return pipe(
        assocPath(
          ['controlledHvacInfoByOrgId', action.orgId, 'ishvacDataLoading'],
          true
        )
      )(state);

    case Actions.GET_CONTROLLED_HVACS_BY_ORG_ID_ERROR:
      return pipe(
        assocPath(
          ['controlledHvacInfoByOrgId', action.orgId, 'ishvacDataLoading'],
          false
        )
      )(state);

    case Actions.GET_CONTROLLED_HVACS_BY_ORG_ID_SUCCESS:
      const aggregateData = action.payload;
      return pipe(
        assocPath(
          ['controlledHvacInfoByOrgId', action.orgId, 'ishvacDataLoading'],
          false
        ),
        assocPath(['controlledHvacInfoByOrgId', 'aggregateData'], aggregateData)
      )(state);

    case Actions.CREATE_CURRENT_UPDATE_MODE:
      return pipe(
        applyAction(updateCurrentControlModeUpdate, action),
        applyAction(updateCurrentMode, action),
        applyAction(updateShowUpdateControlWorkingModeModal, action),
        applyAction(updatePrimedControlModeUpdateById, action)
      )(state, action);
    default:
      return state;
  }
};

export default controls;
