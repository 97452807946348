import React from 'react';
import { Loader } from '@energybox/react-ui-library/dist/components';

const Loading = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}
  >
    <div data-testid="Loading" style={{ minWidth: '24px' }}>
      <Loader />
    </div>
  </div>
);

export default Loading;
