import {
  MeasurementSystem,
  SubscribedThermostats,
  ThermostatWorkingModeLabel,
} from '@energybox/react-ui-library/dist/types';
import Tile from '../../../components/Tile/Tile';
import TileHeader from '../../../components/Tile/TileHeader';
import TileContent from '../../../components/Tile/TileContent';
import {
  ControlOverridesDisplay,
  Table,
} from '@energybox/react-ui-library/dist/components';
import TileFooter from '../../../components/Tile/TileFooter';
import * as Routes from '../../../routes';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import {
  ControlledHvacInfoByOrgId,
  HvacOrgControls,
} from '../../../reducers/types/controlsReducer';
import { Columns } from '@energybox/react-ui-library/dist/components/Table/Table';
import {
  ViewportTypes,
  useViewportType,
} from '@energybox/react-ui-library/dist/hooks';
import { useEffect, useRef, useState } from 'react';
import useCurrentUser, {
  useOrganizationId,
} from '../../../hooks/useCurrentUser';
import { getHvacControlsByOrgId } from '../../../actions/controls';
import { useSitesById } from '../../../hooks/useSites';
import {
  createTemperatureString,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import styles from './HvacControlsOperationsTile.module.css';
import {
  subscribeToDeviceReadings,
  subscribeToDeviceStatus,
  unsubscribeFromDeviceReadings,
  unsubscribeFromDeviceStatus,
} from '../../../actions/streamApi';
import { DeviceStatusById } from '../../../reducers/deviceStatusById';
import HappyHornOverlay from '../../../components/Tile/HappyHornOverlay';

type Props = {
  className?: string;
};

const HvacControlsOperationsTile: React.FC<Props> = ({ className }) => {
  const orgId = useOrganizationId();
  const sites = useSitesById();
  const currentUser = useCurrentUser();
  const [loading, setLoading] = useState(true);
  const hasRunRef = useRef(false);

  const isImperial = isDefined(currentUser?.temperature)
    ? currentUser?.temperature === 'FAHRENHEIT'
    : currentUser?.measurementSystem === MeasurementSystem.IMPERIAL;
  const dispatch = useDispatch();
  const listOfThermostats = useSelector<
    ApplicationState,
    SubscribedThermostats
  >((state) => state.subscribedThermostats);
  const byId = listOfThermostats?.byId ?? {};
  const thermostatSubscriptionData = byId ? Object.values(byId) : [];

  useEffect(() => {
    if (orgId) dispatch(getHvacControlsByOrgId(orgId));
  }, [orgId]);

  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const thermostatInfoByOrgId = useSelector<
    ApplicationState,
    ControlledHvacInfoByOrgId
  >(({ controls }) => controls?.controlledHvacInfoByOrgId);

  const aggregateData = thermostatInfoByOrgId?.aggregateData ?? {};

  const finalData = aggregateData ? Object.values(aggregateData) : [];

  useEffect(() => {
    if (hasRunRef.current || finalData.length === 0) return;
    finalData.forEach((data, index) => {
      const thermostat = data.thermostat!;
      let unsubscribe: (() => void) | null = null;

      if (isDefined(thermostat)) {
        dispatch(
          subscribeToDeviceReadings(
            thermostat?.vendor,
            thermostat?.uuid,
            thermostat?.id
          )
        );
        dispatch(
          subscribeToDeviceStatus(
            thermostat?.vendor,
            thermostat?.uuid,
            thermostat?.id
          )
        );
      }
      unsubscribe = () => {
        dispatch(
          unsubscribeFromDeviceReadings(
            thermostat?.vendor,
            thermostat?.uuid,
            thermostat?.id
          )
        );
        dispatch(
          unsubscribeFromDeviceStatus(
            thermostat?.vendor,
            thermostat?.uuid,
            thermostat?.id
          )
        );
      };

      if (index === finalData.length - 1) {
        setLoading(true);
      }

      hasRunRef.current = true;

      return () => {
        if (isDefined(unsubscribe)) {
          unsubscribe();
        }
      };
    });
  }, [finalData, dispatch]);

  useEffect(() => {
    let timer;
    if (loading) {
      // Set a timer to keep loading true for 5 extra seconds
      timer = setTimeout(() => {
        setLoading(false);
      }, 2000);

      // Clear the timer if the component unmounts or loading changes
      return () => clearTimeout(timer);
    }
  }, [loading]);

  const deviceStatusById: DeviceStatusById = useSelector<
    ApplicationState,
    DeviceStatusById
  >((state) => state.deviceStatusById);

  const onlineThermostatSubscriptionData = thermostatSubscriptionData.filter(
    (thermostat) => {
      return deviceStatusById?.[thermostat.id]?.onlineState === true;
    }
  );

  const tileViewHvacContorlData = (finalData) => {
    onlineThermostatSubscriptionData.sort((a, b) => {
      const aValue = a.localAdjustmentOverrideEnergybox ? 1 : 0;
      const bValue = b.localAdjustmentOverrideEnergybox ? 1 : 0;
      if (aValue !== bValue) {
        return bValue - aValue;
      }
      return b.temperature! - a?.temperature!;
    });

    const filteredIds = onlineThermostatSubscriptionData
      .slice(0, 5)
      .map((data) => data.id);

    const thermostatData = finalData
      .filter((item) => filteredIds.includes(item.thermostat?.id))
      .sort(
        (a, b) =>
          filteredIds.indexOf(a.thermostat.id) -
          filteredIds.indexOf(b.thermostat.id)
      );

    return thermostatData;
  };

  const getStreamDataObject = (data) => {
    return thermostatSubscriptionData.filter(
      (item) => item.id === data.thermostat.id
    );
  };

  const TOOLTIP_DESCRIPTION =
    'A highlevel view of any HVAC unit that is in Local Override, sorted by highest ambient temperature';

  const isHvacDataLoading = useSelector(
    ({ controls }: ApplicationState) => controls?.ishvacDataLoading
  );

  const columns: Columns<HvacOrgControls>[] = [
    {
      width: '5%',
      header: '#',
      cellContent: (_, rowIndex) => (
        <span className={styles.index}>{rowIndex + 1}</span>
      ),
    },
    {
      width: '20%',
      header: 'Equipment',
      cellContent: (data) => {
        return (
          <div>
            <Link
              to={`${Routes.SITES}/${data.siteId}/equipment/${data?.equipment.id}`}
            >
              {data.equipment.title}
            </Link>
          </div>
        );
      },
    },
    {
      width: '20%',
      header: 'Site Name',
      cellContent: (data) => sites[data.siteId]?.title,
    },
    {
      width: '14%',
      header: (
        <div className={styles.headerTitle}>
          {' '}
          Thermostat Mode / Current Settings{' '}
        </div>
      ),
      cellContent: (data) => {
        const streamDataObject = getStreamDataObject(data);
        return (
          <>
            <div className={styles.mode}>
              {ThermostatWorkingModeLabel[streamDataObject[0].mode!]}
            </div>
            <div>
              {createTemperatureString(
                streamDataObject[0].heatSetPoint!,
                currentUser!,
                isImperial ? 0 : 1
              )}{' '}
              -{' '}
              {createTemperatureString(
                streamDataObject[0].coolSetPoint!,
                currentUser!,
                isImperial ? 0 : 1
              )}
            </div>
          </>
        );
      },
    },
    {
      width: '12%',
      header: 'Current Temp',
      cellContent: (data) => {
        const streamDataObject = getStreamDataObject(data);
        return (
          <>
            {createTemperatureString(
              streamDataObject[0].temperature!,
              currentUser!,
              isImperial ? 0 : 1
            )}
          </>
        );
      },
    },
    {
      width: '12%',
      header: (
        <div className={styles.headerTitle}>
          {' '}
          Local Override / Control Mode{' '}
        </div>
      ),
      cellContent: (data) => {
        const streamDataObject = getStreamDataObject(data);
        return (
          <>
            <ControlOverridesDisplay
              controlWorkingMode={streamDataObject[0].workingMode}
              isLocalOverrideActive={
                streamDataObject[0].localAdjustmentOverrideEnergybox
              }
              useGreenText={true}
            />
          </>
        );
      },
    },
  ];

  const mobileColumns: Columns<HvacOrgControls>[] = [
    {
      width: '3%',
      header: '#',
      cellContent: (_, rowIndex) => (
        <span className={styles.index}>{rowIndex + 1}</span>
      ),
    },
    {
      width: '15%',
      header: 'Equipment / Site',
      cellContent: (data) => {
        return (
          <>
            <div>
              <Link
                to={`${Routes.SITES}/${data.siteId}/equipment/${data?.equipment.id}`}
              >
                {data.equipment.title}
              </Link>
            </div>
            <div>{sites[data.siteId]?.title}</div>
          </>
        );
      },
    },
    {
      width: '5%',
      header: 'Current Temp',
      cellContent: (data) => {
        const streamDataObject = getStreamDataObject(data);
        return (
          <>
            {createTemperatureString(
              streamDataObject[0].temperature!,
              currentUser!,
              isImperial ? 0 : 1
            )}
          </>
        );
      },
    },
    {
      width: '23%',
      header: (
        <div className={styles.headerTitle}>
          {' '}
          Local Override / Control Mode{' '}
        </div>
      ),
      cellContent: (data) => {
        const streamDataObject = getStreamDataObject(data);
        return (
          <>
            <ControlOverridesDisplay
              controlWorkingMode={streamDataObject[0].workingMode}
              isLocalOverrideActive={
                streamDataObject[0].localAdjustmentOverrideEnergybox
              }
              useGreenText={true}
            />
          </>
        );
      },
    },
  ];

  return (
    <Tile
      testId="hvac"
      className={className}
      isLoading={loading || isHvacDataLoading}
    >
      <TileHeader
        title={'HVAC Controls Operations'}
        tooltipDescription={TOOLTIP_DESCRIPTION}
        arrowDirection={'right'}
      />
      {tileViewHvacContorlData(finalData).length == 0 ? (
        <HappyHornOverlay />
      ) : (
        <>
          <TileContent
            className={`${
              isMobile ? styles.mobileContentContainer : styles.contentContainer
            } ${
              isMobile ? styles.mobileScrollOnOverflow : styles.scrollOnOverflow
            }`}
          >
            <Table
              className={styles.table}
              columns={isMobile ? mobileColumns : columns}
              data={tileViewHvacContorlData(finalData)}
            />
          </TileContent>

          <TileFooter
            redirectTo={`${Routes.HVAC_CONTROLS_OPERATIONS}`}
            search="?prev=today"
          />
        </>
      )}
    </Tile>
  );
};

export default HvacControlsOperationsTile;
