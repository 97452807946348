import {
  Button,
  Label,
  Loader,
  Modal,
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import {
  CommentType,
  CommentTypeLabel,
  ResourceType,
  ValueType,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  determineTimeStringByTimezone,
  formattedCommentValueString,
} from '@energybox/react-ui-library/dist/utils';

import React, { useCallback, useEffect, useState } from 'react';
import { Actions as CommentsActions } from '../../actions/comments';
import useAppLocale from '../../hooks/useAppLocale';
import { useNewComment } from '../../hooks/useComments';
import useCurrentUser from '../../hooks/useCurrentUser';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import styles from './NewCommentPopup.module.css';

type Props = {
  ianaTimeZoneCode?: string;
  resourceId: number;
  resourceTitle?: string;
  resourceType: ValueType;
  onUnmount: () => void;
  //optional parentResourceId needed b/c if comment is created on child operational sensorId of
  //parent equipment or space page, then we store comment in redux under the parentId
  //TO NOTE: energy comments are always stored under equipmentId in both redux as well as BE
  parentResourceId?: number | string;
  getCurrentValueFromTimestamp?: (timestamp: string) => any;
};

const COMMENT_TEXTBOX_CHARACTER_LIMIT = 250;

const NewCommentPopup: React.FC<Props> = ({
  ianaTimeZoneCode,
  resourceId,
  resourceTitle,
  resourceType,
  onUnmount,
  parentResourceId,
  getCurrentValueFromTimestamp,
}) => {
  const locale = useAppLocale();
  const currentUser = useCurrentUser();
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const { create, fields, hideNewCommentPopup, isLoading, apiError } =
    useNewComment(`${resourceId}_${resourceType}`);

  const { from, value, comment } = fields;

  const [commentType, setCommentType] = useState<CommentType>(
    CommentType.GENERAL
  );
  const [commentText, setCommentText] = useState('');

  useEffect(() => {
    return () => onUnmount();
  }, [onUnmount]);

  if (!currentUser) return null;

  //*** Custom functions/vars ***//
  const onCommentTypeSelect = (value: CommentType) => {
    return setCommentType(value);
  };

  const renderResourceValue = () => {
    return formattedCommentValueString(
      String(value),
      resourceType,
      currentUser,
      'US'
    );
  };

  const renderCurrentValue = () => {
    if (fields.from) {
      return (
        Number(getCurrentValueFromTimestamp?.(fields.from)).toFixed(2) + ' A'
      );
    } else {
      return null;
    }
  };

  const onClickSave = () => {
    const payload = { ...fields, comment: commentText, commentType };
    create(payload, parentResourceId);
  };

  const actions = (
    <div className={styles.actionButtons}>
      <Button variant="text" onClick={hideNewCommentPopup}>
        Cancel
      </Button>
      <Button onClick={() => onClickSave()}>Save</Button>
    </div>
  );

  const content = (
    <div className={classNames(styles.root, isMobile ? styles.mobileRoot : '')}>
      <div className={styles.content}>
        <div className={`${styles.container} ${styles.flexSpaceBetween}`}>
          <div className={styles.title}>Add Comment</div>
          {isLoading && (
            <div>
              <Loader size={16} />
            </div>
          )}
        </div>

        <div className={`${styles.container} ${styles.flexContainer}`}>
          <Label>
            <span className={styles.commentTypeLabel}>Comment Type:</span>
          </Label>
          <Select variant="select" title={CommentTypeLabel[commentType]}>
            {Object.values(CommentType).map((type: CommentType) => (
              <SelectItem
                key={type}
                isSelected={type === commentType}
                onSelect={() => onCommentTypeSelect(type)}
              >
                {CommentTypeLabel[type]}
              </SelectItem>
            ))}
          </Select>
        </div>

        <div className={styles.container}>
          <textarea
            className={styles.textBox}
            value={commentText}
            minLength={1}
            maxLength={COMMENT_TEXTBOX_CHARACTER_LIMIT}
            onChange={({ currentTarget }) => {
              setCommentText(currentTarget.value);
            }}
            placeholder="Add Comment Here"
          />
          <div className={styles.textBoxCounter}>
            {COMMENT_TEXTBOX_CHARACTER_LIMIT - comment?.length} /{' '}
            {COMMENT_TEXTBOX_CHARACTER_LIMIT}
          </div>
        </div>

        <div className={styles.container}>
          <div className={styles.italicGrayText}>
            {determineTimeStringByTimezone(
              new Date(from),
              locale.fullDateTimeFormat,
              ianaTimeZoneCode,
              true
            )}
          </div>
          <div>
            {resourceTitle && (
              <span className={styles.resourceTitle}>{resourceTitle}</span>
            )}
            <span className={styles.resourceValue}>
              {renderResourceValue()}
            </span>
            {fields.from && getCurrentValueFromTimestamp && (
              <>
                <span className={styles.grayText}>{' | '}</span>
                <span className={styles.currentValue}>
                  {renderCurrentValue()}
                </span>
              </>
            )}
          </div>
        </div>

        {currentUser && (
          <div
            className={`${styles.container} ${styles.borderTop} ${styles.italicGrayText}`}
          >
            {`${currentUser.firstName} ${currentUser.lastName}`}
          </div>
        )}

        {Object.values(apiError).length > 0 &&
          renderAPIerror(apiError, CommentsActions.POST_COMMENT_ERROR)}
      </div>

      <div className={styles.actionsContainer}>{actions}</div>
    </div>
  );

  if (isMobile) {
    return (
      <Modal containerClassName={styles.mobileModalContainer}>{content}</Modal>
    );
  }

  return content;
};

export default NewCommentPopup;
