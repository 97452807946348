import { useEffect, useState } from 'react';
import useGetOpenSourceLicense from '../../hooks/useGetOpenSourceLicense';
import { useRemark } from 'react-remark';
import styles from './License.module.css';
import { Loader } from '@energybox/react-ui-library/dist/components';
import { PageContentWrapper, PageContentHeader } from '../../components/Page';
import { useLocation } from 'react-router-dom';
import BaseFilterBar from '../../components/Filters/BaseFilterBar';
import BaseContentContainer from '../../components/layout/BaseContentContainer';
import { format } from 'date-fns';

const disclaimer = `This product incorporates software components from the projects listed below. The original licenses and copy right notices under which ENERGYBOX received such components are listed below. ENERGYBOX reserves all rights not expressly granted herein, whether by implication, estoppel or otherwise. This product partly includes copyrighted third-party software licensed under the terms of the GNU General Public License among other open source license types. All listed software packages are copyright by their respective authors. Please see the source code for detailed information.`;

const License = () => {
  const location = useLocation();
  const markdown = useGetOpenSourceLicense();
  const [reactContent, setMarkdownSource] = useRemark({
    rehypeReactOptions: {
      components: {
        pre: (props) => <pre className={styles.license_div} {...props} />,
      },
    },
  });
  const [updatedAt, setUpdatedAt] = useState('');
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!!!markdown.isLoading && markdown.text !== '') {
      const updated = format(new Date(markdown.lastUpdate), 'd-MMM-y');
      setMarkdownSource(markdown.text);
      setUpdatedAt(updated);
      setLoading(false);
    }
  }, [markdown]);

  const isPublic = location.pathname === '/public/license';

  return (
    <>
      {!isPublic && (
        <BaseFilterBar className={styles.topBar}>
          <div className={styles.tabBar}></div>
        </BaseFilterBar>
      )}
      <BaseContentContainer>
        <PageContentWrapper className={isPublic ? '' : styles.root}>
          {!isPublic && <PageContentHeader header="Open Source Notices" />}
          <div
            className={
              isPublic ? styles.public_license_wrapper : styles.license_wrapper
            }
          >
            {isLoading ? (
              <Loader className={styles.loader} />
            ) : (
              <>
                {updatedAt !== '' ? `Last Updated on ${updatedAt}` : updatedAt}
                <br />
                <br />
                <p>{disclaimer}</p>
                {reactContent}
              </>
            )}
          </div>
        </PageContentWrapper>
      </BaseContentContainer>
    </>
  );
};

export default License;
