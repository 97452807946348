import {
  ControlBoard,
  LightSensorPort,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';

import { Actions } from '../actions/control_boards';
import { spacesFromApiResponse } from './spaces';

export interface ControlBoards {
  controlBoardsById: ControlBoardsById;
}

export type ControlBoardsById = {
  [id: string]: ControlBoard;
};

export const ControlBoardsFromApiResponse = (data: any) => ({
  id: data.id,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt || undefined,
  title: data.title,
  description: data.description || '',
  networkGroup: data.networkGroup,
  networkGroupId: data.networkGroupId,
  actuators: data.actuators,
  actuatorsStates: data.actuatorsStates,

  gatewayModel: data.gatewayModel || undefined,
  modelId: data.modelId || undefined,
  spaceId: data.spaceId,
  pairedSensorIds: data.pairedSensorIds || undefined,
  pairedSensors: data.pairedSensors,
  resourceType: ResourceType[(data._entity as string).toUpperCase()],

  scannedSensorIds: data.scannedSensorIds || undefined,
  scannedSensors: data.scannedSensors,

  sensorsWhitelist: data.sensorsWhitelist || undefined,

  uuid: data.uuid,
  vendor: data.vendor,
  model: data.model,
  lightSensorPort: data.lightSensorPort || LightSensorPort.NONE,
  edgeId: data.edgeId,
  productId: data.productId || undefined,
  space: spacesFromApiResponse(data.space),
  gatewayInfo: data.gatewayInfo || undefined,
  gatewayConfig: data.gatewayConfig || undefined,
  gatewayStatus: data.gatewayStatus || undefined,
  gatewayOnlineStatus: data.gatewayOnlineStatus || undefined,

  lightSensorPublishingInterval: data.lightSensorPublishingInterval,
});

const initialState = {
  controlBoardsById: {},
};

const controlBoards = (state: ControlBoards = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_CONTROL_BOARD_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['controlBoardsById', action.id],
          ControlBoardsFromApiResponse(action.payload)
        )
      )(state);

    default:
      return state;
  }
};

export default controlBoards;
