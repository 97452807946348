import {
  Edge,
  Equipment,
  NetworkGroup,
  Organization,
  ResourcePath,
  ResourceType,
  Sensor,
  Space,
  Site,
} from '@energybox/react-ui-library/dist/types';
import { assocPath, dissocPath, pipe } from 'ramda';
import { Actions } from '../actions/resources';

const initialState = {
  byId: {},
  isLoadingById: {},
};

export type Resources = {
  byId: PathsById;
  isLoadingById: IsPathLoadingById;
};

export type IsPathLoadingById = {
  [id: number]: boolean;
};

export type PathsById = {
  [id: number]: ResourcePath[];
};

function mapTargetTypes(
  resource:
    | Site
    | Sensor
    | Equipment
    | Space
    | Organization
    | Edge
    | NetworkGroup
) {
  switch (resource._entity) {
    case ResourceType.EQUIPMENT:
      return {
        title: resource.title,
        id: resource.id,
        type: resource._entity,
        typeId: (resource as Equipment).typeId,
      };

    case ResourceType.ORGANIZATION:
    case null:
      return null;

    case ResourceType.EDGE:
      return {
        title: (resource as Edge).uuid,
        id: resource.id,
        type: resource._entity,
      };

    case ResourceType.SITE:
      return {
        type: resource._entity,
        title: resource.title,
        id: resource.id,
        timeZone: (resource as Site).timeZone,
      };

    default:
      return {
        title: resource.title,
        id: resource.id,
        type: resource._entity,
      };
  }
}

export default (state: Resources = initialState, action: any) => {
  switch (action.type) {
    case Actions.PATH_TINY_SUCCESS:
      return pipe(
        assocPath(
          ['byId', action.resourceId],
          action.payload.map((r) => mapTargetTypes(r)).filter((p) => p)
        ),
        assocPath(['isLoadingById', action.resourceId], false)
      )(state);

    case Actions.PATH_SUCCESS:
      return pipe(
        assocPath(
          ['byId', action.resourceId],
          action.payload
            .reverse()
            .map((r) => mapTargetTypes(r))
            .filter((p) => p)
        ),
        assocPath(['isLoadingById', action.resourceId], false)
      )(state);

    case Actions.PATH_TINY_LOADING:
    case Actions.PATH_LOADING:
      return assocPath(['isLoadingById', action.resourceId], true, state);

    case Actions.PATH_TINY_ERROR:
    case Actions.PATH_ERROR:
      return assocPath(['isLoadingById', action.resourceId], false, state);

    case Actions.CLEAR_RESOURCE_PATH:
      return dissocPath(['byId', action.resourceId], state);

    default:
      return state;
  }
};
