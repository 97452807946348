const apiBase = '/api/v1/network-groups';

export enum Actions {
  GET_NETWORK_GROUP_SUCCESS = '@network_group/GET_NETWORK_GROUP_SUCCESS',
  GET_NETWORK_GROUP_ERROR = '@network_group/GET_NETWORK_GROUP_ERROR',
  GET_NETWORK_GROUP_LOADING = '@network_group/GET_NETWORK_GROUP_LOADING',

  GET_NETWORK_GROUP_BY_ORG_ID_SUCCESS = '@network_group/GET_NETWORK_GROUP_BY_ORG_ID_SUCCESS',
  GET_NETWORK_GROUP_BY_ORG_ID_ERROR = '@network_group/GET_NETWORK_GROUP_BY_ORG_ID_ERROR',
  GET_NETWORK_GROUP_BY_ORG_ID_LOADING = '@network_group/GET_NETWORK_GROUP_BY_ORG_ID_LOADING',

  GET_NETWORK_GROUP_BY_THERMOSTAT_SUCCESS = '@network_group/GET_NETWORK_GROUP_BY_THERMOSTAT_SUCCESS',
  GET_NETWORK_GROUP_BY_THERMOSTAT_ERROR = '@network_group/GET_NETWORK_GROUP_BY_THERMOSTAT_ERROR',
  GET_NETWORK_GROUP_BY_THERMOSTAT_LOADING = '@network_group/GET_NETWORK_GROUP_BY_THERMOSTAT_LOADING',

  GET_NETWORK_GROUP_BY_SITE_ID_SUCCESS = '@network_group/GET_NETWORK_GROUP_BY_SITE_ID_SUCCESS',
  GET_NETWORK_GROUP_BY_SITE_ID_ERROR = '@network_group/GET_NETWORK_GROUP_BY_SITE_ID_ERROR',
  GET_NETWORK_GROUP_BY_SITE_ID_LOADING = '@network_group/GET_NETWORK_GROUP_BY_SITE_ID_LOADING',
}

export const getNetworkGroup = (id: string | number | undefined) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: Actions.GET_NETWORK_GROUP_SUCCESS,
  error: Actions.GET_NETWORK_GROUP_ERROR,
  loading: Actions.GET_NETWORK_GROUP_LOADING,
});

export const getNetworkGroupsByOrgId = (id: number | string) => {
  return {
    type: 'API_GET',
    path: `${apiBase}/organization/${id}`,
    success: {
      type: Actions.GET_NETWORK_GROUP_BY_ORG_ID_SUCCESS,
      orgId: id,
    },
    error: {
      type: Actions.GET_NETWORK_GROUP_BY_ORG_ID_ERROR,
      orgId: id,
    },
    loading: {
      type: Actions.GET_NETWORK_GROUP_BY_ORG_ID_LOADING,
      orgId: id,
    },
  };
};

export const getNetworkGroupsBythermostat = (id: number | string) => {
  return {
    type: 'API_GET',
    path: `${apiBase}/organization/${id}/using-thermostat`,
    success: {
      type: Actions.GET_NETWORK_GROUP_BY_THERMOSTAT_SUCCESS,
      orgId: id,
    },
    error: {
      type: Actions.GET_NETWORK_GROUP_BY_THERMOSTAT_ERROR,
      orgId: id,
    },
    loading: {
      type: Actions.GET_NETWORK_GROUP_BY_THERMOSTAT_LOADING,
      orgId: id,
    },
  };
};

export const getNetworkGroupsBySiteId = (id: number | string) => {
  return {
    type: 'API_GET',
    path: `${apiBase}/site/${id}`,
    success: {
      type: Actions.GET_NETWORK_GROUP_BY_SITE_ID_SUCCESS,
      siteId: id,
    },
    error: {
      type: Actions.GET_NETWORK_GROUP_BY_SITE_ID_ERROR,
      siteId: id,
    },
    loading: {
      type: Actions.GET_NETWORK_GROUP_BY_SITE_ID_LOADING,
      siteId: id,
    },
  };
};
