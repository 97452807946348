import {
  Notification,
  NotificationApiFilter,
} from '@energybox/react-ui-library/dist/types';
import equals from 'ramda/src/equals';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFilteredNotifications } from '../actions/notifications';
import { ApplicationState } from '../reducers';

const useFilteredNotifications = (
  queryParams: NotificationApiFilter,
  isEnriched?: boolean
) => {
  const dispatch = useDispatch();
  const {
    shouldFetch = true,
    equipmentIds,
    sensorIds,
    fromDate,
    toDate,
    limit,
    offset,
    siteIds,
  } = queryParams;

  const notifications = useSelector<ApplicationState, Notification[]>(
    ({ notificationFilter }) => notificationFilter.filteredNotifications,
    equals
  );

  const isLoading = useSelector<ApplicationState, boolean>(
    ({ notificationFilter }) => {
      return notificationFilter.isLoading;
    }
  );

  useEffect(() => {
    if (!shouldFetch) {
      return;
    }
    const queryParams = {
      shouldFetch,
      fromDate,
      toDate,
      limit,
      offset,
      equipmentIds,
      sensorIds,
      siteIds,
    };

    dispatch(getFilteredNotifications(queryParams, isEnriched));
  }, [
    dispatch,
    shouldFetch,
    limit,
    fromDate,
    toDate,
    offset,
    equipmentIds?.join(''),
    sensorIds?.join(''),
    siteIds,
    isEnriched,
  ]);

  return {
    data: notifications,
    isLoading,
  };
};

export default useFilteredNotifications;
