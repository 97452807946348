import { Service } from '../config';
import {
  createOperationalSamplesQueryString,
  convertQueryDatesToTsApiFormat,
} from '@energybox/react-ui-library/dist/utils';
import { GetOperationalSamplesParams } from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';

const apiBase = '/operational/sensor';

export enum Actions {
  GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS = '@operational_samples/GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS',
  GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR = '@operational_samples/GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR',
  GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING = '@operational_samples/GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING',

  GET_VALUE_CHIP_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS = '@operational_samples/GET_VALUE_CHIP_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS',
  GET_VALUE_CHIP_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR = '@operational_samples/GET_VALUE_CHIP_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR',
  GET_VALUE_CHIP_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING = '@operational_samples/GET_VALUE_CHIP_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING',
}

export const getOperationalSamplesBySensorId = (
  sensorId: string | number,
  params: GetOperationalSamplesParams,
  notificationId?: string | number
) => ({
  service: Service.timeSeries,
  type: 'API_GET',
  path: `${apiBase}/${params.sensorId}?${createOperationalSamplesQueryString(
    convertQueryDatesToTsApiFormat(R.omit(['sensorId'], params))
  )}`,
   success: {
    type: Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS,
    sensorId,
    notificationId,
  },
  error: {
    type: Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR,
    sensorId,
    notificationId,
  },
  loading: {
    type: Actions.GET_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING,
    sensorId,
    notificationId,
  },
});

export const getOperationalSamplesBySensorIdForValueChip = (
  sensorId: string | number,
  params: GetOperationalSamplesParams,
  notificationId?: string | number
) => ({
  service: Service.timeSeries,
  type: 'API_GET',
  path: `${apiBase}/${params.sensorId}?${createOperationalSamplesQueryString(
    convertQueryDatesToTsApiFormat(R.omit(['sensorId'], params))
  )}`, 
   success: {
    type: Actions.GET_VALUE_CHIP_OPERATIONAL_SAMPLES_BY_SENSOR_ID_SUCCESS,
    sensorId,
    notificationId,
  },
  error: {
    type: Actions.GET_VALUE_CHIP_OPERATIONAL_SAMPLES_BY_SENSOR_ID_ERROR,
    sensorId,
    notificationId,
  },
  loading: {
    type: Actions.GET_VALUE_CHIP_OPERATIONAL_SAMPLES_BY_SENSOR_ID_LOADING,
    sensorId,
    notificationId,
  },
});
