import {
  Button,
  Card,
  InputField,
  Label,
} from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { connect } from 'react-redux';
import { switchOrganization } from '../../actions/app';
import styles from './OrgSwitch.module.css';

interface Props {
  switchOrganization: typeof switchOrganization;
}

interface State {
  orgId: string;
}

class OrgSwitch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      orgId: '',
    };
  }

  onOrgIdChange = (e) => this.setState({ orgId: e.target.value });
  onSubmit = () => this.props.switchOrganization(Number(this.state.orgId));

  render() {
    const { orgId } = this.state;

    return (
      <Card className={styles.root}>
        <div>
          <Label>Switch Org</Label>
        </div>
        <div>
          <InputField
            name="orgId"
            onChange={this.onOrgIdChange}
            value={orgId}
          />
        </div>
        <div>
          <Button onClick={this.onSubmit}>Search</Button>
        </div>
      </Card>
    );
  }
}

const mapDispatchToProps = {
  switchOrganization,
};
export default connect(null, mapDispatchToProps)(OrgSwitch);
