import { Checkbox } from '@energybox/react-ui-library/dist/components';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import { withStyles } from '@material-ui/core/styles';

import React from 'react';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import Icon from '../../Icon';
import IconEquipmentFactory from '../../icons/IconEquipmentFactory';

type Props = {
  classes: any;
  activeItems: number;
  maxItems: number;
  onClick: Function;
  title: string;
  children: React.ReactNode;
  alias: string;
  isCompact?: boolean;
};

type State = {
  isActive: boolean;
};

class EquipmentMenuItem extends React.Component<Props, State> {
  static defaultProps = {
    checked: false,
    onClick: () => {},
  };

  state = {
    isActive: false,
  };

  onDropDownClick = () => {
    this.setState((state) => ({
      isActive: !state.isActive,
    }));
  };

  render() {
    const { isActive } = this.state;
    const { classes, isCompact, activeItems, maxItems, onClick, title, alias } =
      this.props;

    return (
      <li className={classes.listStyleNone}>
        <div
          className={classNames(
            classes.listItem,
            isActive ? classes.listItemActive : undefined
          )}
        >
          <Checkbox
            checked={activeItems > 0}
            variant={maxItems > activeItems ? 'outlined' : 'contained'}
            className={classNames(
              classes.checkbox,
              isCompact ? classes.compactCheckbox : ''
            )}
            size={16}
            onChange={onClick}
          ></Checkbox>
          <div
            className={classes.listItemContentContainer}
            onClick={this.onDropDownClick}
          >
            <div
              className={classNames(
                classes.listIcon,
                isCompact ? classes.compactListIcon : ''
              )}
            >
              <IconEquipmentFactory id={alias} size={isCompact ? 24 : 40} />
            </div>
            <div
              className={classNames(
                classes.listTitle,
                isCompact ? classes.compactListTitle : ''
              )}
            >
              {title}
            </div>
            <div
              className={classNames(
                classes.dropDownIcon,
                isCompact ? classes.compactDropDownIcon : ''
              )}
            >
              <Icon size={20} icon="arrow" />
            </div>
          </div>
        </div>
        {isActive ? (
          <ul className={classes.childList}>{this.props.children}</ul>
        ) : null}
      </li>
    );
  }
}

const styles = (theme: any) => ({
  checkbox: {
    alignItems: 'center',
    display: 'inline-block',
    height: '60px',
    width: '2rem',
    '& > *': {
      height: '100%',
    },
  },
  compactCheckbox: {
    width: 'auto',
    height: '50px',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    listStyle: 'none',
    borderBottom: '1px solid #e1e2e4',
    cursor: 'pointer',
  },
  listItemActive: {
    '& $dropDownIcon > svg': {
      transform: 'rotate(180deg)',
    },
  },
  listIcon: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '18px',
    color: 'var(--accent-base)',
  },
  compactListIcon: {
    paddingLeft: '0.6rem',
  },
  listTitle: {
    width: '70%',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    paddingLeft: '18px',
    fontSize: '14px',
    lineHeight: '18px',
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginRight: '1rem',
  },
  compactListTitle: {
    paddingLeft: '0.6rem',
    marginRight: 0,
    fontSize: '0.8rem',
  },
  listItemContentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  dropDownIcon: {
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  compactDropDownIcon: {
    height: '50px',
  },
  childList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: 0,
    padding: '20px 0 10px 0',
    borderBottom: '1px solid #e1e2e4',
    listStyle: 'none',
  },
  childListItem: {
    flexBasis: '50%',
    paddingBottom: '10px',
  },
  hvacChildListItem: {
    flexBasis: '50%',
    padding: '10px',
    borderBottom: '1px solid var(--ambient-basePlus90)',
  },
  compactChildListItem: {
    flexBasis: '100%',
  },
  listStyleNone: {
    listStyle: 'none',
  },
  active: {
    color: 'var(--accent-base)',
  },
  radioIcon: { color: 'var(--accent-base)', marginRight: '1rem' },
  radioButton: { display: 'flex', alignItems: 'center' },
});

type ListItemProps = {
  classes: { [className: string]: any };
  key: string;
  label: string;
  checked: boolean;
  onClick: () => void;
  isRadio?: boolean;
  isCompact?: boolean;
  isOnlyHvacType?: boolean;
  variant?: 'outlined' | 'contained' | 'indeterminate';
};

// @ts-ignore
export const ListItem = withStyles(styles)(
  ({
    classes,
    key,
    label,
    checked,
    onClick,
    isRadio,
    isCompact,
    isOnlyHvacType,
    variant = 'outlined',
  }: ListItemProps) => (
    <li
      className={classNames(
        isOnlyHvacType ? classes.hvacChildListItem : classes.childListItem,
        isCompact ? classes.compactChildListItem : ''
      )}
      key={key}
    >
      {isRadio ? (
        <div className={classes.radioButton} onClick={onClick}>
          {checked ? (
            <MdRadioButtonChecked className={classes.radioIcon} />
          ) : (
            <MdRadioButtonUnchecked className={classes.radioIcon} />
          )}
          <span className={checked ? classes.active : classes.inactive}>
            {label}
          </span>
        </div>
      ) : (
        <Checkbox
          size={16}
          checked={checked}
          label={label}
          onChange={onClick}
          variant={variant}
        />
      )}
    </li>
  )
);

// @ts-ignore
export default withStyles(styles)(EquipmentMenuItem);
