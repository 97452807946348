import { equipmentStatus } from '@energybox/react-ui-library/dist/types';
import { Actions as EquipmentFilterActions } from '../actions/equipmentFilter';

export enum EquipmentFilters {
  STATUS = 'STATUS',
  TYPE = 'TYPE',
  SPACE = 'SPACE',
  RESET = 'RESET',
}

export type EquipmentFilter = {
  [EquipmentFilters.STATUS]: string[];
  [EquipmentFilters.TYPE]: number[];
  [EquipmentFilters.SPACE]: string[];
  [EquipmentFilters.RESET]: number[];
};

const initialState: EquipmentFilter = {
  [EquipmentFilters.STATUS]: [equipmentStatus.on],
  [EquipmentFilters.TYPE]: [],
  [EquipmentFilters.SPACE]: [],
  [EquipmentFilters.RESET]: [],
};

function updateByValue(s: string[], needle: string): string[] {
  return s.includes(needle) ? s.filter((f) => f !== needle) : [...s, needle];
}

const equipmentFilters = (
  state: EquipmentFilter = initialState,
  action: any
) => {
  switch (action.type) {
    case EquipmentFilterActions.STATUS: {
      return {
        ...state,
        [EquipmentFilters.STATUS]: updateByValue(
          state[EquipmentFilters.STATUS],
          action.payload
        ),
      };
    }

    case EquipmentFilterActions.SPACE: {
      return {
        ...state,
        [EquipmentFilters.SPACE]: updateByValue(
          state[EquipmentFilters.SPACE],
          action.payload
        ),
      };
    }

    default: {
      return state;
    }
  }
};

export default equipmentFilters;
