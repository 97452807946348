import { IsoDateFormat } from '@energybox/react-ui-library/dist/types';
import { addDays, format, subDays } from 'date-fns';
import { fetchApi } from '../utils/fetchApi';
import { processAndRemoveBuggyTimetablePreviews } from '../reducers/timetables';

const apiBase = '/api/v1/timetables';

export enum Actions {
  GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_SUCCESS = '@time_table/GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_SUCCESS',
  GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_ERROR = '@time_table/GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_ERROR',
  GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_LOADING = '@time_table/GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_LOADING',

  GET_TODAY_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_SUCCESS = '@time_table/GET_TODAY_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_SUCCESS',
  GET_TODAY_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_ERROR = '@time_table/GET_TODAY_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_ERROR',
  GET_TODAY_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_LOADING = '@time_table/GET_TODAY_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_LOADING',

  GET_TIME_TABLE_PREVIEWS_BY_SITE_ID_SUCCESS = '@time_table/GET_TIME_TABLE_PREVIEWS_BY_SITE_ID_SUCCESS',
  GET_TIME_TABLE_PREVIEWS_BY_SITE_ID_ERROR = '@time_table/GET_TIME_TABLE_PREVIEWS_BY_SITE_ID_ERROR',
  GET_TIME_TABLE_PREVIEWS_BY_SITE_ID_LOADING = '@time_table/GET_TIME_TABLE_PREVIEWS_BY_SITE_ID_LOADING',

  GET_TIME_TABLE_BY_ID_SUCCESS = '@time_table/GET_TIME_TABLE_BY_ID_SUCCESS',
  GET_TIME_TABLE_BY_ID_ERROR = '@time_table/GET_TIME_TABLE_BY_ID_ERROR',
  GET_TIME_TABLE_BY_ID_LOADING = '@time_table/GET_TIME_TABLE_BY_ID_LOADING',
}

export const fetchControlPreviewsBySiteId = (
  siteId: number | string,
  timezone: string,
  date: string
) =>
  fetchApi({
    endpoint: `${apiBase}/previews/site/${siteId}?startDate=${date}&endDate=${date}`,
  }).then((d) => {
    return (
      d &&
      d.timetablePreview &&
      processAndRemoveBuggyTimetablePreviews(d.timetablePreview, timezone)
    );
  });

export const getTodayTimeTablePreviewsByEquipmentId = (
  equipmentId: string | number,
  timezone: string
) => {
  const formattedTodayDate = format(new Date(), IsoDateFormat);

  return {
    type: 'API_GET',
    path: `${apiBase}/previews/equipment/${equipmentId}?startDate=${formattedTodayDate}&endDate=${formattedTodayDate}`,
    success: {
      type: Actions.GET_TODAY_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_SUCCESS,
      equipmentId,
      timezone,
    },
    error: {
      type: Actions.GET_TODAY_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_ERROR,
      equipmentId,
    },
    loading: {
      type: Actions.GET_TODAY_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_LOADING,
      equipmentId,
    },
  };
};

export const getTimeTablePreviewsByEquipmentId = (
  equipmentId: string | number,
  timezone: string,
  fromDate: Date,
  toDate?: Date
) => {
  const formattedFromDate = format(fromDate, IsoDateFormat);
  const formattedToDate = toDate
    ? format(toDate, IsoDateFormat)
    : formattedFromDate;

  return {
    type: 'API_GET',
    path: `${apiBase}/previews/equipment/${equipmentId}?startDate=${formattedFromDate}&endDate=${formattedToDate}`,
    success: {
      type: Actions.GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_SUCCESS,
      equipmentId,
      timezone,
    },
    error: {
      type: Actions.GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_ERROR,
      equipmentId,
    },
    loading: {
      type: Actions.GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_LOADING,
      equipmentId,
    },
  };
};

export const getTimeTablePreviewsBySiteId = (
  siteId: string | number,
  timezone: string,
  fromDate: Date,
  toDate?: Date
) => {
  const formattedFromDate = format(fromDate, IsoDateFormat);
  const formattedToDate = toDate
    ? format(toDate, IsoDateFormat)
    : formattedFromDate;
  return {
    type: 'API_GET',
    path: `${apiBase}/previews/site/${siteId}?startDate=${formattedFromDate}&endDate=${formattedToDate}`,
    success: {
      type: Actions.GET_TIME_TABLE_PREVIEWS_BY_SITE_ID_SUCCESS,
      siteId,
      timezone,
    },
    error: {
      type: Actions.GET_TIME_TABLE_PREVIEWS_BY_SITE_ID_ERROR,
      siteId,
    },
    loading: {
      type: Actions.GET_TIME_TABLE_PREVIEWS_BY_SITE_ID_LOADING,
      siteId,
    },
  };
};

export const getTimeTableById = (id: number | string) => {
  return {
    type: 'API_GET',
    path: `${apiBase}/${id}`,
    success: {
      type: Actions.GET_TIME_TABLE_BY_ID_SUCCESS,
      id,
    },
    error: {
      type: Actions.GET_TIME_TABLE_BY_ID_ERROR,
      id,
    },
    loading: {
      type: Actions.GET_TIME_TABLE_BY_ID_LOADING,
      id,
    },
  };
};

export const getTimeTablePreviewsByEquipmentIdTimezoneHack = (
  equipmentId: string | number,
  timezone: string,
  fromDate: Date,
  toDate?: Date
) => {
  const formattedFromDate = format(subDays(fromDate, 1), IsoDateFormat);
  const formattedToDate = toDate
    ? format(addDays(toDate, 1), IsoDateFormat)
    : formattedFromDate;

  return {
    type: 'API_GET',
    path: `${apiBase}/previews/equipment/${equipmentId}?startDate=${formattedFromDate}&endDate=${formattedToDate}`,
    success: {
      type: Actions.GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_SUCCESS,
      equipmentId,
      timezone,
    },
    error: {
      type: Actions.GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_ERROR,
      equipmentId,
    },
    loading: {
      type: Actions.GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_LOADING,
      equipmentId,
    },
  };
};
