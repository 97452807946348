import {
  IsoDateFormat,
  NotificationApiFilter,
} from '@energybox/react-ui-library/dist/types';
import { asIsoWithTz } from '@energybox/react-ui-library/dist/utils';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import subDays from 'date-fns/subDays';
import { Service } from '../config';

export enum Actions {
  FILTERED_NOTIFICATIONS_SUCCESS = '@@notifications/FILTERED_NOTIFICATIONS_SUCCESS',
  FILTERED_NOTIFICATIONS_LOADING = '@@notifications/FILTERED_NOTIFICATIONS_LOADING',
  FILTERED_NOTIFICATIONS_ERROR = '@@notifications/FILTERED_NOTIFICATIONS_ERROR',

  DISMISS_NOTIFICATION_SUCCESS = '@@notifications/DISMISS_NOTIFICATION_SUCCESS',
  DISMISS_NOTIFICATION_LOADING = '@@notifications/DISMISS_NOTIFICATION_LOADING',
  DISMISS_NOTIFICATION_ERROR = '@@notifications/DISMISS_NOTIFICATION_ERROR',

  GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_ERROR = '@@notifications/GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_ERROR',
  GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_LOADING = '@@notifications/GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_LOADING',
  GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_SUCCESS = '@@notifications/GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_SUCCESS',

  GET_NOTIFICATIONS_BY_SITE_ID_ERROR = '@@notifications/GET_NOTIFICATIONS_BY_SITE_ID_ERROR',
  GET_NOTIFICATIONS_BY_SITE_ID_LOADING = '@@notifications/GET_NOTIFICATIONS_BY_SITE_ID_LOADING',
  GET_NOTIFICATIONS_BY_SITE_ID_SUCCESS = '@@notifications/GET_NOTIFICATIONS_BY_SITE_ID_SUCCESS',

  GET_NOTIFICATIONS_BY_EQUIPMENT_ID_ERROR = '@@notifications/GET_NOTIFICATIONS_BY_EQUIPMENT_ID_ERROR',
  GET_NOTIFICATIONS_BY_EQUIPMENT_ID_LOADING = '@@notifications/GET_NOTIFICATIONS_BY_EQUIPMENT_ID_LOADING',
  GET_NOTIFICATIONS_BY_EQUIPMENT_ID_SUCCESS = '@@notifications/GET_NOTIFICATIONS_BY_EQUIPMENT_ID_SUCCESS',

  GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_ERROR = '@@notifications/GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_ERROR',
  GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_LOADING = '@@notifications/GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_LOADING',
  GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_SUCCESS = '@@notifications/GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_SUCCESS',

  GET_SITE_NOTIFICATIONS_COUNT_BY_SITE_ID_ERROR = '@@notifications/GET_SITE_NOTIFICATIONS_COUNT_BY_SITE_ID_ERROR',
  GET_SITE_NOTIFICATIONS_COUNT_BY_SITE_ID_LOADING = '@@notifications/GET_SITE_NOTIFICATIONS_COUNT_BY_SITE_ID_LOADING',
  GET_SITE_NOTIFICATIONS_COUNT_BY_SITE_ID_SUCCESS = '@@notifications/GET_SITE_NOTIFICATIONS_COUNT_BY_SITE_ID_SUCCESS',

  GET_EQUIPMENT_NOTIFICATIONS_COUNT_BY_SITE_ID_ERROR = '@@notifications/GET_EQUIPMENT_NOTIFICATIONS_COUNT_BY_SITE_ID_ERROR',
  GET_EQUIPMENT_NOTIFICATIONS_COUNT_BY_SITE_ID_LOADING = '@@notifications/GET_EQUIPMENT_NOTIFICATIONS_COUNT_BY_SITE_ID_LOADING',
  GET_EQUIPMENT_NOTIFICATIONS_COUNT_BY_SITE_ID_SUCCESS = '@@notifications/GET_EQUIPMENT_NOTIFICATIONS_COUNT_BY_SITE_ID_SUCCESS',
}

const DEFAULT_LIMIT = 2500;
const DEFAULT_OFFSET = 0;

export const setNotificationFilter = (
  filter?: NotificationApiFilter,
  withoutTime?: boolean
): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.equipmentIds) {
    queryParams.set('equipmentIds', filter.equipmentIds.join(','));
  }

  if (filter && filter.equipmentId) {
    queryParams.set('equipmentId', filter.equipmentId.toString());
  }

  if (filter && filter.sensorIds) {
    queryParams.set('sensorIds', filter.sensorIds.join(','));
  }

  if (filter && filter.siteIds) {
    if (typeof filter.siteIds === 'string')
      queryParams.set('siteIds', filter.siteIds);
    else {
      queryParams.set('siteIds', filter.siteIds.join(','));
    }
  }

  if (filter && filter.siteId) {
    queryParams.set('siteId', filter.siteId.toString());
  }

  if (filter && filter.spaceIds) {
    queryParams.set('spaceIds', filter.spaceIds.join(','));
  }

  if (filter && filter.state) {
    queryParams.set('state', filter.state.toString());
  }

  if (filter && filter.fromDate) {
    //currently notification list endpoint CANNOT take time...
    // so we add a day on either end so that our request includes notifications that
    // fall on another date from the notification API's POV because it is TZ insensitive
    if (withoutTime) {
      const dateToQuery = subDays(filter.fromDate, 1);
      queryParams.set('fromDate', format(dateToQuery, IsoDateFormat));
    } else {
      //however, notification counts endpoint CAN take time in ISO,
      //not in UTC, but with timezone offset...
      queryParams.set('fromDate', asIsoWithTz(filter.fromDate));
    }
  }

  if (filter && filter.toDate) {
    if (withoutTime) {
      //currently notification list endpoint CANNOT take time...
      // so we add a day on either end so that our request includes notifications that
      // fall on another date from the notification API's POV because it is TZ insensitive
      const dateToQuery = addDays(filter.toDate, 1);
      queryParams.set('toDate', format(dateToQuery, IsoDateFormat));
    } else {
      //however, notification counts endpoint CAN take time in ISO,
      //not in UTC, but with timezone offset...
      queryParams.set('toDate', asIsoWithTz(filter.toDate));
    }
  }

  if (filter && filter.from) {
    //flatNotifications endpoint only takes ISO in UTC
    queryParams.set('from', filter.from.toISOString());
  }

  if (filter && filter.to) {
    //flatNotifications endpoint only takes ISO in UTC
    queryParams.set('to', filter.to.toISOString());
  }

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && (filter.offset || filter.offset === 0)) {
    queryParams.set('offset', filter.offset.toString());
  }

  if (filter && filter.sentinelTypes) {
    queryParams.set('sentinelTypes', filter.sentinelTypes.join(','));
  }

  return queryParams.toString();
};

///*** Actions ***///
export const getFilteredNotifications = (
  queryParams: NotificationApiFilter,
  isEnriched?: boolean
) => {
  const updatedQueryParams = queryParamsWithDefaultLimitAndOffset(queryParams);

  const path = `/api/v1/notifications${
    isEnriched ? '/enriched' : ''
  }?${setNotificationFilter(updatedQueryParams, true)}`;

  return {
    type: 'API_GET',
    service: Service.sentinel,
    path,
    limit: updatedQueryParams.limit,
    offset: updatedQueryParams.offset,
    loading: Actions.FILTERED_NOTIFICATIONS_LOADING,
    success: Actions.FILTERED_NOTIFICATIONS_SUCCESS,
    error: Actions.FILTERED_NOTIFICATIONS_ERROR,
  };
};

export const getNotificationsByEquipmentId = (
  equipmentId: number,
  queryParams: NotificationApiFilter
) => {
  const updatedQueryParams = queryParamsWithDefaultLimitAndOffset(queryParams);

  return {
    type: 'API_GET',
    path: `/api/v1/notifications?${setNotificationFilter(
      updatedQueryParams,
      true
    )}`,
    service: Service.sentinel,
    limit: updatedQueryParams.limit,
    offset: updatedQueryParams.offset,
    loading: {
      type: Actions.GET_NOTIFICATIONS_BY_EQUIPMENT_ID_LOADING,
      equipmentId,
    },
    success: {
      type: Actions.GET_NOTIFICATIONS_BY_EQUIPMENT_ID_SUCCESS,
      equipmentId,
      timePeriod: {
        fromDate: queryParams.fromDate,
        toDate: queryParams.toDate,
      },
    },
    error: {
      type: Actions.GET_NOTIFICATIONS_BY_EQUIPMENT_ID_ERROR,
      equipmentId,
    },
  };
};

export const getFlatNotificationsByEquipmentId = (
  equipmentId: number,
  queryParams: NotificationApiFilter
) => {
  const updatedQueryParams = queryParamsWithDefaultLimitAndOffset(queryParams);

  return {
    type: 'API_GET',
    path: `/api/v1/flatnotifications?${setNotificationFilter(
      updatedQueryParams
    )}`,
    service: Service.sentinel,
    limit: updatedQueryParams.limit,
    offset: updatedQueryParams.offset,
    loading: {
      type: Actions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_LOADING,
      equipmentId,
    },
    success: {
      type: Actions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_SUCCESS,
      equipmentId,
      timePeriod: { from: queryParams.from, to: queryParams.to },
    },
    error: {
      type: Actions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_ERROR,
      equipmentId,
    },
  };
};

export const getFlatNotificationsBySensorId = (
  sensorId: string | number,
  queryParams: NotificationApiFilter,
  notificationId?: string | number
) => {
  const updatedQueryParams = queryParamsWithDefaultLimitAndOffset(queryParams);

  return {
    type: 'API_GET',
    path: `/api/v1/flatnotifications?${setNotificationFilter(
      updatedQueryParams
    )}`,
    service: Service.sentinel,
    limit: updatedQueryParams.limit,
    offset: updatedQueryParams.offset,
    loading: {
      type: Actions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_LOADING,
      sensorId,
      notificationId,
    },
    success: {
      type: Actions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_SUCCESS,
      sensorId,
      notificationId,
      timePeriod: { from: queryParams.from, to: queryParams.to },
    },
    error: {
      type: Actions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_ERROR,
      sensorId,
      notificationId,
    },
  };
};

export const getNotificationsBySiteId = (
  siteId: string | number,
  queryParams: NotificationApiFilter
) => {
  queryParams.siteIds = String(siteId);
  const updatedQueryParams = queryParamsWithDefaultLimitAndOffset(queryParams);

  return {
    type: 'API_GET',
    path: `/api/v1/notifications?${setNotificationFilter(
      updatedQueryParams,
      true
    )}`,
    service: Service.sentinel,
    limit: updatedQueryParams.limit,
    offset: updatedQueryParams.offset,
    loading: {
      type: Actions.GET_NOTIFICATIONS_BY_SITE_ID_LOADING,
      siteId,
    },
    success: {
      type: Actions.GET_NOTIFICATIONS_BY_SITE_ID_SUCCESS,
      siteId,
      timePeriod: {
        fromDate: queryParams.fromDate,
        toDate: queryParams.toDate,
      },
    },
    error: {
      type: Actions.GET_NOTIFICATIONS_BY_SITE_ID_ERROR,
      siteId,
    },
  };
};

export const dismissNotification = (
  id: string,
  comment: string,
  siteId?: string
) => ({
  type: 'API_PUT',
  path: `/api/v1/notifications/${id}/dismiss`,
  payload: {
    dismissedComment: comment,
  },
  service: Service.sentinel,
  loading: { type: Actions.DISMISS_NOTIFICATION_LOADING, siteId },
  success: { type: Actions.DISMISS_NOTIFICATION_SUCCESS, siteId },
  error: { type: Actions.DISMISS_NOTIFICATION_ERROR, siteId },
});

export const getSiteNotificationsCountBySiteId = (
  siteId: string,
  queryParams: NotificationApiFilter
) => ({
  type: 'API_GET',
  path: `/api/v1/count/notifications/by-site-and-equipment?${setNotificationFilter(
    queryParams
  )}`,
  service: Service.sentinel,
  loading: {
    type: Actions.GET_SITE_NOTIFICATIONS_COUNT_BY_SITE_ID_LOADING,
    siteId,
  },
  success: {
    type: Actions.GET_SITE_NOTIFICATIONS_COUNT_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.GET_SITE_NOTIFICATIONS_COUNT_BY_SITE_ID_ERROR,
    siteId,
  },
});

export const getEquipmentNotificationsCountBySiteId = (
  siteId: string,
  equipmentId: string | number,
  queryParams: NotificationApiFilter
) => ({
  type: 'API_GET',
  path: `/api/v1/count/notifications/by-site-and-equipment?${setNotificationFilter(
    queryParams
  )}`,
  service: Service.sentinel,
  loading: {
    type: Actions.GET_EQUIPMENT_NOTIFICATIONS_COUNT_BY_SITE_ID_LOADING,
    siteId,
    equipmentId,
  },
  success: {
    type: Actions.GET_EQUIPMENT_NOTIFICATIONS_COUNT_BY_SITE_ID_SUCCESS,
    siteId,
    equipmentId,
  },
  error: {
    type: Actions.GET_EQUIPMENT_NOTIFICATIONS_COUNT_BY_SITE_ID_ERROR,
    siteId,
    equipmentId,
  },
});
///*** End Actions ***///

///*** Miscellaneous ***///
export const queryParamsWithDefaultLimitAndOffset = (
  queryParams: NotificationApiFilter
) => ({
  ...queryParams,
  limit: queryParams.limit || DEFAULT_LIMIT,
  offset: queryParams.offset || DEFAULT_OFFSET,
});
