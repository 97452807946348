import React from 'react';

export const hatchedLinePatternDef = (
  patternId: string,
  hatchColor: string,
  backgroundColor: string
) => (
  <defs>
    <pattern id={patternId} patternUnits="userSpaceOnUse" width="4" height="4">
      {/* <!-- background --> */}
      <path
        id="background"
        d="M-1,3 L3,-1
               M1,5 L5,1"
        style={{ stroke: backgroundColor, strokeWidth: 10, opacity: 0.25 }}
      />
      {/* <!-- hatches --> */}
      <path
        id="hatches"
        d="M-2,2 L2,-2
               M0,4 L4,0
               M2,6 L6,2"
        style={{ stroke: hatchColor, strokeWidth: 1, opacity: 0.35 }}
      />
    </pattern>
  </defs>
);
