import ReactGA from 'react-ga';
import { Actions as AppActions } from '../actions/constants/Actions';

if (process.env.NODE_ENV !== 'test') {
  ReactGA.initialize([
    {
      trackingId: process.env.REACT_APP_GA_TRACKING_ID || '',
    },
  ]);
}

const trackPage = (page) => {
  ReactGA.set({
    page,
  });
  ReactGA.pageview(page);
};

let currentPage = '';

const googleAnalytics = (store) => (next) => (action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;
    if (currentPage !== nextPage) {
      currentPage = nextPage;
      trackPage(nextPage);
    }
  }

  if (action.type === AppActions.CURRENT_USER_SUCCESS) {
    if (action.payload) {
      ReactGA.set({
        userId: action.payload.id,
      });
      ReactGA.set({
        dimension1:
          String(action.payload.organizationId) ===
          String(process.env.REACT_APP_ENERGYBOX_ORG)
            ? 'INTERNAL'
            : 'EXTERNAL',
      });
    }
  }

  return next(action);
};

export default googleAnalytics;
