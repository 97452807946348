import { Actions } from '../actions/license';

export type License = {
  content: {
    text: string;
    lastUpdate: string;
    isLoading: boolean;
  };
};

const initialState = {
  content: {
    text: '',
    lastUpdate: '',
    isLoading: false,
  },
};

const license = (state: License = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_OPEN_SOURCE_LICENSE_LOADING:
      return {
        ...state,
        content: {
          text: '',
          lastUpdate: '',
          isLoading: action.isLoading,
        },
        isLoading: action.isLoading,
      };

    case Actions.GET_OPEN_SOURCE_LICENSE_ERROR:
      return {
        ...state,
        content: {
          ...action.data,
          isLoading: action.isLoading,
        },
        isLoading: action.isLoading,
      };

    case Actions.GET_OPEN_SOURCE_LICENSE_SUCCESS:
      return {
        ...state,
        content: {
          ...action.data,
          isLoading: action.isLoading,
        },
        isLoading: action.isLoading,
      };

    default:
      return state;
  }
};

export default license;
