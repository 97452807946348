import { SubscribedThermostats } from '@energybox/react-ui-library/dist/types';
import {
  isDefined,
  normalizeSubscribedThermostat,
} from '@energybox/react-ui-library/dist/utils';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions as StreamActions } from '../actions/streamApi';
import {
  SubscribedRFThermostat,
  SubscribedThermostat,
} from '@energybox/react-ui-library/dist/types/SubscribedThermostat';
import { normalizeSubscribedRFThermostatStatus } from '@energybox/react-ui-library/dist/utils/thermostat';

const initialState = {
  byId: {},
  byRFId: {},
};

const subscribedThermostats = (
  state: SubscribedThermostats = initialState,
  action: any
) => {
  switch (action.type) {
    case StreamActions.RECEIVED_DEVICE_READING: {
      if (
        action.payload.vendor !== 'venstar' &&
        action.payload.vendor !== 'energybox'
      ) {
        return state;
      }

      //RFThermostatStatus is an energybox thermostat with no CAM attached
      if (action.payload?.messageType === 'RFThermostatStatus') {
        let normalizedData: SubscribedRFThermostat;
        normalizedData = normalizeSubscribedRFThermostatStatus(action.payload);
        return pipe(assocPath(['byRFId', action.payload.id], normalizedData))(
          state
        );
      } else {
        //RFThermostatCamStatus is an energybox thermostat with CAM attached
        let normalizedData: SubscribedThermostat;
        normalizedData = normalizeSubscribedThermostat(action.payload);
        const readingType = normalizedData?.readingType;
        //reading type is available for venstar thermostat
        if (!isDefined(readingType)) {
          return state;
        } else {
          return pipe(assocPath(['byId', action.payload.id], normalizedData))(
            state
          );
        }
      }
    }

    default:
      return state;
  }
};

export default subscribedThermostats;
