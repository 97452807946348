import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import useCurrentUser from './useCurrentUser';
import { TemperatureUnit } from '@energybox/react-ui-library/dist/types';
import { getUserPreferenceTemperatureUnit } from '@energybox/react-ui-library/dist/utils/temperature';
import { AreaUnitLabels } from '@energybox/react-ui-library/dist/types/Global';
import { getUserPreferenceAreaUnit } from '@energybox/react-ui-library/dist/utils/util';

export const useApiFetch = <T, F extends (...args: any) => Promise<any>>(
  fetchFunction: F,
  condition: boolean = true
): {
  isLoading: boolean;
  data?: T;
} => {
  const [isLoading, setLoading] = useState<boolean | undefined>(undefined);
  const [fetchStack, updateFetchStack] = useState<F[]>([fetchFunction]);
  const [data, setData] = useState<T | undefined>(undefined);

  useEffect(() => {
    // discard all fetches in the stack
    // only the latest fetch callback is useful.
    const updatedFetchStack = [fetchFunction];
    updateFetchStack(updatedFetchStack);
  }, [fetchFunction]);

  useEffect(() => {
    // prevent race condition
    // call from a fetch stack when previous requests are done
    if (!condition) {
      setLoading(false);
      return;
    }
    if (fetchStack.length === 0) return;
    if (isLoading) return;

    const updatedFetchStack = [...fetchStack];
    const fetchCall = updatedFetchStack.pop();
    if (fetchCall === undefined) return;
    setLoading(true);
    updateFetchStack(updatedFetchStack);
    const fetchData = async () => {
      const response = await fetchCall().catch((err) => console.log(err));
      setData(response);
      setLoading(false);
    };
    fetchData();
  }, [isLoading, fetchFunction, fetchStack, condition]);

  return {
    data,
    isLoading: isLoading !== undefined ? isLoading : true,
  };
};

export const useApiDispatch = <T extends (...args: any) => any>(
  isLoading: boolean | undefined,
  dispatchAction?: T
) => {
  const dispatch = useDispatch();
  // prevent race condition
  // if isLoading is undefined, a request has not been sent
  if (isLoading === undefined && dispatchAction) {
    dispatch(dispatchAction());
  }
};

export const useApiDispatchWithDependencies = <T extends (...args: any) => any>(
  isLoading: boolean | undefined,
  memoizedDispatch: T | null
) => {
  const dispatch = useDispatch();
  const [shouldRefetchData, setRefetchStatus] = useState(true);

  useEffect(() => {
    setRefetchStatus(true);
  }, [memoizedDispatch]);

  // prevent race condition
  if (!isLoading && shouldRefetchData) {
    setRefetchStatus(false);
    if (memoizedDispatch === null) {
      return;
    }
    dispatch(memoizedDispatch());
  }
};

// Prevent component no data state flashes before making API calls on first render.
// When sending a request, isLoading state must be false to prevent race condition
// To determine whether a component should display loading state, use this hook.
export const useComponentLoadingStatus = (isLoading: boolean) => {
  const [isComponentLoading, setComponentLoadingState] = useState(true);
  useEffect(() => {
    setComponentLoadingState(isLoading);
  }, [isLoading]);
  return isComponentLoading;
};

export const useTemperatureUnit = () => {
  const currentUser = useCurrentUser();

  if (!currentUser) return TemperatureUnit.C;
  return getUserPreferenceTemperatureUnit(currentUser);
};

export const useAreaUnit = () => {
  const currentUser = useCurrentUser();

  if (!currentUser) return AreaUnitLabels.M2;
  return getUserPreferenceAreaUnit(currentUser);
};
