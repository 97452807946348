import {
  IncidentPriority,
  IncidentStatus,
  SentinelType,
} from '@energybox/react-ui-library/dist/types';
import assoc from 'ramda/src/assoc';
import { Actions } from '../actions/filters';

export type Filters = {
  incidentPriorities: IncidentPriority[];
  incidentStatuses: IncidentStatus[];
  sites: number[];
  equipmentTypeIds: number[];
  sentinelTypes: SentinelType[];
};

const initialState = {
  incidentPriorities: [],
  incidentStatuses: [],
  sites: [],
  equipmentTypeIds: [],
  sentinelTypes: [],
};

const filters = (state: Filters = initialState, action: any) => {
  switch (action.type) {
    case Actions.INCIDENT_PRIORITY: {
      return assoc('incidentPriorities', action.payload, state);
    }

    case Actions.INCIDENT_STATUS: {
      return assoc('incidentStatuses', action.payload, state);
    }

    case Actions.SITE: {
      return assoc('sites', action.payload, state);
    }

    case Actions.EQUIPMENT: {
      return assoc('equipmentTypeIds', action.payload, state);
    }

    case Actions.SENTINEL_TYPE: {
      return assoc('sentinelTypes', action.payload, state);
    }

    default:
      return state;
  }
};

export default filters;
