import {
  ComponentTypes,
  DoorOpenDurationSop,
  EnergyTariff,
  HumiditySop,
  ResourceType,
  Sop,
  SopTypes,
  SopComponent,
  TemperatureSop,
} from '@energybox/react-ui-library/dist/types';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/sops';
import * as R from 'ramda';
import {
  mapArrayToObject,
  mapValues,
} from '@energybox/react-ui-library/dist/utils';

export type SopsById = {
  [id: string]: Sop | Sop[];
};

export type EnergyTariffByResourceId = {
  [id: string]: {
    sop?: EnergyTariff | null;
    isLoading?: boolean;
  };
};

export type TemperatureRangeByResourceId = {
  [id: string]: {
    sop?: TemperatureSop | null;
    isLoading?: boolean;
  };
};

export type HumidityRangeByResourceId = {
  [id: string]: {
    sop?: HumiditySop | null;
    isLoading?: boolean;
  };
};

export type DoorDurationByResourceId = {
  [id: string]: {
    sop?: DoorOpenDurationSop | null;
    isLoading?: boolean;
  };
};

export type HvacSopType = {
  [id: string]: {
    sop: Sop;
  };
};

export type SopComponentsByResourceId = {
  [id: string]: SopComponent[];
};

// Complex type that's a little verbose
export type SopComponentTypesByResourceId = {
  [SopTypes.ENERGY_TARIFF]: EnergyTariffByResourceId;
  [SopTypes.TEMPERATURE_RANGE]: TemperatureRangeByResourceId;
  [SopTypes.HUMIDITY_RANGE]: HumidityRangeByResourceId;
  [SopTypes.DOOR_OPENED_MAX_DURATION]: DoorDurationByResourceId;
};

const initialSopComponentTypesByResourceId = {
  [SopTypes.ENERGY_TARIFF]: {},
  [SopTypes.TEMPERATURE_RANGE]: {},
  [SopTypes.HUMIDITY_RANGE]: {},
  [SopTypes.DOOR_OPENED_MAX_DURATION]: {},
};

export type HvacSopByResourceId = {
  [SopTypes.HVAC]: HvacSopType;
};

const initialHvacSopByResourceId = {
  [SopTypes.HVAC]: {},
};

export type Sops = {
  sopComponentTypesByResourceId: SopComponentTypesByResourceId;
  organizationUnitIdToSopIds: OrganizationUnitIdToSopIds;
  sopsById: SopsById;
  havcSopByResourceId: HvacSopByResourceId;
  sopComponentsByResourceId: SopComponentsByResourceId;
};

export type OrganizationUnitIdToSopIds = {
  [id: string]: number[];
};

/**
 * hvac sop components need to always be sorted so that
 * unoccupied schedule is the last element of array
 */
export const processHvacSop = (
  components: ComponentTypes[] | ComponentTypes
) => {
  const sortHvacSchedule = (c) => {
    const isHvacSop = c._entity === 'HvacSopComponent';
    if (isHvacSop) {
      return {
        ...c,
        hvacSettings: c.hvacSettings
          ? {
              ...c.hvacSettings,
              hvacSchedules: c.hvacSettings?.hvacSchedules
                ? c.hvacSettings.hvacSchedules.sort(
                    (hvacScheduleA, hvacScheduleB) => {
                      if (
                        hvacScheduleA.hvacScheduleType ===
                        hvacScheduleB.hvacScheduleType
                      ) {
                        //sorting so that UNOCCUPIED schedule is always sorted last
                        //for multiple OCCUPIED schedules, the hvacSchedule.default === true needs to always be the first one
                        return hvacScheduleA.default === true ? -1 : 1;
                      }

                      return hvacScheduleA.hvacScheduleType.localeCompare(
                        hvacScheduleB.hvacScheduleType
                      );
                    }
                  )
                : undefined,
            }
          : undefined,
      };
    }
    return { ...c };
  };

  if (Array.isArray(components)) {
    return components.map(sortHvacSchedule);
  }

  return sortHvacSchedule(components);
};

const sopsFromApiResponse = (data: any) => ({
  id: data.id,
  title: data.title,
  components: data.components || [],
  description: data.description,
  equipmentTypeIds: data.equipmentTypeIds || [],
  organizationUnitId: data.organizationUnitId,
  resourceIds: data.resourceIds || [],
  timetableId: data.timetableId || null,
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
});

export const initialState = {
  sopComponentTypesByResourceId: initialSopComponentTypesByResourceId,
  organizationUnitIdToSopIds: {},
  sopsById: {},
  havcSopByResourceId: initialHvacSopByResourceId,
  sopComponentsByResourceId: {},
};

const sops = (state: Sops = initialState, action: any) => {
  switch (action.type) {
    case Actions.RESOLVE_SOP_BY_RESOURCE_ID_SUCCESS: {
      if (!action.payload) {
        return pipe(
          assocPath(
            ['sopComponentTypesByResourceId', action.sopType, action.id, 'sop'],
            null
          ),
          assocPath(
            [
              'sopComponentTypesByResourceId',
              action.sopType,
              action.id,
              'isLoading',
            ],
            false
          )
        )(state);
      }

      const sop = sopsFromApiResponse(action.payload);
      const sopComponent =
        sop.components.find((c) => c.type === action.sopType) || null;

      return pipe(
        assocPath(
          ['sopComponentTypesByResourceId', action.sopType, action.id, 'sop'],
          sopComponent
        ),
        assocPath(
          ['havcSopByResourceId', action.sopType, action.id, 'sop'],
          sop
        ),
        assocPath(
          [
            'sopComponentTypesByResourceId',
            action.sopType,
            action.id,
            'isLoading',
          ],
          false
        )
      )(state);
    }
    case Actions.RESOLVE_SOP_BY_RESOURCE_ID_LOADING: {
      return assocPath(
        [
          'sopComponentTypesByResourceId',
          action.sopType,
          action.id,
          'isLoading',
        ],
        true,
        state
      );
    }

    case Actions.RESOLVE_SOP_BY_RESOURCE_ID_ERROR: {
      return pipe(
        assocPath(
          ['sopComponentTypesByResourceId', action.sopType, action.id, 'sop'],
          null
        ),
        assocPath(
          [
            'sopComponentTypesByResourceId',
            action.sopType,
            action.id,
            'isLoading',
          ],
          false
        )
      )(state);
    }

    case Actions.GET_SOPS_BY_ORG_UNIT_ID_LOADING: {
      return R.pipe(R.assoc('isLoading', true))(state);
    }

    case Actions.GET_SOPS_BY_ORG_UNIT_ID_ERROR: {
      return R.pipe(R.assoc('isLoading', false))(state);
    }

    case Actions.GET_SOPS_BY_ORG_UNIT_ID_SUCCESS: {
      return R.pipe(
        R.assoc('isLoading', false),
        R.assocPath(['sopsById', action.id], action.payload),
        R.assocPath(
          ['organizationUnitIdToSopIds', action.id],
          action.payload.map(({ id }) => id)
        )
      )(state);
    }

    case Actions.GET_SOP_COMPONENTS_BY_RESOURCE_ID_LOADING: {
      return R.pipe(R.assoc('isLoading', true))(state);
    }

    case Actions.GET_SOP_COMPONENTS_BY_RESOURCE_ID_ERROR: {
      return R.pipe(R.assoc('isLoading', false))(state);
    }

    case Actions.GET_SOP_COMPONENTS_BY_RESOURCE_ID_SUCCESS: {
      if (action.payload) {
        return R.pipe(
          R.assocPath(
            ['sopComponentsByResourceId', action.id],
            action.payload.map((rawData: any) => ({
              ...rawData,
              component: processHvacSop(rawData.component),
            }))
          )
        )(state);
      }
      return state;
    }

    default:
      return state;
  }
};

export default sops;
