type Queue<T> = {
  data: T[];
};

export function Queue<U>(this: Queue<U>) {
  this.data = [];
}

Queue.prototype.enqueue = function(record) {
  this.data.unshift(record);
};

Queue.prototype.remove = function() {
  this.data.pop();
};

Queue.prototype.peekEndOfQueue = function() {
  return this.data[0];
};

Queue.prototype.peek = function() {
  return this.data[this.data.length - 1];
};

Queue.prototype.size = function() {
  return this.data.length;
};

Queue.prototype.isEmpty = function() {
  return this.data.length === 0;
};
