import { SiteOnlineStats } from '@energybox/react-ui-library/dist/types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSitesOnlineStats } from '../actions/sites';
import { ApplicationState } from '../reducers';

export const useSiteOnlineStats = () => {
  const dispatch = useDispatch();
  const siteOnlineStats = useSelector<
    ApplicationState,
    SiteOnlineStats | undefined
  >(({ siteOnlineStatusById }) => siteOnlineStatusById.stats);

  const dispatchGetSitesOnlineStats = useCallback(() => {
    dispatch(getSitesOnlineStats());
  }, [dispatch]);

  useEffect(() => {
    if (siteOnlineStats === undefined) {
      dispatchGetSitesOnlineStats();
    }
  }, [siteOnlineStats, dispatchGetSitesOnlineStats]);

  return siteOnlineStats;
};
