import { SopApiFilter, SopTypes } from '@energybox/react-ui-library/dist/types';
import { formatAPIFilters } from '../util';

const apiBase = '/api/v1/sop';

export enum Actions {
  RESOLVE_SOP_BY_RESOURCE_ID_SUCCESS = '@sops/RESOLVE_SOP_BY_RESOURCE_ID_SUCCESS',
  RESOLVE_SOP_BY_RESOURCE_ID_ERROR = '@sops/RESOLVE_SOP_BY_RESOURCE_ID_ERROR',
  RESOLVE_SOP_BY_RESOURCE_ID_LOADING = '@sops/RESOLVE_SOP_BY_RESOURCE_ID_LOADING',

  GET_SOPS_BY_ORG_UNIT_ID_SUCCESS = '@sops/GET_SOPS_BY_ORG_UNIT_ID_SUCCESS',
  GET_SOPS_BY_ORG_UNIT_ID_ERROR = '@sops/GET_SOPS_BY_ORG_UNIT_ID_ERROR',
  GET_SOPS_BY_ORG_UNIT_ID_LOADING = '@sops/GET_SOPS_BY_ORG_UNIT_ID_LOADING',

  GET_SOP_COMPONENTS_BY_RESOURCE_ID_SUCCESS = '@sops/GET_SOP_COMPONENTS_BY_RESOURCE_ID_SUCCESS',
  GET_SOP_COMPONENTS_BY_RESOURCE_ID_LOADING = '@sops/GET_SOP_COMPONENTS_BY_RESOURCE_ID_LOADING',
  GET_SOP_COMPONENTS_BY_RESOURCE_ID_ERROR = '@sops/GET_SOP_COMPONENTS_BY_RESOURCE_ID_ERROR',
}

export const resolveSopByResourceIdAndSopType = (
  id: string,
  sopType: SopTypes
) => ({
  type: 'API_GET',
  path: `${apiBase}/resolve/resource/${id}?type=${sopType}`,
  success: { type: Actions.RESOLVE_SOP_BY_RESOURCE_ID_SUCCESS, id, sopType },
  error: { type: Actions.RESOLVE_SOP_BY_RESOURCE_ID_ERROR, id },
  loading: { type: Actions.RESOLVE_SOP_BY_RESOURCE_ID_LOADING, id, sopType },
});

export const getSopByOrganizationalUnitId = (siteId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/organization-unit/${siteId}`,
  success: { type: Actions.GET_SOPS_BY_ORG_UNIT_ID_SUCCESS, id: siteId },
  error: { type: Actions.GET_SOPS_BY_ORG_UNIT_ID_ERROR, id: siteId },
  loading: { type: Actions.GET_SOPS_BY_ORG_UNIT_ID_LOADING, id: siteId },
});

export const getSopComponentsByResourceId = (id: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/components/resource/${id}`,
  success: { type: Actions.GET_SOP_COMPONENTS_BY_RESOURCE_ID_SUCCESS, id },
  error: { type: Actions.GET_SOP_COMPONENTS_BY_RESOURCE_ID_ERROR, id },
  loading: { type: Actions.GET_SOP_COMPONENTS_BY_RESOURCE_ID_LOADING, id },
});
