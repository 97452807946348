import {
  ResourceType,
  Sensor,
  SensorType,
  SpacesByParentId,
} from '@energybox/react-ui-library/dist/types';
import { global, classNames } from '@energybox/react-ui-library/dist/utils';
import { Tooltip } from '@energybox/react-ui-library/dist/components';
import { formatDistance, parseISO } from 'date-fns';

import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  getSensorById as getSensorByIdAction,
  getSensorsByEquipmentId,
} from '../actions/sensors';
import { ValueChip } from '../components/Tables/Cells';
import {
  useHasSensorsByResourceId,
  useSensorsByResourceId,
  useSensorStreamReadings,
  useSubscribeToSensors,
} from '../hooks/useSensors';
import { SensorReading } from '../reducers/sensors';
import {
  countNumClosedSensorReadings,
  countNumOpenSensorReadings,
  getValueFromReading,
  pickReadingsToShow,
} from '../utils/sensors';
import styles from './StreamingValueChip.module.css';

interface Props {
  resourceId: string;
  resourceType:
    | ResourceType.EQUIPMENT
    | ResourceType.SENSOR
    | ResourceType.SPACE;
  sensorType: SensorType;
  sensors?: Sensor[];
  noTooltip?: boolean;
  siteId?: string;
  inlineMultipleValues?: boolean;
  collapseBinaryValues?: boolean;
  includeSubspaces?: boolean;
  isCompact?: boolean;
  spacesByParentId?: SpacesByParentId;
}

const StreamingValueChip = ({
  resourceType,
  resourceId,
  sensorType,
  sensors: propSensors,
  noTooltip = false,
  siteId,
  inlineMultipleValues = false,
  collapseBinaryValues = false,
  includeSubspaces = false,
  isCompact = false,
  spacesByParentId,
}: Props) => {
  const dispatch = useDispatch();
  const hasSensorsForResource: boolean = useHasSensorsByResourceId(
    siteId !== undefined ? siteId : resourceId,
    resourceType === ResourceType.SPACE ? ResourceType.SITE : resourceType
  );
  const sensors =
    propSensors ||
    useSensorsByResourceId(
      resourceId,
      resourceType,
      includeSubspaces,
      spacesByParentId,
      sensorType,
      siteId
    );
  useSubscribeToSensors(sensors);
  const sensorReadings = useSensorStreamReadings(
    sensors,
    sensorType,
    collapseBinaryValues
  );

  const getSensorsForEquipment = useCallback(
    () => dispatch(getSensorsByEquipmentId(Number.parseInt(resourceId))),
    [dispatch, resourceId]
  );
  const getSensorById = useCallback(
    () => dispatch(getSensorByIdAction(Number.parseInt(resourceId))),
    [dispatch, resourceId]
  );

  useEffect(() => {
    if (
      hasSensorsForResource === false &&
      resourceType === ResourceType.EQUIPMENT
    ) {
      // We need to fetch sensors from BE so that above hooks can access data in store
      getSensorsForEquipment();
    } else if (
      hasSensorsForResource === false &&
      resourceType === ResourceType.SENSOR
    ) {
      getSensorById();
    }
  }, [
    hasSensorsForResource,
    getSensorsForEquipment,
    resourceType,
    getSensorById,
  ]);

  return (
    <SensorReadingDisplay
      sensorReadings={sensorReadings}
      resourceId={resourceId}
      resourceType={resourceType}
      sensorType={sensorType}
      noTooltip={noTooltip}
      inlineMultipleValues={inlineMultipleValues}
      isCompact={isCompact}
    />
  );
};

interface SensorReadingDisplayProps extends Props {
  sensorReadings: undefined | SensorReading[];
}

interface SensorReadingDisplayConfig {
  reading: SensorReading;
  value: number | boolean | global.NOT_AVAILABLE;
  label?: string;
  position?: 'after' | 'before';
}

const SensorReadingDisplay = ({
  sensorReadings,
  resourceType,
  resourceId,
  sensorType,
  collapseBinaryValues = false,
  noTooltip = false,
  inlineMultipleValues = false,
  isCompact = false,
}: SensorReadingDisplayProps) => {
  const createDisplayConfig = (readings: SensorReading[]) => {
    const isBinarySensor = sensorType === SensorType.BINARY;
    if (isBinarySensor) {
      const binaryDisplay = generateSensorsBinaryDisplayConfig(readings);
      return binaryDisplay;
    }
    const minMaxDisplay = generateSensorsMinMaxDisplayConfig(
      isCompact,
      readings,
      sensorType
    );
    return minMaxDisplay;
  };

  if (sensorReadings && sensorReadings.length > 0) {
    const readingsToShow =
      sensorType === SensorType.BINARY
        ? sensorReadings
        : pickReadingsToShow(sensorReadings, sensorType);
    const displayConfig: SensorReadingDisplayConfig[] =
      createDisplayConfig(readingsToShow);
    const now = new Date();

    return (
      <div
        className={
          !inlineMultipleValues ? styles.displayBlockMultipleValues : ''
        }
      >
        {displayConfig.map(({ reading, value, label, position }, index) => {
          const needSeparator = inlineMultipleValues && index > 0;
          const valueContent = (
            <ValueChip
              resourceType={resourceType}
              resourceId={resourceId}
              types={value !== global.NOT_AVAILABLE ? [sensorType] : []}
              predeterminedValue={value}
              secondaryText={label}
              inlineLabel
              secondaryTextPosition={position}
            />
          );
          const toDisplay = noTooltip ? (
            valueContent
          ) : (
            <Tooltip
              arrowDirection="bottom"
              content={
                <div>
                  {reading !== null &&
                    `${formatDistance(parseISO(reading.timestamp), now)} ago`}
                </div>
              }
            >
              {valueContent}
            </Tooltip>
          );
          return (
            <div key={`${label}${reading.sensorId}${value}`}>
              {needSeparator && (
                <span
                  className={classNames(
                    styles.sensorValueSeparator,
                    isCompact ? styles.displayBlockMultipleValues : null
                  )}
                >
                  {isCompact ? ' - ' : '/'}
                </span>
              )}
              {toDisplay}
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <ValueChip
        resourceType={resourceType}
        resourceId={resourceId}
        types={[]}
        predeterminedValue={global.NOT_AVAILABLE}
      />
    );
  }
};

const generateSensorsBinaryDisplayConfig = (readings: SensorReading[]) => {
  if (readings.length === 1) {
    const numOpen = countNumOpenSensorReadings(readings);
    const numClosed = countNumClosedSensorReadings(readings);
    return [
      {
        label: numOpen ? 'Open' : 'Closed',
        value: numOpen ? numOpen : numClosed,
        reading: readings[0],
      },
    ];
  }
  const numOpen = countNumOpenSensorReadings(readings);
  const numClosed = countNumClosedSensorReadings(readings);
  return [
    {
      label: 'Open',
      position: 'after',
      value: numOpen,
      reading: readings[0],
    },
    {
      label: 'Closed',
      position: 'after',
      value: numClosed,
      reading: readings[1],
    },
  ] as SensorReadingDisplayConfig[];
};

const generateSensorsMinMaxDisplayConfig = (
  isCompact: boolean,
  readings: SensorReading[],
  sensorType: SensorType
) => {
  if (readings.length === 1) {
    const value = getValueFromReading(readings[0], sensorType);
    return [
      {
        value: value,
        reading: readings[0],
      },
    ] as SensorReadingDisplayConfig[];
  }
  const valueMin = getValueFromReading(readings[0], sensorType);
  const valueMax = getValueFromReading(readings[1], sensorType);
  return [
    {
      label: isCompact ? '' : 'Min: ',
      position: 'before',
      value: valueMin,
      reading: readings[0],
    },
    {
      label: isCompact ? '' : 'Max: ',
      position: 'before',
      value: valueMax,
      reading: readings[1],
    },
  ] as SensorReadingDisplayConfig[];
};

export default StreamingValueChip;
