import { Button, Tooltip } from '@energybox/react-ui-library/dist/components';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import styles from './CommentModeButton.module.css';

type Props = {
  className?: string;
  onClick: () => void;
  isActive: boolean;
  disabled?: boolean;
  showTooltip?: boolean;
};

const CommentModeButton: React.FC<Props> = ({
  className,
  onClick,
  isActive,
  disabled,
  showTooltip,
}) => {
  const CommentButton = (
    <Button
      className={classNames(
        className,
        styles.button,
        isActive ? styles.active : ''
      )}
      onClick={onClick}
      roundedCorners
      size="small"
      disabled={disabled}
    >
      {isActive ? 'Comment Mode' : 'Add Comment'}
    </Button>
  );

  const TooltipContent = (
    <div className={styles.tooltipContent}>
      <div className={styles.bold}>
        Select a datapoint on the graph to add a comment
      </div>
      <div>
        Please note, you will not be able to comment on a graph with multiple
        sensors selected. Select only one sensor before adding a comment.
      </div>
    </div>
  );

  return (
    <div className={styles.root}>
      {showTooltip && isActive ? (
        <Tooltip
          disableAutoAdjust
          hoverOverride
          arrowDirection="bottom"
          content={TooltipContent}
        >
          {CommentButton}
        </Tooltip>
      ) : (
        CommentButton
      )}
    </div>
  );
};

export default CommentModeButton;
