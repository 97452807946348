import startOfDay from 'date-fns/startOfDay';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BaseFilterBar from '../../../../components/Filters/BaseFilterBar';
import useRefreshTimeRange from '../../../../hooks/useRefreshTimeRange';
import * as Routes from '../../../../routes';
import tileStyles from '../../../../styles/tiles.module.css';
import { SitesMapTile } from '../../../MultiSiteTiles';
import ActiveIncidentCountsTile from '../../../TodayTiles/ActiveIncidentCountsTile';
import ActiveIncidentsOverviewTile from '../../../TodayTiles/ActiveIncidentsOverviewTile';
import styles from './TodayOverviewPage.module.css';
import HvacControlsOperationsTile from '../../../TodayTiles/HvacControlsOperationsTile';

export type TodayPageTimeRange = {
  //should be ISO string, UTC
  from: string;
  to: string;
};

const TodayOverviewPage = () => {
  const [timeRange, setTimeRange] = useState({
    from: startOfDay(new Date()).toISOString(),
    to: new Date().toISOString(),
  });

  //setInterval for every 15 minutes if the user keeps this window open
  useRefreshTimeRange(setTimeRange);

  return (
    <div className={styles.root}>
      <BaseFilterBar className={styles.tabBarContainer}>
        <div className={styles.tabBar}>
          <div className={styles.currentTab}>
            <Link to={Routes.TODAY} className={styles.topLink}>
              Today
            </Link>
          </div>
        </div>
      </BaseFilterBar>
      <div className={styles.mobileTitle}>Today</div>
      <div className={`${styles.container} ${tileStyles.tilesContainer}`}>
        <ActiveIncidentCountsTile
          className={tileStyles.singleTile}
          timeRange={timeRange}
        />
        <SitesMapTile className={tileStyles.doubleTile} timeRange={timeRange} />

        <ActiveIncidentsOverviewTile
          className={tileStyles.tripleTile}
          timeRange={timeRange}
        />

        <HvacControlsOperationsTile className={tileStyles.doubleTile} />
      </div>
    </div>
  );
};

export default TodayOverviewPage;
