import { WeatherData } from '@energybox/react-ui-library/dist/types';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/weather';

export type Weather = {
  weatherBySiteId: WeatherBySiteId;
  isLoading: boolean;
};

export type WeatherBySiteId = {
  [siteId: string]: WeatherData;
};

const initialState = {
  weatherBySiteId: {},
  isLoading: false,
};

const normalizeWeatherResponse = (apiResponse: any) => ({
  siteId: apiResponse.id,
  humidity: apiResponse.humidity,
  temperature: apiResponse.temperature,
  timestamp: apiResponse.timestamp,
  windchill: apiResponse.windchill,
});

const weather = (state: Weather = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_WEATHER_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['weatherBySiteId', action.siteId],
          normalizeWeatherResponse(action.payload)
        ),
        assoc('isLoading', false)
      )(state);

    case Actions.GET_WEATHER_BY_SITE_ID_ERROR:
      return assoc('isLoading', false, state);

    case Actions.GET_WEATHER_BY_SITE_ID_LOADING:
      return assoc('isLoading', true, state);

    case Actions.GET_WEATHER_BY_MULTI_SITES_IDS_SUCCESS:
      const data = action.payload;
      const updatedWeatherBySiteId = data.reduce(
        (acc: WeatherBySiteId, weather) =>
          assocPath([weather.id], normalizeWeatherResponse(weather), acc),
        {},
        data
      );
      return assoc(
        'weatherBySiteId',
        { ...state.weatherBySiteId, ...updatedWeatherBySiteId },
        assoc('isLoading', false, state)
      );
    case Actions.GET_WEATHER_BY_MULTI_SITES_IDS_LOADING:
      return assoc('isLoading', true, state);
    case Actions.GET_WEATHER_BY_MULTI_SITES_IDS_ERROR:
      return assoc('isLoading', false, state);

    default:
      return state;
  }
};

export default weather;
