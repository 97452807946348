import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import { Divider } from '@material-ui/core';
import React, { Children } from 'react';
import BigNumberDivider from '../BigNumberDivider';
import BigNumberHorizontalDivider from '../BigNumberDivider/BigNumberHorizontalDivider';
import styles from './BigNumberGroup.module.css';

interface Props {
  children?: React.ReactNode;
  isMobile?: boolean;
}

const BigNumberGroup: React.FC<Props> = ({
  children,
  // isMobile = false
}) => {
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const childrenArray = Children.toArray(children);

  return (
    <div
      className={classNames(
        styles.root,
        isMobile ? styles.mobileContainer : styles.container
      )}
    >
      {Children.map(childrenArray, (child, idx) => {
        return (
          <>
            {child}
            {idx !== childrenArray.length - 1 &&
              (isMobile ? (
                <Divider className={styles.verticalDivider} />
              ) : (
                <Divider
                  className={styles.horizontalDivider}
                  orientation="vertical"
                  flexItem
                />
              ))}
          </>
        );
      })}
    </div>
  );
};

export default BigNumberGroup;
