import { fetchApi } from '../utils/fetchApi';
import { Space, SpaceApiFilter } from '@energybox/react-ui-library/dist/types';

export enum Actions {
  SPACES_BY_SITE_ID_SUCCESS = '@@spaces/SPACES_BY_SITE_ID_SUCCESS',
  SPACES_BY_SITE_ID_ERROR = '@@spaces/SPACES_BY_SITE_ID_ERROR',
  SPACES_BY_SITE_ID_LOADING = '@@spaces/SPACES_BY_SITE_ID_LOADING',

  GET_SPACE_BY_ID_SUCCESS = '@@spaces/GET_SPACE_BY_ID_SUCCESS',
  GET_SPACE_BY_ID_ERROR = '@@spaces/GET_SPACE_BY_ID_ERROR',
  GET_SPACE_BY_ID_LOADING = '@@spaces/GET_SPACE_BY_ID_LOADING',

  GET_SPACE_STATS_BY_ID_SUCCESS = '@@spaces/GET_SPACE_STATS_BY_ID_SUCCESS',
  GET_SPACE_STATS_BY_ID_ERROR = '@@spaces/GET_SPACE_STATS_BY_ID_ERROR',
  GET_SPACE_STATS_BY_ID_LOADING = '@@spaces/GET_SPACE_STATS_BY_ID_LOADING',
}

export const fetchSpaces = (filter?: SpaceApiFilter): Promise<Space[]> => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.sensorTypes && filter.sensorTypes.length > 0) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.withPath) {
    queryParams.set('withPath', 'true');
  }

  return fetchApi({
    endpoint: `/api/v1/spaces?${queryParams.toString()}`,
  });
};

export const getSpaceById = (spaceId: number | string) => ({
  type: 'API_GET',
  path: `/api/v1/spaces/${spaceId}/?withPath=true`,
  loading: { type: Actions.GET_SPACE_BY_ID_LOADING, spaceId },
  success: { type: Actions.GET_SPACE_BY_ID_SUCCESS, spaceId },
  error: { type: Actions.GET_SPACE_BY_ID_ERROR, spaceId },
});

export const fetchSpaceByParentId = (
  parentId: number | string
): Promise<Space[]> =>
  fetchApi({
    endpoint: `/api/v1/spaces/parent/${parentId}`,
  });

export const getSpacesBySiteId = (siteId: number | string) => ({
  type: 'API_GET',
  path: `/api/v1/spaces?siteIds=${siteId}`,
  loading: { type: Actions.SPACES_BY_SITE_ID_LOADING, siteId },
  success: { type: Actions.SPACES_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.SPACES_BY_SITE_ID_ERROR, siteId },
});

export const getSpacesStat = (spaceId: number) => ({
  type: 'API_GET',
  path: `/api/v1/spaces/${spaceId}/stats`,
  loading: { type: Actions.GET_SPACE_STATS_BY_ID_LOADING, spaceId },
  success: { type: Actions.GET_SPACE_STATS_BY_ID_SUCCESS, spaceId },
  error: { type: Actions.GET_SPACE_STATS_BY_ID_ERROR, spaceId },
});
