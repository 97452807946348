import { NothingToReportLogo } from '@energybox/react-ui-library/dist/icons';
import {
  ViewportTypes,
  useViewportType,
} from '@energybox/react-ui-library/dist/hooks';
import styles from './NothingToReportOverlay.module.css';

const NothingToReportOverlay = () => {
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const logoSize = isMobile ? 120 : 200;

  return (
    <div className={styles.noDataFoundSection}>
      <NothingToReportLogo size={logoSize} />
      <div className={styles.noDataText}>Nothing to Report</div>
      <div className={styles.subText}>No matching entries found</div>
    </div>
  )
};

export default NothingToReportOverlay;
