import { Columns } from '@energybox/react-ui-library/dist/components/Table';
import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import TemperatureComplianceTile, {
  PercentageData,
} from '../../../components/MultiSiteTiles/TemperatureComplianceTile';
import Tile from '../../../components/Tile/Tile';
import { useTemperatureComplianceByOrgId } from '../../../hooks/useAnalytics';
import { formatNumber, shortenString } from '../../../util';
import styles from './TemperatureComplianceTileContainer.module.css';
import useDynamicFilter from '../../../hooks/useDynamicFilter';

type Props = {
  className?: string;
};

interface SiteComplianceData extends PercentageData {
  siteId: string | number;
  numberOfEquipment: number;
}

export enum ErrorMessage {
  NO_DATA = 'There is no data available in the time period selected',
}

export const CARD_TITLE = 'Temperature Compliance Level';
export const HEADER = 'Multisite Insights';

const compliantColumns: Columns<SiteComplianceData>[] = [
  {
    header: '#',
    cellContent: (_, rowIndex) => (
      <span className={styles.index}>{rowIndex + 1}</span>
    ),
  },
  {
    header: 'Least Compliant Sites',
    cellContent: ({ title: siteTitle }) => (
      <div className={styles.siteTitle}>{shortenString(siteTitle, 30)}</div>
    ),
  },
  {
    header: 'Compliance Pct.',
    rightAlignContent: true,
    cellContent: ({ percentage, siteId }) => (
      <Link to={`/sites/${siteId}/analytics/temperature-compliance`}>
        <div>{formatNumber(percentage, 1)}%</div>
      </Link>
    ),
  },
];

const TemperatureComplianceTileContainer: React.FC<Props> = ({ className }) => {
  // ******** States ********
  const { selectedFilters: selectedEquipmentTypeIds } =
    useDynamicFilter<number>('eqType', (value) => parseInt(value));

  // ******** Selectors ********
  const {
    isLoading,
    errorMessage,
    siteCompliances,
    orgSummary,
    availableEquipmentTypes,
  } = useTemperatureComplianceByOrgId({
    equipment_type_id: selectedEquipmentTypeIds,
  });

  return (
    <Tile className={className} isLoading={isLoading}>
      <TemperatureComplianceTile
        title={CARD_TITLE}
        averagePercentage={orgSummary?.within_compliance}
        entities={siteCompliances.map((c) => ({
          percentage: c.within_compliance,
          title: c.site_title,
          siteId: c.siteId,
        }))}
        tableColumns={compliantColumns}
        errorMessage={errorMessage}
        equipmentTypes={availableEquipmentTypes}
      />
    </Tile>
  );
};

export default React.memo(TemperatureComplianceTileContainer);
