import React from 'react';
import { MdLens } from 'react-icons/md';
import { DeviceConnectivityText } from '@energybox/react-ui-library/dist/types';

type Props = {
  connectionStatus?: boolean | string;
  hideText?: boolean;
  size?: number;
  color?: string;
};

const DeviceConnectionStatus = ({
  connectionStatus,
  hideText,
  size,
  color,
}: Props) => {
  return connectionStatus && connectionStatus !== 'INACTIVE' ? (
    <span>
      <MdLens size={size || 10} color={color ?? 'var(--green-base)'} />{' '}
      {!hideText && DeviceConnectivityText.ONLINE}
    </span>
  ) : (
    <span>
      <MdLens size={size || 10} color={color ?? 'var(--pink-base)'} />{' '}
      {!hideText && DeviceConnectivityText.OFFLINE}
    </span>
  );
};

export default DeviceConnectionStatus;
