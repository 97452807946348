import {
  NetworkGroup,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions } from '../actions/networkGroups';

export interface NetworkGroups {
  networkGroupsById: { [id: string]: NetworkGroup };
  isGetNetworkGroupLoading: boolean;
  networkGroupsByOrgId: { [id: string]: NetworkGroup[] };
  networkGroupsBySiteId: { [id: string]: NetworkGroup[] };
  networkGroupsBythermostat: {
    [key: string]: number[];
  };
  isNetworkGroupsBythermostatLoading: boolean;
}

export const NetworkGroupsFromApiResponse = (data: any) => {
  return {
    id: data.id,
    title: data.title,
    description: data.description,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt || undefined,
    edge: data.edge || {},
    resourceType: ResourceType[(data._entity as string).toUpperCase()],
    siteId: data.siteId,
  };
};

const initialState = {
  networkGroupsById: {},
  isGetNetworkGroupLoading: false,
  networkGroupsByOrgId: {},
  networkGroupsBySiteId: {},
  networkGroupsBythermostat: {},
  isNetworkGroupsBythermostatLoading: false,
};

const networkGroups = (state: NetworkGroups = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_NETWORK_GROUP_SUCCESS:
      let networkGroup = NetworkGroupsFromApiResponse(action.payload);
      return R.pipe(
        R.assocPath(['networkGroupsById', action.payload.id], networkGroup),
        R.assoc('isGetNetworkGroupLoading', false)
      )(state);

    case Actions.GET_NETWORK_GROUP_LOADING:
      return R.pipe(R.assoc('isGetNetworkGroupLoading', true))(state);

    case Actions.GET_NETWORK_GROUP_ERROR:
      return R.pipe(R.assoc('isGetNetworkGroupLoading', false))(state);

    case Actions.GET_NETWORK_GROUP_BY_ORG_ID_SUCCESS:
      let networkGroups = action.payload.map(NetworkGroupsFromApiResponse);

      return R.pipe(
        R.assocPath(['networkGroupsByOrgId', action.orgId], networkGroups),
        R.assoc('isGetNetworkGroupLoading', false)
      )(state);

    case Actions.GET_NETWORK_GROUP_BY_ORG_ID_LOADING:
      return R.pipe(R.assoc('isGetNetworkGroupLoading', true))(state);

    case Actions.GET_NETWORK_GROUP_BY_ORG_ID_ERROR:
      return R.pipe(R.assoc('isGetNetworkGroupLoading', false))(state);

    case Actions.GET_NETWORK_GROUP_BY_THERMOSTAT_SUCCESS:
      return R.pipe(
        R.assocPath(['networkGroupsBythermostat'], action.payload),
        R.assoc('isNetworkGroupsBythermostatLoading', false)
      )(state);

    case Actions.GET_NETWORK_GROUP_BY_THERMOSTAT_LOADING:
      return R.pipe(R.assoc('isNetworkGroupsBythermostatLoading', true))(state);

    case Actions.GET_NETWORK_GROUP_BY_THERMOSTAT_ERROR:
      return R.pipe(R.assoc('isNetworkGroupsBythermostatLoading', false))(
        state
      );

    case Actions.GET_NETWORK_GROUP_BY_SITE_ID_SUCCESS:
      let networkGroupsBySiteId = action.payload.map(
        NetworkGroupsFromApiResponse
      );

      return R.pipe(
        R.assocPath(
          ['networkGroupsBySiteId', action.siteId],
          networkGroupsBySiteId
        ),
        R.assoc('isGetNetworkGroupLoading', false)
      )(state);

    case Actions.GET_NETWORK_GROUP_BY_SITE_ID_LOADING:
      return R.pipe(R.assoc('isGetNetworkGroupLoading', true))(state);

    case Actions.GET_NETWORK_GROUP_BY_SITE_ID_ERROR:
      return R.pipe(R.assoc('isGetNetworkGroupLoading', false))(state);

    default:
      return state;
  }
};

export default networkGroups;
