import { Dispatch } from 'redux';
import { ApplicationState } from '../reducers/index';
import { Actions as AppActions } from '../actions/constants/Actions';
import { Actions as StreamApiActions, Channel } from '../actions/streamApi';

const API_BASE_URL = process.env.REACT_APP_STREAM_SERVICE_URL;

let socket: WebSocket = {} as WebSocket;
let keepAliveSocket;
let accessTokenClosure;

const streamApiMiddleware =
  (store: { getState: () => ApplicationState; dispatch: Dispatch }) =>
  (next: (action: any) => any) =>
  (action: any) => {
    if (
      action.type === AppActions.ACCESS_TOKEN_UPDATED ||
      action.type === AppActions.RESET_SOCKET_CONNECTION
    ) {
      const { accessToken } = action.payload;

      accessTokenClosure = accessToken || accessTokenClosure;

      socket = new WebSocket(`${API_BASE_URL}`, [
        'accessToken',
        accessTokenClosure,
        'keepAlive',
      ]);

      if (!keepAliveSocket) {
        keepAliveSocket = setInterval(() => {
          if (socket.readyState !== 1) {
            socket.close();
            store.dispatch({
              type: AppActions.RESET_SOCKET_CONNECTION,
              payload: { accessToken: store.getState().app.accessToken },
            });
          }
        }, 5000);
      }

      socket.onopen = () => {
        store.dispatch({
          type: StreamApiActions.CONNECTED,
          payload: { status: 'OK' },
        });
      };

      socket.onerror = (error: any) => {
        store.dispatch({
          type: StreamApiActions.ERROR,
          error,
        });
      };

      socket.onclose = (event: any) => {
        store.dispatch({
          type: StreamApiActions.CLOSED,
          reason: event.reason,
        });
      };

      socket.onmessage = (message: any) => {
        const data = JSON.parse(message.data);

        switch (data.channel) {
          case Channel.DEVICE_STATUS:
            store.dispatch({
              type: StreamApiActions.RECEIVED_DEVICE_STATUS,
              payload: data.data,
            });
            break;

          case Channel.SENSOR_READINGS:
            store.dispatch({
              type: StreamApiActions.RECEIVED_DEVICE_READING,
              payload: data.data,
            });
            break;

          case Channel.INCIDENT_INFO:
            store.dispatch({
              type: StreamApiActions.RECEIVED_SUBSCRIBED_INCIDENT,
              payload: data.data,
            });
            break;

          case Channel.REPORTS_STATUS:
            store.dispatch({
              type: StreamApiActions.RECEIVED_REPORT_STATUS,
              payload: data.data,
            });
            break;

          case Channel.PING:
            socket.send(JSON.stringify({ type: 'PONG' }));
            break;

          default:
            break;
        }
      };
    }

    if (action.type === StreamApiActions.SEND_MESSAGE) {
      if (socket.readyState === 1) {
        socket.send(JSON.stringify(action.data));
      }
    }

    next(action);
  };

export default streamApiMiddleware;
