const USER_VISIT = 'User Visit';
const PAGE_VISIT = 'Page Visit';
const TEMP_CHECK = 'Temp Check';
// Report Generation
const REPORT_GENERATION = 'Report Generation';
const REPORT_DOWNLOAD = 'Report Download';
const CONTROL_MODE_CHANGE = 'Control Mode Change';

export default {
  USER_VISIT,
  PAGE_VISIT,
  TEMP_CHECK,
  REPORT_GENERATION,
  REPORT_DOWNLOAD,
  CONTROL_MODE_CHANGE,
};
