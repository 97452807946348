import {
  EnergyLineChart,
  ResetButton,
} from '@energybox/react-ui-library/dist/components';
import {
  useGraphTouchEvents,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import {
  AggregationFilter,
  Comment,
  CommentApiFilter,
  CurrentUser,
  EnergyApiFilter,
  EnergyPowerApiResponse,
  EnergyReadingApiResponse,
  FlatNotification,
  Incident,
  Locale,
  NewCommentReferenceDot,
  Notification,
  Sentinel,
  SentinelType,
  ShowNewCommentPopupPayload,
  Site,
  ThresholdLine,
  UsersById,
  ValueType,
} from '@energybox/react-ui-library/dist/types';
import {
  determineDateRangeAfterZoomIn,
  determineNewCommentPopupInfo,
  determineResponsiveXTicksAfterZoomIn,
  determineWhichEnergyApiFunctionToUse,
  generateResponsiveXTicks,
  processNotificationsByTypes,
  processThresholdLines,
  roundUpTo15Minutes,
  getDefaultAggregationInterval,
  TIME_IN_MILLISECONDS,
} from '@energybox/react-ui-library/dist/utils';
import { toDate as toDateFn } from 'date-fns';
import equals from 'ramda/src/equals';
import pathOr from 'ramda/src/pathOr';

import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { getComments, showNewCommentPopup } from '../../actions/comments';
import { getEnergyByEquipmentId } from '../../actions/energy';
import { getTSEnergyByEquipmentId } from '../../actions/energyTS';
import { getFlatNotificationsBySensorId } from '../../actions/notifications';
import { getSentinelById } from '../../actions/sentinels';
import { getSite } from '../../actions/sites';
import { getUsers } from '../../actions/users';
import CommentModeButton from '../../components/CommentModeButton';
import CommentsFeatureFlag from '../../components/CommentsFeatureFlag';
import NewCommentPopup from '../../components/NewCommentPopup';
import ChartResolutionFilter from '../../components/Filters/ChartResolutionFilter';
import { withViewportType } from '../../components/withViewportType';
import { ApplicationState } from '../../reducers';
import { filterAndProcessComments } from '../../utils/comments';
import {
  determineFetchTimeRangeForNotificationChart,
  getIdsForNotificationChart,
} from '../../utils/notifications';
import styles from './NotificationEnergyChartContainer.module.css';
import useCurrentUser from '../../hooks/useCurrentUser';

interface OwnProps {
  locale: Locale;
  featureNotification?: Notification;
  featureIncident?: Incident;
  equipmentId: string;
  sensorId: string;
  viewportType: ViewportTypes;
  siteId: string | number | undefined;
}

interface Props extends OwnProps {
  currentUser: CurrentUser | undefined;
  getEnergyByEquipmentId: (queryParams: EnergyApiFilter) => void;
  getTSEnergyByEquipmentId: (queryParams: EnergyApiFilter) => void;
  getFlatNotifications: (fromDate: number, toDate: number) => void;
  getSentinels: () => void;
  getSite: (siteId: number | string) => void;
  getUsers: (userIds?: number[]) => void;
  getComments: (queryParams: CommentApiFilter) => void;
  showNewCommentPopup: (payload: ShowNewCommentPopupPayload) => void;
  site: Site | undefined;
  usersById: UsersById;
  energy: EnergyPowerApiResponse;
  flatNotifications: FlatNotification[];
  sentinels: Sentinel[];
  isLoading: boolean;
  comments?: Comment[];
  isNewCommentPopupOpen: boolean;
  isMobile: boolean;
}

type State = {
  initialFromDate: number;
  initialToDate: number;
  currentFromDate: number;
  currentToDate: number;
  ticks: number[];
  refAreaStart: string;
  refAreaEnd: string;
  thresholdLines: ThresholdLine[];
  isCommentModeActive: boolean;
  newCommentPopupXCoordinate: number;
  referenceDot: NewCommentReferenceDot | undefined;
  aggregationFilter: AggregationFilter;
};

class NotificationEnergyChartContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { featureNotification, featureIncident, isMobile } = props;
    //If notification, then timeRange is +/- 12hrs of feature notification
    //If incident, then timeRange is beginning of incident to when incident resolves (or current time if not resolved)
    const { fromDate, toDate } = determineFetchTimeRangeForNotificationChart(
      featureNotification,
      featureIncident
    );

    const initialAggregationFilter = getDefaultAggregationInterval(
      toDate - fromDate
    );

    this.state = {
      initialFromDate: fromDate,
      initialToDate: toDate,
      currentFromDate: fromDate,
      currentToDate: toDate,
      ticks: generateResponsiveXTicks({ fromDate, toDate, isMobile }),
      refAreaStart: '',
      refAreaEnd: '',
      thresholdLines: [],
      isCommentModeActive: false,
      newCommentPopupXCoordinate: -1,
      referenceDot: undefined,
      aggregationFilter: initialAggregationFilter,
    };
  }

  componentDidMount() {
    const {
      currentUser,
      getSite,
      getUsers,
      getSentinels,
      getFlatNotifications,
      site,
      siteId,
      usersById,
      sentinels,
      locale,
      getComments,
    } = this.props;
    const { currentFromDate, currentToDate } = this.state;
    if (!site && siteId) {
      getSite(siteId);
    }

    if (Object.values(usersById).length === 0) {
      getUsers();
    }

    this.getEnergyData(currentFromDate, currentToDate);
    getFlatNotifications(currentFromDate, currentToDate);
    getComments({
      from: new Date(currentFromDate).toISOString(),
      to: new Date(currentToDate).toISOString(),
    });

    if (currentUser && sentinels.length) {
      this.setState({
        thresholdLines: processThresholdLines(sentinels, currentUser, [
          SentinelType.ACTIVE_POWER_THRESHOLD,
        ]),
      });
    } else {
      getSentinels();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      currentUser,
      isMobile,
      site,
      sentinels,
      getFlatNotifications,
      locale,
      getComments,
    } = this.props;
    const { currentFromDate, currentToDate, aggregationFilter } = this.state;
    const didSiteUpdate = !equals(prevProps.site, site);
    const didTimeChange =
      !equals(prevState.currentFromDate, currentFromDate) ||
      !equals(prevState.currentToDate, currentToDate);
    const didSentinelsChange = !equals(prevProps.sentinels, sentinels);
    const didViewportChange = !equals(prevProps.isMobile, isMobile);
    const didAggregationLevelChange = !equals(
      prevState.aggregationFilter,
      aggregationFilter
    );

    if (didSiteUpdate || didTimeChange || didAggregationLevelChange) {
      this.getEnergyData(currentFromDate, currentToDate);
    }

    if (didTimeChange) {
      getFlatNotifications(currentFromDate, currentToDate);
      getComments({
        from: new Date(currentFromDate).toISOString(),
        to: new Date(currentToDate).toISOString(),
      });
    }

    if (currentUser && didSentinelsChange) {
      this.setState({
        thresholdLines: processThresholdLines(sentinels, currentUser, [
          SentinelType.ACTIVE_POWER_THRESHOLD,
        ]),
      });
    }

    if (didViewportChange) {
      this.setState({
        ticks: generateResponsiveXTicks({
          fromDate: currentFromDate,
          toDate: currentToDate,
          isMobile,
        }),
      });
    }
  }

  chartContainerRef = createRef<HTMLDivElement>();

  getEnergyData = (from: number, to: number) => {
    const { site, getEnergyByEquipmentId, getTSEnergyByEquipmentId } =
      this.props;
    const { aggregationFilter } = this.state;

    if (!site) return;
    const getEquipmentEnergy = determineWhichEnergyApiFunctionToUse(
      site.newTsdb,
      getEnergyByEquipmentId,
      getTSEnergyByEquipmentId
    );

    const nowNearestNext15Minutes =
      new Date(roundUpTo15Minutes(new Date())).getTime() / 1000;

    let toConvertedToSeconds = Math.round(to / 1000);
    let fromConvertedToSeconds = Math.round(from / 1000);

    if (toConvertedToSeconds > nowNearestNext15Minutes) {
      toConvertedToSeconds = nowNearestNext15Minutes;
    }

    getEquipmentEnergy({
      from: fromConvertedToSeconds,
      to: toConvertedToSeconds,
      aggregationLevel: aggregationFilter.aggregationLevel,
      seeSpilloverPoint: true,
    });
  };

  updateRefAreaStart = (newrefAreaStart: string) => {
    this.setState({ refAreaStart: newrefAreaStart });
  };

  updateRefAreaEnd = (newrefAreaEnd: string) => {
    this.setState({ refAreaEnd: newrefAreaEnd });
  };

  setChartResolution = (aggregationFilter: AggregationFilter) => {
    this.setState({ aggregationFilter });
  };

  zoomIn = () => {
    const { refAreaStart, refAreaEnd } = this.state;
    const { isMobile } = this.props;
    const refAreaStartNumber = parseInt(refAreaStart);
    const refAreaEndNumber = parseInt(refAreaEnd);

    if (refAreaStart === refAreaEnd || refAreaEnd === '') {
      this.setState(() => ({
        refAreaStart: '',
        refAreaEnd: '',
      }));
      return;
    }

    const { fromDate: newFromDate, toDate: newToDate } =
      determineDateRangeAfterZoomIn(refAreaStartNumber, refAreaEndNumber);

    const newTicks = determineResponsiveXTicksAfterZoomIn({
      fromDate: refAreaStartNumber,
      toDate: refAreaEndNumber,
      isMobile,
    });

    const newAggregationFilter = getDefaultAggregationInterval(
      newToDate - newFromDate
    );

    this.setState({
      aggregationFilter: newAggregationFilter,
      currentFromDate: newFromDate,
      currentToDate: newToDate,
      ticks: newTicks,
      refAreaStart: '',
      refAreaEnd: '',
    });
  };

  zoomOut = () => {
    const { initialFromDate, initialToDate } = this.state;
    const { isMobile } = this.props;

    const newAggregationFilter = getDefaultAggregationInterval(
      initialToDate - initialFromDate
    );

    this.setState({
      aggregationFilter: newAggregationFilter,
      currentFromDate: initialFromDate,
      currentToDate: initialToDate,
      ticks: generateResponsiveXTicks({
        fromDate: initialFromDate,
        toDate: initialToDate,
        isMobile,
      }),
    });
  };

  onAddCommentButtonClick = () => {
    this.setState({
      isCommentModeActive: !this.state.isCommentModeActive,
    });
  };

  processEnergyData = (response: EnergyPowerApiResponse) => {
    const { readings, spilloverPoint } = response;
    if (!readings) return undefined;
    const data = [...readings];
    if (spilloverPoint) data.push(spilloverPoint);
    return data.map((_data: EnergyReadingApiResponse) => ({
      timestamp: _data.timestamp * 1000,
      value: _data.power,
    }));
  };

  setNewCommentPopupXCoordinate = (value: number) => {
    this.setState({
      newCommentPopupXCoordinate: value,
    });
  };

  setReferenceDot = (value: NewCommentReferenceDot | undefined) => {
    this.setState({
      referenceDot: value,
    });
  };

  onNewCommentPopupUnmount = () => {
    this.setNewCommentPopupXCoordinate(-1);
    this.setReferenceDot(undefined);
  };

  onClickDuringCommentMode = (e) => {
    const { isCommentModeActive } = this.state;
    const {
      currentUser,
      equipmentId,
      showNewCommentPopup,
      isNewCommentPopupOpen,
    } = this.props;

    if (currentUser && isCommentModeActive && e && !isNewCommentPopupOpen) {
      const newCommentPopupInfo = determineNewCommentPopupInfo(
        e,
        'value',
        +equipmentId,
        ValueType.ENERGY,
        this.chartContainerRef,
        currentUser
      );
      if (newCommentPopupInfo) {
        const {
          newCommentReferenceDot,
          newCommentPopupXCoordinate,
          newCommentPopupPayload,
        } = newCommentPopupInfo;
        this.setNewCommentPopupXCoordinate(newCommentPopupXCoordinate);
        this.setReferenceDot(newCommentReferenceDot);
        showNewCommentPopup(newCommentPopupPayload);
      }
    }
  };

  render() {
    const {
      currentUser,
      equipmentId,
      usersById,
      energy,
      flatNotifications,
      isLoading,
      locale,
      comments,
      isNewCommentPopupOpen,
      site,
      isMobile,
    } = this.props;
    const {
      initialFromDate,
      initialToDate,
      currentFromDate,
      currentToDate,
      ticks,
      refAreaStart,
      refAreaEnd,
      thresholdLines,
      isCommentModeActive,
      referenceDot,
      newCommentPopupXCoordinate,
      aggregationFilter,
    } = this.state;

    if (!currentUser) return null;

    const processedComments =
      filterAndProcessComments(
        comments,
        [+equipmentId],
        ValueType.ENERGY,
        currentUser
      ) || [];

    const isZoomedIn =
      initialFromDate !== currentFromDate || initialToDate !== currentToDate;

    if (energy === undefined) return null;
    const processedData = this.processEnergyData(energy);

    const timeDelta = currentToDate - currentFromDate;
    const disableOneMinuteResolution = timeDelta > TIME_IN_MILLISECONDS.ONE_DAY;

    return (
      <div className={styles.container}>
        <header className={styles.header}>
          <div>
            <h5 className={styles.title}>Energy Activity</h5>
          </div>
          {!isMobile && (
            <div className={styles.rightAlign}>
              <ChartResolutionFilter
                // className={styles.chartResolutionFilter}
                onChange={this.setChartResolution}
                title={aggregationFilter.title}
                disableOneMinuteResolution={disableOneMinuteResolution}
              />

              {/* TODO COMMENTS: delete FeatureFlag all orgs can use commentsFeature */}
              <CommentsFeatureFlag>
                <CommentModeButton
                  className={styles.commentButtonContainer}
                  onClick={this.onAddCommentButtonClick}
                  disabled={isLoading}
                  isActive={isCommentModeActive}
                />
              </CommentsFeatureFlag>

              <div className={styles.zoomResetContainer}>
                <ResetButton
                  customText="Reset Zoom"
                  displayInfoTooltip
                  onClick={this.zoomOut}
                  disabled={!isZoomedIn || isCommentModeActive}
                />
              </div>
            </div>
          )}
        </header>

        <div
          className={styles.energyLineChartContainer}
          ref={this.chartContainerRef}
        >
          <EnergyLineChartWrapper
            {...{
              isMobile,
              site,
              isLoading,
              processedData,
              flatNotifications,
              locale,
              thresholdLines,
              currentFromDate,
              currentToDate,
              ticks,
              refAreaStart,
              refAreaEnd,
              isCommentModeActive,
              processedComments,
              referenceDot,
              isNewCommentPopupOpen,
              updateRefAreaStart: this.updateRefAreaStart,
              updateRefAreaEnd: this.updateRefAreaEnd,
              zoomIn: this.zoomIn,
              onClickDuringCommentMode: this.onClickDuringCommentMode,
            }}
          />

          {isNewCommentPopupOpen && newCommentPopupXCoordinate && (
            <div
              style={{
                position: 'absolute',
                top: -50,
                left: newCommentPopupXCoordinate,
              }}
            >
              <NewCommentPopup
                ianaTimeZoneCode={site?.timeZone}
                resourceId={+equipmentId}
                resourceType={ValueType.ENERGY}
                onUnmount={this.onNewCommentPopupUnmount}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  {
    app,
    users,
    comments,
    sites,
    energy,
    sentinels,
    notifications,
  }: ApplicationState,
  {
    siteId: siteIdProp,
    equipmentId,
    featureNotification,
    featureIncident,
    viewportType,
  }: OwnProps
) => {
  const {
    siteId: notificationSiteId,
    sentinelId,
    notificationId,
  } = getIdsForNotificationChart(featureNotification, featureIncident);
  const siteId = siteIdProp ? siteIdProp : notificationSiteId;
  const site = sites.sitesById[siteId];
  return {
    currentUser: app.currentUser,
    isMobile: viewportType === ViewportTypes.MOBILE,
    site,
    usersById: users.usersById,
    comments: pathOr(
      undefined,
      [equipmentId, 'comments'],
      comments.commentsByResourceId
    ),
    sentinels: sentinels.byId[sentinelId] || [],
    flatNotifications:
      notifications.flatNotificationsByFeatureNotificationId[notificationId] ||
      [],
    energy: energy.energyByFeatureNotificationId[notificationId],
    isLoading:
      energy.isLoadingByResourceId[notificationId] ||
      notifications.isLoadingByResourceId[notificationId] ||
      sentinels.isLoadingByResourceId[notificationId] ||
      pathOr(false, [equipmentId, 'isLoading'], comments.commentsByResourceId),
    isNewCommentPopupOpen: pathOr(
      false,
      [`${equipmentId}_${ValueType.ENERGY}`],
      comments.isNewCommentPopupOpenByResourceId_ResourceType
    ),
  };
};

const mapDispatchToProps = (
  dispatch,
  { equipmentId, sensorId, featureNotification, featureIncident }: OwnProps
) => {
  const { sentinelId, notificationId } = getIdsForNotificationChart(
    featureNotification,
    featureIncident
  );

  return {
    getSite: (siteId: number | string) => dispatch(getSite(siteId)),
    getEnergyByEquipmentId: (queryParams: EnergyApiFilter) =>
      dispatch(
        getEnergyByEquipmentId(equipmentId, queryParams, notificationId)
      ),
    getTSEnergyByEquipmentId: (queryParams: EnergyApiFilter) =>
      dispatch(
        getTSEnergyByEquipmentId(equipmentId, queryParams, notificationId)
      ),
    getFlatNotifications: (fromDate: number, toDate: number) =>
      dispatch(
        getFlatNotificationsBySensorId(
          sensorId,
          {
            sensorIds: [sensorId],
            from: toDateFn(fromDate),
            to: toDateFn(toDate),
          },
          notificationId
        )
      ),
    getSentinels: () => dispatch(getSentinelById(sentinelId)),
    getUsers: (userIds?: number[]) => dispatch(getUsers(userIds)),
    getComments: (queryParams: CommentApiFilter) =>
      dispatch(getComments(equipmentId, queryParams)),
    showNewCommentPopup: (payload: ShowNewCommentPopupPayload) =>
      dispatch(
        showNewCommentPopup(`${equipmentId}_${ValueType.ENERGY}`, payload)
      ),
  };
};

export default withViewportType(
  connect(mapStateToProps, mapDispatchToProps)(NotificationEnergyChartContainer)
);

const EnergyLineChartWrapper = ({
  isMobile,
  site,
  isLoading,
  processedData,
  flatNotifications,
  locale,
  thresholdLines,
  currentFromDate,
  currentToDate,
  ticks,
  refAreaStart,
  refAreaEnd,
  updateRefAreaStart,
  updateRefAreaEnd,
  zoomIn,
  isCommentModeActive,
  processedComments,
  referenceDot,
  onClickDuringCommentMode,
  isNewCommentPopupOpen,
}) => {
  const currentUser = useCurrentUser();
  const graphTouchEvents = useGraphTouchEvents(
    true,
    updateRefAreaStart,
    updateRefAreaEnd,
    zoomIn
  );
  if (!currentUser) return null;

  return (
    <EnergyLineChart
      isMobile={isMobile}
      ianaTimeZoneCode={site?.timeZone}
      isLoading={isLoading}
      data={processedData || []}
      notifications={processNotificationsByTypes(
        flatNotifications,
        currentUser
      )}
      thresholdLines={thresholdLines}
      locale={locale}
      fromDate={currentFromDate}
      toDate={currentToDate}
      ticks={ticks}
      refAreaStart={refAreaStart}
      refAreaEnd={refAreaEnd}
      startZoom={graphTouchEvents.startZoom}
      onTouchStart={graphTouchEvents.onTouchStart}
      onTouchMove={graphTouchEvents.onTouchMove}
      onTouchEnd={graphTouchEvents.onTouchEnd}
      isCommentModeActive={isCommentModeActive}
      comments={processedComments}
      newCommentReferenceDot={referenceDot}
      onClickDuringCommentMode={onClickDuringCommentMode}
      isNewCommentPopupOpen={isNewCommentPopupOpen}
    />
  );
};
