import {
  MeasurementSystem,
  TemperatureUnit,
} from '@energybox/react-ui-library/dist/types';
import { Service } from '../config';

// ASSUMPTION: We are only trying to generate 1 report at a time
export enum Actions {
  GET_REPORT_SUCCESS = '@@reports/GET_REPORT_SUCCESS',
  GET_REPORT_LOADING = '@@reports/GET_REPORT_LOADING',
  GET_REPORT_ERROR = '@@reports/GET_REPORT_ERROR',

  GET_REPORTS_BY_SITE_ID_LOADING = '@report/GET_REPORTS_BY_SITE_ID_LOADING',
  GET_REPORTS_BY_SITE_ID_SUCCESS = '@report/GET_REPORTS_BY_SITE_ID_SUCCESS',
  GET_REPORTS_BY_SITE_ID_ERROR = '@report/GET_REPORTS_BY_SITE_ID_ERROR',

  GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_SUCCESS = '@reports/GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_SUCCESS',
  GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_ERROR = '@reports/GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_ERROR',
  GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_LOADING = '@reports/GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_LOADING',
}

export const generateEnergyCSV = (
  siteId: number | string,
  from: number,
  to: number
) => ({
  type: 'API_GET',
  service: Service.iam,
  contentType: 'application/octet-stream',
  path: `/api/v1/ioe-energy/consumption/site/${siteId}/csv?from=${from}&to=${to}`,
  loading: { type: Actions.GET_REPORT_LOADING },
  success: {
    type: Actions.GET_REPORT_SUCCESS,
    fileName: `energy-report-site-${siteId}.csv`,
    fileType: 'data:text/csv;charset=utf-8',
  },
  error: { type: Actions.GET_REPORT_SUCCESS },
});

/**
 * @param from ISO 8601 Date (i.e. "2022/02/23")
 * @param to ISO 8601 Date (i.e. "2022/02/23")
 */
export const generateTemperatureCSV = (
  siteId: number | string,
  temperatureUnit: TemperatureUnit,
  from: string,
  to: string
) => ({
  type: 'API_GET',
  service: Service.iam,
  contentType: 'application/octet-stream',
  path: `/api/v1/reports/temperature/site/${siteId}/xlsx?startDate=${from}&endDate=${to}&measurementSystem=${
    temperatureUnit === TemperatureUnit.C
      ? MeasurementSystem.METRIC
      : MeasurementSystem.IMPERIAL
  }`,
  loading: { type: Actions.GET_REPORT_LOADING },
  success: {
    type: Actions.GET_REPORT_SUCCESS,
    fileName: `temperature-sensor-report-site-${siteId}.xlsx`,
    fileType:
      'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
  },
  error: { type: Actions.GET_REPORT_SUCCESS },
});

export const getReportsBySiteId = (siteId: number | string) => ({
  type: 'API_GET',
  service: Service.iam,
  path: `/api/v1/report/organization-unit/${siteId}`,
  success: { type: Actions.GET_REPORTS_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_REPORTS_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_REPORTS_BY_SITE_ID_LOADING, siteId },
});

export const getSiteEquipmentRecentTempReport = (siteId: number | string) => ({
  type: 'API_GET',
  path: `/api/v1/report/generate/recent-temp/site/${siteId}`,
  success: {
    type: Actions.GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_SUCCESS,
    siteId,
  },
  error: { type: Actions.GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_ERROR, siteId },
  loading: {
    type: Actions.GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_LOADING,
    siteId,
  },
});
