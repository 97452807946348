import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import * as Routes from '../routes';
import { isSignedIn } from '../reducers/app';
import DefaultLayout from './layout/DefaultLayout';
import LoginPageContainer from './LoginPageContainer/LoginPageContainer';
import ForgotPasswordPageContainer from './Password/ForgotPasswordPageContainer';
import NewPasswordPageContainer from './Password/NewPasswordPageContainer';
import history from '../history';
import { ApplicationState } from '../reducers';
import OrgSwitch from './OrgSwitch/OrgSwitch';
import { useOrganization } from '../hooks/useCurrentUser';
import License from './License/License';

type Props = {
  isSignedIn: boolean;
};

function App({ isSignedIn }: Props) {
  const org = useOrganization();

  useEffect(() => {
    document.title = org?.title || '';
  }, [org]);

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path={Routes.ORG_SWITCH} component={OrgSwitch} />
        <Route path={Routes.LOGIN} component={LoginPageContainer} />
        <Route
          path={Routes.FORGOT_PASSWORD}
          component={ForgotPasswordPageContainer}
        />
        <Route
          path={Routes.NEW_PASSWORD}
          component={NewPasswordPageContainer}
        />
        <Route
          path={Routes.USER_INVITE_EMAIL}
          component={NewPasswordPageContainer}
        />
        <Route path={Routes.PUBLIC_OPEN_SOURCE_LICENSE} component={License} />

        {isSignedIn ? (
          <Route path="/*" component={DefaultLayout} />
        ) : (
          <Redirect
            from="/*"
            to={`${Routes.LOGIN}?next=${encodeURIComponent(
              window.location.pathname
            )}${window.location.search.replace('?', '&')}`}
          />
        )}
      </Switch>
    </ConnectedRouter>
  );
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  isSignedIn: isSignedIn(app),
});

export default connect(mapStateToProps, null)(App);
