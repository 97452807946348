import {
  Button,
  Checkbox,
  ErrorBoundary,
} from '@energybox/react-ui-library/dist/components';
import CardList, {
  CardListGroupHeader,
  CardListHeader,
  CardListRowData,
  CardListStatus,
  Cell,
} from '@energybox/react-ui-library/dist/components/CardList';
import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import {
  Incident,
  Locale,
  ParametersSwitch,
  ParametersSwitchOnOff,
  ResourcePath,
  ResourceType,
  SensorType,
  SentinelPriorityLevel,
  SentinelType,
  Site,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  determineTimeStringByTimezone,
  genericTableSort,
  global,
  isDefined,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import parseISO from 'date-fns/parseISO';
import formatDistance from 'date-fns/formatDistance';
import pathOr from 'ramda/src/pathOr';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openDialog } from '../../actions/dialog';
import { dismissNotification } from '../../actions/notifications';
import NotificationEnergyChartContainer from '../../containers/NotificationEnergyChartContainer';
import NotificationOperationalSensorChartContainer from '../../containers/NotificationOperationalSensorChartContainer';
import NotificationThermostatChartContainer from '../../containers/NotificationThermostatChartContainer';
import { IncidentsListPageType } from '../../containers/views/incidents/IncidentsListPage';
import useAppLocale from '../../hooks/useAppLocale';
import { useGetAllSites } from '../../hooks/useSites';
import { ApplicationState } from '../../reducers';
import { PathsById } from '../../reducers/resources';
import { doesNotificationHaveChartData } from '../../utils/notifications';
import IconSensorTypeFactory from '../icons/IconSensorTypeFactory';
import IncidentValueChip from '../IncidentValueChip';
import SensorResource from '../SensorResource';
import ShortenedSpan from '../ShortenedSpan';
import styles from './IncidentsCardList.module.css';
import usePaginationFilter from '../../hooks/usePaginationFilter';

type Props = {
  siteId?: string;
  reviewMode?: boolean;
  reviewSort?: boolean;
  pageType: IncidentsListPageType;
  incidents: Incident[];
  listPageRef: React.RefObject<HTMLDivElement>;
  isLoading: boolean;
};

const IncidentsCardList: React.FC<Props> = ({
  siteId,
  reviewMode,
  reviewSort,
  incidents,
  pageType,
  listPageRef,
  isLoading,
}) => {
  ///*** Hooks ***///
  const dispatch = useDispatch();
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const locale = useAppLocale();
  const sitesById = useGetAllSites();
  //End Hooks

  ///*** Local vars + Functions ***///
  const incidentsCount = incidents.length;
  const showGroupHeaders = !isMobile;

  const openDismissModal = (notificationId: string) => {
    dispatch(
      openDialog({
        isOpen: true,
        title: 'Dismiss Notification',
        type: 'DISMISS_NOTIFICATION',
        targetId: notificationId,
        actionFn: ({ notificationId, comment }) =>
          dispatch(dismissNotification(notificationId, comment, siteId)),
      })
    );
  };

  const determineIconParameters = (
    incident: Incident
  ): ParametersSwitch | undefined => {
    if (incident.sentinelType === SentinelType.DOOR_SIREN_CHECK) {
      return {
        onOff:
          incident.resolved === false
            ? ParametersSwitchOnOff.OFF
            : ParametersSwitchOnOff.ON,
      };
    }
    return undefined;
  };

  const renderChart = (incident: Incident, timezone: string | undefined) => {
    const sensorId = incident.sensorParams?.[0]?.sensorId;
    const sensorType = incident.sensorParams?.[0]?.type;
    const equipmentId = incident.sensorParams?.[0]?.resourceId;

    switch (incident.sentinelType) {
      case SentinelType.ACTIVE_POWER_THRESHOLD: {
        return (
          <NotificationEnergyChartContainer
            siteId={siteId}
            sensorId={sensorId}
            equipmentId={equipmentId}
            locale={locale}
            featureIncident={incident}
            isMobile={isMobile}
          />
        );
      }

      case SentinelType.THERMOSTAT_LOCAL_OVERRIDE_CHECK: {
        return (
          <NotificationThermostatChartContainer featureIncident={incident} />
        );
      }

      case SentinelType.CUSTOMER_COMFORT:
      case SentinelType.TEMPERATURE_AND_DOOR:
      case SentinelType.BINARY_COUNTER:
      case SentinelType.BINARY:
      case SentinelType.HUMIDITY:
      case SentinelType.TEMPERATURE: {
        return (
          <NotificationOperationalSensorChartContainer
            sensorId={sensorId}
            sensorType={sensorType}
            locale={locale}
            featureIncident={incident}
            ianaTimeZoneCode={timezone}
          />
        );
      }

      default:
        return null;
    }
  };

  const normalNumberOfGridColumns =
    pageType === IncidentsListPageType.MULTI_SITE ? 20 : 18;
  const numberOfGridColumns = isMobile ? 12 : normalNumberOfGridColumns;
  ///*** End Local vars + Functions ***///

  ///*** CardList Data ***///
  const data: CardListRowData[] = incidents.map((incident) => {
    const site: Site | undefined = sitesById[incident.siteId];
    const siteTitle = site?.title || global.NOT_AVAILABLE;
    const siteTimezone: string | undefined = site?.timeZone;

    const sensorId = incident.sensorId ? incident.sensorId : undefined;
    const sensorType: SensorType = pathOr(
      undefined,
      ['sensorParams', 0, 'type'],
      incident
    );
    const sentinelType = incident.sentinelType;

    const hasChartData = doesNotificationHaveChartData(incident.sentinelType);

    const priorityLevel = incident.incidentPriority;

    const extraContent =
      sensorId && sensorType && hasChartData
        ? [
            <Cell
              width={String(numberOfGridColumns)}
              className={styles.extraContent}
            >
              <div className={styles.chartPadding}>
                <ErrorBoundary>
                  {renderChart(incident, siteTimezone)}
                </ErrorBoundary>
              </div>
              {isMobile && (
                <Button onClick={() => openDismissModal(incident.incidentId)}>
                  Dismiss
                </Button>
              )}
            </Cell>,
          ]
        : [
            <Cell
              width={String(numberOfGridColumns)}
              className={styles.extraContent}
            >
              {isMobile && (
                <Button onClick={() => openDismissModal(incident.incidentId)}>
                  Dismiss
                </Button>
              )}
            </Cell>,
          ];

    if (isMobile) {
      return {
        status: incident.incidentPriority.toLowerCase() as CardListStatus,
        key: `Incident-Card-${incident.incidentId}`,
        dataObject: incident,
        content: (
          <>
            <Cell width="9">
              <div className={styles.iconAndTitle}>
                <div className={styles[`icon--${priorityLevel.toLowerCase()}`]}>
                  <IconSensorTypeFactory
                    size={20}
                    id={incident.sentinelType}
                    parameters={determineIconParameters(incident)}
                  />
                </div>
                <div className={styles.equipmentTitle}>
                  {sensorId ? (
                    <SensorResource
                      sensorId={sensorId}
                      sentinelType={sentinelType}
                      maxStringLength={80}
                      equipmentTitleClassName={
                        styles.mobileEquipmentTitleClassName
                      }
                      showSiteName
                    />
                  ) : (
                    <span className={styles.mobileEquipmentTitleClassName}>
                      {global.NOT_AVAILABLE}
                    </span>
                  )}
                </div>
              </div>
              <div className={styles.mostRecentValue}>
                {renderMostRecentValue(incident)}
              </div>
            </Cell>
            <Cell
              width="3"
              cellAlign="right"
              className={styles.mobileResolvedTextContainer}
            >
              {renderStatus(incident.resolved)}
            </Cell>
            <Cell width="13">
              <div className={styles.hr} />
            </Cell>
            <Cell width="7">
              <div className={styles.sentinelTitle}>
                {incident.sentinelTitle}
              </div>
              <div className={styles.sentinelDescription}>
                <ShortenedSpan
                  maxStringLength={80}
                  content={incident.sentinelDescription}
                />
              </div>
            </Cell>
            <Cell width="6" cellAlign="right">
              <div className={styles.duration}>
                {'Duration: ' +
                  renderDuration(incident.createdAt, incident.recoveredAt)}
              </div>
            </Cell>
            <Cell width="10">
              <div className={styles.startTime}>
                {renderTime(
                  isMobile,
                  incident.createdAt,
                  locale,
                  'left',
                  'Start Time: ',
                  site?.timeZone
                )}
              </div>
              <div className={styles.endTime}>
                {renderTime(
                  isMobile,
                  incident.recoveredAt,
                  locale,
                  'left',
                  'End Time: ',
                  site?.timeZone
                )}
              </div>
            </Cell>
            <Cell
              width="3"
              cellAlign="right"
              className={styles.priorityBadgesContainer}
            >
              <div className={styles.priorityBadges}>
                {Object.keys(SentinelPriorityLevel)
                  .filter(
                    (level) => incident.notificationCountsByPriority[level] > 0
                  )
                  .map((level) => (
                    <div
                      className={classNames(
                        styles.badge,
                        styles[`badge--${level.toLowerCase()}`]
                      )}
                    >
                      {incident.notificationCountsByPriority[level]}
                    </div>
                  ))}
              </div>
            </Cell>
          </>
        ),
        startExpanded: reviewMode,
        extraContent,
      };
    }

    return {
      status: incident.incidentPriority.toLowerCase() as CardListStatus,
      key: `Incident-Card-${incident.incidentId}`,
      dataObject: incident,
      content: (
        <>
          <Cell width="1">
            <div className={styles[`icon--${priorityLevel.toLowerCase()}`]}>
              <IconSensorTypeFactory
                size={20}
                id={incident.sentinelType}
                parameters={determineIconParameters(incident)}
              />
            </div>
          </Cell>
          <Cell width="2">
            <div>
              {sensorId ? (
                <SensorResource
                  sensorId={sensorId}
                  sentinelType={sentinelType}
                  maxStringLength={20}
                />
              ) : (
                global.NOT_AVAILABLE
              )}
            </div>

            {renderMostRecentValue(incident)}
          </Cell>
          {pageType === IncidentsListPageType.MULTI_SITE && (
            <Cell width="2">{siteTitle}</Cell>
          )}
          <Cell width="2">
            <div className={styles.sentinelTitle}>{incident.sentinelTitle}</div>
            <div className={styles.sentinelDescription}>
              <ShortenedSpan
                maxStringLength={80}
                content={incident.sentinelDescription || ''}
              />
            </div>
          </Cell>
          <Cell cellAlign="right" width="1">
            {incident.notificationCountsByPriority[
              SentinelPriorityLevel.NORMAL
            ] || global.NOT_AVAILABLE}
          </Cell>
          <Cell cellAlign="right" width="1">
            {incident.notificationCountsByPriority[
              SentinelPriorityLevel.MEDIUM
            ] || global.NOT_AVAILABLE}
          </Cell>
          <Cell cellAlign="right" width="1">
            {incident.notificationCountsByPriority[
              SentinelPriorityLevel.HIGH
            ] || global.NOT_AVAILABLE}
          </Cell>
          <Cell cellAlign="right" width="1">
            {incident.notificationCountsByPriority[
              SentinelPriorityLevel.HIGHEST
            ] || global.NOT_AVAILABLE}
          </Cell>
          <Cell cellAlign="right" width="2">
            {renderTime(
              isMobile,
              incident.createdAt,
              locale,
              'right',
              '',
              site?.timeZone,
              true
            )}
          </Cell>
          <Cell cellAlign="right" width="2">
            {renderTime(
              isMobile,
              incident.recoveredAt,
              locale,
              'right',
              '',
              site?.timeZone,
              true
            )}
          </Cell>
          <Cell cellAlign="right" width="2">
            <div style={{ textAlign: 'right' }}>
              {renderDuration(incident.createdAt, incident.recoveredAt)}
            </div>
          </Cell>
          <Cell cellAlign="center" width="2">
            {renderStatus(incident.resolved)}
          </Cell>
          <Cell width="1">
            <div className={styles.dismissCheckboxContainer}>
              <Checkbox
                //incidentId refers to the incident's most recent notificationId
                id={incident.incidentId}
                checked={isDefined(incident.dismissedAt)}
                disabled={isDefined(incident.dismissedAt)}
                onChange={() => openDismissModal(incident.incidentId)}
                size={20}
              />
            </div>
          </Cell>
        </>
      ),
      startExpanded: reviewMode,
      extraContent:
        sensorId && sensorType && hasChartData
          ? [
              <Cell
                width={
                  pageType === IncidentsListPageType.MULTI_SITE ? '20' : '18'
                }
              >
                <div className={styles.chartPadding}>
                  <ErrorBoundary>
                    {renderChart(incident, siteTimezone)}
                  </ErrorBoundary>
                </div>
              </Cell>,
            ]
          : undefined,
    };
  });
  ///*** CardList Data ***///

  ///*** CardList Headers ***///
  const groupHeaders: CardListGroupHeader[] = [
    {
      width: pageType === IncidentsListPageType.MULTI_SITE ? '7' : '5',
      content: '',
    },
    {
      width: '4',
      content: 'Notifications Sent',
      className: styles.groupHeader,
    },
    {
      width: '7',
      content: '',
    },
  ];
  const singleSiteHeader: CardListHeader<Incident>[] = [
    {
      width: '1',
      content: '',
    },
    {
      width: '2',
      content: 'Equipment/Space',
    },
    {
      width: '2',
      content: 'Incident',
    },
    {
      width: '1',
      align: 'center',
      content: 'Low',
      defaultSortDirection: SortDirection.DESC,
      comparator: (a, b, sortDirection) => {
        const getValue = (i: Incident) => {
          return pathOr(
            undefined,
            [SentinelPriorityLevel.NORMAL],
            i.notificationCountsByPriority
          );
        };
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          getValue
        );
      },
    },
    {
      width: '1',
      align: 'center',
      content: 'Med',
      defaultSortDirection: SortDirection.DESC,
      comparator: (a, b, sortDirection) => {
        const getValue = (i: Incident) => {
          return pathOr(
            undefined,
            [SentinelPriorityLevel.MEDIUM],
            i.notificationCountsByPriority
          );
        };
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          getValue
        );
      },
    },
    {
      width: '1',
      align: 'center',
      content: 'High',
      defaultSortDirection: SortDirection.DESC,
      comparator: (a, b, sortDirection) => {
        const getValue = (i: Incident) => {
          return pathOr(
            undefined,
            [SentinelPriorityLevel.HIGH],
            i.notificationCountsByPriority
          );
        };
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          getValue
        );
      },
    },
    {
      width: '1',
      align: 'center',
      content: 'Crit',
      defaultSortDirection: SortDirection.DESC,
      comparator: (a, b, sortDirection) => {
        const getValue = (i: Incident) => {
          return pathOr(
            undefined,
            [SentinelPriorityLevel.HIGHEST],
            i.notificationCountsByPriority
          );
        };
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          getValue
        );
      },
    },
    {
      width: '2',
      align: 'right',
      content: 'Start Time',
      defaultSortDirection: SortDirection.DESC,
      comparator: (a, b, sortDirection) => {
        const getValue = (i: Incident) => {
          return Date.parse(i.createdAt);
        };
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          getValue
        );
      },
    },
    {
      width: '2',
      align: 'right',
      content: 'End Time',
      defaultSortDirection: SortDirection.DESC,
      comparator: (a, b, sortDirection) => {
        const getValue = (i: Incident) => {
          if (!i.recoveredAt) return undefined;
          return Date.parse(i.recoveredAt);
        };
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          getValue
        );
      },
    },
    {
      width: '2',
      align: 'right',
      content: 'Duration',
      defaultSortDirection: SortDirection.DESC,
      comparator: (a, b, sortDirection) => {
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          getDurationInMillisecond
        );
      },
    },
    {
      width: '2',
      align: 'center',
      content: 'Status',
      isDefaultSortColumn: reviewSort,
      comparator: (a, b, sortDirection) => {
        const getStatus = (i: Incident) => {
          if (i.resolved === true) {
            return 'Resolved';
          }
          return 'Active';
        };
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          getStatus,
          getDurationInMillisecond
        );
      },
    },
    {
      align: 'center',
      width: '1',
      content: 'Dismiss',
    },
  ];

  const multiSiteHeader: CardListHeader<Incident>[] = [
    ...singleSiteHeader.slice(0, 2),
    {
      width: '2',
      content: 'Site',
      comparator: (a, b, sortDirection) => {
        const getSiteName = (i: Incident) => {
          return pathOr(undefined, [i.siteId, 'title'], sitesById);
        };
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          getSiteName
        );
      },
    },
    ...singleSiteHeader.slice(2),
  ];

  const mobileHeader: CardListHeader<Incident>[] = [
    {
      width: '6',
      content: 'Incident',
    },
    {
      width: '3',
      content: 'Start Time',
    },
    {
      width: '3',
      content: 'End Time',
    },
  ];

  const normalHeader =
    pageType === IncidentsListPageType.MULTI_SITE
      ? multiSiteHeader
      : singleSiteHeader;
  const header = isMobile ? undefined : normalHeader;

  const { currentPage, setPagination, rowLimit } = usePaginationFilter(
    incidents?.length
  );
  ///*** End CardList Headers ***///

  return (
    <>
      <CardList
        noDefaultSortColumn={!reviewSort}
        numberOfGridColumns={numberOfGridColumns}
        data={data}
        header={header}
        headerClassName={isMobile ? styles.mobileHeader : ''}
        pageSelectTopRef={listPageRef}
        groupHeaders={showGroupHeaders ? groupHeaders : undefined}
        currentPageFromPaginationHook={currentPage}
        setPagination={setPagination}
        renderLimit={rowLimit}
        headerTitle={'Incidents'}
      />
    </>
  );
};

//Local functions
const getEquipmentId = (resourcePathsById: PathsById, sensorId?: string) => {
  const resourcePath: ResourcePath[] = pathOr(
    [],
    [sensorId],
    resourcePathsById
  );
  const equipment = resourcePath.find((p) => p.type === ResourceType.EQUIPMENT);
  if (equipment !== undefined) return equipment.id;
  return -1;
};

export const renderTime = (
  isMobile: boolean,
  isoString: string | undefined,
  locale: Locale,
  textAlign: 'left' | 'right',
  prependText: string = '',
  ianaTimeZoneCode?: string,
  showTimezone?: boolean
) => {
  if (!isoString) return prependText + global.NOT_AVAILABLE;

  const date = parseISO(isoString);
  const formattedDate = determineTimeStringByTimezone(
    date,
    locale.dateFormat,
    ianaTimeZoneCode
  );
  const formattedTime = determineTimeStringByTimezone(
    date,
    locale.timeFormat,
    ianaTimeZoneCode,
    showTimezone
  );
  return (
    <div
      className={textAlign === 'left' ? styles.leftAlign : styles.rightAlign}
    >
      {isMobile ? (
        <div className={styles.title}>
          {prependText} {formattedTime} {formattedDate}
        </div>
      ) : (
        <>
          <div className={styles.title}>{formattedTime}</div>
          <div>{formattedDate}</div>
        </>
      )}
    </div>
  );
};

const renderDuration = (
  createdAt: string | undefined,
  recoveredAt: string = new Date().toISOString()
): string => {
  if (!createdAt || !recoveredAt) {
    return global.NOT_AVAILABLE;
  }
  const createdDate = parseISO(createdAt);
  const recoveredDate = parseISO(recoveredAt);
  const duration = formatDistance(recoveredDate, createdDate);
  return duration;
};

const renderStatus = (status: boolean) => {
  if (status === false) {
    return <div className={styles.activeText}>Active</div>;
  } else if (status === true) {
    return <div className={styles.resolvedText}>Resolved</div>;
  } else return global.NOT_AVAILABLE;
};

const renderMostRecentValue = (incident: Incident) => {
  const acceptableSentinelTypes = [
    SentinelType.TEMPERATURE,
    SentinelType.HUMIDITY,
    SentinelType.BINARY,
    SentinelType.CONNECTIVITY,
    SentinelType.GATEWAY_ONLINE_STATUS,
    SentinelType.ENERGYPRO_ONLINE_STATUS,
  ];
  const isAcceptableSentinelType = acceptableSentinelTypes.includes(
    incident.sentinelType
  );
  const isIncidentActive = incident.resolved === false;
  const { sensorId, sentinelType } = incident;

  if (isIncidentActive && isAcceptableSentinelType && sensorId) {
    return (
      <div className={styles.currentValue}>
        <div className={styles.currentValueTitle}>
          Most Recent Value:{' '}
          <IncidentValueChip sensorId={+sensorId} sentinelType={sentinelType} />
        </div>
      </div>
    );
  }

  return null;
};

const getDurationInMillisecond = (i: Incident) => {
  if (!i.createdAt || !i.recoveredAt) return undefined;
  const createdDate = new Date(i.createdAt);
  const recoveredDate = new Date(i.recoveredAt);
  return recoveredDate.getTime() - createdDate.getTime();
};

export default IncidentsCardList;
