import { Actions as AppActions } from '../actions/constants/Actions';
import { getCurrentUser } from '../actions/app';

const dashboardMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case AppActions.CREATE_DASHBOARD_SUCCESS:
    case AppActions.PATCH_DASHBOARD_SUCCESS:
    case AppActions.DELETE_DASHBOARD_SUCCESS:
      store.dispatch(getCurrentUser());
      return next(action);

    default:
      return next(action);
  }
};

export default dashboardMiddleware;
