import { Actions } from '../actions/features';
import { assocPath, pipe } from 'ramda';
import { FeaturesReducer, SiteLevelFeatures, SiteNav } from './types/features';
import { Tiles } from '@energybox/react-ui-library/dist/types';

const initialState: FeaturesReducer = {
  featuresBySiteId: {},
  isLoadingBySiteId: {},
  featuresByOrganizationId: {},
  isLoadingByOrganizationId: {},
};

export default (state: FeaturesReducer = initialState, action: any) => {
  switch (action.type) {
    case Actions.AVAILABLE_FEATURES_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath<boolean, FeaturesReducer>(
          ['isLoadingBySiteId', action.siteId],
          false
        ),
        assocPath<SiteLevelFeatures, FeaturesReducer>(
          ['featuresBySiteId', action.siteId],
          {
            SITE_NAV: [...action.payload.SITE_NAV],
            SITE_COMPONENT: [...action.payload.SITE_COMPONENT],
            SITE_CARD: [
              ...action.payload.SITE_CARD,
              Tiles.NonOperatingHourEfficiencyCard,
            ].concat(
              action.payload.SITE_NAV.includes(SiteNav.TASK_TEMP_REVIEW)
                ? [Tiles.RecommendationsCard]
                : []
            ),
          }
        )
      )(state);

    case Actions.AVAILABLE_FEATURES_BY_SITE_ID_LOADING: {
      return assocPath<boolean, FeaturesReducer>(
        ['isLoadingBySiteId', action.siteId],
        true
      )(state);
    }

    case Actions.AVAILABLE_FEATURES_BY_SITE_ID_ERROR:
      return assocPath<boolean, FeaturesReducer>(
        ['isLoadingBySiteId', action.siteId],
        false
      )(state);

    case Actions.AVAILABLE_FEATURES_BY_ORGANIZATION_ID_SUCCESS:
      return pipe(
        assocPath<boolean, FeaturesReducer>(
          ['isLoadingByOrganizationId', action.organizationId],
          false
        ),
        assocPath<SiteLevelFeatures, FeaturesReducer>(
          ['featuresByOrganizationId', action.organizationId],
          {
            SITE_NAV: [...action.payload.SITE_NAV],
            SITE_COMPONENT: [...action.payload.SITE_COMPONENT],
            SITE_CARD: [
              ...action.payload.SITE_CARD,
              Tiles.MajorIssuesCard,
              Tiles.EquipmentIssuesCard,
              Tiles.RecommendationsCard,
              Tiles.NonOperatingHourEfficiencyCard,
            ],
          }
        )
      )(state);

    case Actions.AVAILABLE_FEATURES_BY_ORGANIZATION_ID_LOADING: {
      return assocPath<boolean, FeaturesReducer>(
        ['isLoadingByOrganizationId', action.organizationId],
        true
      )(state);
    }

    case Actions.AVAILABLE_FEATURES_BY_ORGANIZATION_ID_ERROR:
      return assocPath<boolean, FeaturesReducer>(
        ['isLoadingByOrganizationId', action.organizationId],
        false
      )(state);

    default: {
      return state;
    }
  }
};
