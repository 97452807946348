// TODO: move all of this into the "filters" action file
import {
  FilterTimePeriod,
  NotificationPriority,
  NotificationStatus,
} from '@energybox/react-ui-library/dist/types';

export enum Actions {
  STATUS = '@@notificationFilter/STATUS',
  DATE = '@@notificationFilter/DATE',
  EQUIPMENT = '@@notificationFilter/EQUIPMENT',
  PRIORITY = '@@notificationFilter/PRIORITY',
}

export interface SetNotificationPriorityFilter {
  (notificationPriority: NotificationPriority[]): void;
}

export const setNotificationPriorityFilter = (priorities: string[]) => {
  return {
    type: Actions.PRIORITY,
    payload: priorities,
  };
};

export interface SetNotificationStatusFilter {
  (notificationStatus: NotificationStatus[]): void;
}
export const setNotificationStatusFilter = (statuses: string[]) => ({
  type: Actions.STATUS,
  payload: statuses,
});

export interface SetNotificationDateFilter {
  (timePeriod: FilterTimePeriod): any;
}
export const setNotificationDateFilter: SetNotificationDateFilter = (
  dateFilter: FilterTimePeriod
) => ({
  type: Actions.DATE,
  payload: dateFilter,
});

export const setNotificationEquipmentFilter = (equipmentIds: number[]) => ({
  type: Actions.EQUIPMENT,
  payload: equipmentIds,
});
