import React from 'react';
import styles from './TileHeaderMoreInfoContent.module.css';

interface Props {
  howToUseContent?: React.ReactNode;
  howCalculatedContent?: React.ReactNode;
  extraHowTos?: [{ title: string; content: React.ReactNode }];
}

const TileHeaderMoreInfoContent = ({
  howToUseContent,
  howCalculatedContent,
  extraHowTos
}: Props) => {
  return (
    <div className={styles.container}>
      {howToUseContent !== undefined && (
        <>
          <div className={styles.sectionHeader}>How to use this tile</div>
          <div className={styles.sectionContent}>{howToUseContent}</div>
        </>
      )}
      {howCalculatedContent !== undefined && (
        <>
          <div className={styles.sectionHeader}>How is this calculated?</div>
          <div className={styles.sectionContent}>{howCalculatedContent}</div>
        </>
      )}
      {extraHowTos !== undefined &&
        extraHowTos.map(({ title, content }) => (
          <>
            <div className={styles.sectionHeader}>{title}</div>
            <div className={styles.sectionContent}>{content}</div>
          </>
        ))}
    </div>
  );
};

export default TileHeaderMoreInfoContent;
