import {
  Site,
  SiteAstronomicalClock,
  SitesById,
} from '@energybox/react-ui-library/dist/types';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import merge from 'ramda/src/merge';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/files';

export type File = {
  version?: string | number;
  fileSize?: number;
  fileId: string;
  fileName: string;
  fileType?: string | number;
  fileLocation: string;
  createdAt: string;
  modifiedAt: string;
  metadata: {
    jobId: string;
    message?: string;
    reportTemplate?: string;
  };
  recent?: any;
};

const initialState = {
  allDownloadFiles: [],
  initiateDownload: false,
  deleteFile: false,
};

export type Files = {
  allDownloadFiles: any;
  initiateDownload: any;
  deleteFile: any;
};

export default (state: Files = initialState, action: any) => {
  switch (action.type) {
    case Actions.MY_DOWNLOADS_LOADING:
      return {
        ...state,
        allDownloadFiles: state.allDownloadFiles,
      };

    case Actions.MY_DOWNLOADS_SUCCESS:
      return {
        ...state,
        allDownloadFiles: action.payload,
      };

    case Actions.MY_DOWNLOADS_ERROR:
      return {
        ...state,
        allDownloadFiles: action.payload,
      };

    case Actions.INITIATE_DOWNLOAD_FILE: {
      return {
        ...state,
        initiateDownload: action.payload,
      };
    }

    case Actions.DELETE_FILES_LOADING:
      return {
        ...state,
        deleteFile: false,
      };
    case Actions.DELETE_FILES_SUCCESS:
      return {
        ...state,
        deleteFile: true,
      };
    case Actions.DELETE_FILES_ERROR:
      return {
        ...state,
        deleteFile: false,
      };

    case Actions.TOGGLE_DELETE_STATE:
      return {
        ...state,
        deleteFile: action.payload,
      };

    default:
      return state;
  }
};
