import { DefaultSite } from '@energybox/react-ui-library/dist/icons';
import { Site } from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import { getConfig, Service } from '../../config';
import styles from './SiteImage.module.css';

const IMAGE_CDN = getConfig().serviceUrls[Service.cdn];

export enum siteImageSize {
  TINY = 'tiny',
  SMALL = 'small',
  MEDIUM = 'medium',
}

const SiteImage = ({
  site,
  size,
  className,
  imageClassName,
}: {
  site: Site;
  size?: siteImageSize;
  className?: string;
  imageClassName?: string;
}) => {
  const image =
    site.images &&
    site.images.length > 0 &&
    site.images[site.images.length - 1];

  let imageSize, placeholderSize;
  switch (size) {
    case siteImageSize.TINY:
      imageSize = styles.imageSizeTiny;
      placeholderSize = 70;
      break;
    case siteImageSize.SMALL:
      imageSize = styles.imageSizeSmall;
      placeholderSize = 90;
      break;
    case siteImageSize.MEDIUM:
    default:
      imageSize = styles.imageSizeMedium;
      placeholderSize = 140;
  }

  if (image)
    return (
      <div className={classNames(styles.root, className)}>
        <img
          className={classNames(styles.image, imageSize, imageClassName)}
          src={`${IMAGE_CDN}/${image}`}
          alt=""
        />
      </div>
    );

  return (
    <div className={classNames(styles.placeholderContainer, className)}>
      <DefaultSite
        size={placeholderSize}
        color={'var(--accent-base)'}
        className={imageClassName}
      />
    </div>
  );
};

export default SiteImage;
