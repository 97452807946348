import {
  SentinelType,
  sentinelConfigurations,
} from '@energybox/react-ui-library/dist/types';
import { GenericFilter } from '@energybox/react-ui-library/dist/components';
import useDynamicFilter from '../../../hooks/useDynamicFilter';
import { useEffect } from 'react';
import useNotificationsDynamicFilter from '../../../hooks/useNotificationsDynamicFilter';

type Props = {
  setFilter?: (sentinels: SentinelType[]) => void;
  sentinels: SentinelType[];
  disabled?: boolean;
  className?: string;
};

const SentinelTypeFilter = ({ setFilter, sentinels, disabled }: Props) => {
  const {
    setNotificationsFilter: setSentinelTypeFilter,
    filtersFromUrl: selectedSentinelTypesFromUrl,
  } = useNotificationsDynamicFilter('sentinel', setFilter);

  return (
    <GenericFilter
      title="Sentinel Type"
      setFilter={setSentinelTypeFilter}
      items={sentinels}
      selectedItems={selectedSentinelTypesFromUrl}
      disabled={disabled}
      transformItemName={(sentinel: SentinelType) =>
        sentinelConfigurations[sentinel]?.title ||
        // Handle special cases: EnergyProOnlineStatus and GatewayOnlineStatus fallback to Connectivity
        ((sentinel === SentinelType.ENERGYPRO_ONLINE_STATUS ||
          sentinel === SentinelType.GATEWAY_ONLINE_STATUS) &&
          'Connectivity') ||
        sentinel.replace(/([A-Z])/g, ' $1').trim()
      }
    />
  );
};

export default SentinelTypeFilter;
