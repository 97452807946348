import {
  AggregationLevel,
  Site,
  SitesApiFilter,
} from '@energybox/react-ui-library/dist/types';
import { fetchApi } from '../utils/fetchApi';
import { Service } from '../config';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { ReportType, ToReportRequestBody } from '../types/reportworker';
const apiBase = '/api/v2/jobs/toPdf';
const batchApi = '/api/v2/jobs/batch/toPdf';
const toReportApi = '/api/v2/jobs/toReport';
const globalReportApi = '/api/v2/jobs/global-report';

export enum Actions {
  REPORT_WORKER_SUCCESS = '@@report/REPORT_WORKER_SUCCESS',
  REPORT_WORKER_LOADING = '@@report/REPORT_WORKER_LOADING',
  REPORT_WORKER_ERROR = '@@report/REPORT_WORKER_ERROR',
  SET_JOBID_LOADING = '@@report/SET_JOBID_LOADING',
  SET_JOBID_SUCCESS = '@@report/SET_JOBID_SUCCESS',
  SET_JOBID_ERROR = '@@report/SET_JOBID_ERROR',
  NOTIFIY_DOWNLOAD_STATUS = '@@report/NOTIFIY_DOWNLOAD_STATUS',
  SET_DOWNLOAD_FILE_COUNTER = '@@report/SET_DOWNLOAD_FILE_COUNTER',
  SET_DOWNLOAD_COUNTER = '@@report/SET_DOWNLOAD_COUNTER',
  UPDATE_DOWNLOAD_COUNTER = '@@report/UPDATE_DOWNLOAD_COUNTER',
  TOGGLE_HIDE_SHOW_ICON = '@@report/TOGGLE_HIDE_SHOW_ICON',
  UPDATE_REPORT_WORKER_JOBS = '@@report/UPDATE_REPORT_WORKER_JOBS',
  REMOVE_OUTDATED_REPORT_WORKER_JOBS = '@@report/REMOVE_OUTDATED_REPORT_WORKER_JOBS',
  GET_FILE_IDS = '@@report/GET_FILE_IDS',
  SAVE_FILE_IDS = '@@report/SAVE_FILE_IDS',
  REMOVE_FILE_IDS = '@@report/REMOVE_FILE_IDS',
  INCREMENT_FILE_COUNT = '@@report/INCREMENT_FILE_COUNT',
  DECREMENT_FILE_COUNT = '@@report/DECREMENT_FILE_COUNT',
  LATEST_FILE_COUNT = '@@report/LATEST_FILE_COUNT',
  INITIATE_FILE_DOWNLOAD = '@@/report/INITIATE_FILE_DOWNLOAD',
  DEINITIATE_FILE_DOWNLOAD = '@@/report/DEINITIATE_FILE_DOWNLOAD',
  GET_INITIATE_FILE_DOWNLOAD = '@@/report/GET_INITIATE_FILE_DOWNLOAD',

  SAVE_EQUIP_DATA_FOR_DOWNLOAD = '@@/report/SAVE_EQUIP_DATA_FOR_DOWNLOAD',
  GET_EQUIP_DATA_FOR_DOWNLOAD = '@@/report/GET_EQUIP_DATA_FOR_DOWNLOAD',
  CLEAR_EQUIP_DATA_FOR_DOWNLOAD = '@@/report/CLEAR_EQUIP_DATA_FOR_DOWNLOAD',

  REPORT_WORKER_TO_REPORT_SUCCESS = '@@report/REPORT_WORKER_TO_REPORT_SUCCESS',
  REPORT_WORKER_TO_REPORT_LOADING = '@@report/REPORT_WORKER_TO_REPORT_LOADING',
  REPORT_WORKER_TO_REPORT_ERROR = '@@report/REPORT_WORKER_TO_REPORT_ERROR',
}

export type ReportWorkerParam = {
  siteId: string | number;
  reportTemplate: string;
  reportOrgImage: React.ReactNode;
  toDateString?: string;
  fromDateString?: string;
  equipmentId?: string | number;
  reportId?: string | number;
  aggregation?: AggregationLevel;
};

export type BatchReportParam = {
  siteId: string | number;
  reportTemplate: string;
  reportOrgImage: React.ReactNode;
  equipmentId: number[];
  toDateString?: string;
  fromDateString?: string;
  reportId?: string | number;
};

export const getReportWorker = (reportWorkerParam: ReportWorkerParam) => {
  const {
    siteId,
    reportTemplate,
    reportOrgImage,
    toDateString,
    fromDateString,
    equipmentId,
    reportId,
    aggregation,
  } = reportWorkerParam;

  const payload = {
    siteId: siteId,
    reportTemplate,
    reportOrgImage,
  };

  let optional = {};
  if (isDefined(equipmentId)) {
    optional = {
      equipmentId: equipmentId,
    };
  }
  if (isDefined(toDateString) && isDefined(fromDateString)) {
    optional = Object.assign(optional, {
      reportToDate: toDateString,
      reportFromDate: fromDateString,
    });
  }
  if (isDefined(reportId)) {
    optional = Object.assign(optional, {
      reportId: reportId,
    });
  }
  if (isDefined(aggregation)) {
    optional = Object.assign(optional, {
      aggregationLevel: aggregation,
    });
  }

  return {
    type: 'API_POST',
    path: `${apiBase}`,
    payload: {
      ...payload,
      ...optional,
    },
    loading: {
      type: Actions.REPORT_WORKER_LOADING,
    },
    success: {
      type: Actions.REPORT_WORKER_SUCCESS,
      // For the purpose of tracking the report generation event
      requestBody: {
        ...payload,
        ...optional,
      },
    },
    error: {
      type: Actions.REPORT_WORKER_ERROR,
    },
    service: Service.reportworker,
  };
};

export const getBatchReport = (param: BatchReportParam) => {
  const {
    siteId,
    reportTemplate,
    reportOrgImage,
    toDateString,
    fromDateString,
    equipmentId,
    reportId,
  } = param;

  const payload = {
    siteId: siteId,
    reportTemplate,
    reportOrgImage,
    downloadType: 'PDF_MERGE',
  };

  let optional = {
    ...(isDefined(equipmentId) ? { equipmentList: equipmentId } : {}),
    ...(isDefined(toDateString) && isDefined(fromDateString)
      ? {
          reportToDate: toDateString,
          reportFromDate: fromDateString,
        }
      : {}),
    ...(isDefined(reportId) ? { reportId: reportId } : {}),
  };

  return {
    type: 'API_POST',
    path: `${batchApi}`,
    payload: {
      ...payload,
      ...optional,
    },
    loading: {
      type: Actions.REPORT_WORKER_LOADING,
    },
    success: {
      type: Actions.REPORT_WORKER_SUCCESS,
      // For the purpose of tracking the report generation event
      requestBody: {
        ...payload,
        ...optional,
      },
    },
    error: {
      type: Actions.REPORT_WORKER_ERROR,
    },
    service: Service.reportworker,
  };
};

// Save job ID and file name to store
export const setJobID = (jobID: any) => {
  return {
    type: Actions.SET_JOBID_SUCCESS,
    payload: jobID,
  };
};

/* 
Notify the downloado status on top bar 
- This is to indicate the gif icon to stop animation if the file is downloaded successfully or failed to download
*/
export const notifyDownload = (status: boolean) => {
  return {
    type: Actions.NOTIFIY_DOWNLOAD_STATUS,
    payload: status,
  };
};

//  Set File Counter
export const setDownloadedFileCounter = (count: number) => {
  return {
    type: Actions.SET_DOWNLOAD_FILE_COUNTER,
    payload: count,
  };
};

export const toggleShowHideIcon = (status: boolean) => {
  return {
    type: Actions.TOGGLE_HIDE_SHOW_ICON,
    payload: status,
  };
};

export const getFileIDs = () => {
  return {
    type: Actions.GET_FILE_IDS,
  };
};

export const saveFileIDs = (jobID: string) => {
  return {
    type: Actions.SAVE_FILE_IDS,
    payload: jobID,
  };
};

export const removeFileIDs = (jobID: string) => {
  return {
    type: Actions.REMOVE_FILE_IDS,
    payload: jobID,
  };
};

//  File Counter on new file or delete & download  file
export const latestFileCount = () => {
  return {
    type: Actions.LATEST_FILE_COUNT,
  };
};

export const incrementFileCount = () => {
  return {
    type: Actions.INCREMENT_FILE_COUNT,
    payload: 1,
  };
};

export const decrementFileCount = () => {
  return {
    type: Actions.DECREMENT_FILE_COUNT,
    payload: 1,
  };
};

// Toggle file downloading initation
export const initiateFileDownload = (status: boolean) => {
  return {
    type: Actions.INITIATE_FILE_DOWNLOAD,
    payload: status,
  };
};

export const deInitiateFileDownload = (status: boolean) => {
  return {
    type: Actions.DEINITIATE_FILE_DOWNLOAD,
    payload: status,
  };
};

export const getInitiateFileDownload = () => {
  return {
    type: Actions.GET_INITIATE_FILE_DOWNLOAD,
  };
};

// Save SaveEquipmentData
export const saveEquipDataForDownload = (getData: any) => {
  return {
    type: Actions.SAVE_EQUIP_DATA_FOR_DOWNLOAD,
    payload: getData,
  };
};
export const getEquipDataForDownload = () => {
  return {
    type: Actions.GET_EQUIP_DATA_FOR_DOWNLOAD,
  };
};
export const clearEquipDataForDownload = () => {
  return {
    type: Actions.CLEAR_EQUIP_DATA_FOR_DOWNLOAD,
  };
};

export const updateReportWorkerJobs = (job: any) => {
  return {
    type: Actions.UPDATE_REPORT_WORKER_JOBS,
    payload: job,
  };
};

export const removeOutdatedReportWorkerJobs = () => {
  return {
    type: Actions.REMOVE_OUTDATED_REPORT_WORKER_JOBS,
  };
};

export const triggerToReport = (params: {
  page?: string;
  reportType: ReportType;
  requestBody: ToReportRequestBody;
}) => {
  return {
    type: 'API_POST',
    path: `${toReportApi}?reportType=${params.reportType}`,
    payload: {
      ...params.requestBody,
    },
    loading: {
      type: Actions.REPORT_WORKER_LOADING,
      page: params.page,
    },
    success: {
      type: Actions.REPORT_WORKER_SUCCESS,
      page: params.page,
      // For the purpose of tracking the report generation event
      requestBody: {
        ...params.requestBody,
      },
    },
    error: {
      type: Actions.REPORT_WORKER_ERROR,
      page: params.page,
    },
    service: Service.reportworker,
  };
};

export const globalReport = (params: {
  reportTemplate: string;
  organizationId: number;
  periodType?: string;
  reportFromDate: string;
  yearlyTarget?: number;
  energyUnit?: string;
  reportOrgImage?: React.ReactNode;
  searchFilter?: {
    siteIds?: number[];
  };
}) => {
  return {
    type: 'API_POST',
    path:
      params.reportTemplate === 'ORG_ENERGY_PERFORMANCE'
        ? `${apiBase}`
        : `${globalReportApi}`,
    payload: {
      ...params,
    },
    loading: {
      type: Actions.REPORT_WORKER_LOADING,
    },
    success: {
      type: Actions.REPORT_WORKER_SUCCESS,
      // For the purpose of tracking the report generation event
      requestBody: {
        ...params,
      },
    },
    error: {
      type: Actions.REPORT_WORKER_ERROR,
    },
    service: Service.reportworker,
  };
};
