import React from 'react';
import tileStyles from '../../../styles/tiles.module.css';

type Props = {
  className?: string;
  children: React.ReactNode;
};

const TileContent: React.FC<Props> = ({ className, children }) => {
  return (
    <div
      className={`${tileStyles.contentContainer} ${className ? className : ''}`}
    >
      {children}
    </div>
  );
};

export default TileContent;
