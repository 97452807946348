import React, { useEffect } from 'react';
import {
  DateFilter,
  GenericMultipleFilter,
} from '@energybox/react-ui-library/dist/components';
import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import SiteFilter from '../../../../components/Filters/SiteFilter';
import NotificationsTopNavigationBar from '../../../../components/TopNavigationBar/NotificationsTopNavigationBar';
import useAppLocale from '../../../../hooks/useAppLocale';
import { useTimeFilter } from '../../../../hooks/useFilters';
import tileStyles from '../../../../styles/tiles.module.css';
import {
  DailyNotificationsGraphTile,
  EquipmentTimeInIncidentStateTile,
  EquipmentWithMostIncidentsTile,
  IncidentsByPriorityTile,
  SitesWithMostIncidentsTile,
} from '../../../NotificationsOverviewTiles';
import styles from './NotificationsOverviewPage.module.css';
import SiteGroupFilter from '../../../../components/Filters/SiteGroupFilter';
import { SingleGenericFilter } from '@energybox/react-ui-library/dist/types';
import useSiteGroupsFilter from '../../../../hooks/useSiteGroupsFilter';
import useSiteFilter from '../../../../hooks/useSiteFilter';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../reducers';
import { sortSiteGroupTitles } from '../../../../utils/siteGroups/siteGroups';
import { useHistory } from 'react-router-dom';

const NotificationsOverviewPage = () => {
  const history = useHistory();
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const locale = useAppLocale();
  const { timePeriod, setTimeFilter } = useTimeFilter();

  const { selectedSiteFilters } = useSiteFilter();
  const { setSiteGroupsFilter, selectedSiteGroups } = useSiteGroupsFilter();

  const siteGroupsById = useSelector(
    ({ siteGroups }: ApplicationState) => siteGroups.siteGroupsById ?? {}
  );
  const siteGroupsTitles = sortSiteGroupTitles(siteGroupsById);

  const mobileFilters = [
    {
      title: 'Site',
      customFilter: () => <SiteFilter isInDropdown={true} />,
      selectedItems: selectedSiteFilters,
    },
    {
      title: 'Site Groups',
      setFilter: setSiteGroupsFilter,
      items: siteGroupsTitles,
      selectedItems: selectedSiteGroups,
    },
  ].filter((item) => item) as SingleGenericFilter[];

  useEffect(() => {
    return () => {
      history.replace({ search: '' });
    };
  }, [history]);

  return (
    <div>
      <NotificationsTopNavigationBar path="overview" />

      <div className={styles.pageContainer}>
        <div className={styles.row}>
          {isMobile && (
            <GenericMultipleFilter
              className={styles.mobileSiteAndSiteGroupFilter}
              dropdownClassName={styles.mobileFilterDropdown}
              title="Filters"
              filters={mobileFilters}
            />
          )}
          <DateFilter
            useCurrentTime
            value={timePeriod}
            setFilter={setTimeFilter}
            customPickerVariant={'date'}
            alignItemsRight={isMobile}
            locale={locale}
          />
          {!isMobile && (
            <div className={styles.compactFilterRow}>
              <SiteFilter
                className={styles.siteFilter}
                dropdownClassName={styles.siteFilterDropdown}
                alignItemsRight={isMobile}
              />
              <SiteGroupFilter
                dropdownClassName={styles.siteGroupFilterDropdown}
                className={styles.siteGroupFilterContainer}
              />
            </div>
          )}
        </div>

        <div
          className={`${tileStyles.tilesContainer} ${styles.verticalPadding}`}
        >
          <SitesWithMostIncidentsTile
            className={tileStyles.singleTile}
            timePeriod={timePeriod}
          />

          <DailyNotificationsGraphTile
            className={tileStyles.doubleTile}
            timePeriod={timePeriod}
          />

          <EquipmentWithMostIncidentsTile
            className={tileStyles.singleTile}
            timePeriod={timePeriod}
          />

          <EquipmentTimeInIncidentStateTile
            className={tileStyles.singleTile}
            timePeriod={timePeriod}
          />

          <IncidentsByPriorityTile
            className={tileStyles.singleTile}
            timePeriod={timePeriod}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationsOverviewPage;
