import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearAuthenErrorDuringUpdatePassword,
  updateUserPassword,
} from '../../../actions/app';
import BaseFilterBar from '../../../components/Filters/BaseFilterBar';
import { ApplicationState } from '../../../reducers';
import styles from './ChangePasswordPage.module.css';

import { CurrentUser } from '@energybox/react-ui-library/dist/types';
import { NewPasswordPage } from '@energybox/react-ui-library/dist/components/NewPassword';
import { renderAPIerror } from '../../../utils/apiErrorFeedback';
import { Actions } from '../../../actions/constants/Actions';
import { PasswordChangeError } from '@energybox/react-ui-library/dist/components/NewPassword/NewPasswordPage/NewPasswordPage';

type Props = {
  enforced?: boolean;
};

const ChangePasswordPage: React.FunctionComponent<Props> = ({ enforced }) => {
  const { user, didPasswordChangeFail } = useSelector<
    ApplicationState,
    {
      user: CurrentUser | undefined;
      didPasswordChangeFail: PasswordChangeError;
    }
  >(({ app }) => ({
    user: app.currentUser,
    didPasswordChangeFail: app.didPasswordChangeFail,
  }));

  const [state, setState] = React.useState({
    currentPassword: '',
    password: '',
    passwordVerify: '',
  });

  const dispatch = useDispatch();

  if (!user) {
    return null;
  }

  const onFormSubmit = (ev) => {
    ev.preventDefault();
    dispatch(
      updateUserPassword(
        user.id.toString(),
        state.currentPassword,
        state.password,
        state.passwordVerify
      )
    );
  };

  const onChange = (...args: string[]) => {
    let newState = { ...state };
    for (let i = 1; i < args.length; i += 2) {
      const field = args[i - 1];
      newState[field] = args[i];
      if (field === 'currentPassword') {
        dispatch(clearAuthenErrorDuringUpdatePassword);
      }
    }
    setState(newState);
  };

  return (
    <>
      <BaseFilterBar />
      <div className={styles.content}>
        <NewPasswordPage
          email={user.email || ''}
          currentPassword={state.currentPassword}
          password={state.password}
          passwordVerify={state.passwordVerify}
          currentPasswordIncorrect={didPasswordChangeFail === 'authError'}
          apiError={renderAPIerror(
            didPasswordChangeFail === 'error'
              ? {
                  type: Actions.USER_CHANGE_PASSWORD_ERROR,
                  status: 0,
                  message: 'Password change failed. Try again.',
                }
              : {},
            Actions.USER_CHANGE_PASSWORD_ERROR
          )}
          handleSubmit={onFormSubmit}
          handleChange={onChange}
          changeOldPassword
          enforced={enforced}
        />
      </div>
    </>
  );
};

export default ChangePasswordPage;
