import React from 'react';
import CardTitle from '../../Dashboard/CardTitle/CardTitle';
import TilesMenuDropdown from '../TilesMenuDropdown';

import tileStyles from '../../../styles/tiles.module.css';
import styles from './TileHeader.module.css';

type Props = {
  title: string;
  className?: string;
  isEditMode?: boolean;
  onRemoveTile?: () => void;
  allowShortenedTitle?: boolean;
  extraLeftAlignContent?: React.ReactNode;
  extraRightAlignContent?: React.ReactNode;
  tooltipDescription?: string;
  linkPrompt?: React.ReactNode;
  moreInfo?: React.ReactNode;
  icon?: React.ReactNode;
  arrowDirection?: 'top' | 'left' | 'bottom' | 'right' | 'closeBottom';
  SortTypeRadioButton?: React.ReactNode;
  tileSortType?: string;
};

const TileHeader: React.FC<Props> = ({
  title,
  className,
  isEditMode,
  onRemoveTile,
  allowShortenedTitle,
  tooltipDescription,
  moreInfo,
  extraLeftAlignContent,
  extraRightAlignContent,
  icon,
  arrowDirection,
  linkPrompt,
  SortTypeRadioButton,
  tileSortType,
}) => {
  return (
    <div
      className={`${tileStyles.headerContainer} ${className ? className : ''}`}
    >
      <div className={styles.leftAlign}>
        <div className={styles.titleContainer}>
          {icon && <div className={styles.icon}>{icon}</div>}

          <CardTitle
            title={title}
            allowShortenedTitle={allowShortenedTitle}
            tooltipDescription={tooltipDescription}
            arrowDirection={arrowDirection}
            moreInfo={moreInfo}
            linkPrompt={linkPrompt}
            SortTypeRadioButton={SortTypeRadioButton}
          />
        </div>
        {extraLeftAlignContent}
      </div>

      <div className={styles.rightAlign}>
        {extraRightAlignContent}
        {isEditMode && onRemoveTile ? (
          <TilesMenuDropdown onRemoveTile={onRemoveTile} />
        ) : null}
      </div>
    </div>
  );
};

export default TileHeader;
