import React from 'react';
import styles from './NotificationDismissedChip.module.css';
import { Tooltip } from '@energybox/react-ui-library/dist/components';
import DateChip from './DateChip';

type Props = {
  dismissedBy: string;
  dismissedComment: string;
  dismissedAt: string;
};

const returnFirstAndLastInitials = (name: string) => {
  const stringArray = (name || '').split(' ');
  const returnValue = `${(stringArray[0] || '').substr(0, 1) || ''}${
    (stringArray[1] || '').substr(0, 1) || ''
  }`;
  return returnValue.toUpperCase();
};

const generateCircleTextPair = (dismissedBy: string, dismissedAt: string) => (
  <div className={styles.iconAndTextGrouping}>
    <div className={styles.circle}>
      {returnFirstAndLastInitials(dismissedBy)}
    </div>
    <div className={styles.textGrouping}>
      <div> {dismissedBy} </div>
      <DateChip
        isTimeFrom={false}
        value={dismissedAt}
        extraClassNames={[styles.dismissedAt]}
      />
    </div>
  </div>
);

const generateContent = (
  dismissedBy: string,
  dismissedComment: string,
  dismissedAt: string
) => (
  <div className={styles.dismissedContent}>
    <div className={styles.topText}>
      <span className={styles.overflow}>{dismissedComment}</span>
    </div>
    {generateCircleTextPair(dismissedBy, dismissedAt)}
  </div>
);

const NotificationDismissedChip = ({
  dismissedBy,
  dismissedComment,
  dismissedAt,
}: Props) => (
  <div className={styles.root}>
    <Tooltip
      arrowDirection="bottom"
      content={generateContent(dismissedBy, dismissedComment, dismissedAt)}
    >
      <div>
        {dismissedComment && dismissedComment.length > 7
          ? dismissedComment.slice(0, 6) + '...'
          : dismissedComment}
      </div>
    </Tooltip>
  </div>
);

export default NotificationDismissedChip;
