import { GenericFilter } from '@energybox/react-ui-library/dist/components';
import { IncidentPriority } from '@energybox/react-ui-library/dist/types';
import { capitalizeFirstLetterOnly } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import useDynamicFilter from '../../../hooks/useDynamicFilter';

const IncidentPriorityFilter: React.FC = () => {
  const {
    selectedFilters: selectedIncidentPriorityFilters,
    setFilter: setIncidentPriorityFilter,
  } = useDynamicFilter<string>('incidentPriority');

  //TODO: remove this once Mike O cleans up
  //functionally duplicate priorities in HIGHEST and CRITICAL

  const displayedIncidentPriorities = Object.values(IncidentPriority).filter(
    (p) => p !== IncidentPriority.HIGHEST && p !== IncidentPriority.NORMAL
  );
  return (
    <GenericFilter
      title="Severity"
      setFilter={setIncidentPriorityFilter}
      items={displayedIncidentPriorities}
      selectedItems={selectedIncidentPriorityFilters}
      transformItemName={capitalizeFirstLetterOnly}
    />
  );
};

export default IncidentPriorityFilter;
