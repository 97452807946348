import React from 'react';
import Loadable from 'react-loadable';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading: any = () => <CircularProgress size={40} />;

const Signal0 = Loadable({
  loader: () => import('./signal/Signal0'),
  loading: Loading,
});
const Signal1 = Loadable({
  loader: () => import('./signal/Signal1'),
  loading: Loading,
});
const Signal2 = Loadable({
  loader: () => import('./signal/Signal2'),
  loading: Loading,
});
const Signal3 = Loadable({
  loader: () => import('./signal/Signal3'),
  loading: Loading,
});
const Signal4 = Loadable({
  loader: () => import('./signal/Signal4'),
  loading: Loading,
});
const Signal5 = Loadable({
  loader: () => import('./signal/Signal5'),
  loading: Loading,
});

const Signal4G0 = Loadable({
  loader: () => import('./signal/Signal4G-0'),
  loading: Loading,
});
const Signal4G1 = Loadable({
  loader: () => import('./signal/Signal4G-1'),
  loading: Loading,
});
const Signal4G2 = Loadable({
  loader: () => import('./signal/Signal4G-2'),
  loading: Loading,
});
const Signal4G3 = Loadable({
  loader: () => import('./signal/Signal4G-3'),
  loading: Loading,
});
const Signal4G4 = Loadable({
  loader: () => import('./signal/Signal4G-4'),
  loading: Loading,
});
const Signal4G5 = Loadable({
  loader: () => import('./signal/Signal4G-5'),
  loading: Loading,
});

type Props = {
  classes?: any;
  level?:
    | 'signal0'
    | 'signal1'
    | 'signal2'
    | 'signal3'
    | 'signal4'
    | 'signal5'
    | 'signal4G0'
    | 'signal4G1'
    | 'signal4G2'
    | 'signal4G3'
    | 'signal4G4'
    | 'signal4G5';
  size?: number;
};

const IconSignalFactory = ({ classes, size = 20, level }: Props) => {
  switch (level) {
    case 'signal0':
      return <Signal0 classes={classes} size={size} />;
    case 'signal1':
      return <Signal1 classes={classes} size={size} />;
    case 'signal2':
      return <Signal2 classes={classes} size={size} />;
    case 'signal3':
      return <Signal3 classes={classes} size={size} />;
    case 'signal4':
      return <Signal4 classes={classes} size={size} />;
    case 'signal5':
      return <Signal5 classes={classes} size={size} />;
    case 'signal4G0':
      return <Signal4G0 classes={classes} size={size} />;
    case 'signal4G1':
      return <Signal4G1 classes={classes} size={size} />;
    case 'signal4G2':
      return <Signal4G2 classes={classes} size={size} />;
    case 'signal4G3':
      return <Signal4G3 classes={classes} size={size} />;
    case 'signal4G4':
      return <Signal4G4 classes={classes} size={size} />;
    case 'signal4G5':
      return <Signal4G5 classes={classes} size={size} />;
    default:
      return <></>;
  }
};

export default IconSignalFactory;
