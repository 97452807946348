import { parse } from 'date-fns';
import { Service } from '../config';
import { fetchApi } from '../utils/fetchApi';
import { getServiceUrl } from '../config';

export enum Actions {
  GET_WEATHER_BY_SITE_ID_SUCCESS = '@@weather/GET_WEATHER_BY_SITE_ID_SUCCESS',
  GET_WEATHER_BY_SITE_ID_LOADING = '@@weather/GET_WEATHER_BY_SITE_ID_LOADING',
  GET_WEATHER_BY_SITE_ID_ERROR = '@@weather/GET_WEATHER_BY_SITE_ID_ERROR',

  GET_WEATHER_BY_MULTI_SITES_IDS_SUCCESS = '@@weather/GET_WEATHER_BY_MULTI_SITES_IDS_SUCCESS',
  GET_WEATHER_BY_MULTI_SITES_IDS_LOADING = '@@weather/GET_WEATHER_BY_MULTI_SITES_IDS_LOADING',
  GET_WEATHER_BY_MULTI_SITES_IDS_ERROR = '@@weather/GET_WEATHER_BY_MULTI_SITES_IDS_ERROR',
}

export const getWeatherBySiteId = (siteId: number) => ({
  type: 'API_GET',
  service: Service.weather,
  path: `/weather/${siteId}/latest`,
  success: { type: Actions.GET_WEATHER_BY_SITE_ID_SUCCESS, siteId },
  loading: { type: Actions.GET_WEATHER_BY_SITE_ID_LOADING, siteId },
  error: { type: Actions.GET_WEATHER_BY_SITE_ID_ERROR, siteId },
});

export const getWeatherByMultiSitesIds = (siteIds: number[]) => {
  return async (dispatch) => {
    try {
      dispatch({ type: Actions.GET_WEATHER_BY_MULTI_SITES_IDS_LOADING });

      const response = await fetchApi({
        endpoint: `/weather/multisite/latest`,
        method: 'POST',
        service: Service.weather,
        payload: siteIds,
      });

      const result = JSON.parse(response);

      dispatch({
        type: Actions.GET_WEATHER_BY_MULTI_SITES_IDS_SUCCESS,
        payload: result,
      });
    } catch (error) {
      dispatch({ type: Actions.GET_WEATHER_BY_MULTI_SITES_IDS_ERROR, error });
    }
  };
};
