import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';

import styles from './TileFooter.module.css';
import tileStyles from '../../../styles/tiles.module.css';
import { classNames } from '@energybox/react-ui-library/dist/utils';

type Props = {
  redirectTo: string;
  text?: string;
  onClick?: () => void;
  search?: string;
  className?: string;
};

const TileFooter: React.FC<Props> = ({
  redirectTo,
  text = 'See All',
  onClick,
  search,
  className,
}) => {
  return (
    <div className={classNames(className, tileStyles.actionContainer)}>
      <Link to={{ pathname: redirectTo, search }} onClick={onClick}>
        <div className={styles.button}>
          <div className={styles.text}>{text}</div>
          <IoIosArrowForward className={styles.arrowIcon} />
        </div>
      </Link>
    </div>
  );
};

export default TileFooter;
