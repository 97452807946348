import { Site, SiteOnlineStatus } from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import ActiveIncidentIcon from '../ActiveIncidentIcon';
import styles from './SearchResult.module.css';
import SiteImage, { siteImageSize } from './SiteImage';

type Props = {
  site: SiteOnlineStatus;
  onClick: (site: SiteOnlineStatus) => void;
  isOpen: boolean;
  filteredSites?: any;
};

const SearchResult = ({ site, onClick, isOpen, filteredSites }: Props) => {
  return (
    <div
      className={classNames(styles.resultContainer, isOpen ? styles.open : '')}
      key={site.siteId}
      onClick={() => onClick(site)}
    >
      <div className={styles.innerContainer}>
        <div>
          <SiteImage
            site={{ images: site.images } as Site}
            size={siteImageSize.SMALL}
          />
        </div>

        <div className={styles.descContainer}>
          <h6 className={styles.siteTitle}>{site.title}</h6>
          <div className={styles.siteAddress}>{site.address || ' '}</div>
        </div>

        <div className={styles.icons}>
          {site.numberActiveIncidents !== undefined &&
            site.numberActiveIncidents > 0 && (
              <ActiveIncidentIcon
                numberActiveIncidents={site.numberActiveIncidents}
                showTooltip
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
