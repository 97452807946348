import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  FilterTimePeriod,
  Incident,
  Notification,
  NotificationsOverviewTileNames,
  SitesById,
} from '@energybox/react-ui-library/dist/types';
import {
  filterResolvedIncidentsOutsideOfTimeRange,
  global,
} from '@energybox/react-ui-library/dist/utils';
import isWithinInterval from 'date-fns/isWithinInterval';
import mergeDeepRight from 'ramda/src/mergeDeepRight';
import pathOr from 'ramda/src/pathOr';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ShortenedSpan from '../../../components/ShortenedSpan';
import HappyHornOverlay from '../../../components/Tile/HappyHornOverlay';
import Tile from '../../../components/Tile/Tile';
import TileContent from '../../../components/Tile/TileContent';
import TileFooter from '../../../components/Tile/TileFooter';
import TileHeader from '../../../components/Tile/TileHeader';
import useFilteredNotifications from '../../../hooks/useFilteredNotifications';
import { useGetIncidents } from '../../../hooks/useIncidents';
import useSiteFilter from '../../../hooks/useSiteFilter';
import { useGetAllSites } from '../../../hooks/useSites';
import * as Routes from '../../../routes';
import styles from './SitesWithMostIncidentsTile.module.css';
import useSiteGroupsFilter from '../../../hooks/useSiteGroupsFilter';

type Props = {
  className?: string;
  timePeriod: FilterTimePeriod;
};

type ProcessedData = {
  siteId: number;
  siteTitle: string;
  incidentCount: number;
  notificationCount: number;
};

type IncidentCountsBySiteId = {
  [siteId: string]: number;
};

type NotificationCountsBySiteId = {
  [siteId: string]: number;
};

const SitesWithMostIncidentsTile: React.FC<Props> = ({
  className,
  timePeriod,
}) => {
  const sitesById = useGetAllSites();
  const { selectedSiteFilters } = useSiteFilter();
  const location = useLocation();
  const { siteGroupWithoutSites } = useSiteGroupsFilter();

  const { isLoading: isIncidentsLoading, data: incidents = [] } =
    useGetIncidents({
      from: timePeriod.fromDate.toISOString(),
      to: timePeriod.toDate.toISOString(),
      strict: true,
    });

  const { data: notifications, isLoading: isNotificationsLoading } =
    useFilteredNotifications(
      {
        fromDate: timePeriod.fromDate,
        toDate: timePeriod.toDate,
      },
      true
    );

  const filteredIncidents = useMemo(() => {
    return filterResolvedIncidentsOutsideOfTimeRange(
      incidents,
      timePeriod.fromDate,
      timePeriod.toDate
    );
  }, [incidents, timePeriod]);

  const processedData = useMemo(() => {
    return processData(
      filteredIncidents,
      notifications,
      sitesById,
      timePeriod,
      selectedSiteFilters
    );
  }, [sitesById, incidents, notifications, timePeriod, selectedSiteFilters]);

  const isLoading = isIncidentsLoading || isNotificationsLoading;
  const isThereData = processedData && processedData.length > 0;

  if (!isThereData || siteGroupWithoutSites) {
    return (
      <Tile className={className} isLoading={isLoading}>
        <TileHeader
          title={NotificationsOverviewTileNames.SitesWithMostIncidents}
          // tooltipDescription={TOOLTIP_DESCRIPTION}
        />
        <HappyHornOverlay />
      </Tile>
    );
  }

  return (
    <Tile className={className} isLoading={isLoading}>
      <TileHeader
        title={NotificationsOverviewTileNames.SitesWithMostIncidents}
        // tooltipDescription={TOOLTIP_DESCRIPTION}
      />

      <TileContent>
        <Table
          className={styles.table}
          columns={columns}
          data={processedData}
        />
      </TileContent>

      <TileFooter
        search={location.search}
        redirectTo={`${Routes.NOTIFICATIONS}${Routes.INCIDENT_COUNTS}`}
      />
    </Tile>
  );
};

const processData = (
  incidents: Incident[],
  notifications: Notification[],
  sitesById: SitesById,
  timePeriod: FilterTimePeriod,
  selectedSiteIds: number[]
) => {
  const incidentCountsBySiteId = determineIncidentCountsBySiteId(incidents);
  const notificationCountsBySiteId = determineNotificationCountsBySiteId(
    notifications,
    timePeriod
  );

  const combinedDataBySiteId = mergeDeepRight(
    incidentCountsBySiteId,
    notificationCountsBySiteId
  );

  const processedData = Object.keys(combinedDataBySiteId).map((siteId) => {
    return {
      siteId,
      siteTitle: pathOr(global.NOT_AVAILABLE, [siteId, 'title'], sitesById),
      incidentCount: combinedDataBySiteId[siteId]['incidentCount'] || 0,
      notificationCount: combinedDataBySiteId[siteId]['notificationCount'] || 0,
    };
  });

  return processedData
    .filter(({ siteId }) => {
      if (selectedSiteIds.length === 0) return true;
      return selectedSiteIds.includes(Number.parseInt(siteId));
    })
    .sort((dataA, dataB) => dataB.incidentCount - dataA.incidentCount)
    .slice(0, 5);
};

const determineNotificationCountsBySiteId = (
  notifications: Notification[],
  timePeriod: FilterTimePeriod
): NotificationCountsBySiteId => {
  const notificationsBySiteId = {};

  notifications.forEach((n) => {
    const siteId = n.siteId;
    if (!siteId) return;

    const newNotificationCount = n.handlingLogs.filter((log) =>
      isWithinInterval(new Date(log.at), {
        start: timePeriod.fromDate,
        end: timePeriod.toDate,
      })
    ).length;

    if (!notificationsBySiteId[siteId]) {
      notificationsBySiteId[siteId] = {
        notificationCount: newNotificationCount,
      };
    } else {
      notificationsBySiteId[siteId] = {
        notificationCount:
          notificationsBySiteId[siteId]['notificationCount'] +
          newNotificationCount,
      };
    }
  });

  return notificationsBySiteId;
};

const determineIncidentCountsBySiteId = (
  incidents: Incident[]
): IncidentCountsBySiteId => {
  const incidentsBySiteId = {};

  incidents.forEach((i) => {
    const siteId = i.siteId;

    if (!incidentsBySiteId[siteId]) {
      incidentsBySiteId[siteId] = {
        incidentCount: 1,
      };
    } else {
      incidentsBySiteId[siteId] = {
        incidentCount: incidentsBySiteId[siteId]['incidentCount'] + 1,
      };
    }
  });

  return incidentsBySiteId;
};

const columns: Columns<ProcessedData>[] = [
  {
    header: '#',
    width: '5%',
    cellContent: (data: ProcessedData, index: number) => (
      <span className={styles.index}>{index + 1}</span>
    ),
  },
  {
    header: 'Site',
    width: '55%',
    cellContent: (data: ProcessedData) => (
      <span>
        <ShortenedSpan
          content={data.siteTitle}
          maxStringLength={30}
          arrowDirection="bottom"
        />
      </span>
    ),
  },
  {
    header: 'Incidents',
    rightAlignContent: true,
    width: '20%',
    cellContent: (data: ProcessedData) => {
      return (
        <Link to={`/sites/${data.siteId}/incidents`}>{data.incidentCount}</Link>
      );
    },
  },
  {
    header: 'Notifications',
    rightAlignContent: true,
    width: '20%',
    cellContent: (data: ProcessedData) => <div>{data.notificationCount}</div>,
  },
];

export default SitesWithMostIncidentsTile;
