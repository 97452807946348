import React from 'react';
import { useLocation } from 'react-router-dom';
// returns key => value pair object of query parameters
export const useQuery = (
  parseList: string[]
): {
  [key: string]: string;
} => {
  const { search } = useLocation();
  return React.useMemo(() => {
    const query = new URLSearchParams(search);
    const params = {};
    parseList.forEach((key) => {
      params[key] = query.get(key);
    });
    return params;
  }, [search]);
};
