import { RadioButton } from '@energybox/react-ui-library/dist/components';
import {
  Incident,
  SiteOnlineStats,
  SitesById,
} from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getIncidents } from '../../../actions/incidents';
import { getSitesOnlineStats } from '../../../actions/sites';
import Tile from '../../../components/Tile/Tile';
import TileContent from '../../../components/Tile/TileContent';
import TileHeader from '../../../components/Tile/TileHeader';
import { useSiteDataForMap } from '../../../hooks/useMap';
import { ApplicationState } from '../../../reducers';
import { TodayPageTimeRange } from '../../views/today/TodayOverviewPage/TodayOverviewPage';
import SiteMapArea from './SitesMapArea';
import styles from './SitesMapTile.module.css';

type Props = {
  className?: string;
  sitesOnlineStats: SiteOnlineStats | undefined;
  timeRange: TodayPageTimeRange;
  isLoadingActiveIncidents: boolean;
  activeIncidents: Incident[] | undefined;
  getSitesOnlineStats: typeof getSitesOnlineStats;
  getIncidents: typeof getIncidents;
  sitesById: SitesById;
};

const SitesMapTile = ({
  sitesOnlineStats,
  className,
  activeIncidents,
  isLoadingActiveIncidents,
  sitesById,
  timeRange,
}: Props) => {
  const [onlyShowingIncidents, setOnlyShowingIncidents] = useState(false);

  const { siteDataForMap, numberOfSitesWithActiveIncidents } =
    useSiteDataForMap(onlyShowingIncidents, timeRange);

  if (sitesOnlineStats === undefined || activeIncidents === undefined) {
    return (
      <Tile className={className} isLoading={isLoadingActiveIncidents}>
        <TileHeader title="Locations" />
      </Tile>
    );
  }

  const tileDescription = `This tile shows a map displaying all of your sites, those of which that
     have active incidents and those that are operating as necessary.`;

  return (
    <Tile className={className} isLoading={isLoadingActiveIncidents}>
      <TileHeader
        title="Locations"
        className={styles.tileHeader}
        tooltipDescription={tileDescription}
        arrowDirection={'right'}
      />
      <TileContent>
        {siteDataForMap && (
          <>
            <SiteMapArea siteStatuses={siteDataForMap} />

            <div className={styles.sitesContainer}>
              <div className={styles.sitesCountContainer}>
                <RadioButton
                  label={
                    <>
                      <span>Sites with Active Incidents</span>
                      <span className={styles.sitesWithIncidentsCountTextColor}>
                        {numberOfSitesWithActiveIncidents}
                      </span>
                    </>
                  }
                  value="active"
                  checked={onlyShowingIncidents}
                  onChange={() => setOnlyShowingIncidents(true)}
                  variant="traditional"
                />
              </div>
              <div className={styles.containerSeparator} />

              <div className={styles.sitesCountContainer}>
                <RadioButton
                  label={
                    <>
                      <span>All Sites</span>
                      <span className={styles.sitesCountTextColor}>
                        {sitesOnlineStats !== undefined
                          ? sitesOnlineStats.total
                          : global.NOT_AVAILABLE}
                      </span>
                    </>
                  }
                  checked={onlyShowingIncidents === false}
                  value="all"
                  onChange={() => setOnlyShowingIncidents(false)}
                  variant="traditional"
                />
              </div>
            </div>
          </>
        )}
      </TileContent>
    </Tile>
  );
};

const mapStateToProps = ({
  siteOnlineStatusById,
  incidents,
  sites,
}: ApplicationState) => ({
  sitesOnlineStats: siteOnlineStatusById.stats,
  isLoadingActiveIncidents: incidents.incidents.isLoading,
  activeIncidents: incidents.incidents.data,
  sitesById: sites.sitesById,
});

const mapDispatchToProps = {
  getSitesOnlineStats,
  getIncidents,
};

export default connect(mapStateToProps, mapDispatchToProps)(SitesMapTile);
