import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNetworkGroup } from '../actions/networkGroups';
import { ApplicationState } from '../reducers';
import { NetworkGroup } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import {
  unsubscribeFromWorkerStatus,
  subscribeToWorkerStatus,
} from '../actions/streamApi';
import { ReportStatusById } from '../reducers/subscribeReport';
import { controlBoardSelector } from '../selectors/controlBoards/controlBoards';

const useSubscribeToReportStatus = () => {
  const dispatch = useDispatch();
  const [jobIds, setJobIds] = useState([]);

  const reportWorker = useSelector(
    ({ reportworker }: ApplicationState) => reportworker.reportWorker
  );

  const connection = useSelector(
    ({ subscribedReportWorker }: ApplicationState) =>
      subscribedReportWorker.connection
  );

  useEffect(() => {
    if (reportWorker && reportWorker.length) {
      setJobIds(reportWorker.map((data) => data.jobId));
    }
  }, [reportWorker]);

  useEffect(() => {
    if (connection.status === 'OK' && isDefined(jobIds)) {
      if (Array.isArray(jobIds) && jobIds.length !== 0) {
        jobIds.forEach((jobId) => dispatch(subscribeToWorkerStatus(jobId)));
      } else if (typeof jobIds === 'string') {
        dispatch(subscribeToWorkerStatus(jobIds));
      }
    }
  }, [dispatch, jobIds, connection]);

  const subscribedReportStatusById = useSelector<
    ApplicationState,
    ReportStatusById | undefined
  >(({ subscribedReportWorker }) => {
    return subscribedReportWorker.reportStatusById;
  });

  return subscribedReportStatusById;
};

export default useSubscribeToReportStatus;
