import { Actions as AppActions } from '../actions/constants/Actions';
import { getConfig, getServiceUrl, Service } from '../config';
import store from '../store';

const publicPaths = ['/api/v1/auth/login'];

const requiresAuthorization = (path: string): boolean =>
  publicPaths.indexOf(path) === -1;

export async function fetchApi({
  endpoint = '',
  contentType,
  method = 'GET',
  payload = {},
  service,
  skipLogout,
}: {
  endpoint: string;
  contentType?: string;
  method?: string;
  payload?: any;
  service?: string;
  skipLogout?: boolean;
}): Promise<any> {
  const fullUrl = `${getServiceUrl(service)}${endpoint}`;
  const headers = genHeaders(store, {
    service,
  });

  if (contentType !== undefined) {
    headers['Content-Type'] = contentType;
  }

  const body = Object.keys(payload).length
    ? JSON.stringify(payload)
    : undefined;

  try {
    const response = await fetch(fullUrl, { method, headers, body });

    if (response.status === 401 && !skipLogout) {
      store.dispatch({ type: AppActions.LOGOUT });
      return Promise.reject(Error('Unauthorized'));
    }

    if (response.status === 403) {
      return Promise.reject(new Error('Forbidden'));
    }

    if (!response.ok) {
      return Promise.reject(new Error(`API request failed ${response.status}`));
    }

    if (response.status === 204) {
      return Promise.resolve();
    }

    const res = processResponse(response);

    return Promise.resolve(res);
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * The purpose of this function is to ensure response content is processed the
 * same way regardless of whether the call is issued through a redux action or
 * directly.
 * @param response Fetch response object
 */
const processResponse = (response: Response) => {
  const responseContentType = response.headers.get('content-type');
  if (responseContentType && response.status !== 204) {
    if (responseContentType.startsWith('application/octet-stream')) {
      return response.blob();
    } else if (
      responseContentType.startsWith('application/json') ||
      responseContentType.startsWith('application/vnd.api+json')
    ) {
      return response.json();
    }
  }

  return response.text();
};

const genHeaders = (store, action, contentType?: string) => {
  const { accessToken } = store.getState().app;
  const { path, service } = action;

  const headers = {
    'Content-Type':
      contentType !== undefined ? contentType : 'application/json',
  };

  if (requiresAuthorization(path) && accessToken && accessToken !== '') {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }
  const serviceUrl = getServiceUrl(service);
  if (
    serviceUrl === getServiceUrl(Service.iam) ||
    serviceUrl === getServiceUrl(Service.timeSeries)
  ) {
    headers['client'] = 'one-front-end';
  }
  return headers;
};

export function fetchWrapper({
  store,
  action,
  url,
  method,
  payload,
  collection = [],
  limit,
  offset,
  contentType,
}: {
  store;
  action;
  url;
  method;
  payload;
  collection?: any[];
  limit?: number;
  offset?: number;
  contentType?: string;
}) {
  // TODO this is temporary logic to fix sentinels issues and continuously fetch notifications.
  if (limit) url = url.replace(/(limit=)[^&]+/, '$1' + limit);
  if (offset) url = url.replace(/(offset=)[^&]+/, '$1' + offset);
  return fetch(url, {
    method,
    headers: genHeaders(store, action, contentType),
    body: JSON.stringify(payload),
  })
    .then((response) => {
      if (
        response.status === 401 &&
        !(url.includes('/login') || url.includes('/email-password-confirm'))
      ) {
        store.dispatch({ type: AppActions.LOGOUT });
      }

      const time = response.headers.get('servertime');
      if (time) store.dispatch({ type: AppActions.SET_LAST_SERVER_TIME, time });

      const res = processResponse(response);
      return Promise.resolve(res);
    })
    .then((res) => {
      // This logic works with the sentinels api which returns back a massive array of notifications
      if (res && Array.isArray(res) && limit && res.length === limit) {
        return fetchWrapper({
          store,
          action,
          url,
          method,
          payload,
          collection: [...collection, ...res],
          limit,
          offset: (offset || 0) + 1,
        });
      } else if (Array.isArray(res) && Array.isArray(collection)) {
        return [...collection, ...res];
      } else {
        return res;
      }
    });
}
