import {
  ResourceType,
  SiteGroup,
  SiteGroupSite,
} from '@energybox/react-ui-library/dist/types';
import { Actions as SiteGroupActions } from '../actions/site_groups';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';

export type SiteGroupsById = {
  [id: string]: SiteGroup;
};
export interface SiteGroups {
  siteGroupsById: SiteGroupsById;
  isLoading: boolean;
}

const applyActionToState = (actionFunction, action) => (state) =>
  actionFunction(state, action);

const transformApiResponseToSiteGroupSite = (data): SiteGroupSite => ({
  id: data.id,
  title: data.title,
  description: data.description,
});
const transformApiResponseToSiteGroup = (data): SiteGroup => ({
  id: data.id,
  title: data.title,
  description: data.description,
  sites: (data.sites || []).map(transformApiResponseToSiteGroupSite),
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
});

const mapSiteGroupsById = (state, action) => {
  const siteGroups = action.payload;
  if (siteGroups?.length) {
    const siteGroupsById = {};

    siteGroups.forEach((data) => {
      const mappedSiteGroupResponse = transformApiResponseToSiteGroup(data);
      const id = `${data.id}`;
      siteGroupsById[id] = mappedSiteGroupResponse;
    });

    return {
      ...state,
      siteGroupsById,
    };
  } else {
    return state;
  }
};

const initialState = {
  siteGroupsById: {},
  isLoading: false,
};

export const siteGroups = (state: SiteGroups = initialState, action: any) => {
  switch (action.type) {
    case SiteGroupActions.GET_SITE_GROUPS_SUCCESS:
      return pipe(
        assocPath(
          ['isLoading', SiteGroupActions.GET_SITE_GROUPS_LOADING],
          false
        ),
        applyActionToState(mapSiteGroupsById, action)
      )(state, action);

    case SiteGroupActions.GET_SITE_GROUPS_LOADING:
      return assocPath(
        ['isLoading', SiteGroupActions.GET_SITE_GROUPS_LOADING],
        true,
        state
      );

    default:
      return state;
  }
};

export default siteGroups;
