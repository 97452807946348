import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { App } from '../../reducers/app';
import { canAccessFeatures } from '../../utils/featureFlag';
import { ApplicationState } from '../../reducers';
import { getCurrentUser } from '../../actions/app';

type Props = {
  orgWhitelist?: number[];
  orgBlacklist?: number[];
  applyBlacklistStrictly?: boolean;
  children: React.ReactElement;
};

const FeatureFlag: React.FC<Props> = ({
  orgWhitelist,
  orgBlacklist,
  applyBlacklistStrictly = false,
  children
}) => {
  const dispatch = useDispatch();

  const app = useSelector<ApplicationState, App>(({ app }) => {
    return app;
  });

  useEffect(() => {
    if (!app.currentUser)
      dispatch(getCurrentUser());
    }, [dispatch, app]);

  const isVisible = canAccessFeatures(app, {
    orgWhitelist,
    orgBlacklist,
    applyBlacklistStrictly
  });

  return isVisible && children ? children : null;
};

export default FeatureFlag;
