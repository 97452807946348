import { App } from '../reducers/app';
import { Tiles } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { TEMPERATURE_REVIEW_BLACKLIST } from './featureFlagSettings';

const ebOrganizationId = parseInt(
  process.env.REACT_APP_ENERGYBOX_ORG || '275',
  10
);

export type FeatureFlagOptions =
  | {
      orgWhitelist?: number[];
      orgBlacklist?: number[];
      applyBlacklistStrictly?: boolean;
    }
  | undefined;

//TODO: clean up "applyBlacklistStrictly" prop logic
//currently not using, I'm not sure if it's deprecated
//or if we just need to refactor?

//To note:
//app.currentOrganizationId is the org the user is currently in
//app.currentUser.organizationId is the org that the user belongs to
//this only differs for admin accounts that can switch between orgs
export const canAccessFeatures = (
  app: App,
  options: FeatureFlagOptions = {}
) => {
  const { orgWhitelist, orgBlacklist, applyBlacklistStrictly } = options;
  const userOrgId = app.currentUser?.organizationId;
  if (!userOrgId) return false;

  // const viewingOrgId = app.currentOrganizationId;
  // let strictBlacklistFlag = false;

  // if (
  //   applyBlacklistStrictly === true &&
  //   blacklist !== undefined &&
  //   blacklist.length > 0
  // ) {
  //   for (const orgId of blacklist) {
  //     if (viewingOrgId === orgId) strictBlacklistFlag = true;
  //   }
  // }

  if (
    // !strictBlacklistFlag &&
    !applyBlacklistStrictly &&
    (userOrgId === ebOrganizationId ||
      // FYI: this is checking webpack env *not* if EB is connected to staging or prod in the backend
      process.env.NODE_ENV === 'development')
  ) {
    return true;
  }

  if (orgWhitelist !== undefined) {
    for (const orgId of orgWhitelist) {
      if (userOrgId === orgId) return true;
    }
  }
  if (orgBlacklist !== undefined) {
    // if (applyBlacklistStrictly === true) {
    //   return strictBlacklistFlag;
    // } else {
    for (const orgId of orgBlacklist) {
      if (userOrgId === orgId) return false;
    }
    // }
  }

  // If there was a blacklist, return true for everything not on it. Otherwise,
  // we had a whitelist and want to return false
  if (orgBlacklist !== undefined) {
    return true;
  } else {
    return false;
  }
};

export const determineIfUserHasAccessToTile = (app: App, tileName: Tiles) => {
  let featureFlagOptions: FeatureFlagOptions;

  //if you want a basic feature flag (aka only EB user can see)
  //then you want orgWhiteList: []
  switch (tileName) {
    case Tiles.TemperatureReviewCard: {
      featureFlagOptions = {
        orgBlacklist: TEMPERATURE_REVIEW_BLACKLIST,
      };
      break;
    }

    default:
      break;
  }

  if (isDefined(featureFlagOptions)) {
    return canAccessFeatures(app, featureFlagOptions);
  } else {
    return true;
  }
};
