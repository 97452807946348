import React from 'react';
import { MobileNavMenu } from '@energybox/react-ui-library/dist/components';
import { PageNav } from '@energybox/react-ui-library/dist/types';
import BaseFilterBar from '../Filters/BaseFilterBar';
import { Link, useLocation } from 'react-router-dom';
import history from '../../history';

import styles from './TopNavigationBar.module.css';

type Props = {
  path: string;
  resetFilters?: () => void;
};

const NotificationsTopNavigationBar = ({ path, resetFilters }: Props) => {
  const location = useLocation();
  const currentPath = location?.pathname || '';

  const tabs: PageNav[] = [
    {
      to: `/notifications/overview`,
      displayName: 'Overview',
    },
    {
      to: `/incidents`,
      displayName: 'Incidents',
    },
    {
      to: `/notifications`,
      displayName: 'Notifications',
    },
  ];

  return (
    <>
      <BaseFilterBar className={styles.notificationsNavBar}>
        <div className={styles.notificationsNavBarInner}>
          {tabs.map((tab) => (
            <div
              key={tab.displayName.toLowerCase()}
              className={
                tab.to === currentPath ? styles.currentTab : styles.tab
              }
            >
              <Link
                to={`${tab.to}${location?.search}`}
                className={styles.topLink}
                onClick={() => {
                  if (resetFilters) resetFilters();
                }}
              >
                {tab.displayName}
              </Link>
            </div>
          ))}
        </div>
      </BaseFilterBar>
      <div className={styles.mobileNotificationsNavBar}>
        <MobileNavMenu
          tabs={tabs}
          currentPath={currentPath}
          genericOnClick={(to: string) => history.push(to)}
        />
      </div>
    </>
  );
};

export default NotificationsTopNavigationBar;
