import { useCallback, useMemo } from 'react';
import history from '../history';
import { DEFAULT_PAGINATION_ROWS_COUNT, getUrlStateParams } from '../util';
import mixpanel from 'mixpanel-browser';

const DECIMAL_RADIX = 10;
const DEFAULT_PAGE = 1;

const usePaginationFilter = (dataLength: number | null = null) => {
  const search = history?.location?.search;

  const currentPageFromURL = useMemo(() => {
    let page = getUrlStateParams(history, 'page', []);

    return page.length ? parseInt(page[0], DECIMAL_RADIX) : DEFAULT_PAGE;
  }, [search]);

  const rowLimitFromURL = useMemo(() => {
    let rowLimit = getUrlStateParams(history, 'rowLimit', []);

    return rowLimit.length
      ? parseInt(rowLimit[0], DECIMAL_RADIX)
      : DEFAULT_PAGINATION_ROWS_COUNT;
  }, [search]);

  const setPagination = useCallback(
    (newPage, newRowLimit) => {
      const targetRowLimit =
        newRowLimit !== undefined ? newRowLimit : rowLimitFromURL;

      mixpanel?.track('Filter Applied', {
        type: 'Pagination',
        page: newPage,
        rowLimit: targetRowLimit,
        dataLength,
      });

      setPaginationAndUpdateUrl(newPage, targetRowLimit, dataLength);
    },
    [currentPageFromURL, rowLimitFromURL, dataLength]
  );

  return {
    currentPage: currentPageFromURL,
    rowLimit: rowLimitFromURL,
    setPagination,
  };
};

export default usePaginationFilter;

const setPaginationAndUpdateUrl = (page, rowLimit, dataLength) => {
  const pathname = history?.location?.pathname;
  const updatedSearchParams = new URLSearchParams(history?.location?.search);

  updatedSearchParams.set('page', page.toString());
  if (rowLimit !== undefined) {
    updatedSearchParams.set('rowLimit', rowLimit.toString());
  }

  const newUrl = `${pathname}?${updatedSearchParams.toString()}`;

  if (newUrl !== window.location.href && dataLength <= rowLimit) {
    history?.replace({ pathname, search: updatedSearchParams.toString() });
  } else if (newUrl !== window.location.href) {
    history?.push({ pathname, search: updatedSearchParams.toString() });
  }
};
