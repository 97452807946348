import SeeAllPage from '../../../../components/views/SeeAllPage';
import * as Routes from '../../../../routes';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';
import useAppLocale from '../../../../hooks/useAppLocale';
import { useOrganizationId } from '../../../../hooks/useCurrentUser';
import useSiteFilter from '../../../../hooks/useSiteFilter';
import { useEffect, useState } from 'react';
import {
  FilterOption,
  OpacityIndex,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import { endOfYear, startOfYear } from 'date-fns';
import { useSearchFilter, useTimeFilter } from '../../../../hooks/useFilters';
import {
  DateFilter,
  LongExtraLongSkeletonCell,
  ShortenedSpan,
  ShortMediumSkeletonCell,
  ShortSkeletonCell,
  Tooltip as StandardTooltip,
} from '@energybox/react-ui-library/dist/components';
import { DA_REPORT_AVAILABLE_DATE } from '../../constants';
import {
  Columns,
  TableGroupHeader,
} from '@energybox/react-ui-library/dist/components/Table';
import { Link } from 'react-router-dom';
import {
  genericTableSort,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import {
  formatNumber,
  roundToRelativeScale,
} from '@energybox/react-ui-library/dist/utils/number';
import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';
import {
  startOrgNOHReport,
  getOrgNOHReport,
  startMonthlyNOHReport,
  getMonthlyNOHReport,
} from '../../../../actions/analytics';
import SiteFilter from '../../../../components/Filters/SiteFilter';
import styles from './NOHEnergyConsumption.module.css';
import {
  useMonthlyNOHReport,
  useOrgNOHReport,
} from '../../../../hooks/useAnalytics';
import { useEquipmentGroups } from '../../../../hooks/useEquipmentGroups';
import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';

const NOHEnergyConsumption: React.FC = () => {
  const dispatch = useDispatch();
  const locale = useAppLocale();
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const adjustedArrowDirection = isMobile ? 'top' : 'bottom';
  const orgId = useOrganizationId();
  const { selectedSiteFilters } = useSiteFilter();
  const {
    timePeriod: { fromDate, toDate },
  } = useTimeFilter();
  const now = new Date();
  const month = now.getMonth() + 1;
  const year = month >= 2 ? now.getFullYear() : now.getFullYear() - 1;
  const [selectedYear, setSelectedYear] = useState<number>(year);
  const [selectedFilterOption, setSelectedFilterOption] =
    useState<FilterOption>({
      title: '',
      fromDate: fromDate,
      toDate: toDate,
    });
  const disableAfterOption =
    now.getMonth() > 0
      ? now.getFullYear() + 1 + '-' + now.getMonth()
      : now.getFullYear() - 1 + '-' + now.getMonth();

  const orgNOHReportData = useOrgNOHReport();
  const monthlyNOHReportData = useMonthlyNOHReport(fromDate, toDate);
  const equipmentGroups = useEquipmentGroups();

  useEffect(() => {
    if (orgId !== undefined) {
      dispatch(startOrgNOHReport(orgId, fromDate, toDate));
      dispatch(startMonthlyNOHReport(orgId, fromDate, toDate));
    }
  }, [fromDate, toDate, orgId]);

  const {
    data: orgData,
    isLoading: orgIsLoading,
    isReportReady: orgIsReady,
  } = orgNOHReportData;
  const { by_site: bySiteData, by_org: byOrgData } = orgData || {};
  const oh_consumption = byOrgData?.op_hour.energy_total || 0;
  const noh_consumption = byOrgData?.non_op_hour.energy_total || 0;
  const noh_efficiency = byOrgData?.noh_efficiency || 0;
  const oh_eui = orgData?.org_hourly_eui_in || 0;
  const noh_eui = orgData?.org_hourly_eui_out || 0;

  const {
    data: monthlyData,
    isLoading: monthlyIsLoading,
    isReportReady: monthlyIsReady,
  } = monthlyNOHReportData;
  const monthlyBySiteData = monthlyData?.by_site;
  // const noh_efficiency = !!monthlyData?.recom?.org_noh_efficiency ? monthlyData?.recom.org_noh_efficiency : byOrgData?.noh_efficiency;

  const data = mapSiteData(bySiteData, monthlyBySiteData);

  const {
    query,
    setQuery,
    filteredList: filterSearchList,
  } = useSearchFilter(data, [['site_title']]);

  const filteredData = filterSearchList.filter((site) => {
    if (selectedSiteFilters.length > 0) {
      return selectedSiteFilters.includes(Number(site.siteId));
    } else {
      return true;
    }
  });

  // Add total energy consumption to each site
  const displayData = filteredData.map((site) => {
    const { op_hour, non_op_hour } = site.site_noh;
    const energy_total = op_hour.energy_total + non_op_hour.energy_total;
    return {
      ...site,
      energy_total,
    };
  });

  const columns: Columns<typeof bySiteData>[] = [
    {
      header: 'Site Name',
      width: '7%',
      cellContent: ({ site_title, siteId }) => (
        <div>
          <Link to={`/sites/${siteId}/dashboard`}>
            <ShortenedSpan
              content={site_title}
              maxStringLength={45}
              arrowDirection={'bottom'}
            />
          </Link>
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <LongExtraLongSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'site_title',
        ]);
      },
    },
    {
      header: 'NOH Efficiency',
      width: '6%',
      align: 'right',
      rightAlignContent: true,
      isDefaultSort: true,
      defaultSortDirection: SortDirection.ASC,
      cellContent: ({ site_noh, site_comp_status }) => {
        const percentage = site_noh?.noh_efficiency * 100 || '-';
        let color: string | undefined = undefined;
        if (typeof percentage === 'number') {
          if (percentage < 20) {
            color = styles.red;
          } else if (percentage >= 75) {
            color = styles.green;
          }
        } else if (site_comp_status === false) {
          color = styles.incomplete;
        }
        return (
          <div className={color}>
            {typeof percentage === 'string'
              ? percentage
              : `${formatNumber(percentage, 1)}%`}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'site_noh',
          'noh_efficiency',
        ]);
      },
    },
    {
      header: 'Trend (Last 6 months)',
      width: '6%',
      align: 'center',
      cellStyle: { height: '50px' },
      cellContent: ({ monthly }) => {
        const keys = R.keys(monthly);
        const values = R.values(monthly);
        const trendData = keys
          .sort()
          .slice(-7, -1)
          .reduce((acc, key) => {
            acc.push({
              name: key,
              value: monthly[key]?.noh_efficiency * 100 || 0,
            });
            return acc;
          }, []);
        return (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width={150} height={50} data={trendData}>
              <Line
                type="monotone"
                dataKey="value"
                stroke="#00a1af"
                strokeWidth={1}
                dot={{ fill: '#00a1af', strokeWidth: 0, r: 2 }}
                isAnimationActive={false}
              />
              <Tooltip
                formatter={(value, name, props) => {
                  return [`${formatNumber(props.payload.value, 2)}%`];
                }}
                labelFormatter={(value, name, props) => {
                  return props?.payload?.name || '';
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'Total Energy Consumption (kWh)',
      width: '6%',
      align: 'right',
      rightAlignContent: true,
      cellContent: ({ site_noh, site_comp_status, energy_total }) => {
        const color =
          site_comp_status === false ? styles.incomplete : undefined;
        return (
          <div className={color}>
            {energy_total !== null
              ? formatNumber(roundToRelativeScale(energy_total), 1)
              : '-'}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) =>
        genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'energy_total',
        ]),
    },
    {
      header: 'Energy Consumption (kWh)',
      width: '6%',
      align: 'right',
      rightAlignContent: true,
      cellContent: ({ site_noh, site_comp_status, site_data_comp }) => {
        const { op_hour } = site_noh;
        const { energy_total } = op_hour;
        const color =
          site_comp_status === false ? styles.incomplete : undefined;
        return (
          <div className={color}>
            {energy_total !== null
              ? formatNumber(roundToRelativeScale(energy_total), 1)
              : '-'}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'site_noh',
          'op_hour',
          'energy_total',
        ]);
      },
    },
    {
      header: 'Number of Hours (h)',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      // defaultSortDirection: SortDirection.DESC,
      cellContent: ({ site_noh, site_comp_status, site_data_comp }) => {
        const { op_hour } = site_noh;
        const { hours_total } = op_hour;
        const color =
          site_comp_status === false ? styles.incomplete : undefined;
        return (
          <div className={color}>
            {hours_total !== null ? formatNumber(hours_total, 1) : '-'}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'site_noh',
          'op_hour',
          'hours_total',
        ]);
      },
    },
    {
      header: 'Average Consumption per hour (kWh)',
      width: '10%',
      align: 'right',
      rightAlignContent: true,
      // defaultSortDirection: SortDirection.DESC,
      cellContent: ({ site_noh, site_comp_status, site_data_comp }) => {
        const { op_hour } = site_noh;
        const { energy_per_hour } = op_hour;
        const color =
          site_comp_status === false ? styles.incomplete : undefined;
        return (
          <div className={color}>
            {energy_per_hour !== null
              ? formatNumber(roundToRelativeScale(energy_per_hour), 1)
              : '-'}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'site_noh',
          'op_hour',
          'energy_per_hour',
        ]);
      },
    },
    {
      header: (
        <StandardTooltip
          arrowDirection={adjustedArrowDirection}
          tooltipTextClassName={isMobile ? styles.mobileTooltip : undefined}
          childrenWrapperClassName={styles.tooltipText}
          hoverOverride={true}
          simpleContent={{
            title: 'Average Hourly EUI (Wh/m²)',
            description: `Hourly EUI (Operating hour) refers to the average energy consumption per m2 in one hour during Operating hour. 
          It is normalized by time and size so one can compare how much energy consumption one m2 is using in each site`,
          }}
        >
          {'Average Hourly EUI (Wh/m²)'}
        </StandardTooltip>
      ),
      width: '8%',
      align: 'right',
      rightAlignContent: true,
      // defaultSortDirection: SortDirection.DESC,
      cellContent: ({ site_noh, site_comp_status, site_data_comp }) => {
        const { op_hour } = site_noh;
        const { hourly_eui } = op_hour;
        const color =
          site_comp_status === false ? styles.incomplete : undefined;
        return (
          <div className={color}>
            {hourly_eui !== null
              ? roundToRelativeScale(hourly_eui * 1000)
              : '-'}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'site_noh',
          'op_hour',
          'hourly_eui',
        ]);
      },
    },
    {
      header: 'Energy Consumption (kWh)',
      width: '9%',
      align: 'right',
      rightAlignContent: true,
      // defaultSortDirection: SortDirection.DESC,
      cellContent: ({ site_noh, site_comp_status, site_data_comp }) => {
        const { non_op_hour } = site_noh;
        const { energy_total } = non_op_hour;
        const color =
          site_comp_status === false ? styles.incomplete : undefined;
        return (
          <div className={color}>
            {energy_total !== null
              ? formatNumber(roundToRelativeScale(energy_total), 1)
              : '-'}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'site_noh',
          'non_op_hour',
          'energy_total',
        ]);
      },
    },
    {
      header: 'Number of Hours (h)',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      // defaultSortDirection: SortDirection.DESC,
      cellContent: ({ site_noh, site_comp_status, site_data_comp }) => {
        const { non_op_hour } = site_noh;
        const { hours_total } = non_op_hour;
        const color =
          site_comp_status === false ? styles.incomplete : undefined;
        return (
          <div className={color}>
            {hours_total !== null ? formatNumber(hours_total, 1) : '-'}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'site_noh',
          'non_op_hour',
          'hours_total',
        ]);
      },
    },
    {
      header: 'Average Consumption per hour (kWh)',
      width: '10%',
      align: 'right',
      rightAlignContent: true,
      // defaultSortDirection: SortDirection.DESC,
      cellContent: ({ site_noh, site_comp_status, site_data_comp }) => {
        const { non_op_hour } = site_noh;
        const { energy_per_hour } = non_op_hour;
        const color =
          site_comp_status === false ? styles.incomplete : undefined;
        return (
          <div className={color}>
            {energy_per_hour !== null
              ? formatNumber(roundToRelativeScale(energy_per_hour), 1)
              : '-'}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'site_noh',
          'non_op_hour',
          'energy_per_hour',
        ]);
      },
    },
    {
      header: (
        <StandardTooltip
          arrowDirection={adjustedArrowDirection}
          tooltipTextClassName={isMobile ? styles.mobileTooltip : undefined}
          childrenWrapperClassName={styles.tooltipText}
          hoverOverride={true}
          simpleContent={{
            title: 'Average Hourly EUI (Wh/m²)',
            description: `Hourly EUI (Operating hour) refers to the average energy consumption per m2 in one hour during Operating hour. 
                It is normalized by time and size so one can compare how much energy consumption one m2 is using in each site`,
          }}
        >
          {'Average Hourly EUI (Wh/m²)'}
        </StandardTooltip>
      ),
      width: '8%',
      align: 'right',
      rightAlignContent: true,
      // defaultSortDirection: SortDirection.DESC,
      cellContent: ({ site_noh, site_comp_status, site_data_comp }) => {
        const { non_op_hour } = site_noh;
        const { hourly_eui } = non_op_hour;
        const color =
          site_comp_status === false ? styles.incomplete : undefined;
        return (
          <div className={color}>
            {hourly_eui !== null
              ? roundToRelativeScale(hourly_eui * 1000)
              : '-'}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'site_noh',
          'non_op_hour',
          'hourly_eui',
        ]);
      },
    },
    {
      header: 'Key Contributors',
      width: '7%',
      align: 'left',
      // rightAlignContent: true,
      // defaultSortDirection: SortDirection.DESC,
      cellContent: ({
        by_equipment,
        equipment_group,
        site_comp_status,
        site_data_comp,
      }) => {
        const topEquipmentGroup: {
          groupId: number | string;
          eui: number;
          groupTitle: string;
        }[] = [];
        for (const [groupId, eui] of Object.entries(equipment_group)) {
          const pickedGroup = equipmentGroups.filter(
            (group) => group.id === Number(groupId)
          )[0];
          topEquipmentGroup.push({
            groupId: Number(groupId),
            eui: Number(eui) * 1000,
            groupTitle: pickedGroup.title,
          });
        }

        topEquipmentGroup.sort((a, b) => a.eui - b.eui).reverse();

        const color =
          site_comp_status === false ? styles.incomplete : undefined;

        return (
          <div
            className={color}
            style={{ display: 'grid', gridTemplateRows: 'repeat(3, auto)' }}
          >
            {topEquipmentGroup.length === 0 ? (
              <div style={{ textAlign: 'right' }}>{'-'}</div>
            ) : (
              topEquipmentGroup.map((group, i) => {
                const hasBorder = i !== topEquipmentGroup.length - 1;
                return (
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      margin: '0.1rem',
                      borderBottom: hasBorder
                        ? '2px dotted var(--shade-25)'
                        : 'none',
                    }}
                  >
                    {group.groupTitle}
                  </div>
                );
              })
            )}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'EUI (Wh/m²)',
      width: '1%',
      align: 'right',
      rightAlignContent: true,
      // defaultSortDirection: SortDirection.DESC,
      cellContent: ({
        by_equipment,
        equipment_group,
        site_comp_status,
        site_data_comp,
      }) => {
        const topEquipmentGroup: {
          groupId: number | string;
          eui: number;
          groupTitle: string;
        }[] = [];
        for (const [groupId, eui] of Object.entries(equipment_group)) {
          const pickedGroup = equipmentGroups.filter(
            (group) => group.id === Number(groupId)
          )[0];
          topEquipmentGroup.push({
            groupId: Number(groupId),
            eui: Number(eui) * 1000,
            groupTitle: pickedGroup.title,
          });
        }

        topEquipmentGroup.sort((a, b) => a.eui - b.eui).reverse();

        const color =
          site_comp_status === false ? styles.incomplete : undefined;

        return (
          <div
            className={color}
            style={{ display: 'grid', gridTemplateRows: 'repeat(3, auto)' }}
          >
            {topEquipmentGroup.length === 0 ? (
              <div style={{ textAlign: 'right' }}>{'-'}</div>
            ) : (
              topEquipmentGroup.map((group, i) => {
                const hasBorder = i !== topEquipmentGroup.length - 1;
                return (
                  <div
                    style={{
                      margin: '0.1rem',
                      borderBottom: hasBorder
                        ? '2px dotted var(--shade-25)'
                        : 'none',
                    }}
                  >
                    {formatNumber(group?.eui, 1) || '-'}
                  </div>
                );
              })
            )}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'Equipment lower than 20% NOH Efficiency',
      width: '0%',
      align: 'right',
      rightAlignContent: true,
      // defaultSortDirection: SortDirection.DESC,
      cellContent: ({ num_of_offender, site_comp_status, site_data_comp }) => {
        const color =
          site_comp_status === false ? styles.incomplete : undefined;
        return (
          <div className={color}>
            {num_of_offender !== null
              ? roundToRelativeScale(num_of_offender)
              : '-'}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'num_of_offender',
        ]);
      },
    },
  ];

  const groupHeaders: TableGroupHeader[] = [
    {
      header: '',
      colSpan: 4,
      className: styles.tableGroupHeader,
    },
    {
      header: 'Operating Hours',
      colSpan: 4,
      className: styles.tableGroupHeader,
    },
    {
      header: 'Non-Operating Hours',
      colSpan: 6,
      className: styles.tableGroupHeader,
    },
  ];

  return (
    <SeeAllPage
      title="Insights"
      displayCountText="sites"
      headerName="NOH Efficiency Analysis"
      backRoute={Routes.DASHBOARD}
      searchProps={{
        query,
        onChange: setQuery,
        error: filterSearchList.length === 0,
      }}
      filters={[<SiteFilter />]}
      // alternativeContent={
      //   !isLoading && !isThereData ? <HappyHornMessage /> : undefined
      // }
      datePickerType={'date'}
      summaryStatistics={[
        {
          value: noh_efficiency * 100 || '-',
          numDecimals: 1,
          unit: '%',
          title: 'NOH Efficiency',
          bold: true,
        },
        {
          value:
            (oh_consumption &&
              noh_consumption &&
              oh_consumption + noh_consumption) ||
            '-',
          numDecimals: 1,
          unit: 'kWh',
          title: 'Total Energy Consumption',
          bold: true,
        },
        {
          value: oh_consumption || '-',
          numDecimals: 1,
          unit: 'kWh',
          title: 'Operating Hour Consumption',
          bold: true,
        },
        {
          value: noh_consumption || '-',
          numDecimals: 1,
          unit: 'kWh',
          title: 'Non-Operating Hour Consumption',
          bold: true,
        },
        {
          value: oh_eui ? roundToRelativeScale(oh_eui * 1000) : '-',
          numDecimals: 1,
          unit: 'Wh/m²',
          title: 'Operating Hourly EUI',
          bold: true,
        },
        {
          value: noh_eui ? roundToRelativeScale(noh_eui * 1000) : '-',
          numDecimals: 1,
          unit: 'Wh/m²',
          title: 'Non-Operating Hourly EUI',
          bold: true,
        },
      ]}
      tableProps={{
        groupHeaders,
        columns,
        data: displayData || [],
        dataIsLoading: orgIsLoading || monthlyIsLoading,
        listView: true,
        headerClassName: styles.tableHeader,
        headerWrap: true,
      }}
    ></SeeAllPage>
  );
};

const mapSiteData = (siteData = {}, siteMonthlyData = {}) => {
  const siteIds = Object.keys(siteData);
  return siteIds.map((siteId) => ({
    siteId,
    ...siteData[siteId],
    monthly: siteMonthlyData[siteId]?.by_month,
  }));
};

export default NOHEnergyConsumption;
