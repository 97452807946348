import { SiteOnlineStatus } from '@energybox/react-ui-library/dist/types';
import React, { useEffect, useRef, useState } from 'react';
import { useSiteDataForMap } from '../../hooks/useMap';
import { ArrayLatLngBounds, getBounds } from '../../utils/map';
import MapArea from './MapArea';
import styles from './MapAreaContainer.module.css';

type Props = {
  query: string;
  handleQueryChange: (query: string) => void;
};

const MapAreaContainer = ({ query, handleQueryChange }: Props) => {
  const [initialBounds, setInitialBounds]: [
    ArrayLatLngBounds | undefined,
    (ArrayLatLngBounds) => void
  ] = useState(undefined);

  const [openSite, setOpenSite] = useState<SiteOnlineStatus | undefined>(
    undefined
  );
  // Not entirely sure what this is used for..but when I deleted it the map got
  // messed up, and parts were clipped by side of page including the side bar
  const [sidebarCollapsed] = useState(true);
  const [onlyShowingIncidents, setOnlyShowingIncidents] = useState(false);

  const mapRef = useRef<MapArea>(null);

  const {
    siteDataForMap,
    // numberOfSitesWithActiveIncidents
  } = useSiteDataForMap(onlyShowingIncidents);

  useEffect(() => {
    if (siteDataForMap.length > 0 && initialBounds === undefined)
      setInitialBounds(getBounds(siteDataForMap));
  }, [siteDataForMap, initialBounds]);

  const onSiteClick = (site: SiteOnlineStatus) => {
    if (!mapRef.current) return;
    if (openSite === site) mapRef.current.closeSite();
    else mapRef.current.focusSite(site);
  };

  const _setOpenSite = (site) => {
    if (openSite !== site) setOpenSite(site);
  };

  const closeSite = () => {
    if (!mapRef.current) return;
    setOpenSite(undefined);
  };

  return (
    <div className={styles.container}>
      <MapArea
        sidebarCollapsed={sidebarCollapsed}
        ref={mapRef}
        initialBounds={initialBounds}
        openSite={openSite}
        onOpenSite={_setOpenSite}
        onCloseSite={closeSite}
        query={query}
        handleQueryChange={handleQueryChange}
        handleListClick={onSiteClick}
        siteStatuses={siteDataForMap}
        onlyShowingIncidents={onlyShowingIncidents}
        setOnlyShowingIncidents={setOnlyShowingIncidents}
      />
    </div>
  );
};

export default MapAreaContainer;
