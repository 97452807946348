import {
  SubscribedThermostatReadingType,
  SubscribedThermostats,
  MapNumberToFanMode,
  MapNumberToThermostatWorkingMode,
} from '@energybox/react-ui-library/dist/types';
import {
  isDefined,
  normalizeSubscribedThermostat,
} from '@energybox/react-ui-library/dist/utils';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions as StreamActions } from '../actions/streamApi';

const initialState = {
  connection: {},
  subscribeReportById: {},
  reportStatusById: {},
};
export type ReportStatusById = {
  id: {};
  jobState: string;
};

export type SubscribedReportWorker = {
  connection: any;
  subscribeReportById: any;
  reportStatusById: any;
};

const subscribedReportWorker = (state = initialState, action: any) => {
  switch (action.type) {
    case StreamActions.CONNECTED: {
      return pipe(assocPath(['connection'], action.payload))(state);
    }

    case StreamActions.ERROR: {
      return pipe(assocPath(['connection'], action.error))(state);
    }

    case StreamActions.CLOSED: {
      return pipe(assocPath(['connection'], action.reason))(state);
    }

    case StreamActions.RECEIVED_SUBSCRIBED_REPORT: {
      return pipe(
        assocPath(['subscribeReportById', action.payload], action.payload)
      )(state);
    }

    case StreamActions.RECEIVED_REPORT_STATUS: {
      return {
        ...state,
        reportStatusById: action.payload,
      };
    }

    default:
      return state;
  }
};

export default subscribedReportWorker;
