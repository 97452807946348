import {
  Comment,
  CurrentUser,
  User,
  UsersById,
  ValueType,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  determineCommentValue,
} from '@energybox/react-ui-library/dist/utils';
import parseISO from 'date-fns/parseISO';

export const filterAndProcessComments = (
  comments: Comment[] = [],
  resourceIds: number[] = [],
  resourceValueType: ValueType,
  currentUser: CurrentUser
) => {
  if (comments && resourceIds) {
    const filteredComments = comments.filter((c) => {
      return (
        resourceIds.includes(c.resourceId) && resourceValueType === c.valueType
      );
    });

    if (resourceValueType === 'ENERGY') {
      return filteredComments.map((c) => {
        return {
          ...c,
          timestamp: parseISO(c.from).valueOf(),
          userName: c.username || global.NOT_AVAILABLE,
          commentValue: 0,
        };
      });
    }

    return filteredComments.map((c) => {
      return {
        ...c,
        timestamp: parseISO(c.from).valueOf(),
        commentValue: determineCommentValue(
          c.value,
          c.valueType,
          currentUser,
          'forDisplayingInChart'
        ) as number,
        userName: c.username || global.NOT_AVAILABLE,
      };
    });
  }
  return undefined;
};

export const getCurrentValueFromTimestamp = (
  timestamp: string,
  formattedData: any[]
) => {
  if (formattedData.length === 0) return undefined;
  const timestampInMilliseconds = new Date(timestamp).getTime();
  const dataPoint = formattedData.reduce((prev, curr) => {
    return Math.abs(curr.timestamp - timestampInMilliseconds) <
      Math.abs(prev.timestamp - timestampInMilliseconds)
      ? curr
      : prev;
  });
  return dataPoint.current;
};
