import {
  EnergyApiFilter,
  RecentPowerFromEquipmentResponse,
} from '@energybox/react-ui-library/dist/types';
import { Service } from '../config';
import { fetchApi } from '../utils/fetchApi';

export enum Actions {
  ENERGY_BY_EQUIPMENT_ID_SUCCESS = '@@energy/ENERGY_BY_EQUIPMENT_ID_SUCCESS',
  ENERGY_BY_EQUIPMENT_ID_LOADING = '@@energy/ENERGY_BY_EQUIPMENT_ID_LOADING',
  ENERGY_BY_EQUIPMENT_ID_ERROR = '@@energy/ENERGY_BY_EQUIPMENT_ID_ERROR',

  GET_EQUIPMENT_POWER_BY_SITE_ID_LOADING = '@@energy/GET_EQUIPMENT_POWER_BY_SITE_ID_LOADING',
  GET_EQUIPMENT_POWER_BY_SITE_ID_ERROR = '@@energy/GET_EQUIPMENT_POWER_BY_SITE_ID_ERROR',
  GET_EQUIPMENT_POWER_BY_SITE_ID_SUCCESS = '@@energy/GET_EQUIPMENT_POWER_BY_SITE_ID_SUCCESS',

  GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_LOADING = '@@energy/GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_LOADING',
  GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_ERROR = '@@energy/GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_ERROR',
  GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_SUCCESS = '@@energy/GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_SUCCESS',

  GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING = '@@energy/GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING',
  GET_ENERGY_DASHBOARD_BY_SITE_ID_ERROR = '@@energy/GET_ENERGY_DASHBOARD_BY_SITE_ID_ERROR',
  GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS = '@@energy/GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS',

  GET_SITE_ENERGY_CONSUMPTION_LOADING = '@@energy/GET_SITE_ENERGY_CONSUMPTION_LOADING',
  GET_SITE_ENERGY_CONSUMPTION_ERROR = '@@energy/GET_SITE_ENERGY_CONSUMPTION_ERROR',
  GET_SITE_ENERGY_CONSUMPTION_SUCCESS = '@@energy/GET_SITE_ENERGY_CONSUMPTION_SUCCESS',

  GET_ENERGYPRO_BY_SITE_ID_LOADING = '@@energy/GET_ENERGYPRO_BY_SITE_ID_LOADING',
  GET_ENERGYPRO_BY_SITE_ID_ERROR = '@@energy/GET_ENERGYPRO_BY_SITE_ID_ERROR',
  GET_ENERGYPRO_BY_SITE_ID_SUCCESS = '@@energy/GET_ENERGYPRO_BY_SITE_ID_SUCCESS',

  GET_ENERGYPROS_LOADING = '@@energy/GET_ENERGYPROS_LOADING',
  GET_ENERGYPROS_SUCCESS = '@@energy/GET_ENERGYPROS_SUCCESS',
  GET_ENERGYPROS_ERROR = '@@energy/GET_ENERGYPROS_ERROR',

  GET_ENERGY_CO2_EMISSION_BY_SITE_ID_LOADING = '@@energy/GET_ENERGY_CO2_EMISSION_BY_SITE_ID_LOADING',
  GET_ENERGY_CO2_EMISSION_BY_SITE_ID_SUCCESS = '@@energy/GET_ENERGY_CO2_EMISSION_BY_SITE_ID_SUCCESS',
  GET_ENERGY_CO2_EMISSION_BY_SITE_ID_ERROR = '@@energy/GET_ENERGY_CO2_EMISSION_BY_SITE_ID_ERROR',
}
const baseUrl = '/api/v1/ioe-energy';

export const setEnergyFilter = (filter?: EnergyApiFilter): string => {
  const queryParams = new URLSearchParams();

  //filter by equipmentId
  if (filter && filter.id) {
    queryParams.set('id', filter.id.toString());
  }

  if (filter && filter.from) {
    queryParams.set('from', filter.from.toString());
  }

  if (filter && filter.to) {
    queryParams.set('to', filter.to.toString());
  }

  if (filter && filter.aggregationLevel) {
    queryParams.set('aggregationLevel', filter.aggregationLevel.toString());
  }

  return queryParams.toString();
};

//IMPORTANT: IAM IOE endpoints receive from/to timestamps in seconds

export const getEnergyByEquipmentId = (
  equipmentId: number | string,
  queryParams: EnergyApiFilter,
  notificationId?: number | string,
  idAffix?: string
) => ({
  type: 'API_GET',
  path: `${baseUrl}/consumption/equipment/${equipmentId}?${setEnergyFilter(
    queryParams
  )}`,
  loading: {
    type: Actions.ENERGY_BY_EQUIPMENT_ID_LOADING,
    equipmentId: idAffix ? equipmentId + idAffix : equipmentId,
    notificationId,
  },
  success: {
    type: Actions.ENERGY_BY_EQUIPMENT_ID_SUCCESS,
    equipmentId: idAffix ? equipmentId + idAffix : equipmentId,
    notificationId,
  },
  error: {
    type: Actions.ENERGY_BY_EQUIPMENT_ID_ERROR,
    equipmentId: idAffix ? equipmentId + idAffix : equipmentId,
    notificationId,
  },
});

export const fetchRecentPowerByEquipment = (
  equipmentId: number
): Promise<RecentPowerFromEquipmentResponse> =>
  fetchApi({
    endpoint: `${baseUrl}/recent-power/equipment/${equipmentId}`,
  });

export const getEnergyCO2EmissionBySiteId = (
  siteId: string | number,
  startDate: string,
  endDate: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/site_co2_emission_converter/${siteId}/${startDate}/${endDate}`,
  loading: {
    type: Actions.GET_ENERGY_CO2_EMISSION_BY_SITE_ID_LOADING,
    siteId,
  },
  success: {
    type: Actions.GET_ENERGY_CO2_EMISSION_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.GET_ENERGY_CO2_EMISSION_BY_SITE_ID_ERROR,
    siteId,
  },
});

export const getEnergyDashboardBySiteId = (
  siteId: string | number,
  queryParams: EnergyApiFilter
) => ({
  type: 'API_GET',
  path: `${baseUrl}/dashboard/site/${siteId}?${setEnergyFilter(queryParams)}`,
  loading: {
    type: Actions.GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING,
    siteId,
  },
  success: {
    type: Actions.GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.GET_ENERGY_DASHBOARD_BY_SITE_ID_ERROR,
    siteId,
  },
});

export const getEquipmentEnergyPowerBySiteId = (siteId: number) => ({
  type: 'API_GET',
  path: `${baseUrl}/recent-power/equipment-in-site/${siteId}`,
  loading: { type: Actions.GET_EQUIPMENT_POWER_BY_SITE_ID_LOADING, siteId },
  success: { type: Actions.GET_EQUIPMENT_POWER_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_EQUIPMENT_POWER_BY_SITE_ID_ERROR, siteId },
});

export const getSiteEnergyConsumption = (
  siteId: string | number,
  queryParams: EnergyApiFilter,
  key: string
) => ({
  type: 'API_GET',
  path: `${baseUrl}/total/site/${siteId}?${setEnergyFilter(queryParams)}`,
  loading: { type: Actions.GET_SITE_ENERGY_CONSUMPTION_LOADING, siteId, key },
  success: { type: Actions.GET_SITE_ENERGY_CONSUMPTION_SUCCESS, siteId, key },
  error: { type: Actions.GET_SITE_ENERGY_CONSUMPTION_ERROR, siteId, key },
});

export const getEnergySensorsByEquipmentId = (equipmentId: number) => ({
  type: 'API_GET',
  path: `/api/v1/ioe-energy-sensors/equipment/${equipmentId}`,
  loading: {
    type: Actions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_LOADING,
    equipmentId,
  },
  success: {
    type: Actions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_SUCCESS,
    equipmentId,
  },
  error: {
    type: Actions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_ERROR,
    equipmentId,
  },
});

export const getEnergyPros = () => ({
  type: 'API_GET',
  path: `/api/v1/energy-pro`,
  loading: { type: Actions.GET_ENERGYPROS_LOADING },
  success: { type: Actions.GET_ENERGYPROS_SUCCESS },
  error: { type: Actions.GET_ENERGYPROS_ERROR },
});

export const getEnergyProBySiteId = (siteId: string | number) => ({
  type: 'API_GET',
  path: `/api/v1/energy-pro/site/${siteId}`,
  loading: { type: Actions.GET_ENERGYPRO_BY_SITE_ID_LOADING, siteId },
  success: { type: Actions.GET_ENERGYPRO_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_ENERGYPRO_BY_SITE_ID_ERROR, siteId },
});
