import React from 'react';
import { useHistory } from 'react-router';
import { useTimeFilter } from '../../hooks/useFilters';
import useAppLocale from '../../hooks/useAppLocale';
import {
  getDateFiltersWithoutTodayOrYesterday,
  createNotificationDateFilter,
  global,
} from '@energybox/react-ui-library/dist/utils';
import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import { SearchBoxProps } from '@energybox/react-ui-library/dist/types';
import {
  DateFilter,
  Table,
  SearchBox,
} from '@energybox/react-ui-library/dist/components';
import { TableProps } from '@energybox/react-ui-library/dist/components/Table';
import BaseFilterBar from '../Filters/BaseFilterBar';
import BigNumber, { BigNumberProps } from '../ui/BigNumber';
import styles from './SeeAllPage.module.css';
import { PageContentHeader, PageContentWrapper } from '../Page';
import theme from '../../styles/theme';
import FiltersContainer from '../Filters/FiltersContainer/FiltersContainer';
import BigNumberGroup from '../ui/BigNumberContainer/BigNumberGroup';
import { TableWrapper } from '../Tables';

import tableStyles from '../../components/Tables/table.module.css';
import { displayCount } from '../Tables/TableWrapper/TableWrapper';

type Props = {
  backRoute: string;
  title: string;
  displayCountText?: string;
  children?: React.ReactNode;
  errorMessage?: string | undefined;
  tabs?: React.ReactNodeArray;
  filters?: React.ReactNodeArray;
  disableDateFilter?: boolean;
  datePickerType?: 'date' | 'datetime' | 'monthly' | 'yearly' | undefined;
  customDateFilter?: React.ReactNode;
  summaryStatistics?: BigNumberProps[];
  tableProps?: TableProps;
  includeTodayDateFilter?: boolean;
  alternativeContent?: React.ReactNode;
  headerName: string;
  downloadButton?: React.ReactNode;
  searchProps?: Partial<SearchBoxProps>;
};

const SeeAllPage = ({
  backRoute,
  title,
  displayCountText = '',
  children,
  errorMessage,
  tabs,
  filters,
  disableDateFilter = false,
  datePickerType = 'datetime',
  customDateFilter = null,
  summaryStatistics,
  tableProps,
  includeTodayDateFilter = false,
  alternativeContent,
  headerName,
  downloadButton,
  searchProps,
}: Props) => {
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const locale = useAppLocale();
  const history = useHistory();
  const { timePeriod, setTimeFilter } = useTimeFilter();

  let processedTableProps = {} as TableProps;
  if (tableProps?.groupHeaders)
    tableProps.groupHeaders.map(
      (p) => (p.className += ` ${tableStyles.groupHeader}`)
    );
  if (isMobile && tableProps?.columns) {
    processedTableProps = {
      ...tableProps,
      columns: [
        {
          ...tableProps.columns[0],
          highlightIsActive: false,
          showSortIcons: false,
          width: '8rem',
        },
      ],
      scrollableColumns: tableProps.columns.slice(1).map((column) => ({
        ...column,
        highlightIsActive: false,
        showSortIcons: false,
      })),
      highlightScrollableColumns: false,
      showHeaders: true,
      noHeaderShadow: true,
      headerClassName: styles.mobileHeader,
      rowHeight: '3rem',
    } as TableProps;
  } else if (tableProps) {
    processedTableProps = tableProps;
  }

  return (
    <div className={styles.root}>
      <BaseFilterBar
        navigateBack={() => history.push(backRoute)}
        className={isMobile ? styles.mobileTopBar : ''}
      >
        <div className={styles.barHeader}>{title}</div>
      </BaseFilterBar>
      <PageContentWrapper hasPadding>
        {(searchProps || headerName) && (
          <PageContentHeader header={headerName}>
            {searchProps && (
              <SearchBox
                {...searchProps}
                placeholder="Quick Search"
                width={
                  isMobile
                    ? theme.size.table.searchBox.mobile
                    : theme.size.table.searchBox.web
                }
                widthActive={
                  isMobile
                    ? theme.size.table.searchBox.mobile
                    : theme.size.table.searchBox.web
                }
              />
            )}
          </PageContentHeader>
        )}
        {alternativeContent !== undefined ? (
          alternativeContent
        ) : (
          <>
            <FiltersContainer growFirst>
              <FiltersContainer>{filters}</FiltersContainer>
              {disableDateFilter === false &&
                (!customDateFilter || customDateFilter == null) && (
                  <DateFilter
                    value={timePeriod}
                    setFilter={setTimeFilter}
                    optionCreator={
                      includeTodayDateFilter
                        ? createNotificationDateFilter
                        : getDateFiltersWithoutTodayOrYesterday
                    }
                    customPickerVariant={datePickerType}
                    alignItemsRight={true}
                    locale={locale}
                  />
                )}
              {disableDateFilter === false &&
                customDateFilter &&
                customDateFilter}
              {!!downloadButton && downloadButton}
            </FiltersContainer>

            {tabs && (
              <FiltersContainer growFirst>
                <FiltersContainer>{tabs}</FiltersContainer>
              </FiltersContainer>
            )}

            {(errorMessage === undefined || errorMessage === '') && (
              <>
                <BigNumberGroup>
                  {summaryStatistics !== undefined &&
                    summaryStatistics.map((props, index) => {
                      if (index === summaryStatistics.length - 1 && !isMobile) {
                        return <BigNumber {...props} />;
                      } else {
                        return [
                          <BigNumber {...{ ...props, isRow: isMobile }} />,
                        ];
                      }
                    })}
                </BigNumberGroup>
                {tableProps !== undefined && (
                  <TableWrapper
                    header={
                      tableProps.dataIsLoading
                        ? global.NOT_AVAILABLE
                        : displayCount(
                            processedTableProps.data.length,
                            displayCountText
                          )
                    }
                  >
                    <Table {...processedTableProps} />
                  </TableWrapper>
                )}
                {children !== undefined && children}
              </>
            )}
            {errorMessage !== undefined && errorMessage !== '' && (
              <span>{errorMessage}</span>
            )}
          </>
        )}
      </PageContentWrapper>
    </div>
  );
};

export default SeeAllPage;
