import { useSelector } from 'react-redux';
import { EquipmentTags } from '../reducers/equipmentTags';
import {
  EquipmentType,
  EquipmentTypesById,
} from '@energybox/react-ui-library/dist/types';
import { mapArrayToObject } from '@energybox/react-ui-library/dist/utils';

export function useEquipmentTypes() {
  const equipmentTypes: EquipmentType[] = useSelector(
    (state: { equipmentTags: EquipmentTags }) => {
      return state.equipmentTags.equipmentTypes;
    }
  );
  return equipmentTypes;
}

export function useEquipmentTypesById(): EquipmentTypesById {
  const equipmentTypes: EquipmentType[] = useEquipmentTypes();
  return mapArrayToObject(equipmentTypes);
}
