const newApiBase = '/api/v1/thermostat';

export enum Actions {
  GET_THERMOSTAT_BY_ID_LOADING = '@thermostat/GET_THERMOSTAT_BY_ID_LOADING',
  GET_THERMOSTAT_BY_ID_SUCCESS = '@thermostat/GET_THERMOSTAT_BY_ID_SUCCESS',
  GET_THERMOSTAT_BY_ID_ERROR = '@thermostat/GET_THERMOSTAT_BY_ID_ERROR',

  GET_THERMOSTAT_DETAIL_BY_ID_LOADING = '@thermostat/GET_THERMOSTAT_DETAIL_BY_ID_LOADING',
  GET_THERMOSTAT_DETAIL_BY_ID_SUCCESS = '@thermostat/GET_THERMOSTAT_DETAIL_BY_ID_SUCCESS',
  GET_THERMOSTAT_DETAIL_BY_ID_ERROR = '@thermostat/GET_THERMOSTAT_DETAIL_BY_ID_ERROR',
}

export const getThermostatById = (id: number | string) => ({
  type: 'API_GET',
  path: `${newApiBase}/${id}`,
  loading: {
    type: Actions.GET_THERMOSTAT_BY_ID_LOADING,
    id,
  },
  success: {
    type: Actions.GET_THERMOSTAT_BY_ID_SUCCESS,
    id,
  },
  error: {
    type: Actions.GET_THERMOSTAT_BY_ID_ERROR,
    id,
  },
});

const superHubApiBase = '/api/v1/superHub/admin-portal';

export const getThermostatDetailById = (id: number | string) => ({
  type: 'API_GET',
  path: `${superHubApiBase}/getThermostatDetailById?thermostatId=${id}`,
  loading: {
    type: Actions.GET_THERMOSTAT_DETAIL_BY_ID_LOADING,
    id,
  },
  success: {
    type: Actions.GET_THERMOSTAT_DETAIL_BY_ID_SUCCESS,
    id,
  },
  error: {
    type: Actions.GET_THERMOSTAT_DETAIL_BY_ID_ERROR,
    id,
  },
});
