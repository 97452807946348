import {
  Checkbox,
  ErrorBoundary,
  ResetButton,
} from '@energybox/react-ui-library/dist/components';
import { Incident, Notification } from '@energybox/react-ui-library/dist/types';
import {
  isDefined,
  mapArrayToObject,
  mergeOverrideActivityData,
  mergeTimeSeriesData,
  generateResponsiveXTicks,
} from '@energybox/react-ui-library/dist/utils';
import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import { DateTime } from 'luxon';

import React, { useMemo, useState } from 'react';
import { useLocalChartUtils } from '../../hooks/useChartZoomUtils';
import useGetSystemOverrideActivity from '../../hooks/useGetSystemOverrideActivity';
import useAppLocale from '../../hooks/useAppLocale';
import { useSite } from '../../hooks/useSites';
import useThermostatActivity from '../../hooks/useThermostatActivity';
import { useGetHvacControlsBySiteId } from '../../hooks/controls/hvac';
import {
  determineFetchTimeRangeForNotificationChart,
  getIdsForNotificationChart,
} from '../../utils/notifications';
import ThermostatActivityChart from '../../components/ThermostatActivityChart';
import ThermostatBarChart from '../../components/ThermostatBarChart';
import styles from './NotificationThermostatChartContainer.module.css';

interface Props {
  featureNotification?: Notification;
  featureIncident?: Incident;
}

const NotificationThermostatChartContainer: React.FC<Props> = ({
  featureNotification,
  featureIncident,
}) => {
  // ** START useState **//
  const [isBarChartShowing, setIsBarChartShowing] = useState(false);

  // https://github.com/recharts/recharts/issues/1980 Syncing charts requires
  // the data arrays to directly correspond based on index positions of data
  // points in the provided data array. Instead, set the time corresponding to
  // the data moused over in ThermostatActivityChart and draw a reference line
  // in ThermostatBarChart at that location
  const [chartMouseoverTime, setChartMouseoverTime] = useState(0);
  // ** END useState **//

  const { siteId, notificationId } = getIdsForNotificationChart(
    featureNotification,
    featureIncident
  );

  const { fromDate: initialFromDate, toDate: initialToDate } =
    determineFetchTimeRangeForNotificationChart(
      featureNotification,
      featureIncident
    );

  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const timezone = useSite(siteId)?.timeZone;
  const locale = useAppLocale();
  const {
    refAreaStart,
    setRefAreaStart,
    refAreaEnd,
    setRefAreaEnd,
    zoomIn,
    chartFromDate,
    chartToDate,
    onZoomReset,
  } = useLocalChartUtils(initialFromDate, initialToDate);

  const thermostatId = useMemo(() => {
    if (featureNotification) {
      return featureNotification.sensorParams[0].resourceId;
    } else if (featureIncident) {
      return featureIncident.sensorParams?.[0]?.resourceId;
    } else return undefined;
  }, [featureNotification, featureIncident]);

  const siteHvacControls = useGetHvacControlsBySiteId(siteId);
  const hvacControl = useMemo(() => {
    if (!isDefined(siteHvacControls) || !isDefined(thermostatId))
      return undefined;

    return siteHvacControls.find((hvacControl) => {
      return hvacControl.thermostatId === +thermostatId;
    });
  }, [siteHvacControls, thermostatId]);

  const xTicks = useMemo(() => {
    return generateResponsiveXTicks({
      fromDate: chartFromDate,
      toDate: chartToDate,
      options: {
        ianaTimeZone: timezone,
      },
      isMobile,
    });
  }, [chartFromDate, chartToDate, timezone, isMobile]);

  const chartFromDateInJSDate = useMemo(() => {
    return DateTime.fromMillis(chartFromDate).setZone(timezone).toJSDate();
  }, [chartFromDate, timezone]);
  const chartToDateInJSDate = useMemo(() => {
    return DateTime.fromMillis(chartToDate).setZone(timezone).toJSDate();
  }, [chartToDate, timezone]);

  const {
    isLoading: isThermostatActivityLoading = false,
    data: thermostatActivityData = [],
  } =
    useThermostatActivity(
      thermostatId,
      notificationId,
      chartFromDateInJSDate,
      chartToDateInJSDate
    ) || {};

  const {
    isLoading: isSystemOverrideLoading = false,
    data: systemOverrideActivityData = undefined,
  } =
    useGetSystemOverrideActivity(
      hvacControl?.id,
      notificationId,
      chartFromDate,
      chartToDate
    ) || {};

  //TODO: add localOverrideActivityData + actuator portType once available from BE
  const mergedOverrideActivityDataObject = useMemo(() => {
    return mergeOverrideActivityData(
      systemOverrideActivityData,
      undefined,
      undefined
    );
  }, [systemOverrideActivityData]);

  const mergedData = useMemo(() => {
    return mergeTimeSeriesData(
      mapArrayToObject(thermostatActivityData, 'timestamp'),
      mergedOverrideActivityDataObject
    );
  }, [thermostatActivityData, mergedOverrideActivityDataObject]);

  const isLoading = isThermostatActivityLoading || isSystemOverrideLoading;

  if (
    !isDefined(thermostatId) ||
    (!isDefined(featureNotification) && !isDefined(featureIncident))
  ) {
    return null;
  }
  return (
    <div className={styles.root}>
      <div className={styles.chartTitle}>
        <div className={styles.rightAlign}>
          <ResetButton
            customText="Reset Zoom"
            displayInfoTooltip
            onClick={onZoomReset}
            disabled={isLoading}
          />
        </div>
      </div>

      <ErrorBoundary>
        <div
          onMouseLeave={() => {
            // Reset the position so it doesn't display if mouse leaves the chart
            setChartMouseoverTime(0);
          }}
        >
          <ThermostatActivityChart
            ianaTimeZoneCode={timezone}
            thermostatId={+thermostatId}
            processedActivityData={mergedData}
            isLoading={isLoading}
            locale={locale}
            fromDate={chartFromDate}
            toDate={chartToDate}
            ticks={xTicks}
            refAreaStart={refAreaStart}
            refAreaEnd={refAreaEnd}
            zoomIn={zoomIn}
            updateRefAreaStart={setRefAreaStart}
            updateRefAreaEnd={setRefAreaEnd}
            isBarChartShowing={isBarChartShowing}
            setChartMouseoverTime={setChartMouseoverTime}
          />
        </div>
        {isBarChartShowing && (
          <ThermostatBarChart
            activityData={thermostatActivityData}
            chartMouseoverTime={chartMouseoverTime}
            customFromDate={chartFromDate}
            customToDate={chartToDate}
          />
        )}
        <div className={styles.chartOptionContainer}>
          <Checkbox
            onChange={() => setIsBarChartShowing(!isBarChartShowing)}
            checked={isBarChartShowing}
            label="Show Control Details"
          />
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default NotificationThermostatChartContainer;
