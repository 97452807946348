import { RadioButton } from '@energybox/react-ui-library/dist/components';
import { Marker as MarkerIcon } from '@energybox/react-ui-library/dist/icons';
import { SiteOnlineStatus } from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import debounce from 'lodash.debounce';

import React, { Component, createRef } from 'react';
import { ArrayLatLngBounds, getMinMaxLatLng } from '../../utils/map';
import Button from '../ui/Button';
import styles from './MapArea.module.css';
import MapElement, { Viewport } from './MapElement';
import MapSidebar from './MapSidebar';

type Props = {
  initialBounds?: ArrayLatLngBounds;
  onOpenSite: (Site) => void;
  onCloseSite: () => void;
  openSite?: SiteOnlineStatus;
  sidebarCollapsed: boolean;
  handleQueryChange?: any;
  query?: any;
  handleListClick?: any;
  siteStatuses: SiteOnlineStatus[];
  onlyShowingIncidents: boolean;
  setOnlyShowingIncidents: React.Dispatch<React.SetStateAction<boolean>>;
};

type State = {
  showMap: boolean;
  showAllSitesButtonVisible: boolean;
  initialViewport?: Viewport;
};

class MapArea extends Component<Props, State> {
  state = {
    showMap: false,
    showAllSitesButtonVisible: false,
    initialViewport: undefined,
  };

  constructor(props) {
    super(props);
    // Debounce this to avoid it being called too often when mutating viewport
    // or searching
    this.allSitesAreVisible = debounce(this.allSitesAreVisible.bind(this), 750);
  }

  mapRef = createRef<MapElement>();

  componentDidMount() {
    // Restore the viewport from session storage if available
    this.setState({ initialViewport: this.restoreViewport() });
    setTimeout(this.showMap, 100);
  }

  storeViewport(viewport) {
    try {
      window.sessionStorage.setItem(
        '@APP_ENERGYBOX_MAP_VIEWPORT',
        JSON.stringify(viewport)
      );
    } catch (error) {
      console.warn('Error while storing viewport');
    }
  }

  restoreViewport() {
    try {
      const viewportJSON = window.sessionStorage.getItem(
        '@APP_ENERGYBOX_MAP_VIEWPORT'
      );
      if (!viewportJSON) return;
      const initialViewport = JSON.parse(viewportJSON);
      return initialViewport;
    } catch (error) {
      console.warn('Error while restoring viewport');
    }
  }

  componentDidUpdate() {
    // See if the 'Show all' button should be visible
    // XXX: With a large number of sites this might be cause
    // lag / jitter // should be optimized
    this.allSitesAreVisible();
  }

  showMap = () => this.setState({ showMap: true });

  focusSite = (site: SiteOnlineStatus) => {
    if (!this.mapRef.current) return;
    this.mapRef.current.focusSite(site);
  };

  closeSite = () => {
    if (!this.mapRef.current) return;
    this.mapRef.current.closeSite();
  };

  onViewportChanged = (viewport) => {
    this.allSitesAreVisible();
    this.storeViewport(viewport);
  };

  allSitesAreVisible() {
    if (!this.mapRef.current) return;
    // When viewport changes we need to recalculate the visiblity of Show all sites
    // button
    const mapBounds = this.mapRef.current.getBounds();
    if (!mapBounds) return;

    const siteBounds = getMinMaxLatLng(this.props.siteStatuses);
    const southWest = mapBounds.getSouthWest();
    const northEast = mapBounds.getNorthEast();
    const inBoundsWest = southWest.lat <= siteBounds.minLat;
    const inBoundsSouth = southWest.lng <= siteBounds.minLng;
    const inBoundsEast = northEast.lat >= siteBounds.maxLat;
    const inBoundsNorth = northEast.lng >= siteBounds.maxLng;

    const showAllSitesButtonVisible = !(
      inBoundsWest &&
      inBoundsSouth &&
      inBoundsEast &&
      inBoundsNorth
    );
    if (this.state.showAllSitesButtonVisible !== showAllSitesButtonVisible)
      this.setState({ showAllSitesButtonVisible });
  }

  showAllSites = () => {
    if (!this.mapRef.current) return;
    this.mapRef.current.showAllSites();
  };

  render() {
    const { initialBounds, onlyShowingIncidents, setOnlyShowingIncidents } =
      this.props;
    const hasInitialViewport = !!this.state.initialViewport || !!initialBounds;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div className={classNames('map-container', styles.container)}>
          {/*
          The map doesn't seem to size itself correctly unless it is mounted
          after parent container has already entered DOM, so render only after
          small delay
        */}
          {!!this.state.showMap && hasInitialViewport && (
            <MapElement
              ref={this.mapRef}
              sidebarCollapsed={this.props.sidebarCollapsed}
              siteStatuses={this.props.siteStatuses}
              initialBounds={this.props.initialBounds}
              onOpenPopup={this.props.onOpenSite}
              onClosePopup={this.props.onCloseSite}
              onViewportChanged={this.onViewportChanged}
              initialViewport={this.state.initialViewport}
            />
          )}
          {!!this.state.showAllSitesButtonVisible && (
            <Button
              onClick={this.showAllSites}
              extraClassName={styles.showAllButton}
            >
              View all sites
            </Button>
          )}
          <div className={styles.legends}>
            <div className={styles.legendRow}>
              <RadioButton
                label={
                  <>
                    <div className={styles.blueMarker}>
                      <MarkerIcon size={16} />
                    </div>
                    <span>All sites</span>
                  </>
                }
                checked={onlyShowingIncidents === false}
                value="all"
                variant="traditional"
                onChange={() => setOnlyShowingIncidents(false)}
              />
            </div>
            <div className={styles.legendRow}>
              <RadioButton
                label={
                  <>
                    <div className={styles.redMarker}>
                      <MarkerIcon size={16} />
                    </div>
                    <span>Sites with Active Incident(s)</span>
                  </>
                }
                checked={onlyShowingIncidents === true}
                value="active"
                variant="traditional"
                onChange={() => setOnlyShowingIncidents(true)}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            maxHeight: '85%',
            top: '10%',
            right: '2%',
            zIndex: 500,
            backgroundColor: 'white',
            boxShadow: '3px 3px 5px 0px rgba(0,0,0,0.25)',
          }}
        >
          <MapSidebar
            query={this.props.query}
            handleQueryChange={this.props.handleQueryChange}
            handleClick={this.props.handleListClick}
            openSite={this.props.openSite}
            siteStatuses={this.props.siteStatuses}
          />
        </div>
      </div>
    );
  }
}

export default MapArea;
