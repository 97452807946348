import {
  CurrentUser,
  DateFormat,
  defaultLocale,
  Locale,
  MapDateFormat,
  MapFullTimeFormat,
  MapTimeFormat,
  MeasurementSystem,
  TimeFormat,
} from '@energybox/react-ui-library/dist/types';
import { shouldPasswordUpdateEnforced } from '@energybox/react-ui-library/dist/utils/security';
import assoc from 'ramda/src/assoc';
import pipe from 'ramda/src/pipe';
import { Actions as AppActions } from '../actions/constants/Actions';
import { Actions as StreamApiActions } from '../actions/streamApi';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import { PasswordChangeError } from '@energybox/react-ui-library/dist/components/NewPassword/NewPasswordPage/NewPasswordPage';

export const datePickerWorldFormat = 'yyyy-MM-dd';
export const datePickerUsFormat = 'MM-dd-yyyy';

export const datePickerDateFormatMap = {
  IMPERIAL: datePickerUsFormat,
  METRIC: datePickerWorldFormat,
};

export interface TimeZone {
  title: string;
  region?: string;
  commonName: string;
  offset: string;
  offsetMinutes: number;
}

export interface LoginForm {
  email: string;
  password: string;
}

export interface ForgotPasswordForm {
  email: string;
}

export interface ResetPasswordForm {
  email: string;
  password: string;
  passwordVerify: string;
}

const defaultLoginForm = {
  email: '',
  password: '',
};

const defaultForgotPasswordForm = {
  email: '',
};

const defaultResetPasswordForm = {
  email: '',
  password: '',
  passwordVerify: '',
};

export interface App {
  isInitialized: boolean;
  userIsInitialized: boolean;
  accessToken?: string;
  currentOrganizationId?: number;
  currentUser?: CurrentUser;
  locale: Locale;
  timezones?: TimeZone[];
  streamConnected: boolean;
  apiError: ApiError;
  loginForm: LoginForm;
  forgotPasswordForm: ForgotPasswordForm;
  resetPasswordForm: ResetPasswordForm;
  isResetPasswordEmailSent: boolean;
  isPasswordReset: boolean;
  isForgotPasswordLoading: boolean;
  isResetPasswordLoading: boolean;
  scrollTop: number;
  isScrollNumberActive: boolean;
  isMobileMenuOpen: boolean;
  didPasswordChangeFail: PasswordChangeError;
  lastResetEmail?: string;
  passwordUpdateEnforced?: boolean;
  newPasswordPopupDismissed: boolean;
  lastServerTimeFromApi?: string;
}

const initialState: App = {
  isInitialized: false,
  userIsInitialized: false,
  locale: defaultLocale,
  streamConnected: false,
  apiError: {},
  loginForm: defaultLoginForm,
  forgotPasswordForm: defaultForgotPasswordForm,
  resetPasswordForm: defaultResetPasswordForm,
  isResetPasswordEmailSent: false,
  isPasswordReset: false,
  isForgotPasswordLoading: false,
  isResetPasswordLoading: false,
  scrollTop: 0,
  isScrollNumberActive: false,
  isMobileMenuOpen: false,
  didPasswordChangeFail: 'no',
  newPasswordPopupDismissed: false,
};

const sanitizedApiTimezone = (data: any) => ({
  title: data.title,
  commonName: data.title.replace(/_/g, ' '),
  offset: data.offset,
  offsetMinutes: data.offsetMinutes,
});

const currentUserFromApiResponse = (data: any): CurrentUser => ({
  id: data.id,
  firstName: data.firstName,
  lastName: data.lastName,
  role: data.role,
  organizationId: data.organizationId,
  organizationTitle: data.organization && data.organization.title,
  dashboardProfiles: data.dashboardProfiles || [],
  area: data.area,
  temperature: data.temperature,
  measurementSystem: data.measurementSystem,
  userStatus: data.userStatus,
  email: data.email,
  phone: data.phone,
  passwordUpdatedAt: data.passwordUpdatedAt,
  position: data.position,
});

const tokenMeta = (token: string) => {
  if (!token) return;

  const tokenData = JSON.parse(atob(token.split('.')[1]));

  return {
    currentOrganizationId: tokenData.org,
  };
};

const determineLocale = (
  measurementSystem: MeasurementSystem | undefined,
  dateFormat: DateFormat | undefined,
  timeFormat: TimeFormat | undefined
): Locale => {
  const {
    measurementSystem: defaultMeasurementSystem,
    dateFormat: defaultDateFormat,
    timeFormat: defaultTimeFormat,
    dateTimeFormat: defaultDateTimeFormat,
    fullTimeFormat: defaultFullTimeFormat,
    fullDateTimeFormat: defaultFullDateTimeFormat,
  } = defaultLocale;

  return {
    measurementSystem: measurementSystem || defaultMeasurementSystem,
    timeFormat: timeFormat ? MapTimeFormat[timeFormat] : defaultTimeFormat,
    dateFormat: dateFormat ? MapDateFormat[dateFormat] : defaultDateFormat,
    dateTimeFormat:
      dateFormat && timeFormat
        ? `${MapDateFormat[dateFormat]} ${MapTimeFormat[timeFormat]}`
        : defaultDateTimeFormat,
    fullTimeFormat: timeFormat
      ? MapFullTimeFormat[timeFormat]
      : defaultFullTimeFormat,
    fullDateTimeFormat:
      dateFormat && timeFormat
        ? `${MapDateFormat[dateFormat]} ${MapFullTimeFormat[timeFormat]}`
        : defaultFullDateTimeFormat,
  };
};

export default (state: App = initialState, action: any) => {
  switch (action.type) {
    case AppActions.INIT:
    case AppActions.INIT_WITH_TOKEN:
      return {
        ...state,
        isInitialized: true,
      };

    case AppActions.ACCESS_TOKEN_UPDATED:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        ...tokenMeta(action.payload.accessToken),
      };

    case AppActions.UPDATE_LOGIN_FIELD:
      return {
        ...state,
        loginForm: {
          ...state.loginForm,
          [action.field]: action.value,
        },
      };

    case AppActions.UPDATE_FORGOT_PASSWORD_FIELD:
      return {
        ...state,
        forgotPasswordForm: {
          ...state.forgotPasswordForm,
          [action.field]: action.value,
        },
      };

    case AppActions.UPDATE_RESET_PASSWORD_FIELD:
      return {
        ...state,
        resetPasswordForm: {
          ...state.resetPasswordForm,
          [action.field]: action.value,
        },
      };

    case AppActions.SET_LAST_SERVER_TIME:
      return assoc('lastServerTimeFromApi', action.time)(state);

    case AppActions.LOGIN_SUCCESS:
      return pipe(
        assoc('loginForm', defaultLoginForm),
        assoc('apiError', {}),
        assoc('isResetPasswordEmailSent', false),
        assoc('isPasswordReset', false)
      )(state);

    case AppActions.LOGIN_ERROR:
      return {
        ...state,
        apiError: storeAPIerror(action),
      };

    case AppActions.FORGOT_PASSWORD_SUCCESS:
      return pipe(
        assoc('apiError', {}),
        assoc('isResetPasswordEmailSent', true),
        assoc('isForgotPasswordLoading', false),
        assoc('lastResetEmail', action.email)
      )(state);

    case AppActions.FORGOT_PASSWORD_LOADING:
      return assoc('isForgotPasswordLoading', true, state);

    case AppActions.FORGOT_PASSWORD_ERROR:
      return pipe(
        assoc('apiError', storeAPIerror(action)),
        assoc('isForgotPasswordLoading', false)
      )(state);

    case AppActions.RESET_PASSWORD_SUCCESS:
      return pipe(
        assoc('apiError', {}),
        assoc('isPasswordReset', true),
        assoc('isResetPasswordLoading', false)
      )(state);

    case AppActions.RESET_PASSWORD_LOADING:
      return assoc('isResetPasswordLoading', true, state);

    case AppActions.RESET_PASSWORD_ERROR:
      return pipe(
        assoc('apiError', storeAPIerror(action)),
        assoc('isResetPasswordLoading', false)
      )(state);

    case AppActions.CURRENT_USER_SUCCESS:
      const { measurementSystem, timeFormat, dateFormat } = action.payload;
      const currentUser = currentUserFromApiResponse(action.payload);
      return pipe(
        assoc('currentUser', currentUser),
        assoc(
          'passwordUpdateEnforced',
          shouldPasswordUpdateEnforced(
            currentUser,
            process.env.REACT_APP_ENFORCE_PASSWORD_CHANGE_DATE || '2023-07-12',
            state.lastServerTimeFromApi
          )
        ),
        assoc(
          'locale',
          determineLocale(measurementSystem, dateFormat, timeFormat)
        )
      )(state);

    case AppActions.DISMISS_NEW_PASSWORD_POPUP:
      return assoc('newPasswordPopupDismissed', true)(state);

    case AppActions.USER_SITE_CURRENT_SITE: {
      return {
        ...state,
        currentUserSiteId: action.payload,
      };
    }

    case AppActions.USER_TYPE_SUCCESS: {
      return {
        ...state,
        userIsInitialized: true,
        currentUserSiteId: action.payload.siteId,
      };
    }
    case AppActions.ACCESS_TOKEN_CLEARED:
      return {
        ...initialState,
        isInitialized: true,
      };

    case AppActions.GET_TIMEZONES_SUCCESS:
      return {
        ...state,
        timezones: action.payload.map(sanitizedApiTimezone),
      };

    case StreamApiActions.CONNECTED:
      return {
        ...state,
        streamConnected: true,
      };

    case StreamApiActions.CLOSED:
      return {
        ...state,
        streamConnected: false,
      };

    case AppActions.UPDATE_SCROLL_POSITION:
      return {
        ...state,
        scrollTop: action.value,
      };

    case AppActions.ACTIVATE_UPDATE_SCROLL_POSITION:
      return {
        ...state,
        isScrollNumberActive: action.value,
      };

    case AppActions.TOGGLE_MOBILE_MENU:
      return assoc('isMobileMenuOpen', !state.isMobileMenuOpen, state);

    case AppActions.USER_CHANGE_PASSWORD_LOADING:
    case AppActions.USER_CHANGE_PASSWORD_SUCCESS:
      return assoc('didPasswordChangeFail', 'no', state);

    case AppActions.USER_CHANGE_PASSWORD_ERROR:
      let error: PasswordChangeError = 'error';
      if (action.payload.message === 'your old password is not correct')
        error = 'authError';
      return assoc('didPasswordChangeFail', error, state);

    case AppActions.USER_CHANGE_PASSWORD_CLEAR_AUTH_ERROR:
      if (state.didPasswordChangeFail === 'authError')
        return assoc('didPasswordChangeFail', 'no', state);
      return state;

    default:
      return state;
  }
};

export const isSignedIn = (state: App) => {
  return !!state.accessToken;
};
