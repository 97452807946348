import React from 'react';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import styles from './Button.module.css';

type Props = {
  onClick?: any;
  isLoading?: boolean;
  secondary?: boolean;
  width?: string;
  fullWidth?: boolean;
  size?: 'small' | 'regular' | 'large';
  icon?: boolean;
  extraClassName?: string;
};

type ButtonProps = Pick<
  React.HTMLProps<HTMLButtonElement>,
  Exclude<keyof React.HTMLProps<HTMLButtonElement>, 'size'>
>;

export const Button: React.FunctionComponent<Props & ButtonProps> = ({
  children,
  size = 'regular',
  disabled = false,
  secondary = false,
  fullWidth = false,
  isLoading = false,
  type = 'button',
  icon = false,
  extraClassName,
  ...rest
}) => (
  <button
    className={classNames(
      styles.button,
      fullWidth ? styles.fullWidth : styles.autoWidth,
      !secondary && !icon ? styles.primary : null,
      secondary ? styles.secondary : null,
      disabled ? styles.disabled : null,
      !icon ? styles[size] : null,
      isLoading ? styles.isLoading : null,
      icon ? styles.icon : null,
      extraClassName
    )}
    disabled={disabled || isLoading}
    {...rest}
  >
    {children}
  </button>
);

export default Button;
