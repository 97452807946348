import {
  FlatNotification,
  Notification,
  SentinelType,
} from '@energybox/react-ui-library/dist/types';
import { deriveDateValue } from '@energybox/react-ui-library/dist/utils';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/notifications';
import {
  filterFlatNotificationsOutsideDesiredRange,
  filterNotificationsOutsideDesiredRange,
} from '../utils/notifications';

const initialState = {
  isLoading: false,
  isSiteNotificationsCountLoading: false,
  isEquipmentNotificationsCountLoading: false,
  isLoadingByResourceId: {},
  notifications: [],
  notificationsByResourceId: {},
  flatNotificationsBySensorId: {},
  flatNotificationsByFeatureNotificationId: {},
  flatNotificationsByEquipmentId: {},
  notificationsByEquipmentId: {},
  siteNotificationsCountBySiteId: {},
  equipmentNotificationsCountBySiteId: {},
};

export type Notifications = {
  isLoading: boolean;
  isSiteNotificationsCountLoading: boolean;
  isEquipmentNotificationsCountLoading: boolean;
  isLoadingByResourceId: IsLoadingByResourceId;
  notifications: Notification[];
  notificationsByResourceId: NotificationsById;
  flatNotificationsBySensorId: FlatNotificationsById;
  flatNotificationsByFeatureNotificationId: FlatNotificationsById;
  flatNotificationsByEquipmentId: FlatNotificationsById;
  notificationsByEquipmentId: NotificationsById;
  siteNotificationsCountBySiteId: SiteNotificationsCountBySiteId;
  equipmentNotificationsCountBySiteId: EquipmentNotificationsCountBySiteId;
};

export type IsLoadingByResourceId = {
  [resourceId: string]: boolean;
};

export type EquipmentNotificationsCountBySiteId = {
  [siteId: string]: NotificationsCountByEquipmentId;
};

export type NotificationsCountByEquipmentId = {
  [equipmentId: string]: NotificationCountByType;
};

export type SiteNotificationsCountBySiteId = {
  [siteId: string]: NotificationCountByType;
};

export type NotificationCountByType = {
  [type in SentinelType]: number;
};

export type NotificationsById = {
  [id: string]: Notification[];
};

export type FlatNotificationsById = {
  [id: string]: FlatNotification[];
};

function reverseHandlingLogs(notification: Notification): Notification {
  const { handlingLogs, ...rest } = notification;
  return {
    ...rest,
    handlingLogs: [...handlingLogs].reverse(),
  };
}

export function sortByDateDescending(a: Notification, b: Notification): number {
  return (
    deriveDateValue(b.handlingLogs[0].at) -
    deriveDateValue(a.handlingLogs[0].at)
  );
}

export function sortFlatNotificationsByDateAscending(
  a: FlatNotification,
  b: FlatNotification
): number {
  return deriveDateValue(a.at) - deriveDateValue(b.at);
}

const formatNotificationsCountApiReturn = (
  payload: NotificationCountByType
) => {
  let formattedPayload = {};

  if (payload) {
    Object.keys(payload).forEach((key: string) => {
      formattedPayload[SentinelType[key]] = payload[key];
    });
  }

  return formattedPayload;
};

const notifications = (state: Notifications = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_LOADING:
      return assocPath(
        ['isLoadingByResourceId', action.notificationId || action.sensorId],
        true,
        state
      );

    case Actions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_ERROR:
      return assocPath(
        ['isLoadingByResourceId', action.notificationId || action.sensorId],
        false,
        state
      );

    case Actions.GET_FLAT_NOTIFICATIONS_BY_SENSOR_ID_SUCCESS:
      return pipe(
        assocPath(
          action.notificationId
            ? [
                'flatNotificationsByFeatureNotificationId',
                action.notificationId,
              ]
            : ['flatNotificationsBySensorId', action.sensorId],
          filterFlatNotificationsOutsideDesiredRange(
            action.payload,
            action.timePeriod
          ).sort(sortFlatNotificationsByDateAscending)
        ),
        assocPath(
          ['isLoadingByResourceId', action.notificationId || action.sensorId],
          false
        )
      )(state);

    case Actions.GET_NOTIFICATIONS_BY_SITE_ID_LOADING:
      return assocPath(['isLoadingByResourceId', action.siteId], true, state);

    case Actions.GET_NOTIFICATIONS_BY_SITE_ID_SUCCESS: {
      return pipe(
        assocPath(
          ['notificationsByResourceId', action.siteId],
          filterNotificationsOutsideDesiredRange(
            action.payload,
            action.timePeriod
          )
            .map((n) => reverseHandlingLogs(n))
            .sort(sortByDateDescending)
        ),
        assocPath(['isLoadingByResourceId', action.siteId], false)
      )(state);
    }

    case Actions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_LOADING:
    case Actions.GET_NOTIFICATIONS_BY_EQUIPMENT_ID_LOADING:
      return assocPath(
        ['isLoadingByResourceId', action.equipmentId],
        true,
        state
      );

    case Actions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_ERROR:
    case Actions.GET_NOTIFICATIONS_BY_EQUIPMENT_ID_ERROR:
      return assocPath(
        ['isLoadingByResourceId', action.equipmentId],
        false,
        state
      );

    case Actions.GET_NOTIFICATIONS_BY_EQUIPMENT_ID_SUCCESS:
      return pipe(
        assocPath(
          ['notificationsByEquipmentId', action.equipmentId],
          filterNotificationsOutsideDesiredRange(
            action.payload,
            action.timePeriod
          )
            .map((n) => reverseHandlingLogs(n))
            .sort(sortByDateDescending)
        ),
        assocPath(['isLoadingByResourceId', action.equipmentId], false)
      )(state);

    case Actions.GET_FLAT_NOTIFICATIONS_BY_EQUIPMENT_ID_SUCCESS:
      return pipe(
        assocPath(
          ['flatNotificationsByEquipmentId', action.equipmentId],
          filterFlatNotificationsOutsideDesiredRange(
            action.payload,
            action.timePeriod
          ).sort(sortFlatNotificationsByDateAscending)
        ),
        assocPath(['isLoadingByResourceId', action.equipmentId], false)
      )(state);

    case Actions.GET_SITE_NOTIFICATIONS_COUNT_BY_SITE_ID_LOADING:
      return assoc('isSiteNotificationsCountLoading', true, state);

    case Actions.GET_SITE_NOTIFICATIONS_COUNT_BY_SITE_ID_ERROR:
      return assoc('isSiteNotificationsCountLoading', false, state);

    case Actions.GET_SITE_NOTIFICATIONS_COUNT_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['siteNotificationsCountBySiteId', action.siteId],
          formatNotificationsCountApiReturn(action.payload[action.siteId])
        ),
        assoc('isSiteNotificationsCountLoading', false)
      )(state);

    case Actions.GET_EQUIPMENT_NOTIFICATIONS_COUNT_BY_SITE_ID_LOADING:
      return assoc('isEquipmentNotificationsCountLoading', true, state);

    case Actions.GET_EQUIPMENT_NOTIFICATIONS_COUNT_BY_SITE_ID_ERROR:
      return assoc('isEquipmentNotificationsCountLoading', false, state);

    case Actions.GET_EQUIPMENT_NOTIFICATIONS_COUNT_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          [
            'equipmentNotificationsCountBySiteId',
            action.siteId,
            action.equipmentId,
          ],
          formatNotificationsCountApiReturn(action.payload[action.siteId])
        ),
        assoc('isEquipmentNotificationsCountLoading', false)
      )(state);

    default:
      return state;
  }
};

export default notifications;
