import React from 'react';
import { UiIcon } from '../../../types/icon';

const ChevronUpTiny = ({ classes = {}, size = 8 }: UiIcon) => (
  <svg
    xmlnsXlink="http://www.w3.org/1999/xlink"
    className={classes.icon}
    width={size}
    height={size}
    viewBox="0 0 8 8"
  >
    <path
      d="M7 5c0 .3-.2.5-.5.5-.1 0-.3-.1-.3-.1L4 3.2 1.9 5.3c-.2.2-.5.2-.7 0s-.2-.5 0-.7l2.5-2.5c.2-.2.5-.2.7 0l2.5 2.5c.1.1.1.3.1.4z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronUpTiny;
