import { ApiObjectStatus, ObjectById, Report } from '@energybox/react-ui-library/dist/types';
import { mapValues } from '@energybox/react-ui-library/dist/utils';
import FileSaver from 'file-saver';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/reports';
import { RecentEquipmentTemperatureReport } from '../types/report';

const recentEquipmentTemperatureReportFromApi = (data: any) => ({
  title: data.title,
  uuid: data.uuid,
  sensorId: data.sensorId,
  space: data.space,
  equipment: data.equipment,
  ...(data.sample ? data.sample : {})
});

export type Reports = {
  isLoading: boolean;
  error: boolean;
  reportsBySiteId: ReportsBySiteId;
  isReportsBySiteIdLoading: boolean;
  equipmentTemperatureReportBySiteId: ObjectById<
    ApiObjectStatus<RecentEquipmentTemperatureReport[]>
  >
};

export type ReportsBySiteId = {
  [siteId: string]: Report[];
};

const initialState = {
  isLoading: false,
  error: false,
  reportsBySiteId: {},
  isReportsBySiteIdLoading: false,
  equipmentTemperatureReportBySiteId: {}
};

const reports = (state: Reports = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_REPORT_LOADING: {
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    }
    case Actions.GET_REPORT_SUCCESS: {
      const { payload, fileName, fileType } = action;
      const reportFile = new File([payload], fileName, {
        type: fileType,
      });
      FileSaver.saveAs(reportFile);
      return {
        ...state,
        isLoading: false,
        error: false,
      };
    }
    case Actions.GET_REPORT_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    }

    case Actions.GET_REPORTS_BY_SITE_ID_LOADING:
      return assoc('isReportsBySiteIdLoading', true, state);

    case Actions.GET_REPORTS_BY_SITE_ID_ERROR:
      return assoc('isReportsBySiteIdLoading', false, state);

    case Actions.GET_REPORTS_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(['reportsBySiteId', action.siteId], action.payload),
        assoc('isReportsBySiteIdLoading', false)
      )(state);
    
    case Actions.GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_SUCCESS:
      return pipe(
        assocPath(
            ['equipmentTemperatureReportBySiteId', action.siteId, 'data'],
            mapValues(
              action.payload.sensorRecords.filter(r => r.equipment),
              recentEquipmentTemperatureReportFromApi
            )
          ),
          assocPath(['equipmentTemperatureReportBySiteId', action.siteId, 'isLoading'], false)
        )(state);
  
      case Actions.GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_LOADING:
        return pipe(
          assocPath(['equipmentTemperatureReportBySiteId', action.siteId, 'isLoading'], true),
          assocPath(['equipmentTemperatureReportBySiteId', action.siteId, 'data'], [])
        )(state);
  
      case Actions.GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_ERROR:
        return pipe(
          assocPath(['equipmentTemperatureReportBySiteId', action.siteId, 'isLoading'], false),
          assocPath(['equipmentTemperatureReportBySiteId', action.siteId, 'data'], [])
        )(state);

    default:
      return state;
  }
};

export default reports;
