import {
  Gateway,
  GatewayApiFilter,
} from '@energybox/react-ui-library/dist/types';
import { fetchApi } from '../utils/fetchApi';
import { formatAPIFilters } from '../util';

export enum Actions {
  GET_GATEWAYS_BY_NETWORK_GROUP_ID_SUCCESS = '@gateways/GET_GATEWAYS_BY_NETWORK_GROUP_ID_SUCCESS',
  GET_GATEWAYS_BY_NETWORK_GROUP_ID_ERROR = '@gateways/GET_GATEWAYS_BY_NETWORK_GROUP_ID_ERROR',
  GET_GATEWAYS_BY_NETWORK_GROUP_ID_LOADING = '@gateways/GET_GATEWAYS_BY_NETWORK_GROUP_ID_LOADING',
}

const apiBase = '/api/v1/gateways';

export const fetchGateways = (
  filter?: GatewayApiFilter
): Promise<Gateway[]> => {
  const queryParams = formatAPIFilters<GatewayApiFilter>(filter);
  return fetchApi({
    endpoint: `${apiBase}?${queryParams}`,
  });
};

export const fetchGatewaysByResource = (
  resourceId: number,
  recursive: boolean = false
) =>
  fetchApi({
    endpoint: `/api/v1/gateways/resource/${resourceId.toString()}?recursive=${recursive.toString()}`,
  });

export const getGatewaysByNetworkGroupId = (
  networkGroupId: number | string | undefined
) => ({
  type: 'API_GET',
  path: `${apiBase}/network-group/${networkGroupId}`,
  success: {
    type: Actions.GET_GATEWAYS_BY_NETWORK_GROUP_ID_SUCCESS,
    networkGroupId,
  },
  error: {
    type: Actions.GET_GATEWAYS_BY_NETWORK_GROUP_ID_ERROR,
    networkGroupId,
  },
  loading: {
    type: Actions.GET_GATEWAYS_BY_NETWORK_GROUP_ID_LOADING,
    networkGroupId,
  },
});
