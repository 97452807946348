import { Site, SitesApiFilter } from '@energybox/react-ui-library/dist/types';
import { fetchApi } from '../utils/fetchApi';

const apiBase = '/api/v1/sites';

export enum Actions {
  SITES_SUCCESS = '@@app/SITES_SUCCESS',
  SITES_LOADING = '@@app/SITES_LOADING',
  SITES_ERROR = '@@app/SITES_ERROR',

  SITE_SUCCESS = '@@app/SITE_SUCCESS',
  SITE_LOADING = '@@app/SITE_LOADING',
  SITE_ERROR = '@@app/SITE_ERROR',

  GET_SITE_ONLINE_STATUS_SUCCESS = '@sites/GET_SITE_ONLINE_STATUS_SUCCESS',
  GET_SITE_ONLINE_STATUS_ERROR = '@sites/GET_SITE_ONLINE_STATUS_ERROR',
  GET_SITE_ONLINE_STATUS_LOADING = '@sites/GET_SITE_ONLINE_STATUS_LOADING',

  GET_SITES_ONLINE_STATS_SUCCESS = '@sites/GET_SITES_ONLINE_STATS_SUCCESS',
  GET_SITES_ONLINE_STATS_ERROR = '@sites/GET_SITES_ONLINE_STATS_ERROR',
  GET_SITES_ONLINE_STATS_LOADING = '@sites/GET_SITES_ONLINE_STATS_LOADING',

  GET_SITE_ASTRO_CLOCK_SUCCESS = '@sites/GET_SITE_ASTRO_CLOCK_SUCCESS',
  GET_SITE_ASTRO_CLOCK_ERROR = '@sites/GET_SITE_ASTRO_CLOCK_ERROR',
  GET_SITE_ASTRO_CLOCK_LOADING = '@sites/GET_SITE_ASTRO_CLOCK_LOADING',
}

interface GetSiteAstroClockParams {
  startDate: string;
  endDate?: string;
}

const createQueryString = (params: GetSiteAstroClockParams) => {
  return Object.keys(params)
    .map(
      (key) =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const fetchSiteStatus = (site) => {
  // @ts-ignore
  return fetchApi({ endpoint: `${apiBase}/${site.id}/online-status` });
};

export const getSiteOnlineStatus = (id: number) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}/online-status`,
  success: { type: Actions.GET_SITE_ONLINE_STATUS_SUCCESS, id },
  loading: { type: Actions.GET_SITE_ONLINE_STATUS_LOADING, id },
  error: { type: Actions.GET_SITE_ONLINE_STATUS_ERROR, id },
});

export const getSitesOnlineStats = () => ({
  type: 'API_GET',
  path: `${apiBase}/online-status`,
  success: { type: Actions.GET_SITES_ONLINE_STATS_SUCCESS },
  loading: { type: Actions.GET_SITES_ONLINE_STATS_LOADING },
  error: { type: Actions.GET_SITES_ONLINE_STATS_ERROR },
});

export const setSitesFilter = (filter?: SitesApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.equipmentIds) {
    queryParams.set('equipmentIds', filter.equipmentIds.join(','));
  }

  if (filter && filter.sensorIds) {
    queryParams.set('sensorIds', filter.sensorIds.join(','));
  }

  if (filter && filter.spaceIds) {
    queryParams.set('spaceIds', filter.spaceIds.join(','));
  }

  if (filter && filter.sensorTypes && filter.sensorTypes.length > 0) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.sensorTarget) {
    queryParams.set('sensorTarget', filter.sensorTarget);
  }

  return `${apiBase}?${queryParams.toString()}`;
};

export const fetchSites = (filter?: SitesApiFilter): Promise<Site[]> =>
  fetchApi({ endpoint: setSitesFilter(filter) });

export const getSitesByTargets = (targetIds: number[] = []): Promise<Site[]> =>
  fetchApi({
    endpoint: `${apiBase}/by-targets?targetIds=${targetIds.join(',')}`,
  });

export const getSites = (filter?: SitesApiFilter) => ({
  type: 'API_GET',
  path: setSitesFilter(filter),
  loading: Actions.SITES_LOADING,
  success: Actions.SITES_SUCCESS,
  error: Actions.SITES_ERROR,
});

export const getSite = (id: number | string) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  loading: Actions.SITE_LOADING,
  success: Actions.SITE_SUCCESS,
  error: Actions.SITE_ERROR,
});

export const fetchSiteById = (siteId: string | number): Promise<Site> =>
  fetchApi({ endpoint: `${apiBase}/${siteId}` }).catch((err) => err);

export const fetchSiteByResourceId = (resourceId: number): Promise<Site> =>
  fetchApi({
    endpoint: `/api/v1/resources/${resourceId.toString()}/site`,
  });

export const setSites = (sites: Site[]) => ({
  type: Actions.SITES_SUCCESS,
  payload: sites,
});

export const getSiteAstroClock = (
  siteId: number | string,
  startDate: string,
  endDate?: string
) => ({
  type: 'API_GET',
  path: `${apiBase}/${siteId}/astro?${createQueryString({
    startDate,
    endDate: endDate ? endDate : startDate,
  })}`,
  success: {
    type: Actions.GET_SITE_ASTRO_CLOCK_SUCCESS,
    siteId,
    startDate,
    endDate,
  },
  loading: { type: Actions.GET_SITE_ASTRO_CLOCK_LOADING, siteId },
  error: { type: Actions.GET_SITE_ASTRO_CLOCK_ERROR, siteId },
});
