//TODO: move everything from "notificationFilter" here eventually
//to have a general global filterButtons reducer
import {
  IncidentPriority,
  IncidentStatus,
  SentinelType,
} from '@energybox/react-ui-library/dist/types';

export enum Actions {
  INCIDENT_PRIORITY = '@@filters/INCIDENT_PRIORITY',
  INCIDENT_STATUS = '@@filters/INCIDENT_STATUS',
  SITE = '@@filters/SITE',
  SENTINEL_TYPE = '@@filters/SENTINEL_TYPE',
  EQUIPMENT = '@@filters/EQUIPMENT',
}

export const setIncidentPriorityFilter = (priorities: IncidentPriority[]) => ({
  type: Actions.INCIDENT_PRIORITY,
  payload: priorities,
});

export const setIncidentStatusFilter = (statuses: IncidentStatus[]) => ({
  type: Actions.INCIDENT_STATUS,
  payload: statuses,
});

export const setSiteFilter = (siteIds: number[]) => ({
  type: Actions.SITE,
  payload: siteIds,
});

export interface SetSentinelTypeFilter {
  (sentinelType: SentinelType[]): void;
}

export const setSentinelTypeFilter = (sentinelTypes: SentinelType[]) => ({
  type: Actions.SENTINEL_TYPE,
  payload: sentinelTypes,
});

export interface SetEquipmentTypeFilter {
  (equipmentTypeIds: number[]): void;
}

// TODO: should Type stay in this name? separate action for item group or ??
export const setEquipmentTypeFilter = (equipmentTypeIds: number[]) => ({
  type: Actions.EQUIPMENT,
  payload: equipmentTypeIds,
});
