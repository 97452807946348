export type ArrayLatLngBounds = [number, number][];

export type LatLngBounds = {
  maxLat: number;
  minLat: number;
  maxLng: number;
  minLng: number;
};

export function getMinMaxLatLng(points): LatLngBounds {
  const bounds = { minLat: 90, maxLat: -90, minLng: 180, maxLng: -180 };
  points.forEach(site => {
    if (site.latitude === undefined) return;
    if (site.longitude === undefined) return;
    if (parseFloat(site.latitude) < bounds.minLat)
      bounds.minLat = parseFloat(site.latitude);
    if (parseFloat(site.latitude) > bounds.maxLat)
      bounds.maxLat = parseFloat(site.latitude);
    if (parseFloat(site.longitude) < bounds.minLng)
      bounds.minLng = parseFloat(site.longitude);
    if (parseFloat(site.longitude) > bounds.maxLng)
      bounds.maxLng = parseFloat(site.longitude);
  });
  return bounds;
}

export function getBounds(points): ArrayLatLngBounds {
  const bounds = getMinMaxLatLng(points);
  return [[bounds.minLat, bounds.minLng], [bounds.maxLat, bounds.maxLng]];
}
