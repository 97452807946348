import {
  Comment,
  NewCommentFields,
  newCommentFields,
  NonRequiredCommentApiFilters,
} from '@energybox/react-ui-library/dist/types';
import { mapValues } from '@energybox/react-ui-library/dist/utils';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import dissocPath from 'ramda/src/dissocPath';
import path from 'ramda/src/path';
import pathOr from 'ramda/src/pathOr';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/comments';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';

export type Comments = {
  commentsByResourceId: CommentsByResourceId;
  newCommentsByResourceId_ResourceType: NewCommentByResourceId_ResourceType;
  isNewCommentPopupOpenByResourceId_ResourceType: IsNewCommentPopupOpenByResourceId_ResourceType;
  commentFilters: NonRequiredCommentApiFilters;
};

type NewCommentByResourceId_ResourceType = {
  [resourceId_resourceType: string]: NewCommentDataStatus;
};

type IsNewCommentPopupOpenByResourceId_ResourceType = {
  [resourceId_resourceType: string]: boolean;
};

export type NewCommentDataStatus = {
  isLoading: boolean;
  fields: NewCommentFields;
  apiError?: ApiError;
};

export type CommentsByResourceId = {
  [resourceId: string]: CommentStatus;
};

export type CommentStatus = {
  isLoading: boolean;
  comments?: Comment[];
};

const normalizeCommentFromApiResponse = (commentPayload) => ({
  comment: commentPayload.comment,
  commentType: commentPayload.commentType && commentPayload.commentType.key,
  createdAt: commentPayload.createdAt,
  from: commentPayload.from,
  id: commentPayload.id,
  orgId: commentPayload.orgId,
  resourceId: commentPayload.resourceId,
  resourceType: commentPayload.resourceType && commentPayload.resourceType.key,
  siteId: commentPayload.siteId,
  subComments: commentPayload.subComments,
  to: commentPayload.to,
  updatedAt: commentPayload.updatedAt,
  userId: commentPayload.userId,
  username: commentPayload.username,
  value: commentPayload.value,
  valueType: commentPayload.valueType && commentPayload.valueType.key,
  valueSubtype: commentPayload.valueSubtype && commentPayload.valueSubtype.key,
});

const initialState = {
  commentsByResourceId: {},
  newCommentsByResourceId_ResourceType: {},
  isNewCommentPopupOpenByResourceId_ResourceType: {},
  commentFilters: {},
};

export default (state: Comments = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_DOWNSTREAM_COMMENTS_SUCCESS:
      return assocPath(
        ['commentsByResourceId', action.resourceId],
        {
          isLoading: false,
          comments: mapValues(action.payload, normalizeCommentFromApiResponse),
        },
        state
      );

    case Actions.GET_DOWNSTREAM_COMMENTS_LOADING:
      return assocPath(
        ['commentsByResourceId', action.resourceId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_DOWNSTREAM_COMMENTS_ERROR:
      return assocPath(
        ['commentsByResourceId', action.resourceId, 'isLoading'],
        false,
        state
      );

    case Actions.GET_COMMENTS_SUCCESS:
      return assocPath(
        ['commentsByResourceId', action.resourceId, 'comments'],
        mapValues(action.payload, normalizeCommentFromApiResponse),
        state
      );

    case Actions.POST_COMMENT_LOADING:
      return assocPath(
        [
          'newCommentsByResourceId_ResourceType',
          action.resourceId_resourceType,
          'isLoading',
        ],
        true,
        state
      );

    case Actions.POST_COMMENT_ERROR:
      return pipe(
        assocPath(
          [
            'newCommentsByResourceId_ResourceType',
            action.resourceId_resourceType,
            'isLoading',
          ],
          false
        ),
        assocPath(
          [
            'newCommentsByResourceId_ResourceType',
            action.resourceId_resourceType,
            'apiError',
          ],
          storeAPIerror(action)
        )
      )(state);

    case Actions.POST_COMMENT_SUCCESS: {
      const rId = action.payload.resourceId;
      const existingResourceIdList = pathOr(
        [],
        [rId, 'comments'],
        state.commentsByResourceId
      );
      const existingParentIdList = pathOr(
        [],
        [action.parentId, 'comments'],
        state.commentsByResourceId
      );

      const updatedParentIdComments = action.parentId
        ? [
            assocPath(
              ['commentsByResourceId', action.parentId, 'comments'],
              [
                ...existingParentIdList,
                normalizeCommentFromApiResponse(action.payload),
              ]
            ),
          ]
        : [];

      return pipe(
        ...updatedParentIdComments,
        assocPath(
          ['commentsByResourceId', rId, 'comments'],
          [
            ...existingResourceIdList,
            normalizeCommentFromApiResponse(action.payload),
          ]
        ),
        assocPath(
          [
            'newCommentsByResourceId_ResourceType',
            action.resourceId_resourceType,
            'isLoading',
          ],
          false
        ),
        assocPath(
          [
            'isNewCommentPopupOpenByResourceId_ResourceType',
            action.resourceId_resourceType,
          ],
          false
        )
      )(state);
    }

    case Actions.SHOW_NEW_COMMENT_POPUP:
      return pipe(
        assocPath(
          [
            'isNewCommentPopupOpenByResourceId_ResourceType',
            action.resourceId_resourceType,
          ],
          true
        ),
        assocPath(
          [
            'newCommentsByResourceId_ResourceType',
            action.resourceId_resourceType,
          ],
          {
            isLoading: false,
            fields: { ...newCommentFields, ...action.payload },
          }
        )
      )(state);

    case Actions.HIDE_NEW_COMMENT_POPUP:
      return pipe(
        assocPath(
          [
            'isNewCommentPopupOpenByResourceId_ResourceType',
            action.resourceId_resourceType,
          ],
          false
        ),
        dissocPath([
          'newCommentsByResourceId_ResourceType',
          action.resourceId_resourceType,
        ])
      )(state);

    case Actions.UPDATE_FIELD:
      let updatedField = assoc(
        action.field,
        action.value,
        path(
          [
            'newCommentsByResourceId_ResourceType',
            action.resourceId_resourceType,
            'fields',
          ],
          state
        )
      );

      return pipe(
        assocPath(
          [
            'newCommentsByResourceId_ResourceType',
            action.resourceId_resourceType,
            'fields',
          ],
          updatedField
        )
      )(state);

    case Actions.SET_COMMENT_FILTER_FIELD: {
      return assocPath(['commentFilters', action.field], action.value, state);
    }
    case Actions.RESET_ALL_COMMENT_FILTERS: {
      return assocPath(['commentFilters'], {}, state);
    }

    case Actions.RESET_COMMENT_FILTER_FIELD: {
      return assocPath(['commentFilters', action.field], undefined, state);
    }

    default:
      return state;
  }
};
