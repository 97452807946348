import {
  NormalizedTimeSeries,
  ResourceType,
  Sensor,
  SensorType,
  Space,
} from '@energybox/react-ui-library/dist/types';
import { global, isDefined } from '@energybox/react-ui-library/dist/utils';
import { parseISO } from 'date-fns';
import { equals, pathOr } from 'ramda';
import { SensorReading, SensorReadingsById } from '../reducers/sensors';

export const extractSensorsForSpaceFromSite = (
  siteSensors: Sensor[] | undefined,
  spaceId: string,
  includeSubspaces: boolean
) => {
  if (siteSensors !== undefined) {
    return siteSensors.filter((sensor: Sensor) => {
      if (sensor.resource !== undefined) {
        if (includeSubspaces) {
          return (
            sensor.resource._entity === ResourceType.SPACE &&
            (sensor.resourceId.toString() === spaceId ||
              (sensor.resource as Space).parentId.toString() === spaceId)
          );
        } else {
          return (
            sensor.resource._entity === ResourceType.SPACE &&
            sensor.resourceId.toString() === spaceId
          );
        }
      } else {
        return false;
      }
    });
  } else {
    return [] as Sensor[];
  }
};

export const extractSensorReadingsByType = (
  sensors: Sensor[] | undefined,
  sensorReadings: SensorReadingsById,
  sensorType: SensorType,
  collapseBinaryValues: boolean
): SensorReading[] => {
  let streamApiValues: SensorReading[];

  if (sensors === undefined) {
    return [];
  } else {
    streamApiValues = sensors
      //@ts-ignore
      .map<SensorReading | undefined>((sensor) => {
        const reading: SensorReading | undefined = pathOr(
          undefined,
          [sensor.id, sensorType],
          sensorReadings
        );
        if (reading === undefined) {
          return undefined;
        } else {
          return {
            ...reading,
            sensor,
          };
        }
      })
      .filter(isDefined);

    if (sensorType === SensorType.BINARY && collapseBinaryValues) {
      // If binary we want to display open if any sensor reads open.
      // We also want to display the most recent value
      const areAnyOpen = streamApiValues.some((reading) => reading.binary);
      streamApiValues = streamApiValues
        .filter((reading) => reading.binary === areAnyOpen)
        .sort((readingA, readingB) => {
          const numA = parseISO(readingA.timestamp).valueOf();
          const numB = parseISO(readingB.timestamp).valueOf();
          return numB - numA;
        });

      if (streamApiValues.length > 1) streamApiValues = [streamApiValues[0]];
    }

    return streamApiValues;
  }
};

export const isTemperatureOrHumiditySensor = (sensorTypes: SensorType[]) => {
  return (
    sensorTypes.includes(SensorType.TEMPERATURE) ||
    sensorTypes.includes(SensorType.HUMIDITY)
  );
};

export const isDoorSensor = (sensorTypes: SensorType[]) => {
  return sensorTypes.includes(SensorType.BINARY);
};

export const filterSensorsByType = (
  sensors: Sensor[],
  sensorType: SensorType
) => {
  return sensors.filter((sensor: Sensor) => sensor.types.includes(sensorType));
};

export const sortSensorsByRecentUpdate = (sensors: Sensor[]) => {
  return sensors.sort((sensorA: Sensor, sensorB: Sensor) => {
    if (!sensorA.sensorStatus && !sensorB.sensorStatus) return 0;
    if (!sensorA.sensorStatus) return 1;
    if (!sensorB.sensorStatus) return -1;
    return (
      new Date(sensorA.sensorStatus.receivedAt).getTime() -
      new Date(sensorB.sensorStatus.receivedAt).getTime()
    );
  });
};

export const isSameSensorArray = (
  listA: Sensor[],
  listB: Sensor[]
): boolean => {
  if (listA.length !== listB.length) return false;

  const mappedListA = listA.map((s) => s.id);
  const mappedListB = listB.map((s) => s.id);
  const sortedListA = mappedListA.sort();
  const sortedListB = mappedListB.sort();

  return equals(sortedListA, sortedListB);
};

export const getValueFromReading = (
  reading: SensorReading | null | undefined,
  sensorType: SensorType
) => {
  if (reading === undefined || reading === null) {
    return global.NOT_AVAILABLE;
  } else if (
    sensorType === SensorType.TEMPERATURE &&
    reading.temperature !== undefined
  ) {
    return reading.temperature;
  } else if (
    sensorType === SensorType.HUMIDITY &&
    reading.humidity !== undefined
  ) {
    return reading.humidity;
  } else if (sensorType === SensorType.BINARY && reading.binary !== undefined) {
    return reading.binary;
  } else {
    return global.NOT_AVAILABLE;
  }
};

export const getMinMaxSensorReadings = (
  sensorReadings: SensorReading[],
  sensorType: SensorType
) => {
  if (sensorReadings.length === 1) {
    return { minReading: sensorReadings[0], maxReading: sensorReadings[0] };
  } else {
    const getValue = (sensorReading: SensorReading) =>
      getValueFromReading(sensorReading, sensorType);
    return sensorReadings.reduce(
      ({ minReading, maxReading }, currentReading) => {
        if (getValue(currentReading) < getValue(minReading)) {
          return {
            minReading: currentReading,
            maxReading,
          };
        } else if (getValue(currentReading) > getValue(maxReading)) {
          return {
            minReading,
            maxReading: currentReading,
          };
        } else {
          return { minReading, maxReading };
        }
      },
      {
        minReading: sensorReadings[0],
        maxReading: sensorReadings[0],
      }
    );
  }
};

export const pickReadingsToShow = (
  sensorReadings: SensorReading[],
  sensorType: SensorType
) => {
  if (sensorReadings.length > 1) {
    const { minReading, maxReading } = getMinMaxSensorReadings(
      sensorReadings,
      sensorType
    );
    return [minReading, maxReading];
  } else {
    return sensorReadings;
  }
};

export const reduceHumidityReadingsForSort = (
  total: number,
  reading: SensorReading
) => {
  if (reading.humidity !== undefined) {
    return total + reading.humidity;
  } else {
    return total;
  }
};

export const reduceTempReadingsForSort = (
  total: number,
  reading: SensorReading
) => {
  if (reading.temperature !== undefined) {
    return total + reading.temperature;
  } else {
    return total;
  }
};

export const compareSensorValuesForSpace = (
  spaceA: Space,
  spaceB: Space,
  sensorList: Sensor[],
  sensorReadingsById: SensorReadingsById,
  sensorType: SensorType
) => {
  const sensorsA = extractSensorsForSpaceFromSite(
    sensorList,
    spaceA.id.toString(),
    true
  );
  const sensorsB = extractSensorsForSpaceFromSite(
    sensorList,
    spaceB.id.toString(),
    true
  );
  const readingsA = extractSensorReadingsByType(
    sensorsA,
    sensorReadingsById,
    sensorType,
    false
  );
  const readingsB = extractSensorReadingsByType(
    sensorsB,
    sensorReadingsById,
    sensorType,
    false
  );

  if (sensorType === SensorType.BINARY) {
    const numOpenA = countNumOpenSensorReadings(readingsA);
    const numOpenB = countNumOpenSensorReadings(readingsB);

    if (numOpenA === numOpenB) {
      return 0;
    } else if (numOpenA > numOpenB) {
      return -1;
    } else {
      return 1;
    }
  } else if (sensorType === SensorType.HUMIDITY) {
    const avgHumidityA =
      readingsA.reduce(reduceHumidityReadingsForSort, 0) / readingsA.length;
    const avgHumidityB =
      readingsB.reduce(reduceHumidityReadingsForSort, 0) / readingsB.length;
    if (readingsA.length === 0) {
      return 1;
    } else if (readingsB.length === 0) {
      return -1;
    } else {
      return avgHumidityB - avgHumidityA;
    }
  } else {
    const avgTempA =
      readingsA.reduce(reduceTempReadingsForSort, 0) / readingsA.length;
    const avgTempB =
      readingsB.reduce(reduceTempReadingsForSort, 0) / readingsB.length;
    if (readingsA.length === 0) {
      return 1;
    } else if (readingsB.length === 0) {
      return -1;
    } else {
      return avgTempB - avgTempA;
    }
  }
};

const countNumSensorReadingsForStatus = (
  sensorReadings: SensorReading[],
  statusToCount: boolean
) => {
  return sensorReadings.reduce((total: number, reading: SensorReading) => {
    if (reading.binary !== undefined && reading.binary === statusToCount) {
      return total + 1;
    } else {
      return total;
    }
  }, 0);
};

export const countNumOpenSensorReadings = (sensorReadings: SensorReading[]) =>
  countNumSensorReadingsForStatus(sensorReadings, true);

export const countNumClosedSensorReadings = (sensorReadings: SensorReading[]) =>
  countNumSensorReadingsForStatus(sensorReadings, false);

export const checkIfSensorsHaveData = (
  timeSeriesList: NormalizedTimeSeries[]
): {
  [sensorId: string]: boolean;
} => {
  const hasDataBySensorId = {};
  timeSeriesList.forEach(({ data, key }) => {
    if (key === undefined) return;
    hasDataBySensorId[key] = data.length > 0;
  });
  return hasDataBySensorId;
};

export const displayHumidityValue = (humidityValue: number | undefined) => {
  if (!isDefined(humidityValue)) {
    return global.NOT_AVAILABLE;
  }

  return `${Number(humidityValue.toFixed(1))} %`;
};
