import {
  ResourcePath,
  ResourceType,
  SentinelType,
  Thermostat,
} from '@energybox/react-ui-library/dist/types';
import { classNames, global } from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getResourcePathById } from '../../actions/resources';
import { getThermostatById } from '../../actions/thermostats';
import { ApplicationState } from '../../reducers';
import ShortenedSpan from '../ShortenedSpan';
import styles from './SensorResource.module.css';

type Props = {
  //not necessarily id of sensor, can be thermostatId or siteControllerId
  //artifact of BE endpoint
  sensorId: string | number;
  sentinelType: SentinelType;
  maxStringLength?: number;
  showSiteName?: boolean;
  equipmentTitleClassName?: string;
};

const SensorResource: React.FC<Props> = ({
  sensorId,
  sentinelType,
  equipmentTitleClassName,
  maxStringLength = 40,
  showSiteName = false,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getResourcePathById(Number(sensorId)));

    if (sentinelType === SentinelType.THERMOSTAT_LOCAL_OVERRIDE_CHECK) {
      dispatch(getThermostatById(sensorId));
    }
  }, [dispatch, sensorId, sentinelType]);

  const sensorResourcePath = useSelector<ApplicationState, ResourcePath[]>(
    ({ resources }) => {
      return pathOr([], [sensorId], resources.byId);
    }
  );

  const thermostat = useSelector<ApplicationState, Thermostat | undefined>(
    ({ thermostats }) => {
      return pathOr(undefined, [sensorId], thermostats.thermostatsById);
    }
  );

  switch (sentinelType) {
    case SentinelType.THERMOSTAT_LOCAL_OVERRIDE_CHECK: {
      return determineThermostatResource(
        sensorResourcePath,
        maxStringLength,
        showSiteName,
        thermostat,
        equipmentTitleClassName
      );
    }

    case SentinelType.SITE_CONTROLLER_LOCAL_OVERRIDE_CHECK: {
      return determineSiteControllerResource(
        sensorResourcePath,
        maxStringLength,
        showSiteName,
        equipmentTitleClassName
      );
    }

    default: {
      return determineSensorResource(
        sensorResourcePath,
        maxStringLength,
        showSiteName,
        equipmentTitleClassName
      );
    }
  }
};

const determineThermostatResource = (
  path: ResourcePath[],
  maxStringLength: number,
  showSiteName: boolean,
  thermostat: Thermostat | undefined,
  equipmentTitleClassName?: string
) => {
  const thermostatMappedEquipment = thermostat?.equipment;
  const spacePath = path.find((p) => p.type === ResourceType.SPACE);
  const site = path.find((p) => p.type === ResourceType.SITE);
  const siteId = site ? site.id : undefined;
  const equipmentPath = path.find((p) => p.type === ResourceType.EQUIPMENT);
  return (
    <>
      <div className={classNames(styles.title, equipmentTitleClassName)}>
        {displayResourceTitle(equipmentPath, siteId, maxStringLength)}
      </div>
      <div className={styles.title}>
        {showSiteName && site && (
          <>
            {displayResourceTitle(site, siteId, maxStringLength)}
            <span> &gt; </span>
          </>
        )}
        {displayResourceTitle(spacePath, siteId, maxStringLength)}
      </div>
    </>
  );
};

const determineSiteControllerResource = (
  path: ResourcePath[],
  maxStringLength: number,
  showSiteName: boolean,
  equipmentTitleClassName?: string
) => {
  const siteControllerPath = path.find(
    //TODO: path endpoint returns 'ControlBoard,' should be "Control Board"
    // @ts-ignore
    (p) => p.type === 'ControlBoard' || p.type === ResourceType.CONTROLBOARD
  );
  const spacePath = path.find((p) => p.type === ResourceType.SPACE);
  const site = path.find((p) => p.type === ResourceType.SITE);
  const siteId = site ? site.id : undefined;

  return (
    <>
      <div className={classNames(styles.title, equipmentTitleClassName)}>
        {displayResourceTitle(siteControllerPath, siteId, maxStringLength)}
      </div>
      <div className={styles.title}>
        {showSiteName && site && (
          <>
            {displayResourceTitle(site, siteId, maxStringLength)}
            <span> &gt; </span>
          </>
        )}
        {displayResourceTitle(spacePath, siteId, maxStringLength)}
      </div>
    </>
  );
};

const determineSensorResource = (
  path: ResourcePath[],
  maxStringLength: number,
  showSiteName: boolean,
  equipmentTitleClassName?: string
) => {
  const equipmentPath = path.find((p) => p.type === ResourceType.EQUIPMENT);
  const spacePath = path.find((p) => p.type === ResourceType.SPACE);
  const site = path.find((p) => p.type === ResourceType.SITE);
  const siteId = site ? site.id : undefined;

  return (
    <>
      <div className={classNames(styles.title, equipmentTitleClassName)}>
        {displayResourceTitle(equipmentPath, siteId, maxStringLength)}
      </div>
      <div className={styles.title}>
        {showSiteName && site && (
          <>
            {displayResourceTitle(site, siteId, maxStringLength)}
            <span> &gt; </span>
          </>
        )}
        {displayResourceTitle(spacePath, siteId, maxStringLength)}
      </div>
    </>
  );
};

const displayResourceTitle = (
  resourcePath: ResourcePath | undefined,
  siteId: number | undefined,
  maxStringLength: number
) => {
  if (!resourcePath) return global.NOT_AVAILABLE;
  const isResourceEquipment = resourcePath.type === ResourceType.EQUIPMENT;

  //Links are only for equipment, NOT space
  if (siteId && isResourceEquipment) {
    return (
      <Link to={`/sites/${siteId}/equipment/${resourcePath.id}`}>
        <ShortenedSpan
          maxStringLength={maxStringLength}
          content={resourcePath.title}
          arrowDirection={'bottom'}
        />
      </Link>
    );
  }

  return (
    <ShortenedSpan
      maxStringLength={maxStringLength}
      content={resourcePath.title}
      arrowDirection={'bottom'}
    />
  );
};

export default SensorResource;
