import { GenericSiteFilter } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSites } from '../../../actions/sites';
import useSiteFilter from '../../../hooks/useSiteFilter';
import { ApplicationState } from '../../../reducers';
import { sortByTitleWithEmptyStringToLast } from '../../../utils/sorting';

type Props = {
  className?: string;
  dropdownClassName?: string;
  isInDropdown?: boolean;
  alignItemsRight?: boolean;
  onChange?: (selectedSiteIds: number[]) => void;
};

const SiteFilter: React.FC<Props> = ({
  className,
  dropdownClassName,
  isInDropdown,
  alignItemsRight,
  onChange,
}) => {
  const dispatch = useDispatch();
  const sites = useSelector(({ sites }: ApplicationState) => sites.sites || []);
  const { setFilter, selectedSiteFilters } = useSiteFilter(onChange);

  return (
    <GenericSiteFilter
      sites={sites.sort(sortByTitleWithEmptyStringToLast)}
      selectedSiteFilters={selectedSiteFilters}
      getSites={() => dispatch(getSites())}
      setSiteFilter={setFilter}
      className={className}
      dropdownClassName={dropdownClassName}
      isInDropdown={isInDropdown}
      alignItemsRight={alignItemsRight}
    />
  );
};

export default SiteFilter;
