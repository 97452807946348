import {
  Site,
  SiteAstronomicalClock,
  SitesById,
} from '@energybox/react-ui-library/dist/types';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import merge from 'ramda/src/merge';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/sites';
import { ApiError } from '../utils/apiErrorFeedback';

const initialState = {
  sites: [],
  sitesById: {},
  sitesByIdIsLoading: false,
  siteAstroClockById: {},
  apiError: {},
};

export type Sites = {
  sites: Site[];
  sitesById: SitesById;
  sitesByIdIsLoading: boolean;
  siteAstroClockById: SiteAstroClockById;
  apiError: ApiError;
};

export type SiteAstroClockByDateRange = {
  //`${startDate}_${endDate}` of form 'yyyy-MM-dd'
  // Could've only stored the array, with no key, but this ensures hook will
  // return the intended date range. The issue with storing each date with it's
  // own key, is you then need logic to iterate between the two dates to
  // retrieve
  [dateRangeKey: string]: SiteAstronomicalClock[];
};

export type SiteAstroClockById = {
  [siteId: number]: SiteAstroClockByDateRange;
};

const processSitesResponse = (data: Site[]): Site[] => {
  return data.map(
    (site: Site) =>
      ({
        ...site,
        latitude: site.latitude || 0,
        longitude: site.longitude || 0,
      } as Site)
  );
};

const createSiteByIdDict = (siteList: Site[]) => {
  const sitesById: SitesById = {};
  siteList.forEach((site) => (sitesById[site.id] = site));
  return sitesById;
};

export default (state: Sites = initialState, action: any) => {
  switch (action.type) {
    case Actions.SITES_SUCCESS:
      const siteList = processSitesResponse(action.payload as Site[]);
      return pipe(
        assoc('sites', siteList),
        assoc('sitesById', createSiteByIdDict(siteList))
      )(state);

    case Actions.SITE_SUCCESS:
      return merge(state, {
        sitesById: {
          ...state.sitesById,
          [action.payload.id]: action.payload as Site,
        },
        sitesByIdIsLoading: false,
      });

    case Actions.SITE_LOADING:
      return assocPath(['sitesByIdIsLoading'], true, state);

    case Actions.SITE_ERROR:
      return merge(state, {
        sitesByIdIsLoading: false,
        apiError: action.payload,
      });

    case Actions.GET_SITE_ASTRO_CLOCK_SUCCESS: {
      const times: SiteAstronomicalClock[] = action.payload;
      const { startDate, endDate } = action;
      const dateRangeKey = endDate ? `${startDate}_${endDate}` : `${startDate}`;
      return pipe(
        assocPath(['siteAstroClockById', action.siteId, dateRangeKey], times)
      )(state);
    }

    default:
      return state;
  }
};
