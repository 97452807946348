import { useSelector } from 'react-redux';
import { EquipmentTags } from '../reducers/equipmentTags';
import { EquipmentGroup } from '@energybox/react-ui-library/dist/types';
import { mapArrayToObject } from '@energybox/react-ui-library/dist/utils';

export function useEquipmentGroups() {
  const equipmentGroups: EquipmentGroup[] = useSelector(
    (state: { equipmentTags: EquipmentTags }) =>
      state.equipmentTags.equipmentGroups
  );
  return equipmentGroups;
}

export function useEquipmentGroupsById(): {
  [equipmentId: number]: EquipmentGroup;
} {
  const equipmentGroups: EquipmentGroup[] = useEquipmentGroups();

  return mapArrayToObject(equipmentGroups);
}

export function useEquipmentGroupsMatcher(regExp: RegExp) {
  const equipmentGroups = useEquipmentGroups();
  const matchedEquipmentGroup = equipmentGroups.find(
    ({ title }) => title.match(regExp) !== null
  );
  return matchedEquipmentGroup;
}
