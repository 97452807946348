import {
  classNames,
  formatCurrency,
  formatNumberString,
  global,
  KWH_UNIT,
  formatKwhValues,
} from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import styles from './BigNumber.module.css';

export type Props = {
  className?: string;
  countryCode?: string;
  value?: number | string;
  unit?: string;
  title?: React.ReactNode;
  description?: string;
  isRow?: boolean;
  isCurrency?: boolean;
  isPercentage?: boolean;
  bold?: boolean;
  numDecimals?: number;
  isLoading?: boolean;
  subtext?: React.ReactNode;
};

const BigNumber = ({
  className,
  countryCode,
  value,
  unit = '',
  title,
  description,
  isRow,
  isCurrency,
  isPercentage,
  numDecimals = 0,
  isLoading,
  bold,
  subtext,
}: Props) => {
  let displayedValue: string | number = global.NOT_AVAILABLE;
  if (value !== undefined && !isLoading) {
    if (isCurrency && countryCode)
      displayedValue = formatCurrency(value as number, unit, countryCode);
    else if (countryCode) {
      displayedValue = formatNumberString(value as number, {
        countryCode,
        numDecimals,
      });
    } else if (typeof value === 'number' && unit === KWH_UNIT) {
      displayedValue = formatNumberString(value as number, {
        countryCode,
        numDecimals,
        unit,
        displayUnit: false,
      });
    } else if (typeof value === 'number') {
      displayedValue = formatNumberString(value as number, {
        numDecimals,
      });
    } else {
      displayedValue = value;
    }
  }

  return (
    <div
      className={classNames(
        className,
        styles.root,
        isRow ? styles.isRow : null
      )}
    >
      {title && (
        <div
          className={classNames(styles.title, isRow ? styles.rowTitle : null)}
        >
          {title}
        </div>
      )}
      <div
        className={classNames(
          title ? styles.leftAlignContent : styles.rightAlignContent,
          bold ? styles.bold : ''
        )}
      >
        <span
          className={isRow ? styles.rowTitle : styles.bigNumber}
          data-testid="total"
        >
          {displayedValue}
          {value !== undefined && !isLoading && isPercentage && ' %'}
        </span>
        {!isCurrency && value !== undefined && !isLoading && (
          <span className={styles.bigNumberUnit}>{unit}</span>
        )}
        {description && (
          <div className={styles.bigNumberSubTitle}>{description}</div>
        )}
        {subtext && <div className={styles.subtext}>{subtext}</div>}
      </div>
    </div>
  );
};

export default BigNumber;
