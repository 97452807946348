import {
  Checkbox,
  FilterDropdown,
  FilterDropdownTextItem,
  RadioButton,
  RadioGroup,
} from '@energybox/react-ui-library/dist/components';
import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import {
  Incident,
  IncidentStatus,
  Locale,
  ParametersSwitch,
  ParametersSwitchOnOff,
  SentinelType,
  TodayTileNames,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  determineTimeStringByTimezone,
  global,
  isDefined,
  createTimeFilterMapping,
} from '@energybox/react-ui-library/dist/utils';
import parseISO from 'date-fns/parseISO';
import pathOr from 'ramda/src/pathOr';

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openDialog } from '../../../actions/dialog';
import { dismissNotification } from '../../../actions/notifications';
import IconSensorTypeFactory from '../../../components/icons/IconSensorTypeFactory';
import SensorResource from '../../../components/SensorResource';
import HappyHornOverlay from '../../../components/Tile/HappyHornOverlay';
import Tile from '../../../components/Tile/Tile';
import TileContent from '../../../components/Tile/TileContent';
import TileFooter from '../../../components/Tile/TileFooter';
import TileHeader from '../../../components/Tile/TileHeader';
import useAppLocale from '../../../hooks/useAppLocale';
import { useTimeFilter } from '../../../hooks/useFilters';
import { useGetIncidents } from '../../../hooks/useIncidents';
import { useGetAllSites } from '../../../hooks/useSites';
import { sortByDateDescending } from '../../../reducers/incidents';
import * as Routes from '../../../routes';
import { TodayPageTimeRange } from '../../views/today/TodayOverviewPage/TodayOverviewPage';
import styles from './ActiveIncidentsOverviewTile.module.css';

type Props = {
  className?: string;
  timeRange: TodayPageTimeRange;
};

enum TileSortType {
  PRIORITY = 'PRIORITY',
  LATEST = 'LATEST',
}

enum TileSortTypeLabel {
  PRIORITY = 'Severity',
  LATEST = 'Latest',
}

const TOOLTIP_DESCRIPTION =
  'This tile shows a list view of the latest incidents that have occurred across your sites. You can also sort the incidents by priority.';

const ActiveIncidentsOverviewTile: React.FC<Props> = ({
  className,
  timeRange,
}) => {
  ///*** Hooks ***///
  const dispatch = useDispatch();
  const isMobile = useViewportType() === ViewportTypes.MOBILE;

  const [tileSortType, setTileSortType] = useState<TileSortType>(
    TileSortType.PRIORITY
  );
  const locale = useAppLocale();
  const { setTimeFilter } = useTimeFilter();
  const sitesById = useGetAllSites();

  const { isLoading, data: incidents = [] } = useGetIncidents({
    from: timeRange.from,
    to: timeRange.to,
    incidentStatus: IncidentStatus.ACTIVE,
  });

  ///*** Local vars + Functions ***///
  const isThereData = incidents && incidents.length > 0;

  const setDateFilterToToday = () => {
    const { today } = createTimeFilterMapping({ useCurrentTime: true });
    setTimeFilter(today);
  };

  const onSeeAllClick = () => {
    setDateFilterToToday();
  };

  const openDismissModal = (notificationId: string) => {
    dispatch(
      openDialog({
        isOpen: true,
        title: 'Dismiss Notification',
        type: 'DISMISS_NOTIFICATION',
        targetId: notificationId,
        actionFn: ({ notificationId, comment }) =>
          dispatch(dismissNotification(notificationId, comment)),
      })
    );
  };

  const determineIconParameters = (
    incident: Incident
  ): ParametersSwitch | undefined => {
    if (incident.sentinelType === SentinelType.DOOR_SIREN_CHECK) {
      return {
        onOff:
          incident.resolved === false
            ? ParametersSwitchOnOff.OFF
            : ParametersSwitchOnOff.ON,
      };
    }
    return undefined;
  };

  //To note: the reducer already sorts
  //incidents by incidentPriority by default
  const sortedIncidents = (() => {
    let sortedIncidents = [...incidents];

    //when sorting by priority
    //dismissed incidents sort to bottom of list
    if (tileSortType === TileSortType.PRIORITY) {
      sortedIncidents = sortedIncidents.sort((incidentA, incidentB) => {
        const isIncidentADimissed = isDefined(incidentA.dismissedAt);
        const isIncidentBDimissed = isDefined(incidentB.dismissedAt);

        if (isIncidentADimissed && !isIncidentBDimissed) return 1;
        if (!isIncidentADimissed && isIncidentBDimissed) return -1;
        return 0;
      });
    }

    if (tileSortType === TileSortType.LATEST) {
      sortedIncidents = sortedIncidents.sort(sortByDateDescending);
    }

    return sortedIncidents;
  })();

  const SortTypeRadioButton = (
    <div className={styles.radioButtonContainer}>
      <RadioGroup>
        <RadioButton
          label={TileSortTypeLabel[TileSortType.PRIORITY]}
          value={TileSortType.PRIORITY}
          checked={tileSortType === TileSortType.PRIORITY}
          onChange={() => setTileSortType(TileSortType.PRIORITY)}
        />
        <RadioButton
          label={TileSortTypeLabel[TileSortType.LATEST]}
          value={TileSortType.LATEST}
          checked={tileSortType === TileSortType.LATEST}
          onChange={() => setTileSortType(TileSortType.LATEST)}
        />
      </RadioGroup>
    </div>
  );

  const SortTypeDropdown = (
    <FilterDropdown
      title={
        TileSortTypeLabel[
          tileSortType === TileSortType.PRIORITY
            ? TileSortType.PRIORITY
            : TileSortType.LATEST
        ]
      }
      alignItemsRight
    >
      <FilterDropdownTextItem
        title={TileSortTypeLabel[TileSortType.PRIORITY]}
        onClick={() => setTileSortType(TileSortType.PRIORITY)}
        closeOnClick
      />
      <FilterDropdownTextItem
        title={TileSortTypeLabel[TileSortType.LATEST]}
        onClick={() => setTileSortType(TileSortType.LATEST)}
        closeOnClick
      />
    </FilterDropdown>
  );

  const columns: Columns<Incident>[] = [
    {
      cellStyle: { padding: '0.4rem 0' },
      width: '0.75rem',
      header: '',
      cellContent: (incident) => (
        <div
          className={`${styles.incidentPriorityLine} ${
            styles[`incidentPriority-${determineIncidentColor(incident)}`]
          }`}
        />
      ),
    },
    {
      header: '',
      cellContent: (incident) => (
        <div
          className={`${styles.icon} ${
            styles[`icon-${determineIncidentColor(incident)}`]
          }`}
        >
          <IconSensorTypeFactory
            size={20}
            id={incident.sentinelType}
            parameters={determineIconParameters(incident)}
          />
        </div>
      ),
    },
    {
      header: 'Equipment/Space',
      cellContent: ({ sensorId, sentinelType }) =>
        sensorId ? (
          <SensorResource
            maxStringLength={40}
            sensorId={sensorId}
            sentinelType={sentinelType}
          />
        ) : (
          global.NOT_AVAILABLE
        ),
    },
    {
      header: 'Incident',
      cellContent: ({ sentinelTitle, sentinelDescription }) => (
        <div>
          <div className={styles.sentinelTitle}>{sentinelTitle}</div>
          <div>{sentinelDescription}</div>
        </div>
      ),
    },
    {
      header: 'Site',
      cellContent: ({ siteId }) => {
        const siteTitle = pathOr(
          global.NOT_AVAILABLE,
          [siteId, 'title'],
          sitesById
        );

        return <span>{siteTitle}</span>;
      },
    },
    {
      header: 'Notifications',
      align: 'right',
      rightAlignContent: true,
      cellContent: ({ notificationCount }) => <span>{notificationCount}</span>,
    },
    {
      header: 'Start Time',
      width: '7rem',
      align: 'right',
      rightAlignContent: true,
      cellContent: ({ siteId, createdAt }) => (
        <span>
          {renderTime(createdAt, locale, sitesById[siteId]?.timeZone)}
        </span>
      ),
    },
    {
      width: '5rem',
      align: 'center',
      header: 'Dismiss',
      cellContent: ({ incidentId, dismissedAt }) => (
        <div className={styles.dismissCheckboxContainer}>
          <Checkbox
            //incidentId refers to the incident's most recent notificationId
            id={incidentId}
            checked={isDefined(dismissedAt)}
            disabled={isDefined(dismissedAt)}
            onChange={() => openDismissModal(incidentId)}
            size={20}
          />
        </div>
      ),
    },
  ];

  const mobileColumns: Columns<Incident>[] = [
    {
      cellStyle: { padding: '0.4rem 0' },
      header: '',
      cellContent: (incident) => (
        <div
          className={`${styles.incidentPriorityLine} ${
            styles[`incidentPriority-${determineIncidentColor(incident)}`]
          }`}
        />
      ),
    },
    {
      header: 'Equipment/Space',
      cellContent: ({ sensorId, sentinelType }) =>
        sensorId ? (
          <SensorResource
            maxStringLength={20}
            sensorId={sensorId}
            sentinelType={sentinelType}
            showSiteName
          />
        ) : (
          global.NOT_AVAILABLE
        ),
    },
    {
      header: 'Incident',
      cellContent: ({ sentinelTitle, sentinelDescription }) => (
        <div>
          <div className={styles.sentinelTitle}>{sentinelTitle}</div>
          <div>{sentinelDescription}</div>
        </div>
      ),
    },
    {
      header: '#',
      align: 'center',
      rightAlignContent: true,
      cellContent: ({ notificationCount }) => <span>{notificationCount}</span>,
    },
    {
      align: 'center',
      header: '',
      cellContent: ({ incidentId, dismissedAt }) => (
        <div
          className={classNames(
            styles.dismissCheckboxContainer,
            styles.mobileDismissCheckboxContainer
          )}
        >
          <Checkbox
            //incidentId refers to the incident's most recent notificationId
            id={incidentId}
            checked={isDefined(dismissedAt)}
            disabled={isDefined(dismissedAt)}
            onChange={() => openDismissModal(incidentId)}
            size={16}
          />
        </div>
      ),
    },
  ];

  if (!isThereData) {
    return (
      <Tile className={className} isLoading={isLoading}>
        <TileHeader
          title={TodayTileNames.ActiveIncidentsOverview}
          tooltipDescription={TOOLTIP_DESCRIPTION}
        />
        <HappyHornOverlay />
      </Tile>
    );
  }

  return (
    <Tile className={className} isLoading={isLoading}>
      <TileHeader
        title={TodayTileNames.ActiveIncidentsOverview}
        tooltipDescription={TOOLTIP_DESCRIPTION}
        extraRightAlignContent={
          isMobile ? SortTypeDropdown : SortTypeRadioButton
        }
      />

      <TileContent
        className={`${
          isMobile ? styles.mobileContentContainer : styles.contentContainer
        } ${
          isMobile ? styles.mobileScrollOnOverflow : styles.scrollOnOverflow
        }`}
      >
        <Table
          className={styles.table}
          columns={isMobile ? mobileColumns : columns}
          data={sortedIncidents}
        />
      </TileContent>

      <TileFooter onClick={onSeeAllClick} redirectTo={Routes.INCIDENTS} />
    </Tile>
  );
};

const determineIncidentColor = (i: Incident) => {
  if (i.dismissedAt !== undefined) return 'dismissed';
  return i.incidentPriority.toLowerCase();
};

const renderTime = (
  isoString: string | undefined,
  locale: Locale,
  ianaTimeZoneCode?: string
) => {
  if (!isoString) return global.NOT_AVAILABLE;

  const date = parseISO(isoString);
  const formattedDate = determineTimeStringByTimezone(
    date,
    locale.dateFormat,
    ianaTimeZoneCode
  );
  const formattedTime = determineTimeStringByTimezone(
    date,
    locale.timeFormat,
    ianaTimeZoneCode,
    true
  );

  return (
    <div>
      <div className={styles.title}>{formattedTime}</div>
      <div>{formattedDate}</div>
    </div>
  );
};

export default ActiveIncidentsOverviewTile;
