import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';
import { UsersById } from '@energybox/react-ui-library/dist/types';

const useUsersById = () => {
  return useSelector<ApplicationState, UsersById>(({ users }) => {
    return users.usersById;
  });
};

export default useUsersById;
