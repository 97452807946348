import history from '../history';
import * as Routes from '../routes';

import { Actions as AppActions } from '../actions/constants/Actions';

const redirectMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case AppActions.RESET_PASSWORD_SUCCESS: {
      history.push(Routes.HOME);
      return next(action);
    }

    default:
      return next(action);
  }
};

export default redirectMiddleware;
