import { Space, SpaceApiFilter } from '@energybox/react-ui-library/dist/types';

export enum Actions {
  AVAILABLE_FEATURES_BY_SITE_ID_SUCCESS = '@@features/AVAILABLE_FEATURES_BY_SITE_ID_SUCCESS',
  AVAILABLE_FEATURES_BY_SITE_ID_LOADING = '@@features/AVAILABLE_FEATURES_BY_SITE_ID_LOADING',
  AVAILABLE_FEATURES_BY_SITE_ID_ERROR = '@@features/AVAILABLE_FEATURES_BY_SITE_ID_ERROR',

  AVAILABLE_FEATURES_BY_ORGANIZATION_ID_SUCCESS = '@@features/AVAILABLE_FEATURES_BY_ORGANIZATION_ID_SUCCESS',
  AVAILABLE_FEATURES_BY_ORGANIZATION_ID_LOADING = '@@features/AVAILABLE_FEATURES_BY_ORGANIZATION_ID_LOADING',
  AVAILABLE_FEATURES_BY_ORGANIZATION_ID_ERROR = '@@features/AVAILABLE_FEATURES_BY_ORGANIZATION_ID_ERROR',
}

export const getFeaturesBySiteId = (siteId: number | string) => ({
  type: 'API_GET',
  path: `/api/v1/features/site/${siteId}`,
  loading: { type: Actions.AVAILABLE_FEATURES_BY_SITE_ID_LOADING, siteId },
  success: { type: Actions.AVAILABLE_FEATURES_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.AVAILABLE_FEATURES_BY_SITE_ID_ERROR, siteId },
});

export const getFeaturesByOrganizationId = (
  organizationId: number | string
) => ({
  type: 'API_GET',
  path: `/api/v1/features/site/multisite/${organizationId}`,
  loading: {
    type: Actions.AVAILABLE_FEATURES_BY_ORGANIZATION_ID_LOADING,
    organizationId,
  },
  success: {
    type: Actions.AVAILABLE_FEATURES_BY_ORGANIZATION_ID_SUCCESS,
    organizationId,
  },
  error: {
    type: Actions.AVAILABLE_FEATURES_BY_ORGANIZATION_ID_ERROR,
    organizationId,
  },
});
