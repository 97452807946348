import React from 'react';
import HappyHornOverlay from '../Tile/HappyHornOverlay';
import styles from './HappyHornMessage.module.css';

type Props = {
  className?: string;
};

const HappyHornMessage: React.FC<Props> = ({ className }) => (
  <div className={`${styles.container} ${className}`}>
    <HappyHornOverlay />
  </div>
);

export default HappyHornMessage;
