import store from '../store';

export enum Actions {
  GET_OPEN_SOURCE_LICENSE_SUCCESS = '@license/GET_OPEN_SOURCE_LICENSE_SUCCESS',
  GET_OPEN_SOURCE_LICENSE_ERROR = '@license/GET_OPEN_SOURCE_LICENSE_ERROR',
  GET_OPEN_SOURCE_LICENSE_LOADING = '@license/GET_OPEN_SOURCE_LICENSE_LOADING',
}

const licenseUrl =
  process.env.REACT_APP_OPEN_SOURCE_LICENSE_URL ||
  'https://energybox-static.s3.us-west-2.amazonaws.com/open-source-license/LICENSE-DETAILS.md';

export const getOpenSourceLicense = () => async () => {
  store.dispatch({
    type: Actions.GET_OPEN_SOURCE_LICENSE_LOADING,
    data: {
      text: '',
      lastUpdate: '',
      isLoading: true,
    },
    isLoading: true,
  });
  await fetch(licenseUrl)
    .then(async (res) => {
      const mdText = await res.text();
      store.dispatch({
        type: Actions.GET_OPEN_SOURCE_LICENSE_SUCCESS,
        data: {
          text: mdText,
          lastUpdate: res.headers.get('Last-Modified'),
          isLoading: false,
        },
        isLoading: false,
      });
    })
    .catch((err) => {
      console.log('err', err);
      store.dispatch({
        type: Actions.GET_OPEN_SOURCE_LICENSE_ERROR,
        data: {
          text: err.message,
          lastUpdate: '',
          isLoading: false,
        },
        isLoading: false,
      });
    });
};
