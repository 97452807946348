import { RechartsCustomTooltip } from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import styles from './ToolTip.module.css';

interface Props extends RechartsCustomTooltip {
  title?: string;
  titleSecondaryText?: string;
  values: React.ReactNode;
  // Optional color value of value - if can't be inferred from payload
  valuesColor?: string;
  secondaryInfo?: string;
}

const ToolTip = ({
  title = '',
  titleSecondaryText,
  secondaryInfo,
  values,
  valuesColor,
  children,
  active,
  payload,
  label,
}: Props) => {
  let color: string | undefined = undefined;
  if (valuesColor !== undefined) {
    color = valuesColor;
  } else if (payload !== undefined && payload.length > 0) {
    color = payload[0].color;
  }
  if (active) {
    return (
      <div className={styles.tooltipContainer}>
        <div className={styles.tooltipInner}>
          <div className={styles.tooltipHeader}>
            <span>{title}</span>
            {titleSecondaryText && <span>{titleSecondaryText}</span>}
          </div>

          {children !== undefined && children}
          <p className={styles.tooltipValues} style={{ color }}>
            {values}
          </p>
          <div
            className={classNames(
              styles.tooltipValues,
              styles.tooltipSecondaryInfo
            )}
          >
            {secondaryInfo}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default ToolTip;
