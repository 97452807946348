import {
  DashboardProfile,
  NewDashboardProfile,
} from '@energybox/react-ui-library/dist/types';
import { fetchApi } from '../utils/fetchApi';
import { ApplicationState } from '../reducers';
import { Actions } from './constants/Actions';

export const setCurrentSiteId = (siteId) => ({
  type: Actions.USER_SITE_CURRENT_SITE,
  payload: siteId,
});

export const initApp = () => ({
  type: Actions.INIT,
});

export const initAppWithToken = ({ accessToken }) => ({
  type: Actions.INIT_WITH_TOKEN,
  payload: { accessToken },
});

export const logout = () => ({
  type: Actions.LOGOUT,
});

export const login =
  () => (dispatch: any, getState: () => ApplicationState) => {
    const { email, password } = getState().app.loginForm;

    dispatch({
      type: 'API_POST',
      path: '/api/v1/auth/login',
      payload: { email, password },
      success: Actions.LOGIN_SUCCESS,
      error: Actions.LOGIN_ERROR,
      loading: Actions.LOGIN_LOADING,
    });
  };

export const forgotPassword = (email: string) => ({
  type: 'API_POST',
  payload: { email, website: 'PULSE' },
  path: '/api/v1/users/password-forget',
  success: { type: Actions.FORGOT_PASSWORD_SUCCESS, email },
  error: Actions.FORGOT_PASSWORD_ERROR,
  loading: Actions.FORGOT_PASSWORD_LOADING,
});

export const resetPassword = (
  email: string,
  token: string,
  password: string,
  passwordVerify: string
) => ({
  type: 'API_POST',
  payload: { email, token, password, passwordVerify },
  path: '/api/v1/users/email-password-confirm',
  success: Actions.RESET_PASSWORD_SUCCESS,
  error: Actions.RESET_PASSWORD_ERROR,
  loading: Actions.RESET_PASSWORD_LOADING,
});

export const updateLoginField = (field: string, value: string) => ({
  type: Actions.UPDATE_LOGIN_FIELD,
  field: field,
  value: value,
});

export const updateForgotPasswordField = (field: string, value: string) => ({
  type: Actions.UPDATE_FORGOT_PASSWORD_FIELD,
  field: field,
  value: value,
});

export const updateResetPasswordField = (field: string, value: string) => ({
  type: Actions.UPDATE_RESET_PASSWORD_FIELD,
  field: field,
  value: value,
});

export const loginFromLocalStorage = () => ({
  type: Actions.LOGIN_FROM_LOCALSTORAGE,
});

export const getCurrentUser = () => ({
  type: 'API_GET',
  path: '/api/v1/auth/user',
  loading: Actions.CURRENT_USER_LOADING,
  success: Actions.CURRENT_USER_SUCCESS,
  error: Actions.CURRENT_USER_ERROR,
});

export const switchOrganization = (id: number) => ({
  type: 'API_POST',
  path: `/api/v1/auth/switch/organization/${id}`,
  loading: Actions.CHANGE_ORGANIZATION_LOADING,
  success: Actions.CHANGE_ORGANIZATION_SUCCESS,
  error: Actions.CHANGE_ORGANIZATION_ERROR,
});

export const getTimeZones = () => ({
  type: 'API_GET',
  path: '/api/v1/basics/timezones',
  success: Actions.GET_TIMEZONES_SUCCESS,
  error: Actions.GET_TIMEZONES_ERROR,
  loading: Actions.GET_TIMEZONES_LOADING,
});

export const updateScrollPosition = (scrollTop: number) => ({
  type: Actions.UPDATE_SCROLL_POSITION,
  value: scrollTop,
});

export const activateIncrementScrollNumber = (value) => ({
  type: Actions.ACTIVATE_UPDATE_SCROLL_POSITION,
  value,
});

export const updateUserPassword = (
  id: string,
  currentPassword: string,
  password: string,
  passwordVerify: string
) => ({
  type: 'API_PATCH',
  path: `/api/v1/users/${id}/password`,
  payload: {
    oldPassword: currentPassword,
    newPassword: password,
    newPasswordVerify: passwordVerify,
  },
  success: Actions.USER_CHANGE_PASSWORD_SUCCESS,
  error: Actions.USER_CHANGE_PASSWORD_ERROR,
  loading: Actions.USER_CHANGE_PASSWORD_LOADING,
});

export const clearAuthenErrorDuringUpdatePassword = {
  type: Actions.USER_CHANGE_PASSWORD_CLEAR_AUTH_ERROR,
};

export const getUserType = () => (dispatch) =>
  fetchApi({
    endpoint: `/api/v1/sites`,
  })
    .then((sites) => {
      dispatch({
        type: Actions.USER_TYPE_SUCCESS,
        payload: {
          siteId: sites.length === 1 ? sites[0].id : null,
        },
      });
    })
    .catch((error) => {
      console.error(error);
      dispatch({
        type: Actions.USER_TYPE_ERROR,
        payload: {
          error,
        },
      });
    });

export const createCustomDashboard = (
  userId: number,
  payload: NewDashboardProfile
) => ({
  type: 'API_POST',
  path: `/api/v1/users/${userId}/dashboards`,
  payload,
  loading: Actions.CREATE_DASHBOARD_LOADING,
  success: Actions.CREATE_DASHBOARD_SUCCESS,
  error: Actions.CREATE_DASHBOARD_ERROR,
});

export const updateCustomDashboard = (
  userId: number,
  payload: DashboardProfile
) => ({
  type: 'API_PUT',
  path: `/api/v1/users/${userId}/dashboards`,
  payload,
  loading: Actions.PATCH_DASHBOARD_LOADING,
  success: Actions.PATCH_DASHBOARD_SUCCESS,
  error: Actions.PATCH_DASHBOARD_ERROR,
});

export const deleteCustomDashboard = (userId: number, dashboardId: number) => ({
  type: 'API_DELETE',
  path: `/api/v1/users/${userId}/dashboards/${dashboardId}`,
  loading: Actions.DELETE_DASHBOARD_LOADING,
  success: Actions.DELETE_DASHBOARD_SUCCESS,
  error: Actions.DELETE_DASHBOARD_ERROR,
});

export const toggleMobileMenu = () => ({
  type: Actions.TOGGLE_MOBILE_MENU,
});

export const dimissNewPasswordPopup = () => ({
  type: Actions.DISMISS_NEW_PASSWORD_POPUP,
});
