import { Sentinel } from '@energybox/react-ui-library/dist/types';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import dissocPath from 'ramda/src/dissocPath';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/sentinels';
import { replaceNullValues } from '../util';

export type Sentinels = {
  isLoading: boolean;
  isLoadingByResourceId: IsLoadingByResourceId;
  byId: SentinelsById;
  sentinelsBySensorId: SentinelsBySensorId;
  sentinelsByEquipmentId: SentinelsByEquipmentId;
};

export type IsLoadingByResourceId = {
  [resourceId: string]: boolean;
};

export type SentinelsById = {
  [id: string]: Sentinel[];
};

export type SentinelsBySensorId = {
  [id: string]: Sentinel[];
};

export type SentinelsByEquipmentId = {
  [id: string]: Sentinel[];
};

const initialState = {
  isLoading: false,
  isLoadingByResourceId: {},
  byId: {},
  sentinelsBySensorId: {},
  sentinelsByEquipmentId: {},
};

const sanitizeSentinelsApiReturn = (payload: Sentinel[]) => {
  return {
    ...payload.reduce(
      (obj, s: Sentinel) => ({ ...obj, [s.id]: replaceNullValues(s) }),
      {}
    ),
  };
};

export default (state: Sentinels = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SENTINELS_LOADING:
      return assoc('isLoading', true, state);

    case Actions.SENTINELS_SUCCESS:
    case Actions.GET_SENTINELS_SUCCESS:
      return pipe(
        assoc('isLoading', false),
        assoc('byId', sanitizeSentinelsApiReturn(action.payload))
      )(state);

    case Actions.GET_SENTINEL_SUCCESS:
      return pipe(
        assocPath(['isLoadingByResourceId', action.sentinelId], false),
        assocPath(['byId', action.sentinelId], [action.payload])
      )(state);

    case Actions.GET_SENTINELS_BY_SENSOR_ID_LOADING:
      return assocPath(['isLoadingByResourceId', action.sensorId], true, state);

    case Actions.GET_SENTINELS_BY_SENSOR_ID_SUCCESS:
      return pipe(
        assocPath(['isLoadingByResourceId', action.sensorId], false),
        assocPath(
          ['sentinelsBySensorId', action.sensorId],
          action.payload.map((s) => replaceNullValues(s))
        )
      )(state);

    case Actions.GET_SENTINELS_BY_EQUIPMENT_ID_LOADING:
      return assocPath(
        ['isLoadingByResourceId', action.equipmentId],
        true,
        state
      );

    case Actions.GET_SENTINELS_BY_EQUIPMENT_ID_ERROR:
      return assocPath(
        ['isLoadingByResourceId', action.equipmentId],
        false,
        state
      );

    case Actions.GET_SENTINELS_BY_EQUIPMENT_ID_SUCCESS:
      return pipe(
        assocPath(['isLoadingByResourceId', action.equipmentId], false),
        assocPath(
          ['sentinelsByEquipmentId', action.equipmentId],
          action.payload.map((s) => replaceNullValues(s))
        )
      )(state);

    case Actions.DELETE_SUCCESS:
      return dissocPath(['byId', action.id], state);

    case Actions.MUTE_SUCCESS:
      return pipe(
        assoc('isLoading', false),
        assocPath(['byId', action.id], {
          ...state.byId[action.id],
          muted: true,
        })
      )(state);

    case Actions.RESUME_SUCCESS:
      return pipe(
        assoc('isLoading', false),
        assocPath(['byId', action.id], {
          ...state.byId[action.id],
          muted: false,
        })
      )(state);

    default: {
      return state;
    }
  }
};
