import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  IncidentCountBySiteData,
  IncidentCountsBySite,
  IncidentStatus,
  SitesById,
  TodayTileNames,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  createTimeFilterMapping,
} from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import ShortenedSpan from '../../../components/ShortenedSpan';
import HappyHornOverlay from '../../../components/Tile/HappyHornOverlay';
import Tile from '../../../components/Tile/Tile';
import TileContent from '../../../components/Tile/TileContent';
import TileFooter from '../../../components/Tile/TileFooter';
import TileHeader from '../../../components/Tile/TileHeader';
import { useTimeFilter } from '../../../hooks/useFilters';
import { useGetIncidentCountsBySite } from '../../../hooks/useIncidents';
import { useGetAllSites } from '../../../hooks/useSites';
import * as Routes from '../../../routes';
import { TodayPageTimeRange } from '../../views/today/TodayOverviewPage/TodayOverviewPage';
import styles from './ActiveIncidentCountsTile.module.css';
import { useDispatch } from 'react-redux';
import { isActiveIncident } from '../../../actions/incidents';

type Props = {
  className?: string;
  timeRange: TodayPageTimeRange;
};

export interface ProcessedData extends IncidentCountBySiteData {
  siteTitle: string;
}

const TOOLTIP_DESCRIPTION =
  'This tile shows the sites with most incidents occurring currently. To see the full list, click on "See All".';

const ActiveIncidentCountsTile: React.FC<Props> = ({
  className,
  timeRange,
}) => {
  ///*** Hooks ***///
  const dispatch = useDispatch();
  const { setTimeFilter } = useTimeFilter();
  const sitesById = useGetAllSites();
  const queryParams = useMemo(
    () => ({
      from: timeRange.from,
      to: timeRange.to,
      incidentStatus: IncidentStatus.ACTIVE,
    }),
    [timeRange]
  );
  const { isLoading, data: countsBySite } =
    useGetIncidentCountsBySite(queryParams);

  ///*** Local vars + Functions ***///
  const dataToDisplay = processData({
    data: countsBySite || {},
    sitesById: sitesById,
    top5Only: true,
  });
  const isThereData = dataToDisplay && dataToDisplay.length > 0;

  const setDateFilterToToday = () => {
    const { today } = createTimeFilterMapping();
    setTimeFilter(today);
    dispatch(isActiveIncident(true));
  };

  const columns: Columns<ProcessedData>[] = [
    {
      header: '#',
      cellContent: (_, rowIndex) => (
        <span className={styles.index}>{rowIndex + 1}</span>
      ),
    },
    {
      header: 'Site',
      cellContent: ({ siteTitle }) => (
        <div>
          <ShortenedSpan
            content={siteTitle}
            maxStringLength={35}
            arrowDirection={'bottom'}
          />
        </div>
      ),
    },
    {
      header: 'Incidents',
      rightAlignContent: true,
      width: '5rem',
      cellContent: ({ siteId, incidentCountTotal }) => (
        <div>
          <Link
            onClick={setDateFilterToToday}
            to={`/sites/${siteId}/incidents`}
          >
            {incidentCountTotal}
          </Link>
        </div>
      ),
    },
  ];

  if (!isThereData) {
    return (
      <Tile className={className} isLoading={isLoading}>
        <TileHeader
          title={TodayTileNames.ActiveIncidentCounts}
          tooltipDescription={TOOLTIP_DESCRIPTION}
        />
        <HappyHornOverlay />
      </Tile>
    );
  }

  return (
    <Tile className={className} isLoading={isLoading}>
      <TileHeader
        title={TodayTileNames.ActiveIncidentCounts}
        tooltipDescription={TOOLTIP_DESCRIPTION}
      />

      <TileContent>
        <Table
          className={styles.table}
          columns={columns}
          data={dataToDisplay}
        />
      </TileContent>

      <TileFooter redirectTo={Routes.ORG_ACTIVE_INCIDENT_COUNTS} />
    </Tile>
  );
};

export const processData = ({
  data,
  sitesById,
  top5Only,
}: {
  data: IncidentCountsBySite;
  sitesById: SitesById;
  top5Only?: boolean;
}) => {
  const dataArray = Object.values(data).map((d) => {
    const siteTitle = pathOr(
      global.NOT_AVAILABLE,
      [d.siteId, 'title'],
      sitesById
    );
    return { ...d, siteTitle };
  });

  const sortedData = dataArray.sort((siteA, siteB) => {
    return siteB.incidentCountTotal - siteA.incidentCountTotal;
  });

  return top5Only ? sortedData.slice(0, 5) : sortedData;
};

export default ActiveIncidentCountsTile;
