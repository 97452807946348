import {
  ControlsHealth as ControlsHealthIcon,
  DoorSensor as DoorIcon,
  DoorSirenOff as DoorSirenOffIcon,
  DoorSirenOn as DoorSirenOnIcon,
  EnergySensor as IconEnergySensor,
  GasStation as GasStationIcon,
  GatewayLowBattery as GatewayLowBatteryIcon,
  GatewayOffline as GatewayOfflineIcon,
  HumiditySensor as HumidityIcon,
  TemperatureSensor as TemperatureIcon,
  LocalOverride as SiteControllerLocalOverrideIcon,
  ThermostatOverride as ThermostatLocalOverrideIcon,
} from '@energybox/react-ui-library/dist/icons';
import {
  ParametersSwitchOnOff,
  SensorParamType,
  SensorType,
  SentinelType,
} from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { SensorIcon } from '../../types/icon';

export default ({ classes, size, id, parameters }: SensorIcon) => {
  switch (id) {
    case SentinelType.TEMPERATURE:
    case SensorType.TEMPERATURE:
      return <TemperatureIcon classes={classes} size={size} />;

    case SensorType.HUMIDITY:
    case SentinelType.HUMIDITY:
      return (
        <HumidityIcon classes={classes} size={size} parameters={parameters} />
      );

    case SensorType.BINARY:
    case SentinelType.BINARY:
      return <DoorIcon classes={classes} size={size} parameters={parameters} />;

    case SentinelType.SITE_CONTROLLER_LOCAL_OVERRIDE_CHECK:
      return <SiteControllerLocalOverrideIcon classes={classes} size={size} />;

    case SentinelType.THERMOSTAT_LOCAL_OVERRIDE_CHECK:
      return <ThermostatLocalOverrideIcon classes={classes} size={size} />;

    case SentinelType.CONTROLS_HEALTH_CHECK:
      return (
        <ControlsHealthIcon
          classes={classes}
          size={size}
          parameters={parameters}
        />
      );

    case SentinelType.CONNECTIVITY:
      return (
        <GatewayOfflineIcon
          classes={classes}
          size={size}
          parameters={parameters}
        />
      );
    case SensorParamType.ACTIVE_POWER:
    case SentinelType.ACTIVE_POWER_THRESHOLD:
      return (
        <IconEnergySensor
          classes={classes}
          size={size}
          parameters={parameters}
        />
      );
    case SentinelType.BATTERY_LEVEL_CHECK:
      return (
        <GatewayLowBatteryIcon
          classes={classes}
          size={size}
          parameters={parameters}
        />
      );

    case SentinelType.TEMPERATURE_AND_DOOR:
      return <GasStationIcon classes={classes} size={size} />;

    case SentinelType.DOOR_SIREN_CHECK: {
      if (parameters && parameters.onOff === ParametersSwitchOnOff.ON) {
        return <DoorSirenOnIcon classes={classes} size={size} />;
      } else if (parameters && parameters.onOff === ParametersSwitchOnOff.OFF) {
        return <DoorSirenOffIcon classes={classes} size={size} />;
      }
      return null;
    }

    default:
      return null;
  }
};
