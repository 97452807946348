import { GenericFilter } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import useDynamicFilter from '../../../hooks/useDynamicFilter';

type Props = {
  className?: string;
  vendors: string[];
  disabled?: boolean;
  alignItemsRight?: boolean;
};

const VendorFilter = ({
  className,
  vendors,
  disabled,
  alignItemsRight,
}: Props) => {
  const { selectedFilters: selectedVendors, setFilter: setSelectedVendors } =
    useDynamicFilter<string>('vendor');

  return (
    <GenericFilter
      className={className}
      title="Vendor"
      setFilter={setSelectedVendors}
      items={vendors}
      selectedItems={selectedVendors}
      disabled={disabled}
      alignItemsRight={alignItemsRight}
    />
  );
};

export default VendorFilter;
