import { SubscribedControlBoards } from '@energybox/react-ui-library/dist/types/SubscribedControlBoard';
import { SubscribedControlStatusById } from '@energybox/react-ui-library/dist/types/SubscribedControlStatus';
import { SubscribedThermostats } from '@energybox/react-ui-library/dist/types/SubscribedThermostat';
import { connectRouter, RouterState } from 'connected-react-router';
import { combineReducers } from 'redux';
import { Actions } from '../actions/constants/Actions';
import history from '../history';
import analytics, { Analytics } from './analytics';
import app, { App } from './app';
import comments, { Comments } from './comments';
import controls, { Controls } from './controls';
import controlBoards, { ControlBoards } from './control_boards';
import deviceStatusById, { DeviceStatusById } from './deviceStatusById';
import dialog, { Dialog } from './dialog';
import energy, { Energy } from './energy';
import equipment, { Equipments } from './equipment';
import equipmentFilter, { EquipmentFilter } from './equipmentFilter';
import equipmentGrouping, { EquipmentGrouping } from './equipmentGrouping';
import equipmentTags, { EquipmentTags } from './equipmentTags';
import features from './features';
import filters, { Filters } from './filters';
import gateways, { Gateways } from './gateways';
import incidents, { Incidents } from './incidents';
import loading, { Loading } from './loading';
import networkGroups, { NetworkGroups } from './networkGroups';
import notificationFilter, { NotificationFilters } from './notificationFilter';
import notifications, { Notifications } from './notifications';
import organizations, { Organizations } from './organizations';
import reports, { Reports } from './reports';
import resources, { Resources } from './resources';
import operationalSamples, { OperationalSamples } from './samples';
import sensors, { Sensors } from './sensors';
import sensorsBySiteId, { SensorsBySiteId } from './sensorsBySiteId';
import sentinels, { Sentinels } from './sentinels';
import siteOnlineStatusById, {
  SiteOnlineStatusById,
} from './siteOnlineStatusById';
import sites, { Sites } from './sites';
import sops, { Sops } from './sops';
import spaces, { Spaces } from './spaces';
import subscribedControlBoards from './subscribedControlBoards';
import subscribedControlStatus from './subscribedControlStatus';
import subscribedThermostats from './subscribedThermostat';
import timetables, { Timetables } from './timetables';
import { FeaturesReducer } from './types/features';
import userGroups, { UserGroups } from './userGroups';
import users, { Users } from './users';
import weather, { Weather } from './weather';
import thermostats, { Thermostats } from './thermostats';
import files, { Files } from './files';
import subscribedReportWorker, {
  SubscribedReportWorker,
} from './subscribeReport';
import reportworker, { ReportWorker } from './reportworker';
import edgeDevices, { EdgeDeviceState } from './edge_devices';
import whatsNew, { WhatsNew } from './whatsNew';
import siteGroups, { SiteGroups } from './site_groups';
import license, { License } from './license';

export interface ApplicationState {
  router: RouterState;
  app: App;
  analytics: Analytics;
  comments: Comments;
  controls: Controls;
  controlBoards: ControlBoards;
  dialog: Dialog;
  features: FeaturesReducer;
  equipment: Equipments;
  equipmentFilter: EquipmentFilter;
  equipmentGrouping: EquipmentGrouping;
  equipmentTags: EquipmentTags;
  loading: Loading;
  notifications: Notifications;
  notificationFilter: NotificationFilters;
  sites: Sites;
  siteOnlineStatusById: SiteOnlineStatusById;
  spaces: Spaces;
  sensors: Sensors;
  sentinels: Sentinels;
  sops: Sops;
  timetables: Timetables;
  energy: Energy;
  reports: Reports;
  resources: Resources;
  sensorsBySiteId: SensorsBySiteId;
  organizations: Organizations;
  operationalSamples: OperationalSamples;
  weather: Weather;
  incidents: Incidents;
  users: Users;
  filters: Filters;
  deviceStatusById: DeviceStatusById;
  userGroups: UserGroups;
  networkGroups: NetworkGroups;
  gateways: Gateways;
  subscribedThermostats: SubscribedThermostats;
  subscribedControlBoards: SubscribedControlBoards;
  subscribedControlStatus: SubscribedControlStatusById;
  thermostats: Thermostats;
  files: Files;
  subscribedReportWorker: SubscribedReportWorker;
  reportworker: ReportWorker;
  edgeDevices: EdgeDeviceState;
  whatsNew: WhatsNew;
  siteGroups: SiteGroups;
  license: License;
}

const appReducer = combineReducers<ApplicationState>({
  router: connectRouter(history),
  app,
  analytics,
  comments,
  controls,
  controlBoards,
  dialog,
  features,
  equipment,
  equipmentFilter,
  equipmentGrouping,
  equipmentTags,
  loading,
  notifications,
  notificationFilter,
  sites,
  siteOnlineStatusById,
  spaces,
  sensors,
  sentinels,
  sops,
  timetables,
  energy,
  reports,
  resources,
  sensorsBySiteId,
  organizations,
  operationalSamples,
  weather,
  incidents,
  users,
  filters,
  deviceStatusById,
  userGroups,
  siteGroups,
  networkGroups,
  gateways,
  subscribedThermostats,
  subscribedControlBoards,
  subscribedControlStatus,
  thermostats,
  files,
  subscribedReportWorker,
  reportworker,
  edgeDevices,
  whatsNew,
  license,
});

const rootReducer = (state, action) => {
  if (action.type === Actions.LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
