import { Site, SitesApiFilter } from '@energybox/react-ui-library/dist/types';
import { fetchApi } from '../utils/fetchApi';
import { Service } from '../config';
export const apiBase = '/api/v2/files';

export enum Actions {
  MY_DOWNLOADS_SUCCESS = '@@app/MY_DOWNLOADS_SUCCESS',
  MY_DOWNLOADS_LOADING = '@@app/MY_DOWNLOADS_LOADING',
  MY_DOWNLOADS_ERROR = '@@app/MY_DOWNLOADS_ERROR',
  INITIATE_DOWNLOAD_FILE = '@@app/INITIATE_DOWNLOAD_FILE',

  DOWNLOAD_FILES_SUCCESS = '@@app/DOWNLOAD_FILES_SUCCESS',
  DOWNLOAD_FILES_LOADING = '@@app/DOWNLOAD_FILES_LOADING',
  DOWNLOAD_FILES_ERROR = '@@app/DOWNLOAD_FILES_ERROR',

  DELETE_FILES_SUCCESS = '@@app/DELETE_FILES_SUCCESS',
  DELETE_FILES_LOADING = '@@app/DELETE_FILES_LOADING',
  DELETE_FILES_ERROR = '@@app/DELETE_FILES_ERROR',

  TOGGLE_DELETE_STATE = '@@app/TOGGLE_DELETE_STATE',
}

export const fetchDownloadFiles = (id: number | string) => {
  // @ts-ignore
  return fetchApi({
    endpoint: `${apiBase}?siteId=${id}`,
    service: Service.files,
  });
};

export const getFiles = (id?: number | string) => ({
  type: 'API_GET',
  path: `${apiBase}?fileType=REPORT`,
  loading: Actions.MY_DOWNLOADS_LOADING,
  success: Actions.MY_DOWNLOADS_SUCCESS,
  error: Actions.MY_DOWNLOADS_ERROR,
  service: Service.files,
});

export const initiateDownload = (status: boolean) => ({
  type: Actions.INITIATE_DOWNLOAD_FILE,
  payload: status,
});

export const fileDownload = async (fileId: string) => {
  try {
    const { url } = await fetchApi({
      service: Service.files,
      endpoint: `${apiBase}/generate-presigned-url`,
      method: 'POST',
      payload: { fileId, ttl: 900 },
    });

    const downloader = document.createElement('a');
    downloader.href = url;
    document.body.appendChild(downloader);
    downloader.click();
    document.body.removeChild(downloader);
  } catch (e) {
    return;
  }
};

// Delete Files
export const deleteFiles = (fileUuid: string) => {
  return {
    type: 'API_DELETE',
    path: `/api/v2/files/${fileUuid}`,
    loading: Actions.DELETE_FILES_LOADING,
    success: Actions.DELETE_FILES_SUCCESS,
    error: Actions.DELETE_FILES_ERROR,
    service: Service.files,
  };
};

export const toggleDeleteStatus = (status: boolean) => ({
  type: Actions.TOGGLE_DELETE_STATE,
  payload: status,
});
