import {
  TimeDistance,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import { DeviceState } from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';
import format from 'date-fns/format';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
} from '../../actions/streamApi';
import DeviceConnectionStatus from '../../components/DeviceConnectionStatus';
import useAppLocale from '../../hooks/useAppLocale';
import { ApplicationState } from '../../reducers';

// HACK, you can submit the uuid as the id and this will still work,

export const IGNORE_DEFAULT_TIMESTAMPS = [
  '1970-01-01T00:00:00Z',
  '1970-01-01T00:00:00.000Z',
  '1970-01-01T00:00:00',
];

export enum DeviceOnlineStateType {
  TIME_ONLY = 'TIME_ONLY',
  STATUS_ONLY_WITH_TEXT = 'STATUS_ONLY_WITH_TEXT',
  STATUS_ONLY_WITHOUT_TEXT = 'STATUS_ONLY_WITHOUT_TEXT',
  COMBINED = 'COMBINED',
}

type OwnProps = {
  id: number | string;
  uuid?: string;
  vendor?: string;
  displayType?: DeviceOnlineStateType;
  size?: number;
  color?: string;
};

type Props = {
  combinedDeviceState?: DeviceState;
  streamConnected: boolean;
  subscribeToDeviceStatus: typeof subscribeToDeviceStatus;
  unsubscribeFromDeviceStatus: typeof unsubscribeFromDeviceStatus;
} & OwnProps;

const DeviceOnlineState: React.FunctionComponent<Props> = ({
  id,
  uuid,
  vendor,
  streamConnected,
  combinedDeviceState,
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
  displayType,
  size,
  color,
}) => {
  ///*** Hooks ***///
  const locale = useAppLocale();
  // @ts-ignore
  useEffect(() => {
    if (uuid && vendor && streamConnected) {
      subscribeToDeviceStatus(vendor, uuid, id);

      return () => unsubscribeFromDeviceStatus(vendor, uuid, id);
    }
    // eslint-disable-next-line
  }, [uuid, vendor, id, streamConnected]);

  ///*** Local vars + functions ***///
  const timestamp = combinedDeviceState && combinedDeviceState.updatedAt;

  const formatDate = (isoDateString: string) => {
    const date = new Date(isoDateString);
    if (date.toString() === 'Invalid Date') return global.NOT_AVAILABLE;

    return format(date, locale.dateTimeFormat);
  };

  if (!uuid && !vendor) {
    return (
      <DeviceConnectionStatus
        color={color}
        size={size}
        connectionStatus={combinedDeviceState?.onlineState}
        hideText
      />
    );
  }

  if (
    !combinedDeviceState ||
    (timestamp && IGNORE_DEFAULT_TIMESTAMPS.includes(timestamp))
  ) {
    return <span>{global.NOT_AVAILABLE}</span>;
  }

  switch (displayType) {
    case DeviceOnlineStateType.TIME_ONLY:
      return (
        <>
          {' '}
          {timestamp
            ? formatDate(combinedDeviceState.timestamp)
            : global.NOT_AVAILABLE}
        </>
      );

    case DeviceOnlineStateType.STATUS_ONLY_WITH_TEXT:
      return (
        <DeviceConnectionStatus
          color={color}
          size={size}
          connectionStatus={combinedDeviceState.onlineState}
        />
      );

    case DeviceOnlineStateType.STATUS_ONLY_WITHOUT_TEXT:
      return (
        <DeviceConnectionStatus
          color={color}
          size={size}
          connectionStatus={combinedDeviceState.onlineState}
          hideText
        />
      );

    default:
      return (
        <Tooltip
          arrowDirection="bottom"
          content={<TimeDistance timestamp={timestamp} />}
        >
          <strong>
            {combinedDeviceState.onlineState ? (
              <span style={{ color: 'var(--green-base)' }}>Online</span>
            ) : (
              <span style={{ color: 'var(--pink-base)' }}>Offline</span>
            )}
          </strong>
        </Tooltip>
      );
  }
};

const mapStateToProps = (
  { deviceStatusById, app: { streamConnected } }: ApplicationState,
  { id }: OwnProps
) => ({
  streamConnected,
  combinedDeviceState: id ? deviceStatusById[id.toString()] : undefined,
});

const mapDispatchToProps = {
  subscribeToDeviceStatus,
  unsubscribeFromDeviceStatus,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceOnlineState);
