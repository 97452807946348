import { Actions } from '../actions/equipmentTags';
import {
  EquipmentGroup,
  EquipmentType,
} from '@energybox/react-ui-library/dist/types';

export type EquipmentTags = {
  equipmentGroups: EquipmentGroup[];
  equipmentTypes: EquipmentType[];
  isEquipmentTypesLoading: boolean;
};

const initialState = {
  equipmentGroups: [],
  equipmentTypes: [],
  isEquipmentTypesLoading: false,
};

const equipmentTags = (state: EquipmentTags = initialState, action: any) => {
  switch (action.type) {
    case Actions.EQUIPMENT_GROUPS_SUCCESS:
      return {
        ...state,
        equipmentGroups: action.payload,
      };

    case Actions.EQUIPMENT_TYPES_SUCCESS: {
      return {
        ...state,
        equipmentTypes: action.payload,
        isEquipmentTypesLoading: false,
      };
    }

    case Actions.EQUIPMENT_TYPES_ERROR: {
      return {
        ...state,
        isEquipmentTypesLoading: false,
      };
    }

    case Actions.EQUIPMENT_TYPES_LOADING: {
      return {
        ...state,
        isEquipmentTypesLoading: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default equipmentTags;
