import { Actions as AppActions } from '../actions/constants/Actions';
import { getUserType } from '../actions/app';
import {
  getEquipmentGroups,
  getEquipmentTypes,
} from '../actions/equipmentTags';

import history from '../history';
import { LocalStorageKey } from '../utils/localStorage';
import { getCurrentOrganization } from '../actions/organizations';

const STORE_KEY = 'authToken';

const getToken = (): string | null => {
  return window.localStorage.getItem(STORE_KEY);
};

const setToken = (authToken: string) => {
  window.localStorage.setItem(STORE_KEY, authToken);
};

const removeToken = () => {
  window.localStorage.removeItem(STORE_KEY);
};

const authMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case AppActions.INIT:
      const accessToken = getToken();

      if (accessToken) {
        store.dispatch({
          type: AppActions.LOGIN_SUCCESS,
          payload: { accessToken },
        });
      }
      break;

    case AppActions.INIT_WITH_TOKEN:
      if (action.payload.accessToken) {
        store.dispatch({
          type: AppActions.LOGIN_SUCCESS,
          payload: { accessToken: action.payload.accessToken },
        });
      }
      break;

    case AppActions.LOGIN_SUCCESS:
    case AppActions.CHANGE_ORGANIZATION_SUCCESS:
    case AppActions.RESET_PASSWORD_SUCCESS:
      setToken(action.payload.accessToken);
      localStorage.removeItem(LocalStorageKey.ACCOUNT_LOCKED);

      store.dispatch({
        type: AppActions.ACCESS_TOKEN_UPDATED,
        payload: action.payload,
      });

      store.dispatch(getEquipmentGroups());
      store.dispatch(getEquipmentTypes());
      store.dispatch(getUserType());

      const searchParams = new URLSearchParams(window.location.search);
      const redirect = searchParams.get('next');
      if (redirect !== null) {
        searchParams.delete('next');
        history.push(`${redirect}?${searchParams.toString()}`);
        break;
      }

      // if user logs in and token is valid
      if (['/', '/login'].includes(window.location.pathname) === false) {
        store.dispatch(getCurrentOrganization());
        history.push(window.location.pathname);
        break;
      }

      history.push('/');
      break;

    case AppActions.LOGOUT:
      removeToken();

      store.dispatch({
        type: AppActions.ACCESS_TOKEN_CLEARED,
      });

      history.push('/');
      window.location.reload();
      break;

    case AppActions.USER_CHANGE_PASSWORD_SUCCESS:
      history.push('/');
      window.location.reload();
      break;
  }

  next(action);
};

export default authMiddleware;
