import { ObjectById, Tiles } from '@energybox/react-ui-library/dist/types';

export enum SiteComponent {
  CONTROLS = 'CONTROLS',
  CONTROLS_THERMOSTAT = 'CONTROLS_THERMOSTAT',
}

export enum SiteNav {
  ANALYTICS_TEMPERATURE_COMPLIANCE = 'ANALYTICS_TEMPERATURE_COMPLIANCE',
  ANALYTICS_HIGHEST_CONSUMING_EQUIPMENT = 'ANALYTICS_HIGHEST_CONSUMING_EQUIPMENT',
  TASK_TEMP_REVIEW = 'TASK_TEMP_CHECK',
  CONTROLS_THERMOSTAT = 'CONTROLS_THERMOSTAT',
}

export type FeaturesBySiteId = {
  [siteId: string]: SiteLevelFeatures;
};
export type FeaturesByOrganizationId = {
  [OrganizationId: string]: OrganizatioinLevelFeatures;
};

export type SiteLevelFeatures = {
  SITE_CARD: Tiles[];
  SITE_COMPONENT: SiteComponent[];
  SITE_NAV: SiteNav[];
};

export type OrganizatioinLevelFeatures = {
  SITE_CARD: Tiles[];
  SITE_NAV: SiteNav[];
  SITE_COMPONENT: SiteComponent[];
};

export type FeaturesReducer = {
  featuresBySiteId: FeaturesBySiteId;
  isLoadingBySiteId: ObjectById<boolean>;
  featuresByOrganizationId: FeaturesByOrganizationId;
  isLoadingByOrganizationId: ObjectById<boolean>;
};
