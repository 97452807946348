import { Tiles } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import TilesMenuDropdown from '../TilesMenuDropdown';
import styles from './BlurredInfo.module.css';

type Props = {
  className?: string;
  title: React.ReactNode;
  faultyData?: boolean;
  isEditMode?: boolean;
  onRemoveTile?: (tileKey: Tiles) => void;
  errorMessage?: string;
};

const BlurredInfo = ({
  className,
  title,
  faultyData,
  isEditMode,
  onRemoveTile,
  errorMessage,
}: Props) => (
  <>
    {isEditMode ? (
      <div className={styles.tilesMenuDropdown}>
        <TilesMenuDropdown
          onRemoveTile={() => {
            if (onRemoveTile) onRemoveTile(Tiles.EnergyDistributionCard);
          }}
        />
      </div>
    ) : null}

    <div className={`${className} ${styles.content}`}>
      <div className={styles.messageContainer}>
        <div className={styles.title}>{title}</div>
        <div data-testid="blurred_info_user_message">
          {faultyData ? (
            <>
              Your Energy<strong>Pro</strong> is offline, please check the
              connection and refresh the page later
            </>
          ) : (
            errorMessage || `Complete setup process to unlock this tile`
          )}
        </div>
      </div>
    </div>
  </>
);

export default BlurredInfo;
