import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Switch, Redirect, Route } from 'react-router-dom';
import { useGetIncidents } from '../../hooks/useIncidents';
import * as Routes from '../../routes';
import { ApplicationState } from '../../reducers';
import { closeDialog } from '../../actions/dialog';
import { updateScrollPosition } from '../../actions/app';
import BaseDialog from '../../components/BaseDialog';
import TopBarContainer from './TopBarContainer';
import Footer from '../Footer';
import Navigation from '../../components/Navigation';
import BaseContentContainer from '../../components/layout/BaseContentContainer';

import MapPage from '../views/MapPage';
import TodayOverviewPage from '../views/today/TodayOverviewPage';
import NotificationsOverviewPage from '../views/notifications/NotificationsOverviewPage';
import ChangePasswordPage from '../views/setting/ChangePasswordPage';
import ActiveIncidentCountsPage from '../views/today/ActiveIncidentCountsPage';
import IncidentsListPage from '../views/incidents/IncidentsListPage';
import SitesWithMostIncidentsPage from '../views/incidents/SitesWithMostIncidentsPage';
import MyDownloadsPage from '../MyDownloads';
import AnnualCO2Emission from '../views/insights/AnnualCO2Emission/AnnualCO2Emission';

import styles from './DefaultLayout.module.css';
import AnnualUsageIntensity from '../views/insights/AnnualUsageIntensity/AnnualUsageIntensity';
import AnnualEnergyConsumption from '../views/insights/AnnualEnergyConsumption/AnnualEnergyConsumption';
import NOHEnergyConsumption from '../views/insights/NOHEnergyConsumption/NOHEnergyConsumption';
import { isInSitesPage } from '../../util';
import License from '../License/License';
import ControlledHvacPerformance from '../views/equipments/ControlledHvacPerformance';

const ShowEquipmentPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ShowEquipmentPage" */ '../../containers/ShowEquipmentPage'
    )
);

const ShowSitePageContainer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ShowSitePageContainer" */ '../views/sites/ShowSitePageContainer/ShowSitePageContainer'
    )
);

const SitesPage = React.lazy(
  () => import(/* webpackChunkName: "SitesPage" */ '../views/sites/SitesPage')
);

const SummaryPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SummaryPage" */ '../views/insights/MultiSitesSummary'
    )
);

const DashboardPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardPage" */ '../views/insights/MultiSitesOverview'
    )
);

const NotificationsListPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "NotificationsListPage" */ '../views/notifications/NotificationsListPage/NotificationsListPage'
    )
);

const NotificationsBySiteTable = React.lazy(
  () =>
    import(
      /* webpackChunkName: "NotificationsBySiteTable" */ '../views/notifications/NotificationsBySite/NotificationsBySite'
    )
);

const EquipmentPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EquipmentsPage" */ '../views/equipments/EquipmentOverview'
    )
);

const EquipmentPerformancePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EquipmentsPage" */ '../views/equipments/EquipmentPerformance'
    )
);

const TemperatureCompliancePage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SitesPage" */ '../views/dashboard/TemperatureCompliancePage'
    )
);

const OrgControlsSavingsPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SitesPage" */ '../views/dashboard/ControlsSavings/OrgControlsSavings'
    )
);

const OrgSitesEnergyConsumptionPage = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SitesPage" */ '../views/dashboard/SitesEnergyConsumption/OrgSitesEnergyConsumptionPage'
    )
);

const ControlOperations = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ShowControlsPage" */ '../HvacControlsOperations/ControlOperations'
    )
);

const ReportsCentre = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReportsCentrePage" */ '../views/reports/ReportsCentrePage'
    )
);

type Props = {
  isInitialized: boolean;
  userIsInitialized: boolean;
  dialog: any;
  closeDialog: any;
  isScrollNumberActive: boolean;
  updateScrollPosition: (scrollTop: number) => void;
  orgTitle: string | undefined;
};

function DefaultLayout({
  isInitialized,
  userIsInitialized,
  dialog,
  closeDialog,
  isScrollNumberActive,
  updateScrollPosition,
  orgTitle,
}: Props) {
  if (isInitialized === false || userIsInitialized === false) {
    return null;
  }
  const passwordUpdateEnforced = useSelector<
    ApplicationState,
    boolean | undefined
  >(({ app }) => app.passwordUpdateEnforced);
  const mainScrollRef = React.useRef<HTMLElement>(null);
  const onScrollHandler = () => {
    requestAnimationFrame(() =>
      updateScrollPosition(mainScrollRef.current?.scrollTop || 0)
    );
  };

  const noNavigation = window.location!.pathname.includes('/report/');

  const title = document.title;
  const hasPattern = isInSitesPage();

  useEffect(() => {
    if (!hasPattern && title !== orgTitle) {
      document.title = orgTitle || '';
    }
  }, [hasPattern]);

  return (
    <div className={noNavigation ? '' : styles.root}>
      <Navigation noNavigation={noNavigation} />
      <main
        className={styles.content}
        ref={mainScrollRef}
        onScroll={(isScrollNumberActive && onScrollHandler) || undefined}
      >
        <TopBarContainer noNavigation={noNavigation} />
        <div className={styles.routeContainer}>
          <React.Suspense
            fallback={
              <BaseContentContainer isLoading>
                <span />
              </BaseContentContainer>
            }
          >
            <Switch>
              {passwordUpdateEnforced && (
                <Route
                  path="/*"
                  render={() => <ChangePasswordPage enforced />}
                />
              )}

              <Route
                path={Routes.CHANGE_PASSWORD}
                component={ChangePasswordPage}
              />

              <Route
                path={Routes.MY_DOWNLOADS_PAGE}
                component={MyDownloadsPage}
              />

              <Route exact path={Routes.SUMMARY} component={SummaryPage} />
              <Route
                exact
                path={Routes.ANNUAL_ENERGY_CONSUMPTION}
                component={AnnualEnergyConsumption}
              />
              <Route
                exact
                path={Routes.ANNUAL_CO2_EMISSION}
                component={AnnualCO2Emission}
              />
              <Route
                exact
                path={Routes.ANNUAL_USAGE_INTENSITY}
                component={AnnualUsageIntensity}
              />

              <Route exact path={Routes.DASHBOARD} component={DashboardPage} />

              <Route
                exact
                path={Routes.NOH_VS_OH_ENERGY_CONSUMPTION}
                component={NOHEnergyConsumption}
              />

              <Route exact path={Routes.TODAY} component={TodayOverviewPage} />
              <Route
                exact
                path={Routes.ORG_ACTIVE_INCIDENT_COUNTS}
                component={ActiveIncidentCountsPage}
              />
              <Route
                path={`${Routes.NOTIFICATIONS}/sites`}
                component={NotificationsBySiteTable}
              />
              <Route
                exact
                path={`${Routes.NOTIFICATIONS}${Routes.INCIDENT_COUNTS}`}
                component={SitesWithMostIncidentsPage}
              />

              <Route
                path={Routes.NOTIFICATION_OVERVIEW}
                component={NotificationsOverviewPage}
              />
              <Route
                path={Routes.NOTIFICATIONS}
                component={NotificationsListPage}
              />

              <Route
                path={Routes.INCIDENTS}
                render={() => (
                  <IncidentsListPage getIncidents={useGetIncidents} />
                )}
              />

              <Route path={Routes.ORG_EQUIPMENT} component={EquipmentPage} />

              <Route
                path={`${Routes.ORG_EQUIPMENT_PERFORMANCE}/:equipmentGroupId`}
                component={EquipmentPerformancePage}
              />

              <Route
                path={`${Routes.ORG_EQUIPMENT_PERFORMANCE_HVAC}/hvac-controls`}
                component={ControlledHvacPerformance}
              />

              <Route
                path={`${Routes.HVAC_CONTROLS_OPERATIONS}/:issues?`}
                component={ControlOperations}
              />

              <Route path={Routes.MAP} component={MapPage} />

              <Route exact path={Routes.SITES} component={SitesPage} />

              <Route
                exact
                path={Routes.ORG_TEMPERATURE_COMPLIANCE}
                component={TemperatureCompliancePage}
              />
              <Route
                exact
                path={Routes.ORG_CONTROLS_SAVINGS}
                component={OrgControlsSavingsPage}
              />
              <Route
                exact
                path={Routes.ORG_SITES_ENERGY_CONSUMPTION}
                component={OrgSitesEnergyConsumptionPage}
              />

              <Route
                path={`${Routes.SITES}/:id`}
                render={({ match }) =>
                  match && <ShowSitePageContainer {...match.params} />
                }
              />

              <Route
                exact
                path={`${Routes.EQUIPMENT}/:id`}
                render={({ match }) =>
                  match && <ShowEquipmentPage {...match.params} />
                }
              />

              <Route
                exact
                path={Routes.REPORT_CENTRE}
                render={({ match }) =>
                  match && <ReportsCentre {...match.params} />
                }
              />

              <Route
                exact
                path={Routes.OPEN_SOURCE_LICENSE}
                render={({ match }) => match && <License {...match.params} />}
              />

              <Redirect from="/*" to={Routes.TODAY} />
            </Switch>
          </React.Suspense>
        </div>
        <Footer />
      </main>
      <BaseDialog dialog={dialog} onClose={closeDialog} />
    </div>
  );
}

const mapStateToProps = ({ app, organizations, dialog }: ApplicationState) => ({
  isInitialized: app.isInitialized,
  userIsInitialized: !!app.userIsInitialized,
  isScrollNumberActive: app.isScrollNumberActive,
  dialog: dialog,
  orgTitle: organizations.currentOrganization?.title,
});

const mapDispatchToProps = { closeDialog, updateScrollPosition };

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
