import { FilterTimePeriod } from '@energybox/react-ui-library/dist/types';
import {
  generateToAndFromEpoch,
  hasSubstr,
} from '@energybox/react-ui-library/dist/utils';
import { equals, pathOr } from 'ramda';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationDateFilter } from '../actions/notificationFilter';
import { ApplicationState } from '../reducers';

export function useTimeFilter() {
  const dispatch = useDispatch();
  const timePeriod = useSelector<ApplicationState, FilterTimePeriod>(
    ({ notificationFilter }) => notificationFilter.timePeriod,
    equals
  );
  return useMemo(
    () => ({
      timePeriod,
      apiTimeFilter: generateToAndFromEpoch(timePeriod),
      setTimeFilter: (timeFilter: FilterTimePeriod) =>
        dispatch(setNotificationDateFilter(timeFilter)),
    }),
    [timePeriod, dispatch]
  );
}

export function useSearchFilter<T extends Object>(
  list: T[],
  filterKeys: string[][],
  filterReference?: {
    reference: any[];
    referenceKey: string[];
    searchReferenceKey: string[];
    key: string[];
  }[]
) {
  const [query, setQuery] = useState('');
  const shouldFilterApply = query && query.length >= 3;
  if (shouldFilterApply) {
    const filteredList: T[] =
      (query &&
        list.filter(
          (item) =>
            filterKeys.some((key) => {
              const dataString = pathOr('', key, item);
              return hasSubstr(dataString, query);
            }) ||
            filterReference?.some((r) => {
              const filteredReference = r.reference
                .filter((i) =>
                  hasSubstr(pathOr('', r.searchReferenceKey, i), query)
                )
                .map((i) => pathOr('', r.referenceKey, i));
              return filteredReference.includes(pathOr('', r.key, item));
            })
        )) ||
      list;
    return {
      query,
      setQuery,
      filteredList,
    };
  } else {
    return {
      query,
      setQuery,
      filteredList: list,
    };
  }
}
