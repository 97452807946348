import {
  ApiObjectStatusById,
  HvacControl,
  ObjectById,
  RawTimeTablePreview,
  TimeTable,
  TimeTableEvent,
  TimeTableEventUnit,
  TimeTablePreview,
  TimetableControlTypes,
} from '@energybox/react-ui-library/dist/types';
import { DateTime } from 'luxon';
import { assocPath, unnest } from 'ramda';
import { Actions } from '../actions/timetables';

export interface Timetables {
  timetablesById: ApiObjectStatusById<TimeTable>;
  timetablePreviewsByEquipmentId: TimetablePreviewsByEquipmentId;
  todayTimetablePreviewsByEquipmentId: ObjectById<TimeTablePreview[]>;
  timetablePreviewsBySiteId: TimetablePreviewsBySiteId;
}

export type TimetablePreviewsByEquipmentId = {
  [equipmentId: string]: TimeTablePreview[];
};

export type TimetablePreviewsBySiteId = {
  [siteId: string]: TimeTablePreview[];
};

const initialState = {
  timetablesById: {},
  timetablePreviewsByEquipmentId: {},
  todayTimetablePreviewsByEquipmentId: {},
  timetablePreviewsBySiteId: {},
};

export const genericProcessTimetablePreview =
  (timezone: string) =>
  (d: RawTimeTablePreview): TimeTablePreview | undefined => {
    if (!d.equipment) {
      return undefined;
    }

    if (d.events === null || d.events === undefined) {
      return {
        setPoint: null,
        ...d,
        events: null,
      } as TimeTablePreview;
    }

    if (d.events && d.events.length === 0) {
      return {
        setPoint: null,
        ...d,
      } as TimeTablePreview;
    }

    let index = 0;

    // The first event must have STATE 1 (event starts)
    while (d.events[index].setAction !== 1) {
      ++index;
    }

    d.events = d.events.slice(index);
    const processedEvents: TimeTableEventUnit[][] = Array.from(
      { length: Math.ceil(d.events.length / 2) },
      (_: TimeTableEventUnit, i: number) => {
        return (d.events || []).slice(i * 2, i * 2 + 2);
      }
    ).map((eventPair) => {
      return eventPair.map((eventUnit) => {
        const dateString = d.date;
        const utcTime = DateTime.fromISO(`${dateString}T${eventUnit.time}`, {
          zone: timezone,
        }).toMillis();

        return {
          ...eventUnit,
          unixTimeInMs: utcTime,
        };
      });
    });

    return {
      setPoint: null,
      ...d,
      events: processedEvents as TimeTableEvent[],
    } as TimeTablePreview;
  };

export const processAndRemoveBuggyTimetablePreviews = (
  t: RawTimeTablePreview[],
  timezone: string
): TimeTablePreview[] => {
  const genericallyProcessedTimetablePreviews = t
    .map(genericProcessTimetablePreview(timezone))
    .filter((previews) => previews !== undefined) as TimeTablePreview[];
  return processHvacSopTimetablePreviews(genericallyProcessedTimetablePreviews);
};

const processHvacSopTimetablePreviews = (data: TimeTablePreview[]) => {
  const processedTimetablePreviews: TimeTablePreview[] = [];

  data.forEach((preprocessedPreview) => {
    if (
      preprocessedPreview.controlType !== TimetableControlTypes.VENSTAR_HVAC
    ) {
      processedTimetablePreviews.push(preprocessedPreview);
    } else {
      const isSopHvacTimetableAlreadyProcessed =
        processedTimetablePreviews.find((processedPreview) => {
          return (
            processedPreview.equipment.id ===
              preprocessedPreview.equipment.id &&
            processedPreview.controlType === TimetableControlTypes.VENSTAR_HVAC
          );
        });

      if (isSopHvacTimetableAlreadyProcessed) return;

      const allPreviewsOfHvacControl = data.filter((preview) => {
        return (
          preprocessedPreview.equipment.id === preview.equipment.id &&
          preview.controlType === TimetableControlTypes.VENSTAR_HVAC
        );
      });

      const processedHvacSopTimetablePreview = processHvacTimetablePreviews(
        allPreviewsOfHvacControl
      );

      processedTimetablePreviews.push(
        processedHvacSopTimetablePreview as unknown as TimeTablePreview
      );
    }
  });

  return processedTimetablePreviews;
};

/**
 * @param timetablePreviews: all timetable previews of an hvac-sop attached to equipment
 */
const processHvacTimetablePreviews = (
  timetablePreviews: TimeTablePreview[]
) => {
  const nestedMergedEvents = timetablePreviews.map((p) => {
    return p.events?.map((event) =>
      event?.map((eventUnit) => ({
        ...eventUnit,
        hvacSopMinTemp: p.minTemp,
        hvacSopMaxTemp: p.maxTemp,
        thermostatWorkingMode: p.thermostatWorkingMode, //only works for venstar hvac
      }))
    );
  });

  const flattenedSortedMergedEvents = unnest(nestedMergedEvents).sort(
    (event1, event2) => {
      const event1StartTime = event1[0]?.time;
      const event2StartTime = event2[0]?.time;

      return event1StartTime.localeCompare(event2StartTime);
    }
  );

  return {
    ...timetablePreviews[0],
    outsideMaxTemp: null,
    outsideMinTemp: null,
    timetableId: null,
    events: flattenedSortedMergedEvents,
  };
};

const controls = (
  state: Timetables = initialState,
  action: any
): Timetables => {
  switch (action.type) {
    case Actions.GET_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_SUCCESS: {
      return assocPath(
        ['timetablePreviewsByEquipmentId', action.equipmentId],
        processAndRemoveBuggyTimetablePreviews(
          action.payload.timetablePreview || [],
          action.timezone
        ),
        state
      );
    }

    case Actions.GET_TODAY_TIME_TABLE_PREVIEWS_BY_EQUIPMENT_ID_SUCCESS: {
      return assocPath(
        ['todayTimetablePreviewsByEquipmentId', action.equipmentId],
        processAndRemoveBuggyTimetablePreviews(
          action.payload.timetablePreview || [],
          action.timezone
        ),
        state
      );
    }

    case Actions.GET_TIME_TABLE_PREVIEWS_BY_SITE_ID_SUCCESS: {
      return assocPath(
        ['timetablePreviewsBySiteId', action.siteId],
        processAndRemoveBuggyTimetablePreviews(
          action.payload.timetablePreview,
          action.timezone
        ),
        state
      );
    }

    case Actions.GET_TIME_TABLE_BY_ID_ERROR:
      return assocPath(
        ['timetablesById', action.id, 'isLoading'],
        false,
        state
      );

    case Actions.GET_TIME_TABLE_BY_ID_LOADING:
      return assocPath(['timetablesById', action.id, 'isLoading'], true, state);

    case Actions.GET_TIME_TABLE_BY_ID_LOADING:
      return assocPath(['timetablesById', action.id, 'isLoading'], true, state);

    case Actions.GET_TIME_TABLE_BY_ID_SUCCESS: {
      return assocPath(
        ['timetablesById', action.id, 'data'],
        action.data,
        state
      );
    }

    default:
      return state;
  }
};

export default controls;
