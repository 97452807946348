import {
  Equipment,
  EquipmentGroupCount,
  EquipmentPerformance,
  EquipmentPerformanceData,
  ObjectById,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import { mapArrayToObject } from '@energybox/react-ui-library/dist/utils';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions } from '../actions/equipment';
import { ApiError } from '../utils/apiErrorFeedback';

export type Equipments = {
  isLoading: boolean;
  isLoadingBySiteId: IsLoadingBySiteId;
  isLoadingById: IsLoadingById;
  equipment: Equipment[];
  equipmentById: EquipmentById;
  equipmentBySiteId: EquipmentsBySiteId;
  equipmentIdsBySiteId: ObjectById<number[]>;
  equipmentsBySpaceId: EquipmentsBySpaceId;
  isLoadingBySpaceId: EquipmentsBySpaceIdLoading;
  equipmentCounts: EquipmentCounts;
  hvacEquipmentCount: EquipmentCounts;
  performance: EquipmentPerformanceReport;
  performanceReportStatus: EquipmentPerformanceReportStatus;
  equipmentApiError: ApiError;
};

const initialState = {
  isLoading: false,
  isLoadingBySiteId: {},
  isLoadingById: {},
  equipment: [],
  equipmentBySiteId: {},
  equipmentIdsBySiteId: {},
  equipmentById: {},
  equipmentsBySpaceId: {},
  isLoadingBySpaceId: {},
  equipmentCounts: {
    byGroupIsLoading: false,
  },
  hvacEquipmentCount: {
    byGroupIsLoading: false,
  },
  performance: {
    isLoading: false,
    performanceByGroupId: {},
  },
  performanceReportStatus: {
    reportId: undefined,
    reportVersion: -1,
    loading: 0,
  },
  equipmentApiError: {},
};

export const equipmentsFromApiResponse = (data: any): Equipment => ({
  id: data.id,
  title: data.title,
  spaceId: data.spaceId || undefined,
  space: data.space,
  description: data.description || '',
  createdAt: data.createdAt,
  updatedAt: data.updatedAt || undefined,
  type: data.type,
  typeId: data.typeId,
  groupId: data.groupId,
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
  model: data.model || '',
  vendor: data.vendor || '',
  activeControl: data.activeControl,
  manufactureDate: data.manufactureDate,
  serialNumber: data.serialNumber,
});

export default (state: Equipments = initialState, action: any) => {
  switch (action.type) {
    case Actions.CLEAR_FILTERED_EQUIPMENT_LIST:
      return assoc('equipment', [], state);
    case Actions.GET_EQUIPMENT_SUCCESS:
      return pipe(
        assoc('equipment', action.payload),
        assoc('isLoading', false)
      )(state);

    case Actions.GET_EQUIPMENT_LOADING:
      return assoc('isLoading', true, state);

    case Actions.GET_EQUIPMENT_ERROR:
      return assoc('isLoading', false, state);

    case Actions.GET_EQUIPMENT_BY_ID_SUCCESS:
      return pipe(
        assocPath(['equipmentById', action.equipmentId], action.payload),
        assocPath(['isLoadingById', action.equipmentId], false)
      )(state);

    case Actions.GET_EQUIPMENT_BY_ID_LOADING:
      return assocPath(['isLoadingById', action.equipmentId], true, state);

    case Actions.GET_EQUIPMENT_BY_ID_ERROR:
      return pipe(
        assoc('equipmentApiError', action.payload),
        assocPath(['isLoadingById', action.equipmentId], false)
      )(state);

    case Actions.GET_EQUIPMENT_BY_SITE_ID_LOADING:
      return assocPath(['isLoadingBySiteId', action.siteId], true, state);

    case Actions.GET_EQUIPMENT_BY_SITE_ID_SUCCESS:
      const equipmentById = mapArrayToObject(action.payload);
      return pipe(
        assocPath(['equipmentById'], equipmentById),
        assocPath(['equipmentBySiteId', action.siteId], action.payload),
        assocPath(
          ['equipmentIdsBySiteId', action.siteId],
          action.payload.map(({ id }) => id)
        ),
        assocPath(['isLoadingBySiteId', action.siteId], false)
      )(state);

    case Actions.GET_EQUIPMENT_BY_SITE_ID_ERROR:
      return assocPath(['isLoadingBySiteId', action.siteId], false, state);

    case Actions.GET_EQUIPMENT_BY_SPACE_ID_LOADING:
      return assocPath(['isLoadingBySpaceId', action.spaceId], true, state);

    case Actions.GET_EQUIPMENT_BY_SPACE_ID_SUCCESS:
      return pipe(
        assocPath(['equipmentsBySpaceId', action.spaceId], action.payload),
        assocPath(['isLoadingBySpaceId', action.spaceId], false)
      )(state);

    case Actions.GET_EQUIPMENT_BY_SPACE_ID_ERROR:
      return assocPath(['isLoadingBySpaceId', action.spaceId], false, state);

    case Actions.GET_ALL_EQUIPMENT_COUNT_PER_GROUP_ID_SUCCESS:
      const data = action.payload;
      const mappedData = mapArrayToObject(data, 'equipmentGroupId');
      return pipe(
        assocPath(['equipmentCounts', 'byGroupIsLoading'], false),
        assocPath(['equipmentCounts', 'byGroupId'], mappedData)
      )(state);

    case Actions.GET_HVAC_CONTROL_EQUIPMENT_COUNT_SUCCESS:
      return pipe(
        assocPath(['hvacEquipmentCount', 'byGroupIsLoading'], false),
        assocPath(['hvacEquipmentCount', 'byGroupId'], action.payload)
      )(state);
    case Actions.GET_HVAC_CONTROL_EQUIPMENT_COUNT_LOADING:
      return assocPath(['hvacEquipmentCount', 'byGroupIsLoading'], true, state);

    case Actions.GET_ALL_EQUIPMENT_COUNT_PER_GROUP_ID_LOADING:
      return assocPath(['equipmentCounts', 'byGroupIsLoading'], true, state);
    case Actions.GET_ALL_EQUIPMENT_COUNT_PER_GROUP_ID_ERROR:
      return assocPath(['equipmentCounts', 'byGroupIsLoading'], false, state);

    case Actions.GET_EQUIPMENT_PERFORMANCE_BY_GROUP_SUCCESS:
      const {
        equipmentGroupId,
        payload,
      }: {
        equipmentGroupId: number;
        payload: EquipmentPerformancePayload;
      } = action;
      return pipe(
        assocPath(
          ['performance', 'performanceByGroupId', equipmentGroupId],
          processEquipmentPerformancePayload(payload)
        ),
        assocPath(['performance', 'isLoading'], false)
      )(state);

    case Actions.GET_EQUIPMENT_PERFORMANCE_BY_GROUP_LOADING:
      return assocPath(['performance', 'isLoading'], true, state);
    case Actions.GET_EQUIPMENT_PERFORMANCE_BY_GROUP_ERROR:
      return assocPath(['performance', 'isLoading'], false, state);

    case Actions.CLEAR_EQUIPMENT_PERFORMANCE:
      return pipe(
        assocPath(['performance', 'performanceByGroupId'], {}),
        assocPath(['performanceReportStatus', 'reportId'], undefined)
      )(state);

    // Async report id
    case Actions.GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ID_SUCCESS:
      const { equipmentGroupId: asyncEquipmentGroupId, payload: asyncPayload } =
        action;
      return pipe(
        assocPath(
          ['performanceReportStatus', 'reportId'],
          asyncPayload.report_key
        )
      )(state);
    case Actions.GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ID_LOADING:
      return assocPath(['performance', 'isLoading'], true, state);
    case Actions.GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ID_ERROR:
      return assocPath(['performance', 'isLoading'], false, state);

    // Async report
    case Actions.GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_SUCCESS:
      const {
        equipmentGroupId: asyncReportEquipmentGroupId,
        payload: asyncReportPayload,
      } = action;
      if (asyncReportPayload.data.loading) {
        return pipe(
          assocPath(['performance', 'isLoading'], true),
          assocPath(
            ['performanceReportStatus', 'reportVersion'],
            asyncReportPayload.report_version
          ),
          assocPath(
            ['performanceReportStatus', 'loading'],
            asyncReportPayload.data.loading
          )
        )(state);
      } else {
        return pipe(
          assocPath(
            [
              'performance',
              'performanceByGroupId',
              asyncReportEquipmentGroupId,
            ],
            processEquipmentPerformancePayload(asyncReportPayload)
          ),
          assocPath(['performance', 'isLoading'], false)
        )(state);
      }
    case Actions.GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_LOADING:
      return assocPath(['performance', 'isLoading'], true, state);
    case Actions.GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ERROR:
      return assocPath(['performance', 'isLoading'], false, state);

    default:
      return state;
  }
};

export type EquipmentPerformanceReport = {
  isLoading: boolean;
  performanceByGroupId: {
    [equipmentGroupId: number]: EquipmentPerformance;
  };
};

export type EquipmentPerformanceReportStatus = {
  reportId: string | undefined;
  reportVersion: number;
  loading: number;
};

export type EquipmentsBySpaceId = {
  [spaceId: string]: Equipment[];
};

type EquipmentsBySpaceIdLoading = {
  [spaceId: string]: boolean;
};

type IsLoadingBySiteId = {
  [siteId: string]: boolean;
};

type IsLoadingById = {
  [equipmentId: string]: boolean;
};

export type EquipmentById = {
  [equipmentId: string]: Equipment;
};

type EquipmentsBySiteId = {
  [siteId: string]: Equipment[];
};

type EquipmentPerformancePayload = {
  key: number;
  data: {
    by_equipment: {
      [equipmentId: string]: {
        site_id: number;
        site_title: string;
        equipment_group_id: number;
        equipment_type_id: number;
        default_kpis: {
          equipment_title: string;
          site_title: string;
          vendor: string;
          model: string;
          total_consumption: number | null;
        };
        // TODO: ask beau if this should return null vs {}
        equipment_type_kpis: null | {
          defrost_cycle_count: number;
          kwh_per_degree_cooling: number;
        };
        by_temp_sensor: {
          [sensorId: string]: {
            average_temperature: number;
            temperature_deviation: number;
            compliance_percentage: number;
          };
        };
        by_access_sensor: {
          [sensorId: string]: {
            total_duration_open_mins: number;
            total_number_access: number;
          };
        };
      };
    };
  };
};

export type EquipmentCountByGroupId = {
  [groupId: number]: EquipmentGroupCount;
};

export type EquipmentCounts = {
  byGroupIsLoading: boolean;
  byGroupId?: EquipmentCountByGroupId;
};

const processEquipmentPerformancePayload = (
  apiPayload: EquipmentPerformancePayload
): EquipmentPerformance => {
  const { key, data } = apiPayload;
  return {
    key,
    performanceDataByEquipment: Object.entries(data.by_equipment).reduce(
      (byEquipmentAccumulator, [equipmentId, currentData]) => {
        const {
          site_id,
          equipment_group_id,
          equipment_type_id,
          default_kpis,
          equipment_type_kpis,
          by_temp_sensor,
          by_access_sensor,
        } = currentData;
        const byTempSensor = {};
        const byAccessSensor = {};
        Object.entries(by_temp_sensor).forEach(([sensorId, apiData]) => {
          byTempSensor[sensorId] = {
            averageTemperature: apiData.average_temperature,
            temperatureDeviation: apiData.temperature_deviation,
            compliancePercentage: apiData.compliance_percentage,
          };
        });
        Object.entries(by_access_sensor).forEach(([sensorId, apiData]) => {
          byAccessSensor[sensorId] = {
            totalDurationOpenMins: apiData.total_duration_open_mins,
            totalNumberAccess: apiData.total_number_access,
          };
        });
        // TODO: need to type this return val
        return {
          ...byEquipmentAccumulator,
          [equipmentId]: {
            equipmentGroupId: equipment_group_id,
            equipmentTypeId: equipment_type_id,
            siteId: site_id,
            siteName: default_kpis.site_title,
            defaultKpis: {
              equipmentTitle: default_kpis.equipment_title,
              vendor: default_kpis.vendor,
              model: default_kpis.model,
              totalConsumption: default_kpis.total_consumption,
            },
            equipmentTypeKpis: {
              kwhPerDegreeCooling:
                equipment_type_kpis !== null
                  ? equipment_type_kpis.kwh_per_degree_cooling
                  : null,
              defrostCycleCount:
                equipment_type_kpis !== null
                  ? equipment_type_kpis.defrost_cycle_count
                  : null,
            },
            byTempSensor,
            byAccessSensor,
          } as EquipmentPerformanceData,
        };
      },
      {}
    ),
  };
};
