import {
  ComparisonData,
  FilterTimePeriod,
  RechartsCustomTooltip,
} from '@energybox/react-ui-library/dist/types';
import { formatNumberString } from '@energybox/react-ui-library/dist/utils';
import ToolTip from '../../../components/Charts/ToolTip';
import styles from './NOHEfficiencyTile.module.css';
import { format, parseISO } from 'date-fns';
import { KWH_UNIT } from '@energybox/react-ui-library/dist/utils';
import * as Routes from '../../../routes';
import NOHEfficiencyLineChart from './NOHEfficiencyLineChart';
import Tile from '../../../components/Tile/Tile';
import TileHeader from '../../../components/Tile/TileHeader';
import TileContent from '../../../components/Tile/TileContent';
import TileFooter from '../../../components/Tile/TileFooter';

const CHART_BAR_FILL_COLOR_ARRAY = [
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
  'var(--turquoise-base)',
];

type Props = {
  className?: string;
  data: {
    by_org: {
      by_month: {
        [date: string]: {
          noh: {
            area: number;
            noh_efficiency: number;
            non_op_hour: object;
            op_hour: object;
          } | null;
          org_hourly_eui_in: number | null;
          org_hourly_eui_out: number | null;
        };
      };
    };
    by_site: {
      [siteId: number]: {
        by_month: {
          [date: string]: {
            area: number;
            noh_efficiency: number;
            non_op_hour: object;
            op_hour: object;
          };
        };
      };
    };
  };
  isLoading: boolean | undefined;
};

const CustomTooltip = (props: RechartsCustomTooltip) => {
  const { payload } = props;
  if (
    !!!payload ||
    payload.length < 1 ||
    payload[0].payload === undefined ||
    payload[0].payload === null
  ) {
    return null;
  } else {
    const {
      value,
      entity: title,
      fillColor,
    } = payload[0].payload as ComparisonData;
    if (!value) {
      return null;
    }
    return (
      <ToolTip
        {...props}
        title={title}
        values={`${formatNumberString(value, { numDecimals: 1 })} %`}
        valuesColor={fillColor}
      />
    );
  }
};

const NOHEfficiencyTile: React.FC<Props> = ({
  className,
  data,
  isLoading = true,
}) => {
  let comparisonData: any[] = [];
  const { by_site, by_org } = data || {};
  const { by_month } = by_org || {};
  for (const [key, value] of Object.entries(by_month || {})) {
    const dataYear = Number(format(parseISO(key), 'yyyy'));
    const month = format(parseISO(key), 'MMM');
    const nohEfficiency = value?.noh?.noh_efficiency || null;
    comparisonData.push({
      entity: month,
      value: nohEfficiency !== null ? nohEfficiency * 100 : null,
      fillColor: 'var(--accent-base)',
    });
  }

  return (
    <Tile className={className} isLoading={isLoading}>
      <TileHeader
        className={styles.titleText}
        title={'NOH Efficiency'}
        tooltipDescription={
          'NOH Efficiency refers to the percentage of running power shut off during non-operating hours. 100% NOH Efficiency would mean all power is shut off during non-operating hours.'
        }
      />

      <TileContent>
        <div className={styles.tileContainer}>
          <NOHEfficiencyLineChart
            data={comparisonData.slice(-12)}
            dataFillColor={CHART_BAR_FILL_COLOR_ARRAY}
            dataUnit={'%'}
            tooltip={CustomTooltip}
          />
        </div>
      </TileContent>

      {/* <TileFooter
        text={'Breakdown'}
        redirectTo={Routes.NOH_VS_OH_ENERGY_CONSUMPTION}
      /> */}
    </Tile>
  );
};

export default NOHEfficiencyTile;
