import { FeaturesByMonth } from '@energybox/react-ui-library/dist/types';
import { Actions } from '../actions/whatsNew';
import { assoc, dissoc, pipe } from 'ramda';
import { FeatureStatus } from '@energybox/react-ui-library/dist/types/Feature';

export type WhatsNew = {
  newFeatures?: FeaturesByMonth;
  featureStatus: FeatureStatus;
  featuresAreLoading: boolean;
  viewStatusIsLoading: boolean;
};

const initialState: WhatsNew = {
  featureStatus: FeatureStatus.NO_UPDATE,
  featuresAreLoading: false,
  viewStatusIsLoading: false,
};

const whatsNew = (state: WhatsNew = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_FEATURE_SUCCESS:
      return pipe(
        action.payload
          ? assoc('newFeatures', action.payload as FeaturesByMonth)
          : dissoc('newFeatures'),
        assoc('featuresAreLoading', false)
      )(state);

    case Actions.GET_FEATURE_LOADING:
      return assoc('featuresAreLoading', true)(state);

    case Actions.GET_FEATURE_ERROR:
      return pipe(
        dissoc('newFeatures'),
        assoc('featuresAreLoading', false)
      )(state);

    case Actions.GET_FEATURE_IS_NEW_SUCCESS:
      const loadedState = assoc('viewStatusIsLoading', false)(state);
      if (
        [FeatureStatus.NO_UPDATE, FeatureStatus.UPDATE_VIEWED].includes(
          state.featureStatus
        ) &&
        action.payload === false
      )
        return assoc('featureStatus', FeatureStatus.HAS_UPDATE)(loadedState);
      return loadedState;

    case Actions.DISMISS_NEW_FEATURE:
      if (state.featureStatus === FeatureStatus.HAS_UPDATE)
        return assoc('featureStatus', FeatureStatus.UPDATE_DISMISSED)(state);
      return state;

    case Actions.VIEW_FEATURE_SUCCESS:
      if (action.payload === true)
        return assoc('featureStatus', FeatureStatus.UPDATE_VIEWED)(state);
      return state;

    default:
      return state;
  }
};

export default whatsNew;
