import { Site } from '@energybox/react-ui-library/dist/types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSites } from '../../actions/sites';
import BaseContentContainer from '../../components/layout/BaseContentContainer';
import LoadingBar from '../../components/LoadingBar';
import MapAreaContainer from '../../components/Map/MapAreaContainer';
import TopNavigationBar from '../../components/TopNavigationBar/TopNavigationBar';
import { ApplicationState } from '../../reducers';
import styles from './MapPage.module.css';
import SiteMobileNav from './sites/SiteMobileNav';

type Props = {
  path: string;
  sites: Site[];
  getSites: () => void;
  isSitesLoading: boolean;
  sitesOnline;
};

const MapPage = ({ isSitesLoading, sites, getSites, sitesOnline }: Props) => {
  const [query, setQuery] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getSites, []);

  return (
    <BaseContentContainer className={styles.content} fillFrame>
      <TopNavigationBar path="map" className={styles.topNavBar} />
      <div className={styles.mobileTopNavBar}>
        <SiteMobileNav />
      </div>
      {isSitesLoading && (
        <div style={{ position: 'relative' }}>
          <LoadingBar isLoading />
        </div>
      )}

      <MapAreaContainer query={query} handleQueryChange={setQuery} />
    </BaseContentContainer>
  );
};

const mapStateToProps = ({
  sites,
  loading,
  siteOnlineStatusById,
}: ApplicationState) => ({
  sites: sites.sites,
  isSitesLoading: loading.sites,
  sitesOnline: siteOnlineStatusById,
});

const mapDispatchToProps = {
  getSites,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapPage);
