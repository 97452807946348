export enum Actions {
  STATUS = '@@equipmentFilter/STATUS',
  SPACE = '@@equipmentFilter/SPACE'
}

export const setEquipmentStatusListFilter = (filterType: string) => ({
  type: Actions.STATUS,
  payload: filterType
});

export const setEquipmentSpaceListFilter = (filterType: string) => ({
  type: Actions.SPACE,
  payload: filterType
});
