import {
  RechartsCustomTooltip,
  Site,
} from '@energybox/react-ui-library/dist/types';
import {
  formatKwhValues,
  shortenString,
  toMultipleLines,
} from '@energybox/react-ui-library/dist/utils';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import RechartsBarWithBaseline, {
  AVERAGE_ID,
  BarChartWithBaselineData,
} from '../../../components/Charts/BarWithBaseline/RechartsBarWithBaseline';
import ToolTip from '../../../components/Charts/ToolTip';
import BlurredInfo from '../../../components/Tile/BlurredInfo';
import Tile from '../../../components/Tile/Tile';
import TileContent from '../../../components/Tile/TileContent';
import TileFooter from '../../../components/Tile/TileFooter';
import TileHeader from '../../../components/Tile/TileHeader';
import { useOrgHasEnergyPro } from '../../../hooks/useEnergyPro';
import { useEnergyUseBySite } from '../../../hooks/useSites';
import { ApplicationState } from '../../../reducers';
import {
  OrganizationEnergyReport,
  OrganizationEnergyReportBySiteId,
} from '../../../reducers/analytics';
import { ORG_SITES_ENERGY_CONSUMPTION as ORG_SITES_ENERGY_CONSUMPTION_ROUTE } from '../../../routes';
import styles from './SitesEnergyTile.module.css';

export interface Props {
  className?: string;
  countryCode?: string;
}

const CARD_TITLE = 'Top Energy Consuming Sites';

const SitesEnergyTile = ({ className, countryCode = 'US' }: Props) => {
  const [isLoadingGuard, setIsLoadingGuard] = useState(true);
  const orgHasEnergyPros = useOrgHasEnergyPro();
  const energyReportDataExists = useSelector<ApplicationState, boolean>(
    ({ analytics }) => analytics.orgEnergyReport.hasData
  );
  const isLoadingEnergyReport = useSelector<ApplicationState, boolean>(
    ({ analytics }) => analytics.orgEnergyReport.isLoading
  );

  const { orgReportBySiteId: energyUseBySite } = useEnergyUseBySite();
  const avgEnergy: number = calculateAverageSiteEnergyUse(energyUseBySite);
  const top5ConsumingSites = getTop5ConsumingSites(energyUseBySite);
  const transformedTopSites = transformDataForChart(top5ConsumingSites);
  const faultyData = !orgHasEnergyPros || !energyReportDataExists;

  useEffect(() => {
    // Prevents loading flash on first render
    setIsLoadingGuard(false);
  }, []);
  const isLoading = isLoadingGuard || isLoadingEnergyReport;

  const renderContent = () => {
    return (
      <div className={styles.chartContainer}>
        <RechartsBarWithBaseline
          countryCode={countryCode}
          data={transformedTopSites}
          baseline={avgEnergy}
          CustomYTick={CustomYTick}
          CustomTooltip={CustomToolTip(countryCode)}
        />
      </div>
    );
  };

  if (!orgHasEnergyPros || !energyReportDataExists) {
    return (
      <Tile className={className} isLoading={isLoading}>
        <BlurredInfo
          title={CARD_TITLE}
          errorMessage={
            !orgHasEnergyPros
              ? 'None of your sites are monitored with Energybox’s energy sensors. This tile is not applicable for you'
              : 'There is no energy data available in the time period selected'
          }
        />
      </Tile>
    );
  }

  return (
    <Tile className={className} isLoading={isLoading}>
      {!faultyData && <TileHeader title={CARD_TITLE} />}
      <TileContent>{renderContent()}</TileContent>
      <TileFooter redirectTo={ORG_SITES_ENERGY_CONSUMPTION_ROUTE} />
    </Tile>
  );
};

export const CustomYTick = (props) => {
  const { x, y, payload, data, averageTitle } = props;
  const typedData = data as BarChartWithBaselineData<Site>[];
  const { value: title }: { value: string } = payload;
  const isAverageTick = title === averageTitle;

  const maxCharactersPerLine = 21;
  const maxNumLines = 2;
  const lines: string[] = toMultipleLines(
    title,
    maxNumLines,
    maxCharactersPerLine
  );

  // Values to center line(s) of text
  const tickYTranslate = lines.length > 1 ? 8 : 2;
  const tickElem = (
    <g transform={`translate(${x},${y - tickYTranslate})`}>
      <text
        className={isAverageTick ? styles.tickText : styles.linkText}
        x={0}
        y={0}
        fontSize={15}
        textAnchor="end"
      >
        {lines.map((line, index) => {
          return (
            <tspan x="0" dy={index === 0 ? '.6em' : '1.2em'} key={index}>
              {index + 1 === maxNumLines
                ? shortenString(line, maxCharactersPerLine)
                : line}
            </tspan>
          );
        })}
        <title>{title}</title>
      </text>
    </g>
  );

  if (typedData !== undefined && typedData.length > 1 && !isAverageTick) {
    const dataItem = typedData.find((item: BarChartWithBaselineData<Site>) =>
      item.dataObject !== undefined ? item.title === title : false
    );
    return dataItem !== undefined && dataItem.dataObject !== undefined ? (
      <Link to={`/sites/${dataItem.dataObject.id}`}>{tickElem}</Link>
    ) : (
      tickElem
    );
  } else {
    return tickElem;
  }
};

const CustomToolTip =
  (countryCode: string) => (props: RechartsCustomTooltip) => {
    const { payload } = props;

    if (
      payload === undefined ||
      payload.length < 1 ||
      payload[0].payload === undefined
    ) {
      return null;
    }

    const title = payload[0].payload.title;
    const isAvgBar = payload[0].payload.id === AVERAGE_ID;
    return (
      <ToolTip
        {...props}
        title={title}
        values={formatKwhValues(payload[0].value, countryCode)}
        valuesColor={isAvgBar ? '#626DF9' : '#2ADDD0'}
      />
    );
  };

const getTop5ConsumingSites = (
  energyUseBySite: OrganizationEnergyReportBySiteId
) => {
  return Object.entries(energyUseBySite)
    .sort(
      ([siteA, { siteKwh: energyUseA }], [siteB, { siteKwh: energyUseB }]) => {
        if (energyUseA === null) {
          return 1;
        } else if (energyUseB === null) {
          return -1;
        }
        return energyUseB - energyUseA;
      }
    )
    .slice(0, 5)
    .map(([siteId, report]) => ({
      ...report,
      siteId,
    }));
};

const calculateAverageSiteEnergyUse = (
  energyUseBySite: OrganizationEnergyReportBySiteId
) => {
  const energyUseArray = Object.entries(energyUseBySite);
  const totalEnergyUse = energyUseArray.reduce(
    (totalEnergy, [siteId, { siteKwh }]) => {
      if (siteKwh === null) return totalEnergy;
      return totalEnergy + siteKwh;
    },
    0
  );
  return totalEnergyUse / energyUseArray.length;
};

const transformDataForChart = (
  reports: OrganizationEnergyReport[]
): BarChartWithBaselineData<Site>[] => {
  return reports.map((report) => ({
    id: report.siteId !== undefined ? report.siteId : '',
    value: report.siteKwh !== undefined ? report.siteKwh : 0,
    title: report.siteTitle !== undefined ? report.siteTitle : '',
    dataObject: report.site,
  })) as BarChartWithBaselineData<Site>[];
};

export default SitesEnergyTile;
