import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SystemOverrideActivityDataStatus } from '@energybox/react-ui-library/dist/types';
import { useTimeFilter } from './useFilters';
import { ApplicationState } from '../reducers';
import { getSystemOverrideActivityByControlId } from '../actions/controls';

const useGetSystemOverrideActivity = (
  controlId: number | undefined,
  featureNotificationId?: string,
  customFromDate?: number,
  customToDate?: number
): SystemOverrideActivityDataStatus | undefined => {
  const dispatch = useDispatch();
  const { timePeriod } = useTimeFilter();
  const { fromDate, toDate } = timePeriod;

  const systemOverrideData = useSelector<
    ApplicationState,
    SystemOverrideActivityDataStatus | undefined
  >(({ controls }) => {
    if (!controlId) return undefined;
    return controls.systemOverrideByControlId[controlId];
  });

  useEffect(() => {
    if (controlId) {
      dispatch(
        getSystemOverrideActivityByControlId(
          controlId,
          customFromDate || fromDate.valueOf(),
          customToDate || toDate.valueOf(),
          featureNotificationId
        )
      );
    }
  }, [
    dispatch,
    featureNotificationId,
    controlId,
    customFromDate,
    customToDate,
    fromDate,
    toDate,
  ]);

  return systemOverrideData;
};

export default useGetSystemOverrideActivity;
