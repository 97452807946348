import {
  MenuDropdown,
  MenuDropdownItem,
} from '@energybox/react-ui-library/dist/components';
import React, { useState } from 'react';
import ConfirmReportDialog from '../../../containers/ShowEquipmentPage/ConfirmReportDialog';
import { ReportType } from '../../../types/reportworker';
import ReportGenerateButton from './ReportGenerateButton';

interface Props {
  disabled?: boolean;
  download: (reportType: ReportType) => void;
}

const ReportGenerateDropDownButton: React.FC<Props> = ({
  disabled = false,
  download,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <MenuDropdown
        disabled={disabled}
        icon={<ReportGenerateButton disabled={disabled} />}
      >
        <MenuDropdownItem
          onSelect={() => {
            setDialogOpen(true);
            download(ReportType.CSV);
          }}
        >
          Generate Raw Data (CSV)
        </MenuDropdownItem>
      </MenuDropdown>

      <ConfirmReportDialog
        isCreateDashboardDialogOpen={dialogOpen}
        onSubmit={() => setDialogOpen(false)}
        onClose={() => setDialogOpen(false)}
      />
    </>
  );
};

export default ReportGenerateDropDownButton;
