import { Actions } from '../actions/equipmentGrouping';

export enum EquipmentGrouping {
  ALPHABETICAL = 'GROUPING_ALPHABETICAL',
  EQUIPMENT_GROUP = 'GROUPING_EQUIPMENT_GROUP',
  EQUIPMENT_TYPE = 'GROUPING_EQUIPMENT_TYPE',
  SPACE = 'GROUPING_SPACE',
  STATUS = 'GROUPING_EQUIPMENT_STATUS',
}

const initialState = EquipmentGrouping.ALPHABETICAL;

export default (state: EquipmentGrouping = initialState, action: any) => {
  switch (action.type) {
    case Actions.SET_GROUPING:
      return action.payload;
    default:
      return state;
  }
};
