import {
  ComparisonData,
  FilterTimePeriod,
  IncidentCountByPriority,
  IncidentCountsBySite,
  IncidentPriority,
  IncidentPriorityLabel,
  NotificationsOverviewTileNames,
  RechartsCustomTooltip,
} from '@energybox/react-ui-library/dist/types';
import { clone } from 'ramda';

import React from 'react';
import RechartsComparisonChart from '../../../components/Charts/RechartsComparisonChart';
import ToolTip from '../../../components/Charts/ToolTip';
import Tile from '../../../components/Tile/Tile';
import TileContent from '../../../components/Tile/TileContent';
import TileHeader from '../../../components/Tile/TileHeader';
import { useGetIncidentCountsBySite } from '../../../hooks/useIncidents';
import useSiteFilter from '../../../hooks/useSiteFilter';
import useSiteGroupsFilter from '../../../hooks/useSiteGroupsFilter';
import HappyHornOverlay from '../../../components/Tile/HappyHornOverlay';

const INCIDENT_COUNT_BY_PRIORITY_DEFAULT_DICT = {
  [IncidentPriority.LOW]: {
    incidentPriority: IncidentPriority.LOW,
    incidentCount: 0,
  },
  [IncidentPriority.NORMAL]: {
    incidentPriority: IncidentPriority.NORMAL,
    incidentCount: 0,
  },
  [IncidentPriority.MEDIUM]: {
    incidentPriority: IncidentPriority.MEDIUM,
    incidentCount: 0,
  },
  [IncidentPriority.HIGH]: {
    incidentPriority: IncidentPriority.HIGH,
    incidentCount: 0,
  },
  [IncidentPriority.HIGHEST]: {
    incidentPriority: IncidentPriority.HIGHEST,
    incidentCount: 0,
  },
  [IncidentPriority.CRITICAL]: {
    incidentPriority: IncidentPriority.CRITICAL,
    incidentCount: 0,
  },
};

const CHART_BAR_FILL_COLOR_ARRAY = [
  'var(--notification-blue)',
  'var(--notification-yellow)',
  'var(--notification-orange)',
  'var(--notification-red)',
];

const TOOLTIP_FILL_COLOR_ARRAY = [
  '#95BBD5',
  'var(--notification-yellow)',
  'var(--notification-orange)',
  'var(--notification-red)',
];

type Props = {
  className?: string;
  timePeriod: FilterTimePeriod;
};

const IncidentsByPriorityTile: React.FC<Props> = ({
  className,
  timePeriod,
}) => {
  const { selectedSiteFilters } = useSiteFilter();
  const { siteGroupWithoutSites } = useSiteGroupsFilter();
  const { isLoading, data: countsBySite } = useGetIncidentCountsBySite({
    from: timePeriod.fromDate.toISOString(),
    to: timePeriod.toDate.toISOString(),
    siteIds: selectedSiteFilters,
    strict: true,
  });
  const countByPriority = accumulatePriorityCounts(countsBySite);
  const comparisonData: ComparisonData[] = Object.values(countByPriority)
    // TODO: drop this filter once the IncidentPriorities have been fixed in the BE
    .filter(
      ({ incidentPriority }) =>
        incidentPriority !== IncidentPriority.CRITICAL &&
        incidentPriority !== IncidentPriority.LOW
    )
    .map(({ incidentPriority, incidentCount }, index) => ({
      entity: IncidentPriorityLabel[incidentPriority],
      value: incidentCount,
      fillColor: TOOLTIP_FILL_COLOR_ARRAY[index],
    }));

  if (siteGroupWithoutSites) {
    return (
      <Tile className={className} isLoading={isLoading}>
        <TileHeader
          title={NotificationsOverviewTileNames.IncidentsByPriority}
        />
        <HappyHornOverlay />
      </Tile>
    );
  }

  return (
    <Tile className={className} isLoading={isLoading}>
      <TileHeader
        title={NotificationsOverviewTileNames.IncidentsByPriority}
        // tooltipDescription={'Active Incidents broken down by priority.'}
        // moreInfo={'moreInfo'}
      />

      <TileContent>
        <RechartsComparisonChart
          data={comparisonData}
          dataFillColor={CHART_BAR_FILL_COLOR_ARRAY}
          dataUnit={''}
          tooltip={CustomTooltip}
        />
      </TileContent>
    </Tile>
  );
};

const CustomTooltip = (props: RechartsCustomTooltip) => {
  const { payload } = props;
  if (
    payload === undefined ||
    payload === null ||
    payload.length < 1 ||
    payload[0].payload === undefined ||
    payload[0].payload === null
  ) {
    return null;
  } else {
    const {
      value: numIncidents,
      entity: priority,
      fillColor,
    } = payload[0].payload as ComparisonData;
    return (
      <ToolTip
        {...props}
        title={priority}
        values={numIncidents}
        valuesColor={fillColor}
      />
    );
  }
};

const accumulatePriorityCounts = (
  countsBySite: IncidentCountsBySite | undefined
): IncidentCountByPriority => {
  return countsBySite === undefined
    ? INCIDENT_COUNT_BY_PRIORITY_DEFAULT_DICT
    : Object.values(countsBySite).reduce<IncidentCountByPriority>(
        (total, { incidentCountByPriority }) => {
          // Need to create a clone as opposed to spread because it is nested
          const newTotal = clone(total);
          Object.values(incidentCountByPriority).forEach(
            ({ incidentPriority, incidentCount }) =>
              (newTotal[incidentPriority].incidentCount =
                newTotal[incidentPriority].incidentCount + incidentCount)
          );
          return newTotal;
        },
        INCIDENT_COUNT_BY_PRIORITY_DEFAULT_DICT
      );
};

export default IncidentsByPriorityTile;
