import React from 'react';
import { UiIcon } from '../../../types/icon';

const IconSize16 = () => (
  <g>
    <path
      d="M14.7 1H8.3C7.6 1 7 1.6 7 2.3v3.8L.3 9c-.3.1-.4.4-.3.7.1.2.3.3.5.3h.2l.3-.2V16h15V2.3c0-.7-.6-1.3-1.3-1.3zM2 15V9.4l5-2.1V15H2zm9 0v-3h1v3h-1zm4 0h-2v-4h-3v4H8V2.3c0-.1.2-.3.3-.3h6.3c.2 0 .4.2.4.3V15z"
      fill="currentColor"
    />
    <path
      d="M9 3h1v2H9zM11 3h1v2h-1zM4 11h1v3H4zM11 6h1v2h-1zM9 6h1v2H9zM13 3h1v2h-1zM13 6h1v2h-1z"
      fill="currentColor"
    />
  </g>
);

const IconSize25 = () => (
  <g>
    <path
      d="M22.9 2c.6 0 1.1.5 1.1 1.1v20.3H11.9V3.1c0-.6.5-1.1 1.1-1.1h9.9m0-1H13c-1.2 0-2.1.9-2.1 2.1v21.3H25V3.1c0-1.2-.9-2.1-2.1-2.1zM1.1 13.5h1v10.9h-1z"
      fill="currentColor"
    />
    <path
      d="M.7 14.5c-.3 0-.5-.1-.6-.3-.1-.3.1-.7.5-.8l11-4.6c.4-.2.8-.1.9.2.1.3-.1.7-.5.8L1 14.4c-.1.1-.2.1-.3.1zM19.3 17.6v5.8h-2.7v-5.8h2.7m1-1h-4.7v7.8h4.7v-7.8z"
      fill="currentColor"
    />
    <path
      d="M14.1 4.1h1.6v3.1h-1.6zM17.2 4.1h1.6v3.1h-1.6zM5.8 16.6h2v4.7h-2zM17.2 8.8h1.6v3.1h-1.6zM14.1 8.8h1.6v3.1h-1.6zM20.3 4.1h1.6v3.1h-1.6zM20.3 8.8h1.6v3.1h-1.6z"
      fill="currentColor"
    />
    <path d="M1.6 23.4H11v1H1.6z" fill="currentColor" />
  </g>
);

const IconSize32 = () => (
  <g>
    <path
      d="M4 22h2v4H4zM26 18h3v1h-3zM26 16h3v1h-3zM26 14h3v1h-3zM26 12h3v1h-3zM26 26h3v1h-3zM26 24h3v1h-3zM26 22h3v1h-3zM26 20h3v1h-3zM11 13h2v3h-2zM15 13h2v3h-2zM19 13h2v3h-2zM11 8h2v3h-2zM15 8h2v3h-2zM19 8h2v3h-2z"
      fill="currentColor"
    />
    <path
      d="M24 9V6c0-1.7-1.3-3-3-3H11C9.3 3 8 4.3 8 6v9.2L.3 19c-.3.1-.4.4-.3.7.1.2.3.3.5.3h.2l.3-.2V29h31V9h-8zM2 28v-8.7l6-3V28H2zm12 0v-7h4v7h-4zm9 0h-4v-8h-6v8H9V6c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2v22zm8 0h-7V10h7v18z"
      fill="currentColor"
    />
  </g>
);

const IconSize40 = () => (
  <g>
    <g opacity=".1">
      <path fill="none" d="M23 29h4v7h-4z" />
      <path
        d="M33 36V14c0-1.7-1.3-3-3-3H20c-1.7 0-3 1.3-3 3v9.2L9.3 27c-.3.1-.4.4-.3.7.1.2.3.3.5.3h.2l.3-.2V37h30v-1h-7zm-18-2h-2v-4h2v4zm12 2h-4v-7h4v7z"
        fill="currentColor"
      />
      <path
        d="M40 36h-2v-4.5c0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5V36h-2V18h7v18z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </g>
    <path
      d="M7 26h2v4H7zM29 22h3v1h-3zM29 20h3v1h-3zM29 30h3v1h-3zM29 28h3v1h-3zM29 26h3v1h-3zM29 24h3v1h-3zM29 18h3v1h-3zM29 16h3v1h-3zM14 17h2v3h-2zM18 17h2v3h-2zM22 17h2v3h-2zM14 12h2v3h-2zM18 12h2v3h-2zM22 12h2v3h-2z"
      fill="currentColor"
    />
    <path
      d="M27 13v-3c0-1.7-1.3-3-3-3H14c-1.7 0-3 1.3-3 3v9.2L3.3 23c-.3.1-.4.4-.3.7.1.2.3.3.5.3h.2l.3-.2V33h31V13h-8zM5 32v-8.7l6-3V32H5zm12 0v-7h4v7h-4zm9 0h-4v-8h-6v8h-4V10c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2v22zm8 0h-7V14h7v18z"
      fill="currentColor"
    />
  </g>
);

const IconSize80 = () => (
  <g>
    <path
      d="M62 39.1v-7c0-3.3-2.7-6-6-6H36c-3.3 0-6 2.7-6 6v15.4l-17.5 9.7c-.5.3-.7.9-.4 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1l1.5-.8v15h65v-34H62zm-36 29h-5v-8h5v8zm24 3h-8v-14h8v14zm25-14v4h-9v-4h9zm-9-2v-4h9v4h-9zm9 8v4h-9v-4h9zm0-14h-9v-4h9v4z"
      opacity=".1"
      fill="currentColor"
    />
    <path
      d="M31 30.1h4v6h-4zM38 30.1h4v6h-4zM45 30.1h4v6h-4zM31 39.1h4v6h-4zM38 39.1h4v6h-4zM45 39.1h4v6h-4z"
      fill="currentColor"
    />
    <path
      d="M56 33.1v-7c0-3.3-2.7-6-6-6H30c-3.3 0-6 2.7-6 6v15.4L6.5 51.3c-.5.3-.7.9-.4 1.4.2.3.5.5.9.5.2 0 .3 0 .5-.1l1.5-.9v15h65v-34H56zm-45 32V51l13-7.2v21.3H11zm33 0h-8v-14h8v14zm10 0h-9v-15H35v15h-9v-39c0-2.2 1.8-4 4-4h20c2.2 0 4 1.8 4 4v39zm18 0H56v-30h16v30z"
      fill="currentColor"
    />
    <path
      d="M15 54.1v8h5v-8h-5zm4 7h-3v-6h3v6zM69 39.1h-9v4h9v-4zm-1 3h-7v-2h7v2zM69 45.1h-9v4h9v-4zm-1 3h-7v-2h7v2zM69 51.1h-9v4h9v-4zm-1 3h-7v-2h7v2zM69 57.1h-9v4h9v-4zm-1 3h-7v-2h7v2z"
      fill="currentColor"
    />
  </g>
);

const getIconBySize = (size: number) => {
  switch (true) {
    case size <= 16:
      return { viewBox: '0 0 16 16', icon: <IconSize16 /> };

    case size <= 25:
      return { viewBox: '0 0 25 25', icon: <IconSize25 /> };

    case size <= 32:
      return { viewBox: '0 0 32 32', icon: <IconSize32 /> };

    case size <= 40:
      return { viewBox: '0 0 40 40', icon: <IconSize40 /> };

    default:
      return { viewBox: '0 0 80 80', icon: <IconSize80 /> };
  }
};

export default ({ classes = {}, size = 40 }: UiIcon) => {
  const { viewBox, icon } = getIconBySize(size);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={classes.icon}
      width={size}
      height={size}
    >
      {icon}
    </svg>
  );
};
