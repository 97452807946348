import {
  FilterTimePeriod,
  Incident,
  Notification,
  NotificationsOverviewTileNames,
  Status,
} from '@energybox/react-ui-library/dist/types';
import isWithinInterval from 'date-fns/isWithinInterval';

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Tile from '../../../components/Tile/Tile';
import TileContent from '../../../components/Tile/TileContent';
import TileHeader from '../../../components/Tile/TileHeader';
import useFilteredNotifications from '../../../hooks/useFilteredNotifications';
import { useGetIncidents } from '../../../hooks/useIncidents';
import * as Routes from '../../../routes';
import styles from './IncidentSummaryTile.module.css';

type Props = {
  className?: string;
  timePeriod: FilterTimePeriod;
};

type ProcessedData = {
  totalIncidents: number;
  newIncidents: number;
  existingIncidents: number;
  resolvedIncidents: number;
  activeIncidents: number;
  totalNotifications: number;
};

const IncidentSummaryTile: React.FC<Props> = ({ className, timePeriod }) => {
  const { isLoading: isIncidentsLoading, data: incidents = [] } =
    useGetIncidents({
      from: timePeriod.fromDate.toISOString(),
      to: timePeriod.toDate.toISOString(),
    });

  const { data: notifications, isLoading: isNotificationsLoading } =
    useFilteredNotifications(
      {
        fromDate: timePeriod.fromDate,
        toDate: timePeriod.toDate,
      },
      true
    );

  const processedData: ProcessedData = useMemo(() => {
    return processData(incidents, notifications, timePeriod);
  }, [incidents, notifications, timePeriod]);

  const isLoading = isIncidentsLoading || isNotificationsLoading;

  return (
    <Tile className={className} isLoading={isLoading}>
      <TileHeader
        title={NotificationsOverviewTileNames.IncidentSummary}
        // tooltipDescription={TOOLTIP_DESCRIPTION}
      />

      <TileContent>
        <div className={styles.row}>
          <span>Total Incidents</span>
          <span className={styles.value}>
            <Link to={Routes.INCIDENTS}>{processedData.totalIncidents}</Link>
          </span>
        </div>

        <div className={styles.row}>
          <span>New Incidents</span>
          <span className={styles.value}>{processedData.newIncidents}</span>
        </div>

        <div className={styles.row}>
          <span>Existing Incidents</span>
          <span className={styles.value}>
            {processedData.existingIncidents}
          </span>
        </div>

        <div className={styles.horizontalDivider} />

        <div className={styles.row}>
          <span>Active Incidents</span>
          <span className={styles.value}>{processedData.activeIncidents}</span>
        </div>

        <div className={styles.row}>
          <span>Resolved Incidents</span>
          <span className={styles.value}>
            {processedData.resolvedIncidents}
          </span>
        </div>

        <div className={styles.horizontalDivider} />

        <div className={styles.row}>
          <span>Number of Notifications</span>
          <span className={styles.value}>
            <Link to={Routes.NOTIFICATIONS}>
              {processedData.totalNotifications}
            </Link>
          </span>
        </div>
      </TileContent>
    </Tile>
  );
};

const processData = (
  incidents: Incident[],
  notifications: Notification[],
  timePeriod: FilterTimePeriod
) => {
  let newIncidents = 0;
  let existingIncidents = 0;
  let resolvedIncidents = 0;
  let activeIncidents = 0;

  const totalNotifications = (() => {
    // return incidents.reduce((acc: number, i: Incident) => {
    //   const notificationCount = i.notifications.filter(n => {
    //     return isWithinInterval(new Date(n.timestamp), {
    //       start: timePeriod.fromDate,
    //       end: timePeriod.toDate
    //     });
    //   }).length;

    //   return acc + notificationCount;
    // }, 0);

    return notifications.reduce((acc: number, notification: Notification) => {
      const notificationCount = notification.handlingLogs.filter((log) => {
        return isWithinInterval(new Date(log.at), {
          start: timePeriod.fromDate,
          end: timePeriod.toDate,
        });
      }).length;

      return acc + notificationCount;
    }, 0);
  })();

  incidents.forEach((incident) => {
    if (incident.status === Status.EXISTING) {
      existingIncidents += 1;
    }

    if (incident.status === Status.NEW) {
      newIncidents += 1;
    }

    if (incident.resolved === true) {
      resolvedIncidents += 1;
    }

    if (incident.resolved === false) {
      activeIncidents += 1;
    }
  });

  return {
    totalIncidents: incidents.length,
    totalNotifications,
    newIncidents,
    existingIncidents,
    resolvedIncidents,
    activeIncidents,
  };
};

export default IncidentSummaryTile;
