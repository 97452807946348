import {
  DefaultChartStyles,
  formatCurrencySymbol,
  genericYAxisTickFormatter,
} from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import {
  CartesianGrid,
  Cell,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Line,
} from 'recharts';
import {
  ComparisonData,
  FilterTimePeriod,
} from '@energybox/react-ui-library/dist/types';
import styles from './NOHEfficiencyLineChart.module.css';

export type Props = {
  data: ComparisonData[];
  dataUnit: string;
  // If all bars should be the same just pass a string, else it should be an
  // array of same length of data with a color for each comparison slice
  dataFillColor: string | string[];
  tooltip: React.ReactNode;
  timePeriod?: FilterTimePeriod;
  xDataKey?: string;
  yDataKey?: string;
  showCurrency?: boolean;
};

const NOHEfficiencyLineChart = ({
  data,
  dataUnit,
  dataFillColor,
  tooltip,
  timePeriod,
  xDataKey = 'entity',
  yDataKey = 'value',
  showCurrency,
}: Props) => {
  return (
    <div className={styles.root}>
      <ResponsiveContainer width={700} height={280}>
        <ComposedChart data={data}>
          <CartesianGrid
            vertical={false}
            stroke={DefaultChartStyles.axisLineColor}
          />
          <Tooltip content={tooltip} />
          <XAxis
            type="category"
            dataKey={xDataKey}
            tick={CustomXTick(data, timePeriod)}
            axisLine={{
              stroke: DefaultChartStyles.axisLineColor,
            }}
            tickLine={false}
            interval={0}
          />
          <YAxis
            type="number"
            domain={[(dataMin) => 0, (dataMax) => 100]}
            tickCount={5}
            tick={{
              fontSize: 16,
              fill: DefaultChartStyles.tick.fill,
            }}
            tickFormatter={genericYAxisTickFormatter}
            unit={'%'}
            axisLine={false}
            tickLine={false}
            width={45}
          />
          <Line
            dataKey={yDataKey}
            unit={dataUnit}
            isAnimationActive={true}
            strokeWidth={2}
            dot={{ fill: 'var(--accent-base)', strokeWidth: 4 }}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  Array.isArray(dataFillColor)
                    ? dataFillColor[index]
                    : dataFillColor
                }
              />
            ))}
          </Line>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

const CustomXTick =
  (data: ComparisonData[], timePeriod?: FilterTimePeriod) => (props) => {
    const { x, y, payload } = props;
    const textSplits = payload.value.split(' - ');
    const textForPreviousPeriods =
      textSplits.length === 1 || textSplits[0] === textSplits[1] ? (
        <tspan textAnchor="middle">{textSplits[0]}</tspan>
      ) : (
        <>
          <tspan x={15} y={9}>
            {textSplits[1]}
          </tspan>
          <tspan x={20} y={8}>
            {textSplits[0]} -
          </tspan>
        </>
      );
    let textForSavings = <tspan x={15} y={9}></tspan>;
    let shouldHideTick = false;
    if (timePeriod !== undefined) {
      const { shouldHideTick: temp } = data[payload.index];
      if (temp !== undefined) {
        shouldHideTick = temp;
      }
      textForSavings = (
        <tspan x={15} y={9}>
          {payload.value}
        </tspan>
      );
    }

    const tickElem = (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={10}
          textAnchor="end"
          style={{
            fontSize: 16,
            fill: DefaultChartStyles.tick.fill,
          }}
        >
          {timePeriod === undefined ? textForPreviousPeriods : textForSavings}
        </text>
      </g>
    );

    if (timePeriod === undefined) {
      return tickElem;
    }
    return shouldHideTick ? undefined : tickElem;
  };

export default NOHEfficiencyLineChart;
