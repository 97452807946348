import { Actions } from '../actions/sites';
import {
  SiteOnlineStats,
  SiteOnlineStatus,
} from '@energybox/react-ui-library/dist/types';

export type SiteOnlineStatusById = {
  [key: number]: SiteOnlineStatus;
  stats?: SiteOnlineStats;
};

const initialState = {};

const processStatuses = (status: SiteOnlineStatus): SiteOnlineStatus => {
  return {
    ...status,
    latitude: status.latitude || 0,
    longitude: status.longitude || 0,
  } as SiteOnlineStatus;
};

export default function siteOnlineStatusById(
  state: SiteOnlineStatusById = initialState,
  action: any
) {
  switch (action.type) {
    case Actions.GET_SITE_ONLINE_STATUS_SUCCESS: {
      return {
        ...state,
        [action.id]: processStatuses(action.payload),
      };
    }
    case Actions.GET_SITES_ONLINE_STATS_SUCCESS: {
      return {
        ...state,
        stats: {
          ...action.payload,
          siteOnlineStatuses:
            action.payload.siteOnlineStatuses.map(processStatuses),
        },
      };
    }
  }
  return state;
}
