import { Button } from '@energybox/react-ui-library/dist/components';
import { Marker as MarkerIcon } from '@energybox/react-ui-library/dist/icons';
import { Site, SiteOnlineStatus } from '@energybox/react-ui-library/dist/types';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import L, { MarkerCluster, MarkerOptions } from 'leaflet';

import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { BsArrowsAngleExpand } from 'react-icons/bs';
import { Map, Marker, Popup, TileLayer, ZoomControl } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { Link } from 'react-router-dom';
import ActiveIncidentIcon from '../../../components/ActiveIncidentIcon';
import SiteClusterMarker from '../../../components/icons/map/SiteClusterMarker';
import SiteImage, { siteImageSize } from '../../../components/Map/SiteImage';
// import SiteStatusDot from '../../../components/Map/SiteStatusDot';
import ShortenedSpan from '../../../components/ShortenedSpan';
import history from '../../../history';
import * as Routes from '../../../routes';
import { getBounds } from '../../../utils/map';
import styles from './SitesMapTile.module.css';

const mapSettings = {
  dragging: true,
  doubleClickZoom: false,
  keyboard: false,
  zoomControl: false, // If you want to customize position easily, leave this false and use <ZoomControl /> component
  scrollWheelZoom: false,
  maxZoom: 10,
  minZoom: 2,
  attributionControl: false,
};

const renderMarker = (numberActiveIncidents: number | undefined) =>
  L.divIcon({
    className: 'custom-icon',
    iconSize: [37, 37],
    html: ReactDOMServer.renderToString(
      <div className={numberActiveIncidentsToColor(numberActiveIncidents)}>
        <MarkerIcon size={37} />
      </div>
    ),
  });

const statusToSingleMarkerStyle = {
  OFFLINE: styles.red,
  ONLINE: styles.green,
  PARTIALLY_OFF: styles.orange,
};

const numberActiveIncidentsToColor = (
  numberActiveIncidents: number | undefined
) => {
  if (numberActiveIncidents === undefined || numberActiveIncidents === 0) {
    return styles.noIncidentColor;
  } else {
    return styles.activeIncidentColor;
  }
};

const gotoMapPage = () => {
  history.push(`${Routes.MAP}`);
};

interface MarkerOption extends MarkerOptions {
  options: { status: string; numberActiveIncidents: undefined | number };
}

export const createClusterCustomIcon = (cluster: MarkerCluster) => {
  let setColor = 'ONLINE';
  let numWithActiveIncidents = 0;
  cluster.getAllChildMarkers().forEach(({ options }) => {
    const {
      options: { status, numberActiveIncidents },
    } = options as MarkerOption;
    if (numberActiveIncidents !== undefined && numberActiveIncidents > 0) {
      numWithActiveIncidents += 1;
    }
    if (setColor === 'OFFLINE') return;
    if (status === 'OFFLINE' || status === 'PARTIALLY_OFF') {
      setColor = status;
    }
  });
  return L.divIcon({
    html: ReactDOMServer.renderToString(
      <SiteClusterMarker
        size={32}
        numWithActiveIncidents={numWithActiveIncidents}
        numTotalSites={cluster.getChildCount()}
      />
    ),
    className: '',
    iconSize: L.point(34, 34, true),
  });
};

type Props = {
  siteStatuses: SiteOnlineStatus[];
};

const SiteMapArea = ({ siteStatuses }: Props) => {
  const bounds = getBounds(siteStatuses);
  const markerRefs: any = {};
  return (
    <div className={styles.overrideContainer}>
      <div className={styles.expandIcon} onClick={gotoMapPage}>
        <BsArrowsAngleExpand size={22} />
      </div>
      <Map bounds={bounds} style={{ height: '18.75rem' }} {...mapSettings}>
        <TileLayer
          url={`https://api.mapbox.com/styles/v1/eb-mb/cjsv8jqst6s821fruiv0865kv/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZWItbWIiLCJhIjoiY2pzdjRucmNsMDM3YjQ0cHVraWtwN3BjaiJ9.EnEZGKjFPe1pSx8hhzL1Lw`}
          attribution='&copy; <a href="http://mapbox.com">Mapbox</a> | &copy; <a href="http://osm.org/copyright">OpenStreetMap</a> | <a href="https://apps.mapbox.com/feedback/#/-74.5/40/10">Improve this map</a>'
        />
        <ZoomControl position="bottomright" />
        <MarkerClusterGroup
          maxClusterRadius={35}
          zoomToBoundsOnClick={true}
          showCoverageOnHover={false}
          iconCreateFunction={createClusterCustomIcon}
        >
          {siteStatuses.map(createMarkerWithPopup(false, markerRefs))}
        </MarkerClusterGroup>
      </Map>
    </div>
  );
};

export const createMarkerWithPopup =
  (isBigMap: boolean, markerRefs) => (siteStatus: SiteOnlineStatus) => {
    const {
      siteId,
      status,
      latitude,
      longitude,
      title,
      address,
      numberActiveIncidents,
      images,
    } = siteStatus;
    return (
      <Marker
        key={`${siteId}`}
        status={status}
        position={[latitude, longitude]}
        options={{ status, numberActiveIncidents }}
        title={title}
        icon={renderMarker(numberActiveIncidents)}
      >
        <Popup
          closeButton={false}
          ref={(ref) => (markerRefs[siteId] = ref)}
          closeOnEscapeKey
        >
          <div className={styles.column}>
            <div
              className={classNames(styles.row, styles.popupContentContainer)}
            >
              {isBigMap && (
                <div className={styles.popupSiteImage}>
                  <SiteImage
                    site={{ images } as Site}
                    size={siteImageSize.TINY}
                  />
                </div>
              )}
              <div className={styles.column}>
                <div
                  className={classNames(
                    styles.row,
                    styles.popupSiteOnlineStatusSection,
                    statusToSingleMarkerStyle[status]
                  )}
                >
                  {/* <SiteStatusDot siteStatus={status} /> */}
                </div>
                <div
                  className={classNames(styles.row, styles.popupTitleSection)}
                >
                  <ShortenedSpan content={title} maxStringLength={34} />
                </div>
                <div
                  className={classNames(styles.row, styles.popupAddressSection)}
                >
                  {address}
                </div>
                <div
                  className={classNames(
                    styles.row,
                    styles.popupIncidentCountSection
                  )}
                >
                  <Link to={`/sites/${siteId}/incidents`}>
                    <ActiveIncidentIcon
                      numberActiveIncidents={
                        numberActiveIncidents === undefined
                          ? 0
                          : numberActiveIncidents
                      }
                      showTooltip
                    />
                  </Link>
                </div>
              </div>
            </div>
            <Link to={`/sites/${siteId}/insights`}>
              <Button className={styles.siteInsightsButton}>
                Site Insights
              </Button>
            </Link>
          </div>
        </Popup>
      </Marker>
    );
  };

export default SiteMapArea;
