import React, { useEffect } from 'react';
import startOfDay from 'date-fns/startOfDay';

const useRefreshTimeRange = (
  setTimeRange: React.Dispatch<
    React.SetStateAction<{
      from: string;
      to: string;
    }>
  >,
  //default refresh every 15 min
  refreshInterval = 900000
) => {
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setTimeRange({
        from: startOfDay(now).toISOString(),
        to: now.toISOString()
      });
    }, refreshInterval);

    return () => clearInterval(interval);
  }, [refreshInterval, setTimeRange]);
};

export default useRefreshTimeRange;
