import {
  Incident,
  IncidentCountByEquipmentData,
  IncidentCountBySiteData,
  IncidentCountsByEquipment,
  IncidentCountsBySite,
  IncidentNotification,
  IncidentPriority,
  NotificationCountsByPriority,
} from '@energybox/react-ui-library/dist/types';
import {
  AlertIdentifier,
  SubscribedIncidentsByResourceId,
  SubscribedIncidentsBySentinelId,
} from '@energybox/react-ui-library/dist/types/Incident';
import { SubscriptionStatus } from '@energybox/react-ui-library/dist/types/StreamApi';
import {
  deriveDateValue,
  mapArrayToObject,
  mapValues,
} from '@energybox/react-ui-library/dist/utils';
import {
  normalizeSubscribedIncident,
  processNormalizedIncidents,
  updateAlertIdentifiers,
} from '@energybox/react-ui-library/dist/utils/incident';
import {
  assoc,
  assocPath,
  lensIndex,
  lensPath,
  pathOr,
  pipe,
  set,
  view,
} from 'ramda/src';
import { Actions } from '../actions/incidents';
import { Actions as NotificationActions } from '../actions/notifications';
import { Actions as StreamActions } from '../actions/streamApi';
import { changeIncidentByPriority } from '../util';

export type Incidents = {
  subscribedIncidentsByResourceId: SubscribedIncidentsByResourceId;
  countsBySite: IncidentCountsBySiteDataStatus;
  countsByEquipment: IncidentCountsByEquipmentDataStatus;
  bySiteId: IncidentsBySiteId;
  incidents: IncidentsDataStatus;
  incidentReview: IncidentsDataStatus;
  redirectToIncidentPageEntries: any;
  isActiveIncident: boolean;
  // TODO: Remove this when we have a better way to handle this
  // Negative if there are previous actions loading incidents
  isLastLoading: number;
};

export type IncidentsBySiteId = {
  [siteId: string]: IncidentsBySiteIdDataStatus;
};

export type IncidentsBySiteIdDataStatus = {
  isLoading: boolean;
  data?: Incident[];
};

export type IncidentCountsBySiteDataStatus = {
  isLoading: boolean;
  data?: IncidentCountsBySite;
};

export type IncidentCountsByEquipmentDataStatus = {
  isLoading: boolean;
  data?: IncidentCountsByEquipment;
};

export type IncidentsDataStatus = {
  isLoading: boolean;
  data?: Incident[];
};

export function sortByDateDescending(
  a: Incident | Notification,
  b: Incident | Notification,
  timeProperty = 'createdAt'
): number {
  return deriveDateValue(b[timeProperty]) - deriveDateValue(a[timeProperty]);
}

const priorityMap = {
  [IncidentPriority.LOW]: 1,
  [IncidentPriority.MEDIUM]: 2,
  [IncidentPriority.HIGH]: 3,
  [IncidentPriority.CRITICAL]: 4,
};

function sortByPriority(a: Incident, b: Incident): number {
  const aPrio = priorityMap[a.incidentPriority] || 0;
  const bPrio = priorityMap[b.incidentPriority] || 0;

  return bPrio - aPrio;
}

function sortByPriorityDateDescending(a: Incident, b: Incident): number {
  return sortByPriority(a, b) === 0
    ? sortByDateDescending(a, b)
    : sortByPriority(a, b);
}

const calculateNotificationsCountsByPriority = (
  notifications: IncidentNotification[]
) => {
  const notificationCountsByPriority: NotificationCountsByPriority = {};

  notifications.forEach((n) => {
    const priorityLevel = n.priorityLevel;
    const currentPriorityCount = notificationCountsByPriority[priorityLevel];
    if (!currentPriorityCount) {
      notificationCountsByPriority[priorityLevel] = 1;
    } else {
      notificationCountsByPriority[priorityLevel]! += 1;
    }
  });

  return notificationCountsByPriority;
};

const normalizeIncidentCountBySite = (
  payload: any
): IncidentCountBySiteData => ({
  siteId: payload.siteId,
  incidentCountTotal: payload.incidentCount,
  incidentCountByPriority: mapArrayToObject(
    payload.incidentCountByPrio,
    'incidentPriority'
  ),
});

const normalizeIncidentCountByEquipment = (
  payload: any
): IncidentCountByEquipmentData => ({
  equipmentId: payload.equipmentId,
  equipmentTitle: payload.equipmentTitle,
  incidentDurations: payload.incidentDurations,
  siteId: payload.siteId,
  totalIncidentCount: payload.totalIncidentCount,
  totalIncidentDuration: payload.totalIncidentDuration,
});

const normalizeIncident = (payload: any) => ({
  createdAt: payload.createdAt,
  incidentId: payload.incidentId,
  incidentPriority: payload.incidentPriority,
  notificationCount: payload.notificationCount,
  notifications: payload.notifications.sort(
    (a: Notification, b: Notification) =>
      sortByDateDescending(a, b, 'timestamp')
  ),
  notificationCountsByPriority: calculateNotificationsCountsByPriority(
    payload.notifications
  ),

  sentinelId: payload.sentinelId,
  siteId: payload.siteIds[0],
  resolved: payload.resolved,
  recoveredAt: payload.recoveredAt,
  sensorParams: pathOr(null, [0, 'sensorParams'], payload.notifications),
  sensorId: pathOr(
    null,
    [0, 'sensorParams', 0, 'sensorId'],
    payload.notifications
  ),
  sentinelTitle: payload.sentinelTitle,
  sentinelType: payload.sentinelType,
  sentinelDescription: payload.sentinelDescription,
  equipmentId: payload.equipmentId,
  status: payload.status,

  //refers to the time the incident's most recent notification
  //was dismissed at. BE returns undefined if there is none
  dismissedAt: payload.dismissedAt,
});

const initialState = {
  subscribedIncidentsByResourceId: {},
  countsBySite: { isLoading: false },
  countsByEquipment: { isLoading: false },
  bySiteId: {},
  incidents: { isLoading: false },
  incidentReview: { isLoading: false },
  redirectToIncidentPageEntries: {},
  isActiveIncident: false,
  isLastLoading: 0,
};

const incidents = (state: Incidents = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_INCIDENT_COUNTS_BY_SITE_SUCCESS: {
      return pipe(
        assoc('countsBySite', {
          isLoading: false,
          data: mapArrayToObject(
            mapValues(action.payload, normalizeIncidentCountBySite),
            'siteId'
          ),
        })
      )(state);
    }

    case Actions.GET_INCIDENT_COUNTS_BY_EQUIPMENT_SUCCESS: {
      return pipe(
        assoc('countsByEquipment', {
          isLoading: false,
          data: mapArrayToObject(
            mapValues(action.payload, normalizeIncidentCountByEquipment),
            'equipmentId'
          ),
        })
      )(state);
    }

    case Actions.GET_INCIDENT_COUNTS_BY_EQUIPMENT_LOADING: {
      return pipe(assocPath(['countsByEquipment', 'isLoading'], true))(state);
    }

    case Actions.GET_INCIDENT_COUNTS_BY_EQUIPMENT_ERROR: {
      return pipe(assocPath(['countsByEquipment', 'isLoading'], false))(state);
    }

    case Actions.GET_INCIDENT_COUNTS_BY_SITE_LOADING: {
      return pipe(assocPath(['countsBySite', 'isLoading'], true))(state);
    }

    case Actions.GET_INCIDENT_COUNTS_BY_SITE_ERROR: {
      return pipe(assocPath(['countsBySite', 'isLoading'], false))(state);
    }

    case Actions.GET_INCIDENTS_BY_SITE_ID_SUCCESS: {
      changeIncidentByPriority(action.payload);
      return pipe(
        assocPath(['bySiteId', action.siteId], {
          isLoading: false,
          data: action.payload
            .map(normalizeIncident)
            .sort(sortByPriorityDateDescending),
        })
      )(state);
    }

    case Actions.GET_INCIDENTS_BY_SITE_ID_LOADING: {
      return pipe(assocPath(['bySiteId', action.siteId, 'isLoading'], true))(
        state
      );
    }

    case Actions.GET_INCIDENTS_BY_SITE_ID_ERROR: {
      return pipe(assocPath(['bySiteId', action.siteId, 'isLoading'], false))(
        state
      );
    }

    case Actions.GET_INCIDENTS_BY_INCIDENT_ID_SUCCESS: {
      changeIncidentByPriority(action.payload);
      return pipe(
        assoc('incidentReview', {
          isLoading: false,
          data: action.payload
            .map(normalizeIncident)
            .sort(sortByPriorityDateDescending),
        })
      )(state);
    }

    case Actions.GET_INCIDENTS_BY_INCIDENT_ID_LOADING: {
      return pipe(assocPath(['incidentReview', 'isLoading'], true))(state);
    }

    case Actions.GET_INCIDENTS_BY_INCIDENT_ID_ERROR: {
      return pipe(assocPath(['incidentReview', 'isLoading'], false))(state);
    }

    case Actions.GET_INCIDENTS_SUCCESS: {
      changeIncidentByPriority(action.payload);
      return pipe(
        assoc('isLastLoading', state.isLastLoading + 1),
        assoc('incidents', {
          isLoading: false,
          data: action.payload
            .map(normalizeIncident)
            .sort(sortByPriorityDateDescending),
        })
      )(state);
    }

    case Actions.GET_INCIDENTS_LOADING: {
      return pipe(
        assoc('isLastLoading', state.isLastLoading - 1),
        assocPath(['incidents', 'isLoading'], true)
      )(state);
    }

    case Actions.GET_INCIDENTS_ERROR: {
      return pipe(
        assocPath('isLastLoading', state.isLastLoading + 1),
        assocPath(['incidents', 'isLoading'], false)
      )(state);
    }

    case NotificationActions.DISMISS_NOTIFICATION_SUCCESS: {
      const isSiteLevel = !!action.siteId;
      const updatedIncidentId = action.payload.id;
      const updatedDismissedAt = action.payload.dismissedAt;
      const siteLevelPathArray = ['bySiteId', action.siteId, 'data'];
      const orgLevelPathArray = ['incidents', 'data'];
      const reviewPathArray = ['incidentReview', 'data'];
      const pathArray = isSiteLevel ? siteLevelPathArray : orgLevelPathArray;

      const oldIncidentsList: Incident[] = view(lensPath(pathArray), state);
      const indexToUpdate = oldIncidentsList.findIndex(
        (i: Incident) => i.incidentId === updatedIncidentId
      );

      if (indexToUpdate !== -1) {
        const updatedIncident = {
          ...oldIncidentsList[indexToUpdate],
          dismissedAt: updatedDismissedAt,
        };
        const updatedIncidentsList = set(
          lensIndex(indexToUpdate),
          updatedIncident,
          oldIncidentsList
        );

        return pipe(
          assocPath(pathArray, updatedIncidentsList),
          assocPath(reviewPathArray, [updatedIncident])
        )(state);
      }

      return state;
    }

    case StreamActions.RECEIVED_SUBSCRIBED_INCIDENT: {
      const existingIncidentsByResourceId = {
        ...state.subscribedIncidentsByResourceId,
      };
      const normalizedIncident = normalizeSubscribedIncident(action.payload);
      const { possibleAlerts, incidentsByResourceId } =
        processNormalizedIncidents(
          [normalizedIncident],
          existingIncidentsByResourceId
        );

      return pipe(
        assoc('status', SubscriptionStatus.SUCCESS),
        assocPath(['subscribedIncidentsByResourceId'], incidentsByResourceId)
      )(state);
    }

    case Actions.GET_REDIRECT_TO_INCIDENT_ENTRY: {
      return {
        ...state,
        redirectToIncidentPageEntries: action.payload,
      };
    }
    case Actions.GET_ACTIVE_INCIDENT_TILE_STATUS: {
      return {
        ...state,
        isActiveIncident: action.payload,
      };
    }

    default:
      return state;
  }
};

export default incidents;
