import {
  ResourceType,
  SentinelType,
  SentinelTypeToSensorType,
} from '@energybox/react-ui-library/dist/types';
import { global } from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';

import React from 'react';
import DeviceOnlineState from '../../containers/DeviceOnlineState';
import StreamingValueChip from '../../containers/StreamingValueChip';
import { useSensorById } from '../../hooks/useSensors';

type Props = {
  sensorId: number;
  sentinelType: SentinelType;
};

const IncidentValueChip: React.FC<Props> = ({ sensorId, sentinelType }) => {
  const sensor = useSensorById(sensorId);
  const vendor = pathOr(undefined, ['vendor'], sensor);
  const uuid = pathOr(undefined, ['uuid'], sensor);

  const renderValue = () => {
    switch (sentinelType) {
      case SentinelType.TEMPERATURE:
      case SentinelType.HUMIDITY:
      case SentinelType.BINARY: {
        return (
          <StreamingValueChip
            resourceType={ResourceType.SENSOR}
            resourceId={String(sensorId)}
            sensorType={SentinelTypeToSensorType[sentinelType]}
          />
        );
      }

      case SentinelType.CONNECTIVITY:
      case SentinelType.GATEWAY_ONLINE_STATUS:
      case SentinelType.ENERGYPRO_ONLINE_STATUS: {
        return <DeviceOnlineState id={sensorId} vendor={vendor} uuid={uuid} />;
      }

      default:
        return global.NOT_AVAILABLE;
    }
  };

  return <>{renderValue()}</>;
};

export default IncidentValueChip;
