import { GenericFilter } from '@energybox/react-ui-library/dist/components';
import { EquipmentType } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useEquipmentTypesById } from '../../../hooks/useEquipmentTypes';
import useNotificationsDynamicFilter from '../../../hooks/useNotificationsDynamicFilter';

type Props = {
  setFilters?: (selectedIds: number[]) => void;
  equipmentTypes: Partial<EquipmentType>[];
  className?: string;
  disabled?: boolean;
  alignItemsRight?: boolean;
};

const EquipmentTypeFilter = ({
  setFilters,
  equipmentTypes,
  className,
  disabled,
  alignItemsRight,
}: Props) => {
  const equipmentTypesById = useEquipmentTypesById();

  const {
    setNotificationsFilter: setEquipmentTypeFilter,
    filtersFromUrl: equipmentFilters,
  } = useNotificationsDynamicFilter('eqType', setFilters, (value) =>
    parseInt(value)
  );

  return (
    <GenericFilter
      className={className}
      title="Equipment Type"
      setFilter={setEquipmentTypeFilter}
      items={equipmentTypes.map(({ id }) => id || NaN)}
      selectedItems={equipmentFilters}
      transformItemName={(equipmentTypeId) => {
        const type = equipmentTypesById[equipmentTypeId];
        return isDefined(type) ? type.title : '';
      }}
      disabled={disabled}
      alignItemsRight={alignItemsRight}
    />
  );
};

export default EquipmentTypeFilter;
