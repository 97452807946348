import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import styles from './PageContentWrapper.module.css';

interface Props {
  children: React.ReactNode;
  className?: string;
  hasPadding?: boolean;
}

const PageContentWrapper: React.FC<Props> = ({
  children,
  className = '',
  hasPadding = false,
}) => {
  return (
    <div
      className={classNames(
        styles.container,
        className,
        hasPadding ? styles.containerPadding : null
      )}
    >
      {children}
    </div>
  );
};

export default PageContentWrapper;
