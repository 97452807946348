import { hasKeys } from '../util';
import pathOr from 'ramda/src/pathOr';

import { Actions as AnalyticActions } from '../actions/analytics';
import { Actions as AppActions } from '../actions/constants/Actions';
import { Actions as ControlsActions } from '../actions/controls';
import { Actions as EnergyActions } from '../actions/energy';
import { Actions as EquipmentActions } from '../actions/equipment';
import { Actions as EquipmentTagActions } from '../actions/equipmentTags';
import { Actions as GatewayActions } from '../actions/gateways';
import { Actions as NotificationActions } from '../actions/notifications';
import { Actions as OrganizationActions } from '../actions/organizations';
import { Actions as ResourceActions } from '../actions/resources';
import { Actions as SampleActions } from '../actions/samples';
import { Actions as SensorActions } from '../actions/sensors';
import { Actions as SentinelActions } from '../actions/sentinels';
import { Actions as SiteActions } from '../actions/sites';
import { Actions as SopActions } from '../actions/sops';
import { Actions as SpaceActions } from '../actions/spaces';
import { Actions as StreamAPIActions } from '../actions/streamApi';
import { Actions as WeatherActions } from '../actions/weather';
import { Actions as CommentsActions } from '../actions/comments';
import {
  ApiError as ImportedApiError,
  ErrorPayload,
} from '@energybox/react-ui-library/dist/types';

export type ApiError = ImportedApiError; // preserve original imports

type Action = {
  type: string;
  payload: ErrorPayload;
};

type AllActions =
  | AnalyticActions
  | AppActions
  | ControlsActions
  | EnergyActions
  | EquipmentActions
  | EquipmentTagActions
  | GatewayActions
  | NotificationActions
  | OrganizationActions
  | ResourceActions
  | SampleActions
  | SensorActions
  | SentinelActions
  | SiteActions
  | SpaceActions
  | StreamAPIActions
  | SopActions
  | WeatherActions
  | CommentsActions;

export const storeAPIerror = (action: Action): ApiError => ({
  type: action.type,
  status: pathOr('API ERROR', ['payload', 'status'], action),
  message: apiErrorMessage(action),
  payload: action.payload,
});

const apiErrorMessage = (action: Action) => {
  if (!action || !action.payload) {
    return 'There was an error. Please try again later.';
  }

  //TODO: will likely need to refactor below: backend is currently sending two forms of error messages: DATA.ERRORS (which is an array of object or sometimes returns null) and DATA.MESSAGE (string).
  //For DATA.ERRORS, even when there are multiple api errors (i.e. creating a user with an email that's already taken AND a password less than 6 characters) only one error is sent back...
  return Array.isArray(action.payload.errors) &&
    action.payload.errors.length > 0
    ? action.payload.errors[0].message
    : action.payload.message;
};

export const renderAPIerror = (
  apiError: ApiError,
  ...errorTypes: AllActions[]
) => {
  if (hasKeys(apiError) && errorTypes.includes(apiError['type'])) {
    return (
      <div
        style={{
          color: 'var(--pink-base)',
          textAlign: 'center',
          fontSize: '0.75rem',
        }}
      >
        {apiError['message']
          ? apiError['message']
          : 'There was an error. Please try again later.'}
      </div>
    );
  }
};
