import React from 'react';
import { useSelector } from 'react-redux';
import FeatureFlag from '../../containers/auth/FeatureFlag';
import { COMMENTS_FEATURE_BLACKLIST } from '../../utils/featureFlagSettings';
import { canAccessFeatures } from '../../utils/featureFlag';
import { ApplicationState } from '../../reducers';
import { App } from '../../reducers/app';

type Props = {
  children: React.ReactElement;
};

const CommentsFeatureFlag: React.FC<Props> = ({ children }) => {
  return (
    <FeatureFlag orgBlacklist={COMMENTS_FEATURE_BLACKLIST}>
      {children}
    </FeatureFlag>
  );
};

export const useCanAccessCommentsFeature = () => {
  const app = useSelector<ApplicationState, App>(({ app }) => {
    return app;
  });

  return canAccessFeatures(app, {
    orgBlacklist: COMMENTS_FEATURE_BLACKLIST,
  });
};

export const canAccessCommentsFeature = (app: App) => {
  return canAccessFeatures(app, {
    orgBlacklist: COMMENTS_FEATURE_BLACKLIST,
  });
};

export default CommentsFeatureFlag;
