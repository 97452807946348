import {
  DateFilter,
  GenericMultipleFilter,
  MediaElement,
  MediumLongSkeletonCell,
  ShortMediumSkeletonCell,
  ShortSkeletonCell,
  SkeletonThumbnail,
  SearchBox,
  Loader,
} from '@energybox/react-ui-library/dist/components';
import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import {
  CurrentUser,
  EquipmentPerformance as EquipmentPerformanceType,
  EquipmentPerformanceById,
  EquipmentPerformanceData,
  EquipmentTypesById,
  FilterTimePeriod,
  OpacityIndex,
  SortDirection,
  TemperatureUnit,
} from '@energybox/react-ui-library/dist/types';
import {
  celsiusStdDevToFahrenheit,
  classNames,
  formatNumberString,
  genericTableSort,
  global,
  KWH_UNIT,
  shortenString,
  SORT_IGNORED_VALUES,
  createTimeFilterMapping,
  getDateFiltersWithoutTodayOrYesterday,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import {
  createTemperatureString,
  formatValueAsTemp,
  getUserPreferenceTemperatureUnit,
} from '@energybox/react-ui-library/dist/utils/temperature';
import { path } from 'ramda';

import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { clearEquipmentPerformance } from '../../../actions/equipment';
import {
  SetNotificationDateFilter,
  setNotificationDateFilter as setNotificationDateFilterAction,
} from '../../../actions/notificationFilter';
import BaseFilterBar from '../../../components/Filters/BaseFilterBar';
import EquipmentMenuFilter from '../../../components/Filters/FilterMenus/EquipmentMenuFilter/EquipmentMenuFilter';
import SiteFilter from '../../../components/Filters/SiteFilter';
import VendorFilter from '../../../components/Filters/VendorFilter/VendorFilter';
import useAppLocale from '../../../hooks/useAppLocale';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { useEquipmentPerformanceAsync } from '../../../hooks/useEquipment';
import { useEquipmentGroupsById } from '../../../hooks/useEquipmentGroups';
import {
  useEquipmentTypes,
  useEquipmentTypesById,
} from '../../../hooks/useEquipmentTypes';
import { useSearchFilter, useTimeFilter } from '../../../hooks/useFilters';
import usePrevious from '../../../hooks/usePrevious';
import useSiteFilter from '../../../hooks/useSiteFilter';
import { ORG_EQUIPMENT as ROUTE_ORG_EQUIPMENT } from '../../../routes';
import styles from './EquipmentPerformance.module.css';
import {
  PageContentHeader,
  PageContentWrapper,
} from '../../../components/Page';
import theme from '../../../styles/theme';
import FiltersContainer from '../../../components/Filters/FiltersContainer/FiltersContainer';
import { TableWrapper } from '../../../components/Tables';
import { displayCount } from '../../../components/Tables/TableWrapper/TableWrapper';
import ReportGenerateDropDownButton from '../../../components/Button/ReportGenerateButton/ReportGenerateDropDownButton';
import { useToReport } from '../../../hooks/reportworker/useToReport';
import { ReportTemplate, ReportType } from '../../../types/reportworker';
import SiteGroupFilter from '../../../components/Filters/SiteGroupFilter/SiteGroupFilter';
import { ApplicationState } from '../../../reducers';
import {
  filterBySiteGroups,
  sortSiteGroupTitles,
} from '../../../utils/siteGroups/siteGroups';
import useSiteGroupsFilter from '../../../hooks/useSiteGroupsFilter';
import NothingToReportOverlay from '../../../components/NothingToReportOverlay';
import useDynamicFilter from '../../../hooks/useDynamicFilter';
import usePaginationFilter from '../../../hooks/usePaginationFilter';
import { formatNumber } from '@energybox/react-ui-library/dist/utils/number';

type EquipmentPerformanceTableData = {
  equipmentId: string;
  averageTemperature?: number;
  temperatureDeviation?: number;
  compliancePercentage?: number;
  totalDurationOpenMins?: number;
  totalNumberAccess?: number;
  vendorDataForCompare?: string;
} & EquipmentPerformanceData;

const DEFAULT_VENDOR_NAME = global.NOT_AVAILABLE;

enum ColumnHeaders {
  EQUIPMENT_NAME = 'Equipment',
  EQUIPMENT_TYPE = 'Type',
  SITE = 'Site',
  VENDOR_MODEL = 'Vendor/ Model',
  TOTAL_ENERGY_CONSUMPTION = 'Total Energy Consumption',
  AVERAGE_TEMPERATURE = 'Average Temperature',
  STANDARD_DEVIATION = 'Standard Deviation',
  COMPLIANCE_RATE = 'Compliance Rate',
  DEFROST_CYCLES = 'No. of Defrost Cycles',
  KWH_DEGREE = 'kWh/Degree',
  OPEN_DURATION = 'Total Open Time',
  NUM_OPENING = 'Number of Openings',
}

const columns: (
  isMobile,
  equipmentTypesById,
  currentUser: CurrentUser
) => Columns<EquipmentPerformanceTableData>[] = (
  isMobile: boolean,
  equipmentTypesById: EquipmentTypesById,
  currentUser: CurrentUser
) => {
  const temperatureUnit = getUserPreferenceTemperatureUnit(currentUser);

  return [
    {
      header: ColumnHeaders.EQUIPMENT_NAME,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      width: isMobile ? '8rem' : undefined,
      cellContent: ({
        siteId,
        equipmentId,
        equipmentTypeId,
        defaultKpis: { equipmentTitle },
      }: EquipmentPerformanceTableData) => {
        const title = equipmentTitle
          ? shortenString(equipmentTitle, isMobile ? 30 : 35)
          : global.NOT_AVAILABLE;
        const description =
          isMobile && equipmentTypesById[equipmentTypeId]?.title
            ? shortenString(equipmentTypesById[equipmentTypeId].title, 30)
            : '';
        return (
          <MediaElement
            title={
              <Link
                to={`/sites/${siteId}/equipment/${equipmentId}`}
                className={isMobile ? styles.mobileEquipmentTitle : ''}
              >
                <span>{title}</span>
              </Link>
            }
            description={description}
            className={
              isMobile && title.length >= 20 && description.length >= 20
                ? styles.extraSmallEquipmentFont
                : ''
            }
          />
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediaElement
          icon={
            !isMobile && (
              <SkeletonThumbnail
                width={24}
                height={24}
                opacityIndex={rowIndex}
              />
            )
          }
          title={<MediumLongSkeletonCell opacityIndex={rowIndex} />}
        />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'defaultKpis',
          'equipmentTitle',
        ]);
      },
    },
    {
      header: ColumnHeaders.EQUIPMENT_TYPE,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      cellContent: ({ equipmentTypeId }: EquipmentPerformanceTableData) => {
        if (equipmentTypesById[equipmentTypeId] === undefined)
          return global.NOT_AVAILABLE;
        return equipmentTypesById[equipmentTypeId].title;
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediaElement
          icon={<SkeletonThumbnail width={24} height={24} />}
          title={<MediumLongSkeletonCell opacityIndex={rowIndex} />}
        />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          ({ equipmentTypeId }: EquipmentPerformanceTableData) => {
            if (equipmentTypesById[equipmentTypeId] === undefined)
              return global.NOT_AVAILABLE;
            return equipmentTypesById[equipmentTypeId].title;
          }
        );
      },
    },
    {
      header: ColumnHeaders.SITE,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      cellContent: ({ siteName }: EquipmentPerformanceTableData) => (
        <span>{siteName}</span>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'siteName',
        ]);
      },
    },
    {
      header: ColumnHeaders.VENDOR_MODEL,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      cellContent: ({
        defaultKpis: { vendor, model },
      }: EquipmentPerformanceTableData) => (
        <span>
          {(!vendor ||
            vendor === 'Unnamed' ||
            vendor === global.NOT_AVAILABLE) &&
          !model
            ? global.NOT_AVAILABLE
            : `${vendor} / ${model}`}
        </span>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          ['defaultKpis', 'vendor'],
          ['defaultKpis', 'model']
        );
      },
    },
    {
      header: ColumnHeaders.TOTAL_ENERGY_CONSUMPTION,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({
        defaultKpis: { totalConsumption },
      }: EquipmentPerformanceTableData) =>
        totalConsumption || totalConsumption === 0 ? (
          <span>
            {formatNumberString(totalConsumption, {
              numDecimals: 1,
              unit: KWH_UNIT,
            })}
          </span>
        ) : (
          global.NOT_AVAILABLE
        ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'defaultKpis',
          'totalConsumption',
        ]);
      },
    },
    {
      header: ColumnHeaders.AVERAGE_TEMPERATURE,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ averageTemperature }: EquipmentPerformanceTableData) => (
        <span>
          {!isDefined(averageTemperature) || isNaN(averageTemperature)
            ? global.NOT_AVAILABLE
            : createTemperatureString(averageTemperature, currentUser)}
        </span>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'averageTemperature',
        ]);
      },
    },
    {
      header: ColumnHeaders.STANDARD_DEVIATION,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({
        temperatureDeviation,
      }: EquipmentPerformanceTableData) => (
        <span>
          {temperatureDeviation === undefined ||
          isNaN(temperatureDeviation) ||
          temperatureDeviation === null
            ? global.NOT_AVAILABLE
            : formatValueAsTemp(
                formatNumber(
                  temperatureUnit === TemperatureUnit.C
                    ? temperatureDeviation
                    : celsiusStdDevToFahrenheit(temperatureDeviation),
                  1
                ),
                currentUser
              )}
        </span>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'temperatureDeviation',
        ]);
      },
    },
    {
      header: ColumnHeaders.COMPLIANCE_RATE,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({
        compliancePercentage,
      }: EquipmentPerformanceTableData) => {
        return (
          <span>
            {compliancePercentage === undefined ||
            isNaN(compliancePercentage) ||
            compliancePercentage === null
              ? global.NOT_AVAILABLE
              : formatNumberString(compliancePercentage, {
                  numDecimals: 1,
                  unit: '%',
                })}
          </span>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'compliancePercentage',
        ]);
      },
    },
    {
      header: (
        <span>
          {ColumnHeaders.DEFROST_CYCLES}{' '}
          <span className={styles.betaText}>*Beta</span>
        </span>
      ),
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({
        equipmentTypeKpis: { defrostCycleCount },
      }: EquipmentPerformanceTableData) => (
        <span>
          {defrostCycleCount === null ||
          isNaN(defrostCycleCount) ||
          defrostCycleCount === undefined
            ? global.NOT_AVAILABLE
            : formatNumberString(defrostCycleCount, { numDecimals: 1 })}
        </span>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'equipmentTypeKpis',
          'defrostCycleCount',
        ]);
      },
    },
    {
      header: ColumnHeaders.OPEN_DURATION,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({
        totalDurationOpenMins,
      }: EquipmentPerformanceTableData) => (
        <span>
          {totalDurationOpenMins === undefined ||
          isNaN(totalDurationOpenMins) ||
          totalDurationOpenMins === null
            ? global.NOT_AVAILABLE
            : formatNumberString(totalDurationOpenMins, {
                numDecimals: 1,
                unit: 'mins',
              })}
        </span>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'totalDurationOpenMins',
        ]);
      },
    },
    {
      header: ColumnHeaders.NUM_OPENING,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ totalNumberAccess }: EquipmentPerformanceTableData) => (
        <span>
          {totalNumberAccess === undefined ||
          isNaN(totalNumberAccess) ||
          totalNumberAccess === null
            ? global.NOT_AVAILABLE
            : formatNumberString(totalNumberAccess, {})}
        </span>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'totalNumberAccess',
        ]);
      },
    },
  ];
};

const EquipmentPerformance = () => {
  const history = useHistory();

  /** React State, Selectors, Hooks */
  const dispatch = useDispatch();
  const clearEquipmentPerformanceData = useCallback(() => {
    dispatch(clearEquipmentPerformance());
  }, [dispatch]);
  const { selectedFilters: selectedVendors, setFilter: setSelectedVendors } =
    useDynamicFilter<string>('vendor');

  const {
    equipmentGroupId: _equipmentGroupId,
  }: {
    equipmentGroupId: string;
  } = useParams();
  const equipmentGroupId = Number.parseInt(_equipmentGroupId);
  const equipmentGroupsById = useEquipmentGroupsById();
  const equipmentTypesById = useEquipmentTypesById();
  const equipmentGroupTitle =
    equipmentGroupsById[equipmentGroupId] !== undefined
      ? equipmentGroupsById[equipmentGroupId].title
      : '';
  const { timePeriod, setTimeFilter } = useTimeFilter();
  const { selectedSiteFilters } = useSiteFilter();
  const prevTimePeriod = usePrevious<FilterTimePeriod>(timePeriod);
  const {
    isLoading: equipmentPerformanceIsLoading,
    data: equipmentPerformance,
    loading: equipmentPerformanceLoadingProgress,
    secondsLoaded: secondsLoaded,
  } = useEquipmentPerformanceAsync(equipmentGroupId, timePeriod);

  const {
    selectedFilters: activeEquipmentFilters,
    setFilter: setEquipmentTypeFilter,
  } = useDynamicFilter<number>('eqType', (value) => parseInt(value));

  const setNotificationDateFilter: SetNotificationDateFilter = useCallback(
    (timeFilter: FilterTimePeriod) =>
      dispatch(setNotificationDateFilterAction(timeFilter)),
    [dispatch]
  );
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const locale = useAppLocale();

  const {
    selectedSiteGroups,
    setSiteGroupsFilter,
    selectedSitesBySiteGroups,
    siteGroupWithoutSites,
  } = useSiteGroupsFilter();

  const siteGroupsById = useSelector(
    ({ siteGroups }: ApplicationState) => siteGroups.siteGroupsById ?? {}
  );

  const siteGroupsTitles = sortSiteGroupTitles(siteGroupsById);

  /** End React State, Selectors, Hooks */

  /** React memoized */
  const processedData = useMemoProcessedData(equipmentPerformance);
  const relevantEquipmentTypes = useMemoRelevantEquipmentTypes(processedData);
  const dataFilteredByEquipmentTypeId = useMemoDataFilteredByEquipmentTypeId(
    processedData,
    activeEquipmentFilters
  );
  const availableVendors = useMemoAvailableVendors(
    dataFilteredByEquipmentTypeId
  );
  const filteredDataByVendors = useMemoFilteredData(
    dataFilteredByEquipmentTypeId,
    selectedVendors,
    availableVendors,
    selectedSiteFilters
  );

  const filteredData =
    selectedSiteGroups && selectedSiteGroups?.length
      ? filterBySiteGroups(filteredDataByVendors, selectedSitesBySiteGroups)
      : filteredDataByVendors;

  /** Search base on filter selection */
  const equipmentTypes = useEquipmentTypes();
  const {
    query,
    setQuery,
    filteredList: filterSearchList,
  } = useSearchFilter(
    filteredData,
    [
      ['siteName'],
      ['siteGroups'],
      ['defaultKpis', 'equipmentTitle'],
      ['defaultKpis', 'vendor'],
    ],
    [
      {
        reference: equipmentTypes,
        searchReferenceKey: ['title'],
        referenceKey: ['id'],
        key: ['equipmentTypeId'],
      },
    ]
  );

  const processedColumns = useMemoProcessedColumns(
    isMobile,
    equipmentTypesById,
    processedData
  );

  useEffectClearTodayDateFilterOnLoad(timePeriod, setTimeFilter);
  useEffectClearPerformanceDataOnDateFilterChange(
    prevTimePeriod,
    timePeriod,
    clearEquipmentPerformanceData
  );

  /** End React Effects */

  /** Variables dependent on preceding state */
  const hasResults =
    filterSearchList !== undefined && filterSearchList.length > 0;
  const isLoading =
    equipmentPerformance === undefined && equipmentPerformanceIsLoading;
  const getTimeRemaining = () => {
    if (equipmentPerformanceLoadingProgress * secondsLoaded === 0) {
      return '';
    } else {
      const secondsRemaining =
        secondsLoaded * (1 / equipmentPerformanceLoadingProgress - 1);
      if (secondsRemaining < 60) {
        return `${secondsRemaining.toFixed()} secs remaining`;
      } else {
        return `approx. ${Math.floor(secondsRemaining / 60)} mins remaining`;
      }
    }
  };
  /** End Variables dependent on preceding state */

  const { trigger } = useToReport();
  const downloadReport = (reportType: ReportType) => {
    trigger({
      reportType,
      timePeriod,
      reportTemplate: ReportTemplate.EQUIPMENT_PERFORMANCE,
      equipmentGroupId: equipmentGroupId,
      filterSiteIds: selectedSiteFilters,
      filterEquipmentTypeId: activeEquipmentFilters,
      filterVendor: selectedVendors,
    });
  };

  const mobileFilters = [
    {
      title: 'Site',
      customFilter: () => <SiteFilter isInDropdown={true} />,
      selectedItems: selectedSiteFilters,
    },
    {
      title: 'Site Groups',
      setFilter: setSiteGroupsFilter,
      items: siteGroupsTitles,
      selectedItems: selectedSiteGroups,
    },
    {
      title: 'Equipment Type',
      setFilter: setEquipmentTypeFilter,
      items: Object.values(equipmentTypesById)
        .filter((type) => type.groupId === equipmentGroupId)
        .map((type) => type.id),
      selectedItems: activeEquipmentFilters,
      transformItemName: (typeId) => {
        const type = equipmentTypesById[typeId];
        return type !== undefined ? type.title : '';
      },
    },
    {
      title: 'Vendor',
      setFilter: setSelectedVendors,
      items: availableVendors,
      selectedItems: selectedVendors,
    },
  ];

  const processedColumnsMobile = processedColumns?.length
    ? [processedColumns[0]]
    : [];

  const { currentPage, rowLimit, setPagination } = usePaginationFilter(
    processedData?.length
  );

  return (
    <div className={styles.root}>
      <BaseFilterBar
        className={isMobile ? styles.mobileTopBar : ''}
        navigateBack={() => history.push(ROUTE_ORG_EQUIPMENT)}
      >
        <div
          className={classNames(
            styles.barHeader,
            isMobile ? styles.mobileBarHeader : ''
          )}
        >
          {equipmentGroupTitle}
        </div>
      </BaseFilterBar>
      <PageContentWrapper hasPadding>
        <PageContentHeader header="Performance">
          <SearchBox
            placeholder="Quick Search"
            onChange={setQuery}
            query={query}
            width={
              isMobile
                ? theme.size.table.searchBox.mobile
                : theme.size.table.searchBox.web
            }
            widthActive={
              isMobile
                ? theme.size.table.searchBox.mobile
                : theme.size.table.searchBox.web
            }
            error={filterSearchList.length === 0}
          />
        </PageContentHeader>

        {!processedData && !equipmentPerformanceIsLoading && (
          <span className={styles.errorText}>
            * Select the Date, Equipment Type and Vendor to generate results
          </span>
        )}

        <FiltersContainer growFirst>
          {!isMobile && (
            <FiltersContainer>
              <SiteFilter />
              <SiteGroupFilter />
              <EquipmentMenuFilter
                dropDownWrapperClassName={styles.equipmentFilterDropdown}
                relevantItemGroupId={equipmentGroupId}
                relevantItemTypeIds={relevantEquipmentTypes}
                label="Equipment Type"
              />
              <VendorFilter vendors={availableVendors} />
            </FiltersContainer>
          )}

          <DateFilter
            value={timePeriod}
            setFilter={setNotificationDateFilter}
            optionCreator={getDateFiltersWithoutTodayOrYesterday}
            customPickerVariant="date"
            locale={locale}
            alignItemsRight
          />
          <ReportGenerateDropDownButton
            download={downloadReport}
            disabled={isLoading}
          />

          {isMobile && (
            <GenericMultipleFilter
              className={styles.mobilePriorityAndStatusFilter}
              dropdownClassName={styles.mobileFilterDropdown}
              title="Filters"
              filters={mobileFilters}
              alignItemsRight
            />
          )}
        </FiltersContainer>

        <TableWrapper
          hideTopBar={filterSearchList.length == 0}
          header={
            isLoading
              ? undefined
              : displayCount(filterSearchList.length, 'Equipment')
          }
        >
          <Table
            headerClassName={isMobile ? styles.mobileHeader : ''}
            columns={isMobile ? processedColumnsMobile : processedColumns}
            scrollableColumns={isMobile ? processedColumns.slice(1) : undefined}
            highlightScrollableColumns={!isMobile}
            noHeaderShadow={isMobile}
            data={filterSearchList}
            dataIsLoading={isLoading}
            rowHeight="4rem"
            listView
            rowLimitFromPaginationHook={rowLimit}
            currentPageFromPaginationHook={currentPage}
            setPagination={setPagination}
          />
          {isLoading && (
            <div className={styles.loaderOverlay}>
              <div className={styles.loaderContainer}>
                <Loader size={64} />
                <h4 style={{ marginTop: 20, marginBottom: 12 }}>
                  Data is loading...
                </h4>
                <p>{getTimeRemaining()}</p>
              </div>
            </div>
          )}
        </TableWrapper>
        {!hasResults && !equipmentPerformanceIsLoading && (
          <NothingToReportOverlay />
        )}
      </PageContentWrapper>
    </div>
  );
};

const useMemoProcessedData = (
  equipmentPerformance: EquipmentPerformanceType | undefined
) => {
  return useMemo<EquipmentPerformanceTableData[]>(() => {
    if (
      equipmentPerformance !== undefined &&
      equipmentPerformance.performanceDataByEquipment !== undefined
    ) {
      return processPerformanceDataForTable(
        equipmentPerformance.performanceDataByEquipment
      );
    } else {
      return [];
    }
  }, [equipmentPerformance]);
};

const useMemoRelevantEquipmentTypes = (
  processedData: EquipmentPerformanceTableData[]
) => {
  return useMemo(() => {
    const equipmentTypeIds = processedData.map(
      ({ equipmentTypeId }) => equipmentTypeId
    );
    return Array.from(new Set(equipmentTypeIds));
  }, [processedData]);
};

const useMemoDataFilteredByEquipmentTypeId = (
  processedData: EquipmentPerformanceTableData[],
  activeEquipmentFilters: number[]
) => {
  return useMemo(() => {
    return processedData.filter(({ equipmentTypeId }) => {
      if (activeEquipmentFilters.length === 0) {
        return true;
      } else {
        return activeEquipmentFilters.includes(equipmentTypeId);
      }
    });
  }, [processedData, activeEquipmentFilters]);
};

const useMemoAvailableVendors = (
  dataFilteredByEquipmentTypeId: EquipmentPerformanceTableData[]
) => {
  return useMemo<string[]>(() => {
    const vendorSet = new Set<string>();
    dataFilteredByEquipmentTypeId.forEach(
      ({ defaultKpis: { vendor } }, index) => {
        if (vendor !== null && vendor !== '') {
          vendorSet.add(vendor);
        } else {
          dataFilteredByEquipmentTypeId[index].defaultKpis.vendor =
            DEFAULT_VENDOR_NAME;
          vendorSet.add(DEFAULT_VENDOR_NAME);
        }
      }
    );
    return Array.from(vendorSet).sort();
  }, [dataFilteredByEquipmentTypeId]);
};

const useMemoFilteredData = (
  dataFilteredByEquipmentTypeId: EquipmentPerformanceTableData[],
  selectedVendors: string[],
  availableVendors: string[],
  selectedSiteFilters: number[]
) => {
  return useMemo(() => {
    return dataFilteredByEquipmentTypeId
      .filter(({ defaultKpis: { vendor } }) => {
        if (
          selectedVendors.length === 0 ||
          selectedVendors.length === availableVendors.length
        ) {
          return true;
        } else if (vendor === null) {
          return false;
        } else {
          return selectedVendors.includes(vendor);
        }
      })
      .filter(({ siteId }) => {
        if (selectedSiteFilters.length === 0) {
          return true;
        } else {
          return selectedSiteFilters.find(
            (selectedSiteId) => selectedSiteId === siteId
          );
        }
      });
  }, [
    selectedVendors,
    dataFilteredByEquipmentTypeId,
    availableVendors.length,
    selectedSiteFilters,
  ]);
};

const useMemoProcessedColumns = (
  isMobile: boolean,
  equipmentTypesById: EquipmentTypesById,
  processedData: EquipmentPerformanceTableData[]
) => {
  const currentUser = useCurrentUser();
  return useMemo(() => {
    if (!currentUser) return [];
    let _columns = columns(isMobile, equipmentTypesById, currentUser);

    // I think the issue here is that the function assumes the data actually has the field
    const delColIfNoData = (
      fields: string[],
      colHeader: ColumnHeaders,
      cols: Columns<EquipmentPerformanceTableData>[]
    ): Columns<EquipmentPerformanceTableData>[] => {
      if (!doesDataHaveField(fields, processedData)) {
        const idxToRemove = cols.findIndex(
          ({ header }) => header === colHeader
        );
        if (idxToRemove > -1) {
          _columns.splice(idxToRemove, 1);
        }
        return [...cols];
      } else {
        return cols;
      }
    };

    _columns = delColIfNoData(
      ['defaultKpis', 'totalConsumption'],
      ColumnHeaders.TOTAL_ENERGY_CONSUMPTION,
      _columns
    );
    _columns = delColIfNoData(
      ['averageTemperature'],
      ColumnHeaders.AVERAGE_TEMPERATURE,
      _columns
    );
    _columns = delColIfNoData(
      ['temperatureDeviation'],
      ColumnHeaders.STANDARD_DEVIATION,
      _columns
    );
    _columns = delColIfNoData(
      ['compliancePercentage'],
      ColumnHeaders.COMPLIANCE_RATE,
      _columns
    );

    if (
      !doesDataHaveField(
        ['equipmentTypeKpis', 'defrostCycleCount'],
        processedData
      )
    ) {
      const idxToRemove = _columns.findIndex(({ header }) => {
        if (
          header !== undefined &&
          header !== null &&
          //@ts-ignore
          header.props !== undefined
        ) {
          //@ts-ignore
          return header.props.children.includes(ColumnHeaders.DEFROST_CYCLES);
        } else {
          return false;
        }
      });
      if (idxToRemove > -1) {
        _columns.splice(idxToRemove, 1);
      }
    }
    if (
      !doesDataHaveField(
        ['equipmentTypeKpis', 'kwhPerDegreeCooling'],
        processedData
      )
    ) {
      const idxToRemove = _columns.findIndex(({ header }) => {
        if (
          header !== undefined &&
          header !== null &&
          //@ts-ignore
          header.props !== undefined
        ) {
          //@ts-ignore
          return header.props.children.includes(ColumnHeaders.KWH_DEGREE);
        } else {
          return false;
        }
      });
      if (idxToRemove > -1) {
        _columns.splice(idxToRemove, 1);
      }
    }

    _columns = delColIfNoData(
      ['totalDurationOpenMins'],
      ColumnHeaders.OPEN_DURATION,
      _columns
    );

    _columns = delColIfNoData(
      ['totalNumberAccess'],
      ColumnHeaders.NUM_OPENING,
      _columns
    );

    // Remove equipment type column in mobile view
    if (isMobile) {
      const siteColIndex = _columns.findIndex(
        ({ header }) => header === ColumnHeaders.EQUIPMENT_TYPE
      );
      if (siteColIndex > -1) {
        _columns.splice(siteColIndex, 1);
      }
    }

    return _columns;
  }, [currentUser, equipmentTypesById, processedData]);
};

export const useEffectClearTodayDateFilterOnLoad = (
  timePeriod: FilterTimePeriod,
  setTimeFilter: (timeFilter: FilterTimePeriod) => any
) => {
  useEffect(() => {
    if (timePeriod.title === 'Today') {
      const { last7Days } = createTimeFilterMapping();
      setTimeFilter(last7Days);
    }
    // Ignore deps, want to run once on page load to change filter iff filter is
    // set to Today from user interacting with another page.
    // eslint-disable-next-line
  }, []);
};

const useEffectClearPerformanceDataOnDateFilterChange = (
  prevTimePeriod: FilterTimePeriod | undefined,
  timePeriod: FilterTimePeriod,
  clearEquipmentPerformanceData: () => void
) => {
  useEffect(() => {
    if (
      prevTimePeriod !== undefined &&
      prevTimePeriod.title !== timePeriod.title
    ) {
      clearEquipmentPerformanceData();
    }
  }, [prevTimePeriod, timePeriod, clearEquipmentPerformanceData]);
};

type DataBySensor =
  | {
      [sensorId: number]: {
        totalDurationOpenMins: number;
        totalNumberAccess: number;
      };
    }
  | {
      [sensorId: number]: {
        averageTemperature: number;
        temperatureDeviation: number;
        compliancePercentage: number;
      };
    };

const processPerformanceDataForTable = (
  performanceDataByEquipment: EquipmentPerformanceById
) => {
  const calculateAverage = (field: string, bySensor: DataBySensor) => {
    const averageFieldHelper = ({ total, numSensorsWithField }, sensorData) => {
      if (isDefined(sensorData[field])) {
        numSensorsWithField += 1.0;
        total += sensorData[field];
      }
      return { total, numSensorsWithField };
    };

    const { total: tempTotal, numSensorsWithField: tempNumSensorsWithField } =
      Object.values(bySensor).reduce(averageFieldHelper, {
        total: 0,
        numSensorsWithField: 0.0,
      });
    const averageTemperature = tempTotal / tempNumSensorsWithField;
    return averageTemperature;
  };

  return Object.entries(performanceDataByEquipment).map(
    ([equipmentId, performanceData]: [string, EquipmentPerformanceData]) => {
      const calculateTempAverage = (field: string) =>
        calculateAverage(field, performanceData.byTempSensor);
      const calculateAccessAverage = (field: string) =>
        calculateAverage(field, performanceData.byAccessSensor);
      return {
        ...performanceData,
        equipmentId,
        averageTemperature: calculateTempAverage('averageTemperature'),
        temperatureDeviation: calculateTempAverage('temperatureDeviation'),
        compliancePercentage: calculateTempAverage('compliancePercentage'),
        totalDurationOpenMins: calculateAccessAverage('totalDurationOpenMins'),
        totalNumberAccess: calculateAccessAverage('totalNumberAccess'),
      } as EquipmentPerformanceTableData;
    }
  );
};

// This is a strange way to name this.
type TableNullValues = undefined | null | number | '' | { [id: string]: any };

const doesDataHaveField = (
  fieldName: string[],
  data: EquipmentPerformanceTableData[]
) => {
  if (data.length === 0) {
    return false;
  }

  const doesAnyElementHaveThisField = data.some((tableEntry) => {
    let entry = path(fieldName, tableEntry);
    let field = entry as TableNullValues;
    return (
      field !== undefined &&
      field !== null &&
      field !== '' &&
      (typeof field !== 'object' || Object.keys(field).length === 0) &&
      !isNaN(field as number)
    );
  });

  return doesAnyElementHaveThisField;
};

export default EquipmentPerformance;
