import { SubscribedControlBoards } from '@energybox/react-ui-library/dist/types/SubscribedControlBoard';
import assocPath from 'ramda/src/assocPath';
import pipe from 'ramda/src/pipe';
import { Actions as StreamActions } from '../actions/streamApi';

const initialState = {
  byId: {},
};

const subscribedControlBoards = (
  state: SubscribedControlBoards = initialState,
  action: any
) => {
  switch (action.type) {
    case StreamActions.RECEIVED_DEVICE_STATUS: {
      if (
        action.payload.vendor !== 'energybox' ||
        action.payload.status === undefined ||
        action.payload.status.gatewayStates === undefined
      ) {
        return state;
      }

      const controlBoard = action.payload;

      return pipe(
        assocPath(['byId', action.payload.id.toString()], controlBoard)
      )(state);
    }

    default:
      return state;
  }
};

export default subscribedControlBoards;
