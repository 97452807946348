import { CurrentOrganization } from '@energybox/react-ui-library/dist/types';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';

const useCurrentUser = () => {
  const currentUser = useSelector(
    (state: ApplicationState) => state.app?.currentUser
  );
  return currentUser;
};

export const useUsersById = () => {
  const usersById = useSelector(
    (state: ApplicationState) => state.users.usersById
  );
  return usersById;
};

export const useOrganizationId = () => {
  return useSelector<ApplicationState, number | undefined>(
    ({ organizations }) => {
      return organizations.currentOrganization !== undefined
        ? organizations.currentOrganization.id
        : undefined;
    }
  );
};

export const useOrganization = () => {
  return useSelector<ApplicationState, CurrentOrganization | undefined>(
    ({ organizations }) => {
      return organizations.currentOrganization;
    }
  );
};

export default useCurrentUser;
