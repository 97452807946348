import { Tooltip } from '@energybox/react-ui-library/dist/components';
import {
  useViewportType,
  ViewportTypes,
} from '@energybox/react-ui-library/dist/hooks';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import styles from './CardTitle.module.css';

type Props = {
  className?: string;
  title?: string;
  subTitle?: string;
  tooltipDescription?: string;
  allowShortenedTitle?: boolean;
  moreInfo?: React.ReactNode;
  arrowDirection?: 'top' | 'left' | 'bottom' | 'right' | 'closeBottom';
  linkPrompt?: React.ReactNode;
  SortTypeRadioButton?: React.ReactNode;
};

const CardTitle = ({
  className,
  title = '',
  subTitle = '',
  tooltipDescription = '',
  allowShortenedTitle,
  moreInfo,
  arrowDirection,
  linkPrompt,
  SortTypeRadioButton,
}: Props) => {
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const titleHeader = (
    <div
      className={classNames(
        title == 'Energy Distribution'
          ? styles.cardTitle
          : styles.cardTitleEnergyDistribution,
        allowShortenedTitle ? styles.shortenedCardTitle : ''
      )}
      data-testid="card-title-tooltip"
    >
      {title}
      <div className={classNames(className, styles.cardToggleMain)}>
        {SortTypeRadioButton}
      </div>
    </div>
  );
  let titleComponent: React.ReactNode;
  if (tooltipDescription !== '') {
    const adjustedArrowDirection =
      arrowDirection || (isMobile ? 'top' : 'bottom');
    titleComponent = (
      <Tooltip
        arrowDirection={adjustedArrowDirection}
        disableAutoAdjust={isMobile}
        tooltipTextClassName={isMobile ? styles.mobileTooltip : undefined}
        simpleContent={{
          title,
          description: tooltipDescription,
          moreInfo: moreInfo,
          link: linkPrompt,
        }}
        isClickable
      >
        {titleHeader}
      </Tooltip>
    );
  } else {
    titleComponent = titleHeader;
  }

  return (
    <div className={classNames(className, styles.container)}>
      {titleComponent}
      <div>
        {subTitle !== '' && (
          <span className={styles.cardSubtitle}>{subTitle}</span>
        )}
      </div>
    </div>
  );
};

export default CardTitle;
