import {
  ComparisonData,
  FilterTimePeriod,
} from '@energybox/react-ui-library/dist/types';
import {
  DefaultChartStyles,
  classNames,
  formatCurrencySymbol,
  genericYAxisTickFormatter,
} from '@energybox/react-ui-library/dist/utils';
import { HelpOutline } from '@material-ui/icons';
import React from 'react';
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styles from './RechartsComparisonChart.module.css';
import history from '../../../history';
import { redirectToIncidentPageEntry } from '../../../actions/incidents';
import { useDispatch } from 'react-redux';

type Props = {
  data: ComparisonData[];
  dataUnit: string;
  // If all bars should be the same just pass a string, else it should be an
  // array of same length of data with a color for each comparison slice
  dataFillColor: string | string[];
  tooltip: React.ReactNode;
  timePeriod?: FilterTimePeriod;
  xDataKey?: string;
  yDataKey?: string;
  showCurrency?: boolean;
  rootClassName?: string;
};

const CustomXTick =
  (data: ComparisonData[], timePeriod?: FilterTimePeriod) => (props) => {
    const { x, y, payload } = props;
    const textSplits = payload.value.split(' - ');
    const textForPreviousPeriods =
      textSplits.length === 1 || textSplits[0] === textSplits[1] ? (
        <tspan textAnchor="middle">{textSplits[0]}</tspan>
      ) : (
        <>
          <tspan x={15} y={9}>
            {textSplits[1]}
          </tspan>
          <tspan x={20} y={8}>
            {textSplits[0]} -
          </tspan>
        </>
      );
    let textForSavings = <tspan x={15} y={9}></tspan>;
    let shouldHideTick = false;
    if (timePeriod !== undefined) {
      const { shouldHideTick: temp } = data[payload.index];
      if (temp !== undefined) {
        shouldHideTick = temp;
      }
      textForSavings = (
        <tspan x={15} y={9}>
          {payload.value}
        </tspan>
      );
    }

    const tickElem = (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={10}
          textAnchor="end"
          style={{
            fontSize: DefaultChartStyles.tick.fontSize,
            fill: DefaultChartStyles.tick.fill,
          }}
        >
          {timePeriod === undefined ? textForPreviousPeriods : textForSavings}
        </text>
      </g>
    );

    if (timePeriod === undefined) {
      return tickElem;
    }
    return shouldHideTick ? undefined : tickElem;
  };

const RechartsComparisonChart = ({
  data,
  dataUnit,
  dataFillColor,
  tooltip,
  timePeriod,
  xDataKey = 'entity',
  yDataKey = 'value',
  showCurrency,
  rootClassName,
}: Props) => {
  const dispatch = useDispatch();

  const redirectToIncidentPage = (entry) => {
    dispatch(redirectToIncidentPageEntry(entry));
    history.push('/incidents');
  };

  return (
    <div className={classNames(rootClassName, styles.root)}>
      <div className={styles.yUnitLabel}>
        {showCurrency ? formatCurrencySymbol(dataUnit) : dataUnit}
      </div>
      <ResponsiveContainer>
        <ComposedChart data={data}>
          <CartesianGrid
            vertical={false}
            stroke={DefaultChartStyles.axisLineColor}
          />
          <YAxis
            type="number"
            tick={DefaultChartStyles.tick}
            tickFormatter={genericYAxisTickFormatter}
            axisLine={false}
            tickLine={false}
            width={30}
          />
          <XAxis
            type="category"
            dataKey={xDataKey}
            tick={CustomXTick(data, timePeriod)}
            axisLine={{
              stroke: DefaultChartStyles.axisLineColor,
            }}
            tickLine={false}
            interval={0}
          />
          <Bar
            dataKey={yDataKey}
            unit={dataUnit}
            barSize={50}
            isAnimationActive={true}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  Array.isArray(dataFillColor)
                    ? dataFillColor[index]
                    : dataFillColor
                }
                onClick={() => redirectToIncidentPage(entry)}
              />
            ))}
          </Bar>
          <Tooltip content={tooltip} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default RechartsComparisonChart;
