import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import * as R from 'ramda';
import { Actions as EdgeDeviceActions } from '../actions/edge_devices';

export type EdgeDeviceState = {
  isEdgeDeviceLoadingByNetworkGroupId: IsEdgeDeviceLoadingByNetworkGroupId;
  edgeConfigFileBySerialNumber: EdgeConfigFileBySerialNumber;
  edgeConfigUpdateStatusByEdgeId: EdgeConfigUpdateStatusByEdgeId;
};

export type IsEdgeDeviceLoadingByNetworkGroupId = {
  [networkGroupId: string]: boolean;
};

export type EdgeConfigUpdateStatusByEdgeId = {
  [edgeId: number]: EdgeConfigUpdate;
};

export type EdgeConfigUpdate = {
  edgeId: number;
  isHashEqual: boolean;
  lastRunningHashTimeStamp: string;
  lastSentHashTimeStamp: string;
  reasonForFailure: string;
  isLoading: boolean;
};

export type EdgeConfigFileBySerialNumber = {
  [serialNumber: string]: EdgeConfigFile;
};

export type EdgeConfigFile = {
  configYaml: string;
  id: number;
  serialNumber: string;
  uuid: string;
  lastRunningConfigHash: string;
  lastSentConfigHash: string;
  currentConfigHash: string;
  yamlValidationErrors: [];
};

const initialState: EdgeDeviceState = {
  isEdgeDeviceLoadingByNetworkGroupId: {},
  edgeConfigFileBySerialNumber: {},
  edgeConfigUpdateStatusByEdgeId: {},
};

const edgeDevices = (state = initialState as EdgeDeviceState, action: any) => {
  switch (action.type) {
    case EdgeDeviceActions.POST_NEW_EDGE_LOADING: {
      return R.assocPath(
        ['isEdgeDeviceLoadingByNetworkGroupId', action.networkGroupId],
        true,
        state
      );
    }

    case EdgeDeviceActions.POST_NEW_EDGE_SUCCESS: {
      return R.pipe(
        R.assocPath(
          ['isEdgeDeviceLoadingByNetworkGroupId', action.networkGroupId],
          false
        ),
        R.assocPath(
          ['isConfigAcceptedByNetworkGroupId', action.networkGroupId],
          action.payload
        ),
        R.assocPath(
          ['edgeDeviceResponseTextByNetworkGroupId', action.networkGroupId],
          action.payload.validationErrors
        )
      )(state);
    }

    case EdgeDeviceActions.POST_NEW_EDGE_ERROR: {
      return R.pipe(
        R.assocPath(
          ['isEdgeDeviceLoadingByNetworkGroupId', action.networkGroupId],
          false
        ),
        R.assocPath(
          ['edgeDeviceResponseTextByNetworkGroupId', action.networkGroupId],
          action.payload.message
        ),
        R.assocPath(
          ['isConfigAcceptedByNetworkGroupId', action.networkGroupId],
          false
        )
      )(state);
    }

    case EdgeDeviceActions.GET_EDGE_CONFIG_FILE_SUCCESS: {
      return R.pipe(
        R.assocPath(
          ['edgeConfigFileBySerialNumber', action.serialNumber],
          action.payload
        )
      )(state);
    }

    case EdgeDeviceActions.GET_EDGE_CONFIG_FILE_LOADING: {
      return R.pipe(
        R.assocPath(
          ['edgeConfigFileBySerialNumber', action.serialNumber, 'isLoading'],
          true
        )
      )(state);
    }

    case EdgeDeviceActions.GET_EDGE_CONFIG_FILE_ERROR: {
      return R.pipe(
        R.assocPath(['edgeConfigFileBySerialNumber', action.serialNumber], {
          isLoading: false,
          data: {},
          apiError: storeAPIerror(action),
        })
      )(state);
    }

    case EdgeDeviceActions.GET_EDGE_UPDATE_LOADING:
      return R.pipe(
        R.assocPath(
          ['edgeConfigUpdateStatusByEdgeId', action.edgeId, 'isLoading'],
          true
        )
      )(state);

    case EdgeDeviceActions.GET_EDGE_UPDATE_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['edgeConfigUpdateStatusByEdgeId', action.edgeId],
          action.payload
        ),
        R.assocPath(
          ['edgeConfigUpdateStatusByEdgeId', action.edgeId, 'isLoading'],
          false
        )
      )(state);

    case EdgeDeviceActions.GET_EDGE_UPDATE_ERROR:
      return R.pipe(
        R.assocPath(['edgeConfigUpdateStatusByEdgeId', action.edgeId], {
          isLoading: false,
          apiError: storeAPIerror(action),
        })
      )(state);

    default:
      return state;
  }
};

export default edgeDevices;
