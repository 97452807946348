import {
  ResourceType,
  Space,
  SpaceStats,
} from '@energybox/react-ui-library/dist/types';
import { Actions } from '../actions/spaces';
import { assocPath, pipe } from 'ramda';

export type Spaces = {
  siteId?: number;
  spaces?: Space[];
  isLoadingBySiteId: {
    [site: string]: boolean;
  };
  isLoadingById: {
    [spaceId: string]: boolean;
  };
  bySiteId: SpacesBySiteId;
  byId: SpacesById;
  spaceStatsById: SpaceStatsById;
  spaceStatsLoadingById: SpaceStatsLoadingById;
};

export type SpacesById = {
  [spaceId: string]: Space;
};

export type SpacesBySiteId = {
  [key: number]: Space[];
};

export type SpaceStatsById = {
  [key: number]: SpaceStats;
};

export type SpaceStatsLoadingById = {
  [key: number]: boolean;
};

const initialState = {
  isLoadingBySiteId: {},
  bySiteId: {},
  byId: {},
  isLoadingById: {},
  spaceStatsById: {},
  spaceStatsLoadingById: {},
};

export default (state: Spaces = initialState, action: any) => {
  switch (action.type) {
    case Actions.SPACES_BY_SITE_ID_LOADING: {
      return assocPath<boolean, Spaces>(
        ['isLoadingBySiteId', action.siteId],
        true
      )(state);
    }

    case Actions.SPACES_BY_SITE_ID_SUCCESS: {
      return pipe(
        assocPath<boolean, Spaces>(['isLoadingBySiteId', action.siteId], false),
        assocPath<Space[], Spaces>(['bySiteId', action.siteId], action.payload)
      )(state);
    }

    case Actions.SPACES_BY_SITE_ID_ERROR: {
      return assocPath<boolean, Spaces>(
        ['isLoadingBySiteId', action.siteId],
        false
      )(state);
    }

    case Actions.GET_SPACE_BY_ID_LOADING: {
      return assocPath<boolean, Spaces>(
        ['isLoadingById', action.spaceId],
        true,
        state
      );
    }

    case Actions.GET_SPACE_BY_ID_ERROR: {
      return assocPath<boolean, Spaces>(
        ['isLoadingById', action.spaceId],
        false,
        state
      );
    }

    case Actions.GET_SPACE_BY_ID_SUCCESS: {
      return pipe(
        assocPath<boolean, Spaces>(['isLoadingById', action.spaceId], false),
        assocPath<Space, Spaces>(['byId', action.spaceId], action.payload)
      )(state);
    }

    case Actions.GET_SPACE_STATS_BY_ID_LOADING: {
      return assocPath<boolean, Spaces>(
        ['spaceStatsLoadingById', action.spaceId],
        true,
        state
      );
    }

    case Actions.GET_SPACE_STATS_BY_ID_ERROR: {
      return assocPath<boolean, Spaces>(
        ['spaceStatsLoadingById', action.spaceId],
        false,
        state
      );
    }

    case Actions.GET_SPACE_STATS_BY_ID_SUCCESS: {
      return pipe(
        assocPath<boolean, Spaces>(
          ['spaceStatsLoadingById', action.spaceId],
          false
        ),
        assocPath<SpaceStatsById, Spaces>(
          ['spaceStatsById', action.spaceId],
          action.payload
        )
      )(state);
    }

    default: {
      return state;
    }
  }
};

export const spacesFromApiResponse = (data: any) =>
  ({
    id: data.id,
    title: data.title,
    parentId: data.parentId,
    parent: data.parent,
    description: data.description || '',
    createdAt: data.createdAt,
    updatedAt: data.updatedAt || undefined,
    resourceType: ResourceType[(data._entity as string).toUpperCase()],
    area: data.area,
  } as Space);
