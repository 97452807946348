import { DeviceState } from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions as StreamActions } from '../actions/streamApi';

export type DeviceStatusById = {
  [id: string]: DeviceState;
};

function onlineStatusByDeviceType(event) {
  switch (event.deviceType) {
    // todo: sometimes sensor also returns onlineState and onlineStateUpdatedAt,
    // todo: but it looks outdated
    case 'sensor':
      if (
        event.hasOwnProperty('onlineState') &&
        event.hasOwnProperty('onlineStateUpdatedAt')
      ) {
        return {
          ...(event.status ? event.status : {}),
          id: event.id,
          uuid: event.uuid,
          onlineState: event.onlineState,
          timestamp: event.onlineStateUpdatedAt,
          unixTimestamp: new Date(event.onlineStateUpdatedAt).getTime(),
        };
      }

      if (!event.status) {
        return;
      }

      if (event.vendor === 'monnit') {
        return {
          vendor: 'monnit',
          signalStrengthLevel: event.status.signalStrengthLevel,
          batteryLevel: event.status.batteryLevel,
          onlineState: event.status.state === 'ACTIVE',
          timestamp: event.status.processedAt,
          unixTimestamp: new Date(event.status.processedAt).getTime(),
        };
      }
      // Todo something is weird about this case
      return {
        id: event.id,
        vendor: 'energybox',
        types: event.info && event.info.types,
        batteryLevel: event.status.batteryLevel,
        batteryVoltage: event.status.batteryVoltage,
        signalStrength: event.status.signalStrength,
        signalStrengthLevel: event.status.signalStrengthLevel,
        onlineState: event.status.state === 'ACTIVE',
        timestamp: event.status.receivedAt,
        interval: event.status.interval,
        unixTimestamp: new Date(event.status.receivedAt).getTime(),
      };
    case 'edge': {
      if (
        event.hasOwnProperty('onlineState') &&
        event.hasOwnProperty('onlineStateUpdatedAt')
      ) {
        return {
          ...(event.status ? event.status : {}),
          ...(event.info ? event.status : {}),
          id: event.id,
          uuid: event.uuid,
          onlineState: event.onlineState,
          timestamp: event.onlineStateUpdatedAt,
          unixTimestamp: new Date(event.onlineStateUpdatedAt).getTime(),
        };
      }
      return;
    }
    case 'gateway':
      if (
        event.hasOwnProperty('onlineState') &&
        event.hasOwnProperty('onlineStateUpdatedAt')
      ) {
        return {
          ...(event.status ? event.status : {}),
          id: event.id,
          uuid: event.uuid,
          onlineState: event.onlineState,
          timestamp: event.onlineStateUpdatedAt,
          unixTimestamp: new Date(event.onlineStateUpdatedAt).getTime(),
        };
      }

      return;

    default:
      return;
  }
}

const deviceStatusById = (state = {} as DeviceStatusById, action: any) => {
  switch (action.type) {
    case StreamActions.RECEIVED_DEVICE_STATUS: {
      const status = onlineStatusByDeviceType(action.payload);

      if (status && action.payload.id) {
        const dateFromState = state[action.payload.id.toString()];

        const mergedById = R.mergeDeepRight(
          R.pathOr({}, [action.payload.id.toString()], state),
          status
        );
        const mergedByUuid = R.mergeDeepRight(
          R.pathOr({}, [action.payload.uuid], state),
          status
        );
        if (dateFromState && !isNaN(dateFromState.unixTimestamp)) {
          if (dateFromState.unixTimestamp < status.unixTimestamp) {
            return {
              ...state,
              // Map by both id and uuid, this is for use by whitelist/nearby which may not include id
              [action.payload.id.toString()]: mergedById,
              [action.payload.uuid]: mergedByUuid,
            };
          }
        } else {
          return {
            ...state,
            // Map by both id and uuid, this is for use by whitelist/nearby which may not include id
            [action.payload.id.toString()]: mergedById,
            [action.payload.uuid]: mergedByUuid,
          };
        }
      } else if (status) {
        const dateFromState = state[action.payload.uuid];
        if (dateFromState) {
          if (dateFromState.unixTimestamp < status.unixTimestamp) {
            return {
              ...state,
              [action.payload.uuid]: status,
            };
          }
        } else {
          return {
            ...state,
            [action.payload.uuid]: status,
          };
        }
      }

      return state;
    }

    default:
      return state;
  }
};

export default deviceStatusById;
