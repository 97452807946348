import React from 'react';
import styles from './SiteClusterMarker.module.css';

type Props = {
  size: number;
  numWithActiveIncidents: number;
  numTotalSites: number;
};

const SiteClusterMarker = ({
  size = 32,
  numWithActiveIncidents,
  numTotalSites,
}: Props) => {
  const percentSitesWithIncident = numWithActiveIncidents / numTotalSites;
  const circleCircumference = 284;
  const lengthIncidentArc = percentSitesWithIncident * circleCircumference;
  const lengthNormalArc = circleCircumference - lengthIncidentArc;
  let fontSize = 50;
  let textY = 67;
  if (numTotalSites.toString().length === 3) {
    fontSize = 40;
    textY = 64;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width={size}
      height={size}
    >
      <circle
        cx="50"
        cy="50"
        r="45"
        className={`${styles.circle} ${styles.incidentColor}`}
        style={{
          strokeDasharray: `${lengthIncidentArc} ${lengthNormalArc}`,
          strokeDashoffset: `${circleCircumference / 4}`,
        }}
      />
      <circle
        cx="50"
        cy="50"
        r="45"
        className={`${styles.circle} ${styles.normalColor}`}
        style={{
          strokeDasharray: `${lengthNormalArc} ${lengthIncidentArc}`,
          strokeDashoffset: `${circleCircumference / 4 + lengthNormalArc}`,
        }}
      />
      <text
        x="50"
        y={textY}
        textAnchor="middle"
        fill="#111"
        fontSize={fontSize}
      >
        {numTotalSites}
      </text>
    </svg>
  );
};

export default SiteClusterMarker;
