import { IncidentApiFilter } from '@energybox/react-ui-library/dist/types';
import pathOr from 'ramda/src/pathOr';
import { Service } from '../config';

export enum Actions {
  GET_INCIDENT_COUNTS_BY_SITE_SUCCESS = '@@incidents/GET_INCIDENT_COUNTS_BY_SITE_SUCCESS',
  GET_INCIDENT_COUNTS_BY_SITE_LOADING = '@@incidents/GET_INCIDENT_COUNTS_BY_SITE_LOADING',
  GET_INCIDENT_COUNTS_BY_SITE_ERROR = '@@incidents/GET_INCIDENT_COUNTS_BY_SITE_ERROR',

  GET_INCIDENT_COUNTS_BY_EQUIPMENT_SUCCESS = '@@incidents/GET_INCIDENT_COUNTS_BY_EQUIPMENT_SUCCESS',
  GET_INCIDENT_COUNTS_BY_EQUIPMENT_LOADING = '@@incidents/GET_INCIDENT_COUNTS_BY_EQUIPMENT_LOADING',
  GET_INCIDENT_COUNTS_BY_EQUIPMENT_ERROR = '@@incidents/GET_INCIDENT_COUNTS_BY_EQUIPMENT_ERROR',

  GET_INCIDENTS_SUCCESS = '@@incidents/GET_INCIDENTS_SUCCESS',
  GET_INCIDENTS_LOADING = '@@incidents/GET_INCIDENTS_LOADING',
  GET_INCIDENTS_ERROR = '@@incidents/GET_INCIDENTS_ERROR',

  GET_INCIDENTS_BY_SITE_ID_SUCCESS = '@@incidents/GET_INCIDENTS_BY_SITE_ID_SUCCESS',
  GET_INCIDENTS_BY_SITE_ID_LOADING = '@@incidents/GET_INCIDENTS_BY_SITE_ID_LOADING',
  GET_INCIDENTS_BY_SITE_ID_ERROR = '@@incidents/GET_INCIDENTS_BY_SITE_ID_ERROR',

  GET_INCIDENTS_BY_INCIDENT_ID_SUCCESS = '@@incidents/GET_INCIDENTS_BY_INCIDENT_ID_SUCCESS',
  GET_INCIDENTS_BY_INCIDENT_ID_LOADING = '@@incidents/GET_INCIDENTS_BY_INCIDENT_ID_LOADING',
  GET_INCIDENTS_BY_INCIDENT_ID_ERROR = '@@incidents/GET_INCIDENTS_BY_INCIDENT_ID_ERROR',

  GET_REDIRECT_TO_INCIDENT_ENTRY = '@@incidents/GET_REDIRECT_TO_INCIDENT_ENTRY',
  GET_ACTIVE_INCIDENT_TILE_STATUS = '@@incidents/GET_ACTIVE_INCIDENT_TILE_STATUS',
}

const setIncidentFilter = (filter?: IncidentApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.from) {
    queryParams.set('from', filter.from);
  }

  if (filter && filter.to) {
    queryParams.set('to', filter.to);
  }

  if (filter && filter.equipmentIds) {
    queryParams.set('equipmentIds', filter.equipmentIds.join(','));
  }

  if (filter && filter.incidentStatus) {
    queryParams.set('incidentStatus', filter.incidentStatus);
  }

  if (filter && filter.siteIds && filter.siteIds.length) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.incidentStatus) {
    queryParams.set('incidentStatus', String(filter.incidentStatus));
  }

  if (filter && filter['strict']) {
    queryParams.set('strict', String(filter['strict']));
  }

  return queryParams.toString();
};

export const getIncidentCountsBySite = (queryParams: IncidentApiFilter) => ({
  type: 'API_GET',
  path: `/api/v1/count/incidents/by-site?${setIncidentFilter(queryParams)}`,
  service: Service.sentinel,
  loading: Actions.GET_INCIDENT_COUNTS_BY_SITE_LOADING,
  success: Actions.GET_INCIDENT_COUNTS_BY_SITE_SUCCESS,
  error: Actions.GET_INCIDENT_COUNTS_BY_SITE_ERROR,
});

export const getIncidentCountsByEquipment = (
  queryParams: IncidentApiFilter
) => ({
  type: 'API_GET',
  path: `/api/v1/count/incidents/by-equipment?${setIncidentFilter(
    queryParams
  )}`,
  service: Service.sentinel,
  loading: Actions.GET_INCIDENT_COUNTS_BY_EQUIPMENT_LOADING,
  success: Actions.GET_INCIDENT_COUNTS_BY_EQUIPMENT_SUCCESS,
  error: Actions.GET_INCIDENT_COUNTS_BY_EQUIPMENT_ERROR,
});

export const getIncidents = (queryParams: IncidentApiFilter) => ({
  type: 'API_GET',
  path: `/api/v1/incidents?${setIncidentFilter(queryParams)}`,
  service: Service.sentinel,
  loading: Actions.GET_INCIDENTS_LOADING,
  success: Actions.GET_INCIDENTS_SUCCESS,
  error: Actions.GET_INCIDENTS_ERROR,
});

export const getIncidentsBySiteId = (queryParams: IncidentApiFilter) => {
  //queryParms accepts siteIds as array, so single site will be one element array
  const siteId = pathOr(undefined, [0], queryParams.siteIds);
  return {
    type: 'API_GET',
    path: `/api/v1/incidents?${setIncidentFilter(queryParams)}`,
    service: Service.sentinel,
    loading: {
      type: Actions.GET_INCIDENTS_BY_SITE_ID_LOADING,
      siteId,
    },
    success: {
      type: Actions.GET_INCIDENTS_BY_SITE_ID_SUCCESS,
      siteId,
    },
    error: {
      type: Actions.GET_INCIDENTS_BY_SITE_ID_ERROR,
      siteId,
    },
  };
};

export const getIncidentById = (incidentId: string) => {
  return {
    type: 'API_GET',
    path: `/api/v1/incidents/${incidentId}`,
    service: Service.sentinel,
    loading: {
      type: Actions.GET_INCIDENTS_BY_INCIDENT_ID_LOADING,
      incidentId,
    },
    success: {
      type: Actions.GET_INCIDENTS_BY_INCIDENT_ID_SUCCESS,
      incidentId,
    },
    error: {
      type: Actions.GET_INCIDENTS_BY_INCIDENT_ID_ERROR,
      incidentId,
    },
  };
};

export const redirectToIncidentPageEntry = (data) => {
  return {
    type: Actions.GET_REDIRECT_TO_INCIDENT_ENTRY,
    payload: data,
  };
};
export const isActiveIncident = (status) => {
  return {
    type: Actions.GET_ACTIVE_INCIDENT_TILE_STATUS,
    payload: status,
  };
};
