import {
  FilterTimePeriod,
  FlatNotification,
  Incident,
  Notification,
  SentinelType,
  SubscribedIncident,
} from '@energybox/react-ui-library/dist/types';
import { addHours, isWithinInterval, subHours } from 'date-fns';

export const doesNotificationHaveChartData = (sentinelType: SentinelType) => {
  return (
    sentinelType !== SentinelType.CONNECTIVITY &&
    sentinelType !== SentinelType.DOOR_SIREN_CHECK &&
    sentinelType !== SentinelType.BATTERY_LEVEL_CHECK &&
    sentinelType !== SentinelType.CONTROLS_HEALTH_CHECK &&
    sentinelType !== SentinelType.GATEWAY_ONLINE_STATUS &&
    sentinelType !== SentinelType.ENERGYPRO_ONLINE_STATUS &&
    sentinelType !== SentinelType.SITE_CONTROLLER_LOCAL_OVERRIDE_CHECK
  );
};

export const getIdsForNotificationChart = (
  featureNotif: Notification | undefined,
  featureIncident: Incident | SubscribedIncident | undefined
) => {
  let sentinelId = '-1';
  let notificationId = '-1';
  let siteId = '-1';

  if (featureNotif) {
    sentinelId = featureNotif.sentinelId;
    notificationId = featureNotif.id;
    siteId = featureNotif.siteId ? String(featureNotif.siteId) : '-1';
  } else if (featureIncident) {
    sentinelId = featureIncident.sentinelId;
    notificationId = featureIncident.incidentId;
    siteId = featureIncident.siteId ? String(featureIncident.siteId) : '-1';
  }

  return {
    notificationId,
    sentinelId,
    siteId,
  };
};

//If notification, then timeRange is +/- 12hrs of feature notification
//If incident, then timeRange is beginning of incident to when incident resolves (or current if not resolved)
export const determineFetchTimeRangeForNotificationChart = (
  featureNotification: Notification | undefined,
  featureIncident: Incident | SubscribedIncident | undefined
) => {
  let fromDate = 0;
  let toDate = 0;

  if (featureNotification) {
    const notificationDate = new Date(featureNotification.handlingLogs[0].at);
    fromDate = subHours(notificationDate, 12).valueOf();
    toDate = addHours(notificationDate, 12).valueOf();
  } else if (featureIncident) {
    fromDate = subHours(new Date(featureIncident.createdAt), 1).valueOf();
    toDate = featureIncident.recoveredAt
      ? addHours(new Date(featureIncident.recoveredAt), 1).valueOf()
      : new Date().valueOf();
  }

  return { fromDate, toDate };
};

export function filterNotificationsOutsideDesiredRange(
  notifications: Notification[],
  timePeriod: FilterTimePeriod
) {
  const { fromDate: start, toDate: end } = timePeriod;
  return notifications.filter((n: Notification) => {
    const mostRecentHandlingLogTime = new Date(
      n.handlingLogs[n.handlingLogs.length - 1].at
    );

    // Filter out notifications not within desired range for this TZ
    return isWithinInterval(mostRecentHandlingLogTime, { start, end });
  });
}

export function filterFlatNotificationsOutsideDesiredRange(
  flatNotifications: FlatNotification[],
  timePeriod: { from: string; to: string }
) {
  return flatNotifications.filter((n: FlatNotification) => {
    return isWithinInterval(Date.parse(n.at), {
      start: Date.parse(timePeriod.from),
      end: Date.parse(timePeriod.to),
    });
  });
}
