import { EnergyPro, SitesById } from '@energybox/react-ui-library/dist/types';
import pathOr from 'ramda/src/pathOr';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEnergyProBySiteId, getEnergyPros } from '../actions/energy';
import { getSites } from '../actions/sites';
import { ApplicationState } from '../reducers';
import { EnergyProBySiteId, EnergyProStatus } from '../reducers/energy';
import { sanitizeApiLoadingState } from '../util';

export default function useEnergyPro(siteId: string | number): EnergyProStatus {
  const energyPro: EnergyPro | undefined = useSelector(
    (state: ApplicationState) => {
      return pathOr(
        undefined,
        [siteId.toString(), 'data'],
        state.energy.energyProBySiteId
      );
    }
  );

  const isLoading: boolean | undefined = useSelector(
    (state: ApplicationState) => {
      return pathOr(
        undefined,
        [siteId.toString(), 'isLoading'],
        state.energy.energyProBySiteId
      );
    }
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoading === undefined) {
      dispatch(getEnergyProBySiteId(siteId));
    }
  }, [isLoading, dispatch, siteId]);

  return useMemo(
    () => ({
      isLoading: sanitizeApiLoadingState(isLoading),
      hasEnergyPro: !!energyPro,
      energyPro,
    }),
    [energyPro, isLoading]
  );
}

export const useOrgHasEnergyPro = () => {
  const dispatch = useDispatch();
  const energyProBySiteId = useSelector<ApplicationState, EnergyProBySiteId>(
    ({ energy }) => energy.energyProBySiteId
  );
  const sitesById = useSelector<ApplicationState, SitesById>(({ sites }) => {
    return sites.sitesById;
  });
  const [orgHasSomeEnergyData, setOrgHasSomeEnergyData] = useState(false);

  useEffect(() => {
    if (Object.keys(sitesById).length === 0) {
      dispatch(getSites());
    }
    if (Object.keys(energyProBySiteId).length === 0) {
      dispatch(getEnergyPros());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOrgHasSomeEnergyData(
      Object.keys(sitesById).some(
        (siteId) => energyProBySiteId[siteId] !== undefined
      )
    );
  }, [energyProBySiteId, sitesById]);

  return orgHasSomeEnergyData;
};
