const theme = {
  size: {
    table: {
      searchBox: {
        web: '15rem',
        mobile: '100%',
      },
    },
    radio: {
      icon: '1.25rem',
    },
  },
  space: {
    padding: {
      pageContent: {
        content: '1.25rem 3rem',
      },
    },
  },
};

export default theme;
