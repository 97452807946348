export enum Actions {
  GET_SITE_GROUPS_SUCCESS = '@siteGroups/GET_SITE_GROUPS_SUCCESS',
  GET_SITE_GROUPS_ERROR = '@siteGroups/GET_SITE_GROUPS_ERROR',
  GET_SITE_GROUPS_LOADING = '@siteGroups/GET_SITE_GROUPS_LOADING',
}

export const getSiteGroups = () => ({
  type: 'API_GET',
  path: '/api/v1/site-groups',
  success: Actions.GET_SITE_GROUPS_SUCCESS,
  error: Actions.GET_SITE_GROUPS_ERROR,
  loading: Actions.GET_SITE_GROUPS_LOADING,
});
