const apiBase = `/api/v1/users`;

export enum Actions {
  GET_USERS_SUCCESS = '@users/GET_USERS_SUCCESS',
  GET_USERS_ERROR = '@users/GET_USERS_ERROR',
  GET_USERS_LOADING = '@users/GET_USERS_LOADING',

  GET_USER_SUCCESS = '@users/GET_USER_SUCCESS',
  GET_USER_ERROR = '@users/GET_USER_ERROR',
  GET_USER_LOADING = '@users/GET_USER_LOADING'
}

//Endpoint DOES NOT return userGroup info (field name: group)
export const getUsers = (userIds?: (string | number)[]) => ({
  type: 'API_GET',
  path: `${apiBase}?${userIds ? `ids=${userIds.join()}` : ''}`,
  success: Actions.GET_USERS_SUCCESS,
  error: Actions.GET_USERS_ERROR,
  loading: Actions.GET_USERS_LOADING
});

//Endpoint DOES return userGroup info (field name: group)
export const getUser = (userId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/${userId}`,
  success: Actions.GET_USER_SUCCESS,
  error: Actions.GET_USER_ERROR,
  loading: Actions.GET_USER_LOADING
});
