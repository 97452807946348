import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSite as getSiteById } from '../actions/sites';
import { ApplicationState } from '../reducers';

const useIsNewTsdb = (siteId: number | string): boolean => {
  const dispatch = useDispatch();
  const getSite = useCallback(
    () => dispatch(getSiteById(siteId)),
    [dispatch, siteId]
  );

  const site = useSelector(
    ({ sites }: ApplicationState) => sites.sitesById[siteId]
  );

  useEffect(() => {
    if (!site) {
      getSite();
    }
  }, [getSite, site]);

  return (site && site.newTsdb) || false;
};

export default useIsNewTsdb;

export const useGetSiteById = (siteId) => {
  const dispatch = useDispatch();
  const getSite = useCallback(
    () => dispatch(getSiteById(siteId)),
    [dispatch, siteId]
  );

  const site = useSelector(
    ({ sites }: ApplicationState) => sites.sitesById[siteId]
  );

  useEffect(() => {
    if (!site) {
      getSite();
    }
  }, [getSite, site]);

  return site;
};
