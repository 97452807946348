import {
  CurrentUser,
  DoorValues,
  Locale,
  OperationalSample,
  ResourceType,
  SensorParamType,
  SensorType,
  SentinelType,
} from '@energybox/react-ui-library/dist/types';
import {
  classNames,
  createTemperatureString,
  formatEpochDate,
  formatKwValues,
  formatNumberString,
  getUnixTimestamp,
  global,
  isDefined,
  isNumber,
  toFahrenheit,
} from '@energybox/react-ui-library/dist/utils';

import React from 'react';
import { connect } from 'react-redux';
import { getOperationalSamplesBySensorIdForValueChip } from '../../../actions/samples';
import { ApplicationState } from '../../../reducers';
import styles from './ValueChip.module.css';
import { DateTime } from 'luxon';

interface OwnProps {
  className?: string;
  resourceId: string;
  resourceType:
    | ResourceType.EQUIPMENT
    | ResourceType.SENSOR
    | ResourceType.SPACE;
  updatedAt?: string;
  secondaryText?: string;
  predeterminedValue?: string | number | boolean;
  inlineLabel?: boolean;
  secondaryTextPosition?: 'before' | 'after';
  types: SensorType[] | SentinelType[] | SensorParamType[];
}

interface Props extends OwnProps {
  currentUser?: CurrentUser;
  locale: Locale;
  getOperationalSamples: (updatedAt: string, sensorId: string) => void;
  operationalSamples: OperationalSample[];
  isLoading: boolean;
}

const findMostRecentSensorValueByType = (
  sensorType: SensorType,
  operationalSamples: OperationalSample[]
): OperationalSample | undefined => {
  const filteredSamples = operationalSamples.filter(
    ({ type }) => type === sensorType
  );
  if (filteredSamples.length === 0) return undefined;
  return filteredSamples.reduce(
    (acc, cur) => {
      if (!acc) return cur;
      if (cur.timestamp > acc.timestamp) return cur;
      return acc;
    },
    { timestamp: 0, value: false, sensorId: '', type: sensorType }
  );
};

class ValueChip extends React.Component<Props> {
  componentDidMount() {
    const {
      getOperationalSamples,
      updatedAt,
      predeterminedValue,
      resourceId,
      resourceType,
    } = this.props;

    predeterminedValue === undefined &&
      resourceType === ResourceType.SENSOR &&
      updatedAt &&
      getOperationalSamples(updatedAt, resourceId);
  }

  shouldComponentUpdate(nextProps) {
    if (
      this.props.predeterminedValue &&
      this.props.predeterminedValue === nextProps.predeterminedValue
    ) {
      if (!this.props.secondaryText && !nextProps.secondaryText) {
        return false;
      }
      return this.props.secondaryText !== nextProps.secondaryText;
    }
    return true;
  }

  renderSampleValue = (
    operationalSamples: OperationalSample[],
    type: SensorType[],
    locale: Locale
  ): { date: string; value: string } => {
    const { currentUser } = this.props;
    const { fullDateTimeFormat } = locale;
    let returnObject = {
      date: '',
      value: global.NOT_AVAILABLE as string,
    };

    if (operationalSamples.length === 0 && this.props.isLoading)
      return {
        date: '',
        value: 'Loading...',
      };

    if (operationalSamples.length === 0) return returnObject;

    // Let this cascade to the next if case for sensors with both temp and humidity.
    if (type.indexOf(SensorType.TEMPERATURE) > -1) {
      const sensorReading = findMostRecentSensorValueByType(
        SensorType.TEMPERATURE,
        operationalSamples
      );

      if (!sensorReading || !currentUser) return returnObject;

      returnObject.date = formatEpochDate(
        sensorReading.timestamp,
        fullDateTimeFormat
      );
      returnObject.value = createTemperatureString(
        Number(sensorReading.value),
        currentUser
      );
    }

    if (type.indexOf(SensorType.HUMIDITY) > -1) {
      const sensorReading = findMostRecentSensorValueByType(
        SensorType.HUMIDITY,
        operationalSamples
      );
      if (
        returnObject.value !== (global.NOT_AVAILABLE as string) &&
        sensorReading
      ) {
        returnObject.value = `${returnObject.value} @ ${sensorReading.value}%`;
        returnObject.date = formatEpochDate(
          sensorReading.timestamp,
          fullDateTimeFormat
        );
        return returnObject;
      }

      if (sensorReading) {
        returnObject.value = `${sensorReading.value}%`;
        returnObject.date = formatEpochDate(
          sensorReading.timestamp,
          fullDateTimeFormat
        );
      }
    }

    if (type.indexOf(SensorType.BINARY) > -1) {
      const sensorReading = findMostRecentSensorValueByType(
        SensorType.BINARY,
        operationalSamples
      );
      if (!sensorReading) return returnObject;
      returnObject.value = DoorValues[String(sensorReading.value)];
      returnObject.date = formatEpochDate(
        sensorReading.timestamp,
        fullDateTimeFormat
      );
    }
    // we return formatedString in the case temp does not cascade to humidity
    return returnObject;
  };

  render() {
    const {
      currentUser,
      className,
      types,
      secondaryText,
      locale,
      operationalSamples,
      predeterminedValue,
      inlineLabel = false,
      secondaryTextPosition = 'after',
    } = this.props;
    if (!currentUser) return null;

    const renderPredeterminedValue = () => {
      switch (types[0]) {
        case SentinelType.TEMPERATURE:
        case SensorType.TEMPERATURE:
          return {
            value: isDefined(predeterminedValue)
              ? createTemperatureString(+predeterminedValue, currentUser)
              : global.NOT_AVAILABLE,
          };
        case SentinelType.HUMIDITY:
        case SensorType.HUMIDITY:
          return {
            value: formatNumberString(predeterminedValue as number, {
              unit: '%',
            }),
          };
        case SensorType.BINARY:
        case SentinelType.BINARY:
          if (typeof predeterminedValue === 'number') {
            return { value: predeterminedValue };
          } else if (typeof predeterminedValue === 'boolean') {
            return { value: DoorValues[String(predeterminedValue)] };
          } else if (
            predeterminedValue === 'true' ||
            predeterminedValue === 'false'
          ) {
            return { value: DoorValues[predeterminedValue] };
          } else {
            return { value: global.NOT_AVAILABLE };
          }
        case SentinelType.CONNECTIVITY:
          if (predeterminedValue === 'false' || predeterminedValue === false) {
            return { value: 'Offline' };
          } else {
            return { value: 'Online' };
          }

        case SentinelType.DOOR_SIREN_CHECK:
          const doorState = (predeterminedValue as String).includes('open')
            ? 'Open'
            : 'Closed';
          const sirenState = (predeterminedValue as String).includes(
            'deactivated'
          )
            ? 'Deactivated'
            : 'Activated';
          return {
            value: (
              <>
                <div>{doorState}</div>
                <div>{sirenState}</div>
              </>
            ),
          };

        case SentinelType.ACTIVE_POWER_THRESHOLD:
        case SensorParamType.ACTIVE_POWER:
          let numberValue = Number(predeterminedValue);
          return {
            value: numberValue === 0 ? 0 : formatKwValues(numberValue),
          };

        default:
          return { value: predeterminedValue };
      }
    };

    const valueObject: { value: string; date?: string } =
      predeterminedValue !== undefined
        ? renderPredeterminedValue()
        : this.renderSampleValue(
            operationalSamples,
            types as SensorType[],
            locale
          );

    return (
      <span className={classNames(styles.sensorIconAndTextGrouping, className)}>
        <span
          className={classNames(
            styles.sensorTypeText,
            inlineLabel ? styles.inlineLabel : ''
          )}
        >
          {secondaryText && secondaryTextPosition === 'before' && (
            <div className={styles.secondaryTextBefore}>{secondaryText} </div>
          )}
          <span title={valueObject.date && valueObject.date}>
            {valueObject.value}
          </span>
          {secondaryText && secondaryTextPosition === 'after' && (
            <div className={styles.secondaryTextAfter}> {secondaryText}</div>
          )}
        </span>
      </span>
    );
  }
}

const mapStateToProps = (
  { app, operationalSamples }: ApplicationState,
  { resourceId, resourceType }: OwnProps
) => {
  const isLoading =
    resourceType === ResourceType.SENSOR
      ? operationalSamples.isLoadingValueChipByResourceId[resourceId]
      : false;
  const operationalSamplesArray =
    resourceType === ResourceType.SENSOR
      ? operationalSamples.valueChipOperationalSamplesBySensorId[resourceId]
      : [];
  return {
    currentUser: app.currentUser,
    locale: app.locale,
    isLoading,
    operationalSamples: operationalSamplesArray,
  };
};

const ISOStringAddHours = (timestamp: string, hours: number) => {
  return DateTime.fromISO(timestamp)
    .plus({ hours: hours })
    .toString();
};

const mapDispatchToProps = (dispatch) => ({
  getOperationalSamples: (updatedAt: string, sensorId: string) =>
    dispatch(
      getOperationalSamplesBySensorIdForValueChip(sensorId, {
        sensorId: sensorId as string,
        from: ISOStringAddHours(updatedAt, -1),
        to: ISOStringAddHours(updatedAt, 1),
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ValueChip);
