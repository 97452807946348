import { GenericFilter } from '@energybox/react-ui-library/dist/components';
import { IncidentStatus } from '@energybox/react-ui-library/dist/types';
import { capitalizeFirstLetterOnly } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import useDynamicFilter from '../../../hooks/useDynamicFilter';

const IncidentStatusFilter: React.FC = () => {
  const { selectedFilters: selectedIncidentStatusFilters, setFilter } =
    useDynamicFilter<string>('incidentStatus');

  return (
    <GenericFilter
      title="Status"
      setFilter={setFilter}
      items={Object.values(IncidentStatus)}
      transformItemName={capitalizeFirstLetterOnly}
      selectedItems={selectedIncidentStatusFilters}
    />
  );
};

export default IncidentStatusFilter;
