import React from 'react';
import BaseFilterBar from '../Filters/BaseFilterBar';
import { Link } from 'react-router-dom';
import styles from './TopNavigationBar.module.css';

type Props = {
  path: string;
  className?: string;
};

const TopNavigationBar = ({ path, className }: Props) => {
  return (
    <BaseFilterBar className={className}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className={path === 'sites' ? styles.currentTab : styles.tab}>
          <Link to={'/sites'} className={styles.topLink}>
            List
          </Link>
        </div>
        <div className={path === 'map' ? styles.currentTab : styles.tab}>
          <Link to={'/map'} className={styles.topLink}>
            Map
          </Link>
        </div>
      </div>
    </BaseFilterBar>
  );
};

export default TopNavigationBar;
