const apiBase = `/api/v1/groups`;

export enum Actions {
  GET_USER_GROUPS_SUCCESS = '@users/GET_USER_GROUPS_SUCCESS',
  GET_USER_GROUPS_ERROR = '@users/GET_USER_GROUPS_ERROR',
  GET_USER_GROUPS_LOADING = '@users/GET_USER_GROUPS_LOADING'
}

export const getUserGroups = (userGroupIds?: (string | number)[]) => ({
  type: 'API_GET',
  path: `${apiBase}?${userGroupIds ? `ids=${userGroupIds.join()}` : ''}`,
  success: Actions.GET_USER_GROUPS_SUCCESS,
  error: Actions.GET_USER_GROUPS_ERROR,
  loading: Actions.GET_USER_GROUPS_LOADING
});
