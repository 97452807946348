import {
  classNames,
  deriveDateString,
} from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import useAppLocale from '../../../hooks/useAppLocale';
import { renderTime } from '../../IncidentsCardList/IncidentsCardList';
import styles from './DateChip.module.css';
import {
  ViewportTypes,
  useViewportType,
} from '@energybox/react-ui-library/dist/hooks';

type Props = {
  ianaTimeZoneCode?: string;
  value?: string;
  isTimeFrom?: boolean;
  prefixText?: string;
  suffixText?: string;
  extraClassNames?: string[];
};

const DateChip = ({
  ianaTimeZoneCode,
  value,
  prefixText = '',
  suffixText = '',
  isTimeFrom = false,
  extraClassNames = [],
}: Props) => {
  const locale = useAppLocale();
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  return (
    <div className={styles.root}>
      <div className={classNames(...extraClassNames)}>
        {isTimeFrom ? (
          `${prefixText} ${deriveDateString(
            value,
            locale,
            isTimeFrom,
            ianaTimeZoneCode
          )} ${suffixText}`
        ) : (
          <>
            {renderTime(
              isMobile,
              value,
              locale,
              'left',
              '',
              ianaTimeZoneCode,
              true
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DateChip;
