import {
  IconEquipmentFactory,
  Select,
  SelectItem,
} from '@energybox/react-ui-library/dist/components';
import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  EquipmentEnergyByTypeId,
  EquipmentGroup,
  EquipmentTypeEnergy,
  OrgEquipmentTypeEnergy,
} from '@energybox/react-ui-library/dist/types';
import {
  formatKwhValues,
  KWH_UNIT,
  shortenString,
} from '@energybox/react-ui-library/dist/utils';

import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import BlurredInfo from '../../../components/Tile/BlurredInfo';
import Tile from '../../../components/Tile/Tile';
import TileContent from '../../../components/Tile/TileContent';
import TileHeader from '../../../components/Tile/TileHeader';
import TileHeaderMoreInfoContent from '../../../components/Tile/TileHeaderMoreInfoContent/TileHeaderMoreInfoContent';
import { useOrgEquipmentTypeEnergyByGroupId } from '../../../hooks/useAnalytics';
import { useEquipmentGroupsMatcher } from '../../../hooks/useEquipmentGroups';
import { formatNumber } from '../../../util';
import styles from './EquipmentGroupEnergyTile.module.css';

type Props = {
  className?: string;
  equipmentGroup: string;
};

type EquipmentTypeFilter = {
  equipmentTypeId: number;
  equipmentTypeTitle: string;
};

interface ProcessedEquipmentTypeEnergy extends EquipmentTypeEnergy {
  siteId: string;
}

const EquipmentGroupEnergyTile = ({ className, equipmentGroup }: Props) => {
  /*********** STATES ************/
  const [selectedEquipmentType, setSelectedEquipmentType] = useState<
    number | undefined
  >(undefined);

  /*********** SELECTORS ************/
  const equipmentGroupInfo = useEquipmentGroupsMatcher(
    new RegExp(equipmentGroup)
  );

  const {
    isLoading: reportsAreLoading,
    reportsByTypeId,
    equipmentTypeFilters,
  } = useEquipmentGroupEnergyData(equipmentGroupInfo);

  const selectedReport: OrgEquipmentTypeEnergy | undefined =
    selectedEquipmentType ? reportsByTypeId[selectedEquipmentType] : undefined;

  /*********** EFFECTS ************/
  useEffect(() => {
    if (selectedReport !== undefined) return;
    // when date filter changes
    // resets the selected equipment type if the selected type has no data
    if (equipmentTypeFilters.length > 0)
      setSelectedEquipmentType(equipmentTypeFilters[0].equipmentTypeId);
  }, [equipmentTypeFilters, selectedReport]);

  /*********** LOCAL VARIABLES, PROCESS DATA ************/
  const sitesEquipmentTypeEnergy: ProcessedEquipmentTypeEnergy[] =
    useMemo(() => {
      if (selectedReport === undefined) return [];
      // add siteId field to site energy data to pass into table.
      const reportsEmbeddedWithSiteId: ProcessedEquipmentTypeEnergy[] =
        Object.keys(selectedReport.by_site)
          .map((siteId) => ({
            ...selectedReport.by_site[siteId],
            siteId,
          }))
          .sort(
            (
              site1: ProcessedEquipmentTypeEnergy,
              site2: ProcessedEquipmentTypeEnergy
            ) => site2.kwh_consumption - site1.kwh_consumption
          )
          .slice(0, 5);
      return reportsEmbeddedWithSiteId;
    }, [selectedReport]);

  const renderSelect = () => {
    if (!selectedReport) return null;

    return (
      <Select
        className={styles.select}
        title={selectedReport.type_title}
        iconPosition="left"
        noBottomLine
      >
        {equipmentTypeFilters.map(
          ({ equipmentTypeId: typeId, equipmentTypeTitle: typeTitle }) => (
            <SelectItem
              key={`${equipmentGroup}${typeId}filter`}
              onSelect={() => setSelectedEquipmentType(typeId)}
              variant="outlined"
              isSelected={typeId === selectedEquipmentType}
            >
              {typeTitle}
            </SelectItem>
          )
        )}
      </Select>
    );
  };

  const renderAvgEnergyInfo = () => {
    if (!selectedReport) return null;
    return <AverageTypeEnergyLabel value={selectedReport.avg_type_kwh} />;
  };

  const renderIcon = (equipmentGroupAlias: string) => {
    return <IconEquipmentFactory id={equipmentGroupAlias} size={40} />;
  };

  if (equipmentGroupInfo === undefined) return null;
  if (!reportsAreLoading && sitesEquipmentTypeEnergy.length === 0) {
    return (
      <Tile className={className} isLoading={reportsAreLoading}>
        <BlurredInfo
          title={equipmentGroup + ' Energy'}
          errorMessage={
            'There is no energy data available in the time period selected'
          }
        />
      </Tile>
    );
  }

  const tooltipDescription = `This tile shows the sites which have the highest energy consumption of
  ${equipmentGroup}, for the time period selected. The tile also shows the average consumption of
  the selected equipment type in your organization.`;

  const moreInfo = (
    <TileHeaderMoreInfoContent
      howToUseContent="This metric is one way to catch any sites that are inefficiently using a
particular equipment type in your organization. For example, if most of your sites are using
400 kWh a month in operating a dishwasher whilst one site is using 800 kWh, you can clearly
see that this site is using the dishwater far too much in comparison. You can further explore
to see why this might be occurring."
      howCalculatedContent={`It is calculated by adding the energy consumption of all the
equipment of type ${equipmentGroup} on the site.`}
    />
  );

  return (
    <Tile className={className} isLoading={reportsAreLoading}>
      <TileHeader
        className={styles.titleText}
        title={equipmentGroup}
        tooltipDescription={tooltipDescription}
        moreInfo={moreInfo}
        icon={renderIcon(equipmentGroupInfo.alias)}
        extraLeftAlignContent={renderSelect()}
        extraRightAlignContent={renderAvgEnergyInfo()}
      />

      <TileContent>
        <Table
          className={styles.topTable}
          columns={top5EnergyColumns}
          data={sitesEquipmentTypeEnergy}
        />
      </TileContent>
    </Tile>
  );
};

const useEquipmentGroupEnergyData = (equipmentGroup?: EquipmentGroup) => {
  /*********** SELECTORS ************/
  const { equipmentEnergy, isLoading } = useOrgEquipmentTypeEnergyByGroupId();

  /*********** PROCESS DATA ************/
  const EQUIPMENT_TYPE_ID_TOTAL = -1;
  const reportsByTypeId: EquipmentEnergyByTypeId = useMemo(() => {
    if (equipmentGroup !== undefined && !isLoading) {
      const { id: equipmentGroupId } = equipmentGroup;
      const allEquipmentTypesInGroup = equipmentEnergy[equipmentGroupId] || {};
      const equipmentTypeIds = Object.keys(allEquipmentTypesInGroup);

      if (!equipmentTypeIds.length) {
        return {};
      }

      const reportForTotal = equipmentTypeIds.reduce(
        (acc, id) => {
          const equipmentType = allEquipmentTypesInGroup[id];
          Object.keys(equipmentType.by_site).forEach((siteId) => {
            if (acc.by_site[siteId]) {
              acc.by_site[siteId].num_equipment +=
                equipmentType.by_site[siteId].num_equipment;
              acc.by_site[siteId].kwh_consumption +=
                equipmentType.by_site[siteId].kwh_consumption;
            } else {
              acc.by_site[siteId] = { ...equipmentType.by_site[siteId] };
            }
          });
          return acc;
        },
        {
          avg_type_kwh: 0,
          type_group_id: EQUIPMENT_TYPE_ID_TOTAL,
          type_group_title:
            allEquipmentTypesInGroup[equipmentTypeIds[0]]?.type_group_title ||
            '',
          type_title: 'Total',
          by_site: {},
        } as OrgEquipmentTypeEnergy
      );
      // calculate average energy consumption per site
      const siteIdsForTotal = Object.keys(reportForTotal.by_site);
      const totalEnergyConsumption = siteIdsForTotal.reduce(
        (acc, siteId) => acc + reportForTotal.by_site[siteId].kwh_consumption,
        0
      );
      reportForTotal.avg_type_kwh = siteIdsForTotal.length
        ? totalEnergyConsumption / siteIdsForTotal.length
        : 0;

      return {
        [EQUIPMENT_TYPE_ID_TOTAL]: reportForTotal,
        ...allEquipmentTypesInGroup,
      };
    }
    return {};
  }, [equipmentGroup, isLoading, equipmentEnergy]);

  const equipmentTypeFilters: EquipmentTypeFilter[] = useMemo(() => {
    return Object.keys(reportsByTypeId)
      .map((equipmentTypeId) => {
        const equipmentTypeEnergy: OrgEquipmentTypeEnergy =
          reportsByTypeId[equipmentTypeId];
        const result: EquipmentTypeFilter = {
          equipmentTypeId: +equipmentTypeId,
          equipmentTypeTitle: equipmentTypeEnergy.type_title,
        };
        return result;
      })
      .sort((e1, e2) => {
        if (e1.equipmentTypeId === EQUIPMENT_TYPE_ID_TOTAL) {
          return -1;
        }
        if (e2.equipmentTypeId === EQUIPMENT_TYPE_ID_TOTAL) {
          return 1;
        }
        return e1.equipmentTypeTitle.localeCompare(e2.equipmentTypeTitle);
      });
  }, [reportsByTypeId]);

  return {
    isLoading,
    reportsByTypeId,
    equipmentTypeFilters,
  };
};

const top5EnergyColumns: Columns<ProcessedEquipmentTypeEnergy>[] = [
  {
    header: '#',
    cellContent: (_, rowIndex) => (
      <span className={styles.index}>{rowIndex + 1}</span>
    ),
    width: '0.2rem',
  },
  {
    header: 'Site',
    cellContent: ({ site_title, siteId }: ProcessedEquipmentTypeEnergy) => (
      <Link to={`/sites/${siteId}`}>{shortenString(site_title, 12)}</Link>
    ),
  },
  {
    header: 'No. of Equ.',
    rightAlignContent: true,
    cellContent: ({ num_equipment }: ProcessedEquipmentTypeEnergy) => (
      <div>{num_equipment}</div>
    ),
    width: '5.5rem',
  },
  {
    header: `Total (${KWH_UNIT})`,
    rightAlignContent: true,
    cellContent: ({ kwh_consumption }: ProcessedEquipmentTypeEnergy) => (
      <div>{formatNumber(kwh_consumption, 1)}</div>
    ),
    width: '5.5rem',
  },
];

const AverageTypeEnergyLabel = ({ value }: { value: number }) => {
  return (
    <div className={styles.labelRoot}>
      <div className={styles.labelHeader}>
        {' '}
        Average Energy Consumption Per Site
      </div>
      <div className={styles.labelValue}>{formatKwhValues(value)}</div>
    </div>
  );
};

export default EquipmentGroupEnergyTile;
