import { Service } from '../config';
import {
  CommentApiFilter,
  NewCommentFields,
  ShowNewCommentPopupPayload,
} from '@energybox/react-ui-library/dist/types';

const apiBase = '/api/v1/comments';
export enum Actions {
  GET_COMMENTS_SUCCESS = '@@comments/GET_COMMENTS_SUCCESS',
  GET_COMMENTS_LOADING = '@@comments/GET_COMMENTS_LOADING',
  GET_COMMENTS_ERROR = '@@comments/GET_COMMENTS_ERROR',

  POST_COMMENT_SUCCESS = '@@comments/POST_COMMENT_SUCCESS',
  POST_COMMENT_LOADING = '@@comments/POST_COMMENT_LOADING',
  POST_COMMENT_ERROR = '@@comments/POST_COMMENT_ERROR',

  SHOW_NEW_COMMENT_POPUP = '@@comments/SHOW_NEW_COMMENT_POPUP',
  HIDE_NEW_COMMENT_POPUP = '@@comments/HIDE_NEW_COMMENT_POPUP',
  UPDATE_FIELD = '@@comments/UPDATE_FIELD',

  GET_DOWNSTREAM_COMMENTS_LOADING = '@@resources/GET_DOWNSTREAM_COMMENTS_LOADING',
  GET_DOWNSTREAM_COMMENTS_SUCCESS = '@@resources/GET_DOWNSTREAM_COMMENTS_SUCCESS',
  GET_DOWNSTREAM_COMMENTS_ERROR = '@@resources/GET_DOWNSTREAM_COMMENTS_ERROR',

  SET_COMMENT_FILTER_FIELD = '@@comments/SET_COMMENT_FILTER_FIELD',

  RESET_COMMENT_FILTER_FIELD = '@@comments/RESET_COMMENT_FILTER_FIELD',

  RESET_ALL_COMMENT_FILTERS = '@@comments/RESET_ALL_COMMENT_FILTERS',
}

const setCommentFilter = (filter?: CommentApiFilter): string => {
  const queryParams = new URLSearchParams();
  if (!filter) return queryParams.toString();

  // To and from must be iso timestamps

  const {
    from,
    to,
    resourceType,
    commentType,
    valueType,
    orgId,
    siteId,
    userId,
    query,
  } = filter;

  if (from) {
    queryParams.set('from', from);
  }

  if (to) {
    queryParams.set('to', to);
  }

  if (resourceType && resourceType.length > 0) {
    queryParams.set('resourceType', resourceType.join(','));
  }

  if (commentType && commentType.length > 0) {
    queryParams.set('commentType', commentType.join(','));
  }

  if (valueType && valueType.length > 0) {
    queryParams.set('valueType', valueType.join(','));
  }

  if (orgId) {
    queryParams.set('orgId', orgId.toString());
  }

  if (siteId && siteId.length > 0) {
    queryParams.set('siteId', siteId.join(','));
  }

  if (userId && userId.length > 0) {
    queryParams.set('userId', userId.join(','));
  }

  if (query) {
    queryParams.set('query', query);
  }

  return queryParams.toString();
};

export const getComments = (
  resourceId: number | string,
  filter?: CommentApiFilter
) => ({
  type: 'API_GET',
  service: Service.comments,
  path: `${apiBase}/resource/${resourceId}?${setCommentFilter(filter)}`,
  loading: {
    type: Actions.GET_COMMENTS_LOADING,
    resourceId,
  },
  success: {
    type: Actions.GET_COMMENTS_SUCCESS,
    resourceId,
  },
  error: {
    type: Actions.GET_COMMENTS_ERROR,
    resourceId,
  },
});

export const create = (
  commentPayload: NewCommentFields,
  parentId?: string | number
) => {
  const resourceId_resourceType = `${commentPayload.resourceId}_${commentPayload.valueType}`;
  return {
    type: 'API_POST',
    service: Service.comments,
    path: apiBase,
    payload: commentPayload,
    loading: {
      type: Actions.POST_COMMENT_LOADING,
      resourceId_resourceType,
    },
    success: {
      type: Actions.POST_COMMENT_SUCCESS,
      resourceId_resourceType,
      parentId,
    },
    error: {
      type: Actions.POST_COMMENT_ERROR,
      resourceId_resourceType,
    },
  };
};

export const updateField = (
  resourceId_resourceType: string,
  field: string,
  value: any
) => ({
  type: Actions.UPDATE_FIELD,
  resourceId_resourceType,
  field,
  value,
});

export const showNewCommentPopup = (
  resourceId_resourceType: string,
  payload: ShowNewCommentPopupPayload
) => ({
  type: Actions.SHOW_NEW_COMMENT_POPUP,
  resourceId_resourceType,
  payload,
});

export const hideNewCommentPopup = (resourceId_resourceType: string) => ({
  type: Actions.HIDE_NEW_COMMENT_POPUP,
  resourceId_resourceType,
});

//gets comments directly attached to Resource (i.e. equipment),
//as well as for other resources (i.e. sensors) attached to that Resource
export const getDownstreamComments = (
  resourceId: number | string,
  filter?: CommentApiFilter
) => ({
  type: 'API_GET',
  service: Service.comments,
  path: `${apiBase}/resource/${resourceId}/downstream?${setCommentFilter(
    filter
  )}`,
  loading: { type: Actions.GET_DOWNSTREAM_COMMENTS_LOADING, resourceId },
  success: { type: Actions.GET_DOWNSTREAM_COMMENTS_SUCCESS, resourceId },
  error: { type: Actions.GET_DOWNSTREAM_COMMENTS_ERROR, resourceId },
});

export const setCommentFilterField = (field: string, value: any) => ({
  type: Actions.SET_COMMENT_FILTER_FIELD,
  field,
  value,
});

export const resetCommentFilter = (field: string) => ({
  type: Actions.RESET_COMMENT_FILTER_FIELD,
  field,
});

export const resetAllCommentFilters = () => ({
  type: Actions.RESET_ALL_COMMENT_FILTERS,
});
