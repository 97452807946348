import {
  FilterTimePeriod,
  IsoDateFormat,
} from '@energybox/react-ui-library/dist/types';
import { genericDateFormat } from '@energybox/react-ui-library/dist/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  notifyDownload,
  toggleShowHideIcon,
  triggerToReport,
} from '../../actions/reportworker';
import { ApplicationState } from '../../reducers';
import { PageStatus } from '../../reducers/reportworker';
import {
  ReportStatus,
  ReportTemplate,
  ReportType,
  StatusDetail,
  ToReportRequestBody,
} from '../../types/reportworker';
import { useOrganizationId } from '../useCurrentUser';
import { useSite } from '../useSites';

interface TiggerParams {
  reportType: ReportType;
  timePeriod: FilterTimePeriod;
  reportTemplate: ReportTemplate;
  equipmentGroupId?: number;
  filterSiteIds?: number[];
  filterEquipmentTypeId?: number[];
  filterVendor?: string[];
}

export const useToReport = (
  siteId?: string | number
): {
  status: StatusDetail | null;
  trigger: (p: TiggerParams) => void;
} => {
  const location = useLocation();
  const orgId = useOrganizationId();
  const dispatch = useDispatch();
  const site = useSite(siteId);
  const pageStatus = useSelector<ApplicationState, PageStatus>(
    ({ reportworker }) => reportworker.pageStatus
  );

  const getRequestOrgId = (reportTemplate: ReportTemplate) => {
    switch (reportTemplate) {
      case ReportTemplate.ENERGY_CONSUMPTION:
        return undefined;
      default:
        return orgId;
    }
  };

  const trigger = (params: TiggerParams) => {
    const fromDateString = genericDateFormat(
      params.timePeriod.fromDate,
      IsoDateFormat,
      site?.timeZone
    );
    const toDateString = genericDateFormat(
      params.timePeriod.toDate,
      IsoDateFormat,
      site?.timeZone
    );

    dispatch(
      triggerToReport({
        page: location.pathname,
        reportType: params.reportType,
        requestBody: {
          reportTemplate: params.reportTemplate,
          siteId,
          organizationId: getRequestOrgId(params.reportTemplate),
          equipmentGroupId: params.equipmentGroupId,
          reportFromDate: fromDateString,
          reportToDate: toDateString,
          searchFilter: {
            siteIds:
              (params.filterSiteIds?.length ?? 0) > 0
                ? params.filterSiteIds
                : undefined,
            equipmentTypeId:
              (params.filterEquipmentTypeId?.length ?? 0) > 0
                ? params.filterEquipmentTypeId
                : undefined,
            vendor:
              (params.filterVendor?.length ?? 0) > 0
                ? params.filterVendor
                : undefined,
          },
        },
      })
    );

    dispatch(toggleShowHideIcon(true));
    dispatch(notifyDownload(true));
  };

  return {
    trigger: trigger,
    status: pageStatus[location.pathname] ?? null,
  };
};
