import {
  MapTimeFormat,
  TimeFormat,
} from '@energybox/react-ui-library/dist/types';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';

export function useApp() {
  return useSelector(({ app }: ApplicationState) => app);
}

export default function useAppLocale() {
  const locale = useSelector(({ app }: ApplicationState) => app.locale);
  return locale;
}

export const useIs12HrTimeFormat = () => {
  const timeFormat = useSelector<ApplicationState, string>(({ app }) => {
    return app.locale.timeFormat;
  });

  return timeFormat === MapTimeFormat[TimeFormat.HOUR_12];
};
