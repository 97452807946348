import { Role, User, UsersById } from '@energybox/react-ui-library/dist/types';
import { mapScopeToAccessResource } from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';
import { Actions } from '../actions/users';
import { mapArrayToObject, mapValues } from '../util';

export interface Users {
  usersById: UsersById;
}

export type ActionsLoadingStatus = {
  [Actions.GET_USERS_LOADING]?: boolean;
};

function userFromApiResponse(data: any): User {
  return {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    lastLogin: data.lastLoginAt,
    role: Role[data.role],
    position: data.position || '',
    timeZone: data.timeZone,
    measurementSystem: data.measurementSystem,
    organizationUnitTitle: data.organizationUnit && data.organizationUnit.title,
    organizationUnitId: data.organizationUnit && data.organizationUnit.id,
    accessResources: data.scopes.map(mapScopeToAccessResource),
    kiosk: data.kiosk,
    groups: data.groups,
    groupIds: data.groups ? data.groups.map((g) => g.id) : [],
    userStatus: data.userStatus,
  };
}

export const initialState = {
  usersById: {},
};

export const users = (state: Users = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_USERS_SUCCESS:
      return R.pipe(
        R.assoc(
          'usersById',
          R.mergeRight(
            R.view(R.lensProp('usersById'), state),
            mapArrayToObject(mapValues(action.payload, userFromApiResponse))
          )
        )
      )(state);

    case Actions.GET_USER_SUCCESS:
      return R.pipe(
        R.assoc(
          'usersById',
          R.mergeRight(
            R.view(R.lensProp('usersById'), state),
            mapArrayToObject([userFromApiResponse(action.payload)])
          )
        )
      )(state);

    default:
      return state;
  }
};

export default users;
