import React from 'react';
import styles from './Footer.module.css';

function Footer() {
  const shouldShowFooter =
    process.env.REACT_APP_SERVICE_ENV === 'az-china-production' ||
    process.env.REACT_APP_SERVICE_ENV === 'az-china-staging';
  return shouldShowFooter ? (
    <div className={styles.container}>沪ICP备2021009512号-2</div>
  ) : (
    <></>
  );
}

export default Footer;
