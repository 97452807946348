import React from 'react';

export default () => {
  const icon = (
    <g>
      <g>
        <path
          opacity="0.1"
          fill="#05A1B0"
          d="M53.6,37.8c-4.4-4.4-9.6-6.9-13.4-6.9c-1.7,0-3.1,0.5-4.2,1.5c-0.5,0.5-0.9,1.1-1.1,1.8L15.4,67.8
				c-1.3,1.5-0.6,4.2,1.7,6.5c1,1,2.2,1.8,3.4,2.1c0.5,0.1,0.9,0.2,1.3,0.2c0.7,0,1.3-0.2,1.8-0.6l33.6-19.5
				c0.7-0.2,1.3-0.6,1.8-1.1C62.3,52.1,59.9,44.2,53.6,37.8z"
        />
        <path
          fill="#05A1B0"
          d="M47.4,22.4C47.4,22.4,47.4,22.4,47.4,22.4c0.3,0,0.5-0.2,0.5-0.5c0.2-3.8,2.6-7.3,6-9
				c0.2-0.1,0.3-0.4,0.2-0.7C54,12,53.7,11.9,53.5,12c-3.7,1.8-6.3,5.7-6.6,9.9C46.9,22.1,47.1,22.4,47.4,22.4z"
        />
        <path
          fill="#05A1B0"
          d="M73.5,40.8c-4.6-4.7-11-7.5-17.7-7.7c-2.3-0.1-4.6,0.2-6.8,0.8c-1-1.6-2.3-3.2-3.9-4.8
				c-2.8-2.8-5.8-4.8-8.7-5.9c-0.9-4.5-2.8-8.8-5.4-12.6c-0.3-0.5-0.9-0.6-1.4-0.2c-0.5,0.3-0.6,0.9-0.2,1.4
				c2.3,3.3,3.9,6.9,4.8,10.7c-0.9-0.2-1.7-0.3-2.5-0.3c-1.7,0-3.1,0.5-4.2,1.5c-0.5,0.5-0.9,1.1-1.1,1.8L7,59.1
				c-1.3,1.5-0.6,4.2,1.7,6.5c1,1,2.2,1.8,3.4,2.1C12.6,68,13,68,13.4,68c0.7,0,1.3-0.2,1.8-0.6l33.6-19.5c0.7-0.2,1.3-0.6,1.8-1.1
				c2.4-2.4,1.8-7.1-1-11.9c1.9-0.4,3.8-0.7,5.7-0.7c0.2,0,0.3,0,0.5,0c6.3,0.1,12.5,2.8,17,7.4c0.1,0.1,0.2,0.2,0.4,0.2
				c0.1,0,0.3,0,0.3-0.1C73.7,41.4,73.7,41,73.5,40.8z M40.2,50.6l-22.9-5.2l3.7-6.3l12.7,2.9c3.6,3.4,7.5,5.5,10.8,6.1L40.2,50.6z
				 M38,51.9L16.4,47l0.4-0.7l22.1,5L38,51.9z M28.2,57.6l-8.5-1.9c-0.3-0.1-0.5,0.1-0.6,0.4c-0.1,0.3,0.1,0.5,0.4,0.6l7.4,1.7
				l-2.6,1.5c-0.1-0.1-0.2-0.2-0.4-0.2l-13.2-3l4.6-7.8l20.1,4.6L28.2,57.6z M21.5,38.2l1-1.8l2,0.5c0,0,0.1,0,0.1,0
				c0.2,0,0.4-0.2,0.5-0.4c0.1-0.3-0.1-0.5-0.4-0.6l-1.7-0.4l3.2-5.6c0.6,3.3,2.7,7.2,6,10.7L21.5,38.2z M14.2,65.7
				c-0.1,0-0.1,0.1-0.2,0.2c-0.2,0.2-0.7,0.2-1.3,0c-0.8-0.3-1.8-0.9-2.5-1.6c-1.6-1.6-2.1-3.4-1.6-3.8c0.1-0.1,0.1-0.1,0.2-0.2
				l1.1-1.8l12.2,2.8L14.2,65.7z M49.1,45.3c-0.6,0.6-1.5,0.9-2.7,0.9c-3.4,0-8.1-2.5-11.9-6.3c-5.9-5.9-7.6-12.4-5.4-14.6
				c0.6-0.6,1.5-0.9,2.7-0.9c0.9,0,1.9,0.2,3,0.5c0.4,2.4,0.5,4.9,0.4,7.3c0,0.6,0.4,1,0.9,1.1c0,0,0,0,0.1,0c0.5,0,1-0.4,1-0.9
				c0.1-2.2,0.1-4.4-0.2-6.6c2.3,1.1,4.7,2.8,6.8,4.9c1.3,1.3,2.4,2.6,3.3,3.9c-3.4,1.2-6.5,3-9.1,5.5c-0.2,0.2-0.2,0.5,0,0.7
				c0.2,0.2,0.5,0.2,0.7,0c2.6-2.4,5.7-4.2,9-5.3C50.2,39.8,50.7,43.7,49.1,45.3z"
        />
        <path
          fill="#05A1B0"
          d="M40.9,8.8c0.5-0.9,1-1.7,2.2-2c0,0-2.4-3.9-3.8-3.5c0.4,1.4-0.2,2.4-0.2,2.4S40.5,7.4,40.9,8.8z"
        />
        <path
          fill="#05A1B0"
          d="M55.9,47.4c-0.5,1.9-2,2.8-2,2.8s0.4,3-0.1,5c1.2-0.7,2.5-1.4,4.2-0.7C57.9,54.5,57.8,48,55.9,47.4z"
        />
        <path
          fill="#05A1B0"
          d="M39.1,59.2c0,0-3.8-0.1-4.2,1.1c1.1,0.4,1.6,1.2,1.6,1.2s1.8-0.2,2.9,0.2C39.1,61,38.7,60.2,39.1,59.2z"
        />
        <path
          fill="#05A1B0"
          d="M57.8,28.9c2.2,0,4-1.8,4-4c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4C53.8,27.1,55.6,28.9,57.8,28.9z M57.8,22.9
				c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2s-2-0.9-2-2C55.8,23.8,56.7,22.9,57.8,22.9z"
        />
        <circle fill="#05A1B0" cx="20.7" cy="20.7" r="1" />
        <circle fill="#05A1B0" cx="62.7" cy="12.7" r="1" />
      </g>
    </g>
  );
  return (
    <svg
      width="80px"
      height="80px"
      viewBox="0 0 80 80"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {icon}
    </svg>
  );
};
