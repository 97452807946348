import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOpenSourceLicense } from '../actions/license';
import { ApplicationState } from '../reducers';

const useGetOpenSourceLicense = () => {
  const dispatch = useDispatch();

  const markdown = useSelector(
    ({ license }: ApplicationState) => license.content
  );

  useEffect(() => {
    if (!!!markdown.isLoading && markdown.text === '') {
      dispatch(getOpenSourceLicense());
    }
  }, [dispatch, markdown]);

  return markdown;
};

export default useGetOpenSourceLicense;
