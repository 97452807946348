import { Card, Loader } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import IconChevronRightTiny from '../../components/icons/ui/ChevronRightTiny';
import NotificationIcon from '../../components/icons/ui/NotificationsShade';
import styles from './CardStyles.module.css';

type Props = {
  isLoading: boolean;
  notifications: any;
  onClickTotalNotifications: () => void;
};

const UnseenNotifications = ({
  isLoading,
  notifications,
  onClickTotalNotifications,
}: Props) => {
  return (
    <Card
      onClick={() => {
        onClickTotalNotifications();
      }}
      className={styles.cursorPointer}
    >
      <div className={styles.content}>
        <div className={styles.row}>
          <h6 className={styles.title}>
            Total Notifications <br />
            last 30 days
          </h6>
          <div style={{ color: 'var(--accent-base)' }}>
            <IconChevronRightTiny size={16} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.bigNumber}>
            {isLoading ? <Loader /> : notifications.length}
          </div>
          <div style={{ color: 'var(--accent-basePlus25)' }}>
            <NotificationIcon />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default UnseenNotifications;
